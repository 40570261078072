import {
    CardCollection,
    DateIO,
    eachArray,
    eq,
    Instance,
    Knot,
    noop,
    Objekt,
    Promize,
    Query,
    Table,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { AssetService } from '../services/assetService';
import { GoogleChartService } from '../services/googleChartService';
import { HuntService } from '../services/huntService';
import { IndividualHuntService } from '../services/individualHuntService';
import { LanguageService } from '../services/languageService';
import { OrganizationService } from '../services/organizationService';
import { SocialHuntService } from '../services/socialHuntService';
import { UserService } from '../services/userService';
import { UtilService } from '../services/utilService';
import { DashboardController } from './dashboardController';

export class HuntsIndexByUserController extends DashboardController {
    userId: string;
    huntCardCollection: CardCollection;
    individualHuntsTable: Table;

    constructor(
        instances: Instance,
        private huntService: HuntService,
        private socialHuntService: SocialHuntService,
        private individualHuntService: IndividualHuntService,
        private googleChartService: GoogleChartService,
        private organizationService: OrganizationService,
        private userService: UserService,
        private utilService: UtilService,
        private assetService: AssetService,
        private languageService: LanguageService,
    ) {
        super(instances);
    }

    protected override _initLayout(): void {
        this.userId = this.userService.getUser('id');

        this._initToolbarButtons();
        this._initFilterForm();
        this._initTabPanel('hunts');

        this._drawContent();
    }

    private _initToolbarButtons(): void {
        this.helper.iconButton(
            '.btn-site-home',
            this.dom,
            () => {
                this.state.goBack('site.home');
            },
            '',
            true,
            [],
        );
    }

    private _initHuntCardCollection(): void {
        if (eq(this.activeTab, 'social')) {
            if (!this.huntCardCollection) {
                this.huntCardCollection = new CardCollection(
                    this.dom,
                    '.hunt-collection',
                    {
                        formatStartAt: this.formatStartAt.bind(this),
                    },
                    {
                        no_content: {
                            image_url: this.assetService.getPath(
                                'images/others/no-content.png',
                            ),
                            text: this.languageService.translate(
                                'text.no_content',
                            ),
                        },
                        row_count: 12,
                        sort: {
                            column: 'start_at',
                            order: 'desc',
                        },
                    },
                );
                this.huntCardCollection.eventCardKnot =
                    this._huntCardKnot.bind(this);
                this.huntCardCollection.eventAction = (params) => {
                    params.set('query', this.query);
                    params.set('organization_ids', this.organizationIds);
                    params.set(
                        'fields',
                        'id,title,start_at,location,photo,' +
                            this.organizationService.getAssociationTableFields(),
                    );
                    this.socialHuntService
                        .getAllByUser(this.userId, params)
                        .then((response) => {
                            const count = response.get<number>('count');
                            this.huntCardCollection.setCount(count);
                            const socialHunts =
                                response.get<Objekt[]>('social_hunts');
                            this.huntCardCollection.setData(socialHunts);
                        });
                };

                this.huntCardCollection.render();
            } else {
                this.huntCardCollection.refresh(1);
            }
        }
    }

    private _initHuntTable(): void {
        if (eq(this.activeTab, 'individual')) {
            if (!this.individualHuntsTable) {
                this.individualHuntsTable = new Table(
                    this.dom,
                    '.individual-hunts-table',
                    this.individualHuntService.getTableOptions(),
                );

                this.individualHuntsTable.setActions([
                    {
                        style: (hunt) => {
                            return [
                                'info',
                                hunt.get('description'),
                                false,
                                !hunt.get('description'),
                            ];
                        },
                        click: noop(),
                    },
                ]);

                this.individualHuntsTable.eventAction = (params) => {
                    params.set('query', this.query);
                    params.set('organization_ids', this.organizationIds);
                    params.set(
                        'fields',
                        this.individualHuntService.getTableFields(),
                    );
                    this.individualHuntService
                        .getAllByUser(this.userId, params)
                        .then((response) => {
                            const count = response.get<number>('count');
                            this.individualHuntsTable.setCount(count);
                            const individualHunts =
                                response.get<Objekt[]>('individual_hunts');
                            this.individualHuntsTable.setData(individualHunts);
                        });
                };

                this.individualHuntsTable.render();
            } else {
                this.individualHuntsTable.refresh();
            }
        }
    }

    protected override _drawContent(): Promize | boolean {
        this._initHuntChart();
        this._initHuntCardCollection();
        this._initHuntTable();
        return true;
    }

    private _initHuntChart(): void {
        if (eq(this.activeTab, 'hunts')) {
            this.googleChartService.loadSDK(['corechart', 'bar']).then(() => {
                const chartKnot = new Query('.chart', this.dom).getKnot();

                const chart = new google.visualization.ColumnChart(
                    chartKnot.getNode(),
                );
                const chartOptions: google.visualization.ColumnChartOptions = {
                    // width: '100%',
                    height: 460,
                    legend: { position: 'bottom' },
                    colors: ['#795548', '#FF9800'],
                    isStacked: true,
                };

                this.huntService
                    .getAllByUser(this.userId, {
                        fields: 'id,start_at,end_at,class_type',
                        column: 'start_at',
                        order: 'asc',
                        limit: 100,
                    })
                    .then((response) => {
                        const hunts = response.get<Objekt[]>('hunts');
                        const values = {};
                        let startYear = null;
                        let endYear = null;
                        let startWeek = null;
                        let endWeek = null;
                        eachArray(hunts, (hunt, _index) => {
                            const typeIndex =
                                hunt.get('class_type') === 'individual_hunt'
                                    ? 0
                                    : 1;
                            const startAt = DateIO.parse(hunt.get('start_at'));
                            const year = DateIO.getYear(startAt);
                            const week = DateIO.getWeek(startAt);
                            if (startYear === null || startYear > year) {
                                startYear = year;
                                startWeek = week;
                            }
                            if (endYear === null || endYear < year) {
                                endYear = year;
                                endWeek = week;
                            }
                            const key = this.languageService.format(
                                startAt,
                                'format.week',
                            );
                            if (!values[key]) {
                                values[key] = [0, 0];
                            }
                            values[key][typeIndex]++;
                        });

                        const rows = [];
                        if (startYear === null || endYear === null) {
                            return;
                        }
                        for (let i = startYear; i <= endYear; i++) {
                            const startJ =
                                i === startYear
                                    ? startWeek
                                    : DateIO.getISOWeek(
                                          DateIO.parse(
                                              '01-01-' + i,
                                              'MM-DD-YYYY',
                                          ),
                                      );
                            const endJ =
                                i === endYear
                                    ? endWeek
                                    : DateIO.getISOWeek(
                                          DateIO.parse(
                                              '12-30-' + i,
                                              'MM-DD-YYYY',
                                          ),
                                      );
                            for (let j = startJ; j <= endJ; j++) {
                                const key = this.languageService.format(
                                    DateIO.parse(`${i}-${j}`, 'YYYY-W'),
                                    'format.week',
                                );
                                if (!values[key]) {
                                    values[key] = [0, 0];
                                }
                                rows.push([key].concat(values[key]));
                            }
                        }

                        const data = new google.visualization.DataTable();
                        data.addColumn('string', '');
                        data.addColumn(
                            'number',
                            this.languageService.translate(
                                'captions.individual_hunts.my_index',
                            ),
                        );
                        data.addColumn(
                            'number',
                            this.languageService.translate(
                                'captions.social_hunts.my_index',
                            ),
                        );
                        eachArray(rows, (row) => {
                            data.addRow(row);
                        });
                        chart.draw(data, chartOptions);
                    });
            });
        }
    }

    private _huntCardKnot(cardKnot: Knot, hunt: Objekt): void {
        this._initHuntShowButton(cardKnot, hunt);
        this._initCardButtons(cardKnot, hunt);
        this.utilService.initOrganizationLink(cardKnot, hunt);
    }

    private _initHuntShowButton(cardKnot: Knot, hunt: Objekt): void {
        this.helper.link(
            'a.card',
            cardKnot,
            (href) => {
                this.state.go(href);
            },
            this.state.resolveUrl('hunts.show', {
                huntId: hunt.get('id'),
            }),
        );
    }

    private _initCardButtons(cardKnot: Knot, hunt: Objekt): void {
        // let actionsKnot = new Query('.actions', cardKnot).getKnot();
    }

    formatStartAt(startAt: string): string {
        return this.languageService.format(
            DateIO.parse(startAt),
            'format.datetime',
        );
    }

    exit(): void {
        super.exit();

        this.googleChartService.reset();
    }
}

export const huntsIndexByUserController = app.controller(
    resources.huntsIndexByUserController,
    [
        resources.instances,
        resources.huntService,
        resources.socialHuntService,
        resources.individualHuntService,
        resources.googleChartService,
        resources.organizationService,
        resources.userService,
        resources.utilService,
        resources.assetService,
        resources.languageService,
    ],
    HuntsIndexByUserController,
);
