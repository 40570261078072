import {
    Form,
    Instance,
    Knot,
    LocationField,
    TableCalculation,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { AdminService } from './adminService';
import { AssetService } from './assetService';
import { LanguageService } from './languageService';
import { MapService } from './mapService';

export class AdminSponsorService extends AdminService {
    constructor(
        instances: Instance,
        $languageService: LanguageService,
        $assetService: AssetService,
        private mapService: MapService,
    ) {
        super(
            'sponsor-sponsors-name',
            instances,
            $languageService,
            $assetService,
        );
    }

    handleForm(form: Form, opt_entityName?: string): void {
        const locationField =
            form.findByModel<LocationField>('sponsor.location');

        this.handleLocationField(this.mapService, locationField);
    }

    getTableOptions(): object {
        return {
            no_content: {
                image_url: this.assetService.getPath(
                    'images/others/no-content.png',
                ),
                text: this.languageService.translate('text.no_content'),
            },
            sort: {
                column: 'title',
                order: 'asc',
            },
            row_count: 12,
            column: 'title',
            columns: [
                'logo',
                'title',
                'category_text',
                'url',
                'active',
                'actions',
            ],
            calculations: this.getTableCalculations(),
        };
    }

    getTableFields(): string {
        return 'id,logo,title,category_text,url,active';
    }

    getTableCalculations(): TableCalculation {
        return {
            logo: (sponsor) => {
                const imageWrapper = new Knot('div');
                imageWrapper.addClass('img-wrapper');
                const imageKnot = new Knot('img');
                imageKnot.addClass('img-responsive');
                imageKnot.setAttribute('src', sponsor.get('logo.thumb.url'));
                imageWrapper.appendChild(imageKnot);
                return imageWrapper;
            },
            url: (sponsor) => {
                const url = sponsor.get<string>('url');
                const linkKnot = new Knot('a');
                linkKnot.setHtml(url);
                linkKnot.setAttribute('href', url);
                linkKnot.setAttribute('target', '_blank');
                return linkKnot;
            },
            active: (sponsor) => {
                const active = sponsor.get('active');
                const iconKnot = new Knot('em');
                iconKnot.addClass(['material-icons', 'text-success']);
                iconKnot.setHtml('check_circle');
                return active ? iconKnot : '';
            },
        };
    }
}

export const adminSponsorService = app.service(
    resources.adminSponsorService,
    [
        resources.instances,
        resources.languageService,
        resources.assetService,
        resources.mapService,
    ],
    AdminSponsorService,
);
