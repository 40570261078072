import { actionCableService } from './services/actionCableService';
import { adminChamberService } from './services/adminChamberService';
import { adminCountryService } from './services/adminCountryService';
import { adminCouponService } from './services/adminCouponService';
import { adminGameService } from './services/adminGameService';
import { adminOpenSeasonService } from './services/adminOpenSeasonService';
import { adminOrderService } from './services/adminOrderService';
import { adminOrganizationService } from './services/adminOrganizationService';
import { adminSponsorService } from './services/adminSponsorService';
import { adminSubscriberService } from './services/adminSubscriberService';
import { adminUserService } from './services/adminUserService';
import { adminWildService } from './services/adminWildService';
import { appleAuthService } from './services/appleAuthService';
import { appService } from './services/appService';
import { areaService } from './services/areaService';
import { assetService } from './services/assetService';
import { authService } from './services/authService';
import { bannerService } from './services/bannerService';
import { bigGameTrophyService } from './services/bigGameTrophyService';
import { budgetService } from './services/budgetService';
import { buildingService } from './services/buildingService';
import { categoryService } from './services/categoryService';
import { chamberService } from './services/chamberService';
import { configService } from './services/configService';
import { countryService } from './services/countryService';
import { couponService } from './services/couponService';
import { deviceService } from './services/deviceService';
import { discoveryService } from './services/discoveryService';
import { districtService } from './services/districtService';
import { earTagService } from './services/earTagService';
import { eventService } from './services/eventService';
import { facebookPixelService } from './services/facebookPixelService';
import { facebookService } from './services/facebookService';
import { feedbackService } from './services/feedbackService';
import { footerService } from './services/footerService';
import { googleAnalytics4Service } from './services/googleAnalytics4Service';
import { googleCaptchaService } from './services/googleCaptchaService';
import { googleChartService } from './services/googleChartService';
import { googleIdentityService } from './services/googleIdentityService';
import { googleTagManagerService } from './services/googleTagManagerService';
import { groupService } from './services/groupService';
import { guestService } from './services/guestService';
import { headerService } from './services/headerService';
import { hunterTicketService } from './services/hunterTicketService';
import { huntingPackagePreyService } from './services/huntingPackagePreyService';
import { huntingPackageService } from './services/huntingPackageService';
import { huntService } from './services/huntService';
import { individualHuntService } from './services/individualHuntService';
import { itemService } from './services/itemService';
import { journeyService } from './services/journeyService';
import { languageService } from './services/languageService';
import { layoutService } from './services/layoutService';
import { leftMenuService } from './services/leftMenuService';
import { licenseService } from './services/licenseService';
import { locationService } from './services/locationService';
import { mapService } from './services/mapService';
import { memberService } from './services/memberService';
import { membershipService } from './services/membershipService';
import { menuService } from './services/menuService';
import { militaryService } from './services/militaryService';
import { navBarService } from './services/navBarService';
import { noteService } from './services/noteService';
import { notificationHistoryService } from './services/notificationHistoryService';
import { notificationService } from './services/notificationService';
import { oauth2Service } from './services/oauth2Service';
import { observationService } from './services/observationService';
import { openSeasonService } from './services/openSeasonService';
import { orderService } from './services/orderService';
import { organizationService } from './services/organizationService';
import { organizationUserService } from './services/organizationUserService';
import { pageHeaderService } from './services/pageHeaderService';
import { pageService } from './services/pageService';
import { personService } from './services/personService';
import { planPreyService } from './services/planPreyService';
import { planService } from './services/planService';
import { preyService } from './services/preyService';
import { reportBuildingService } from './services/reportBuildingService';
import { reportPersonService } from './services/reportPersonService';
import { reportService } from './services/reportService';
import { reportTrophyService } from './services/reportTrophyService';
import { securityService } from './services/securityService';
import { siteService } from './services/siteService';
import { smallGameTrophyService } from './services/smallGameTrophyService';
import { socialHuntService } from './services/socialHuntService';
import { subscriberService } from './services/subscriberService';
import { tokenService } from './services/tokenService';
import { topMenuService } from './services/topMenuService';
import { trophyService } from './services/trophyService';
import { userService } from './services/userService';
import { utilService } from './services/utilService';
import { vehicleService } from './services/vehicleService';
import { wildService } from './services/wildService';

export const services = [
    actionCableService,
    adminChamberService,
    adminCountryService,
    adminCouponService,
    adminGameService,
    adminOpenSeasonService,
    adminOrderService,
    adminOrganizationService,
    adminSponsorService,
    adminSubscriberService,
    adminUserService,
    adminWildService,
    appleAuthService,
    appService,
    areaService,
    assetService,
    authService,
    bannerService,
    bigGameTrophyService,
    budgetService,
    buildingService,
    categoryService,
    chamberService,
    configService,
    countryService,
    couponService,
    deviceService,
    discoveryService,
    districtService,
    earTagService,
    eventService,
    facebookPixelService,
    facebookService,
    feedbackService,
    footerService,
    googleAnalytics4Service,
    googleCaptchaService,
    googleChartService,
    googleIdentityService,
    googleTagManagerService,
    groupService,
    guestService,
    headerService,
    hunterTicketService,
    huntingPackagePreyService,
    huntingPackageService,
    huntService,
    individualHuntService,
    itemService,
    journeyService,
    languageService,
    layoutService,
    leftMenuService,
    licenseService,
    locationService,
    mapService,
    memberService,
    membershipService,
    menuService,
    militaryService,
    navBarService,
    noteService,
    notificationHistoryService,
    notificationService,
    oauth2Service,
    observationService,
    openSeasonService,
    orderService,
    organizationService,
    organizationUserService,
    pageHeaderService,
    pageService,
    personService,
    planPreyService,
    planService,
    preyService,
    reportBuildingService,
    reportPersonService,
    reportService,
    reportTrophyService,
    securityService,
    siteService,
    smallGameTrophyService,
    socialHuntService,
    subscriberService,
    tokenService,
    topMenuService,
    trophyService,
    userService,
    utilService,
    vehicleService,
    wildService,
];
