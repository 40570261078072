import { Instance, Knot, TableCalculation } from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { AdminService } from './adminService';
import { AssetService } from './assetService';
import { LanguageService } from './languageService';

export class AdminWildService extends AdminService {
    constructor(
        instances: Instance,
        $languageService: LanguageService,
        $assetService: AssetService,
    ) {
        super('wild-wilds-name', instances, $languageService, $assetService);
    }

    getTableOptions(): object {
        return {
            no_content: {
                image_url: this.assetService.getPath(
                    'images/others/no-content.png',
                ),
                text: this.languageService.translate('text.no_content'),
            },
            sort: {
                column: 'name',
                order: 'asc',
            },
            row_count: 12,
            columns: [
                'name',
                'gender',
                'animal.name',
                'country.name',
                'actions',
            ],
            calculations: this.getTableCalculations(),
        };
    }

    getTableFields(): string {
        return ['id', 'name', 'gender', 'animal.name', 'country.name'].join(
            ',',
        );
    }

    getTableCalculations(): TableCalculation {
        return {
            gender: (wild) => {
                let gender = wild.get<string>('gender');
                const iconKnot = new Knot('em');
                if (gender === 'mixed') {
                    gender = 'transgender';
                }
                iconKnot.addClass(['material-icons']);
                iconKnot.setHtml(gender);
                return iconKnot;
            },
        };
    }
}

export const adminWildService = app.service(
    resources.adminWildService,
    [resources.instances, resources.languageService, resources.assetService],
    AdminWildService,
);
