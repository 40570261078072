import { Form, Instance, LocationField, Objekt } from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { AssetService } from './assetService';
import { LanguageService } from './languageService';
import { MapService } from './mapService';
import { ModelService } from './modelService';

export class VehicleService extends ModelService {
    constructor(
        instances: Instance,
        $languageService: LanguageService,
        $assetService: AssetService,
        private mapService: MapService,
    ) {
        super(
            'vehicle-vehicles-name',
            instances,
            $languageService,
            $assetService,
        );
    }

    getAllByOrganization(organizationId: string, opt_params?: object) {
        return this.getAllBy('organizations', organizationId, opt_params);
    }

    createByOrganization(organizationId: string, vehicle: Objekt) {
        return this.createBy('organizations', organizationId, vehicle);
    }

    handleForm(form: Form, opt_entityName?: string): void {
        const locationField =
            form.findByModel<LocationField>('vehicle.location');

        this.handleLocationField(this.mapService, locationField);
    }
}

export const vehicleService = app.service(
    resources.vehicleService,
    [
        resources.instances,
        resources.languageService,
        resources.assetService,
        resources.mapService,
    ],
    VehicleService,
);
