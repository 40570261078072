import {
    Cookie,
    Depot,
    EventBus,
    Flash,
    Http,
    Instance,
    Objekt,
    Page,
    Query,
    Service,
    State,
    capitalize,
    inArray,
    isNull,
    neq,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { ConfigService } from './configService';
import { LanguageService } from './languageService';

export class AppService extends Service {
    eventBus: EventBus;
    page: Page;
    http: Http;
    flash: Flash;
    localDepot: Depot;
    sessionDepot: Depot;
    cookie: Cookie;
    state: State;

    constructor(
        instances: Instance,
        private languageService: LanguageService,
        private configService: ConfigService,
    ) {
        super();

        this.eventBus = instances.eventBus;
        this.page = instances.page;
        this.http = instances.http;
        this.flash = instances.flash;
        this.localDepot = instances.localDepot;
        this.sessionDepot = instances.sessionDepot;
        this.cookie = instances.cookie;
        this.state = instances.state;
    }

    enter(): void {
        this.eventBus.set('state.change', (state) => {
            if (
                neq(state.get('id'), 'site.maintenance') &&
                this.configService.get('api.maintenance') &&
                inArray(['site.landing-page'], state.get('id'))
            ) {
                this.state.go('site.maintenance');
                return false;
            }
            this._setTitle(state);
        });

        this.eventBus.set('geoLocation.success', (_message) => {
            // let content = this.languageService.translate('text.geo_location.success', message);
            // this.flash.addInfo(content, 0, null, 'GEO_LOCATION');
        });

        this.eventBus.set('geoLocation.error', (message, code) => {
            const content = this.languageService.translate(
                'text.geo_location.' + code,
                message,
            );
            this.flash.addError(content, 0, null, 'GEO_LOCATION');
        });

        this.eventBus.set('window.offline', (notification) => {
            const content = this.languageService.translate(
                'text.window.offline',
                notification.message,
            );
            notification.node = this.flash.addWarning(
                content,
                notification.duration,
            );
        });

        this.eventBus.set('module.failed', () => {
            // let message = this.languageService.translate('text.module.failed');
            // this.flash.addWarning(message);
        });

        this.eventBus.set('module.serviceLoaded', () => {
            this._removeNoJs();
        });

        this._checkVersion();
    }

    private _setTitle(state: Objekt): void {
        const stateTitle = capitalize(
            this.languageService.translate(state.get('title')),
        );
        const appTitle = this.languageService.translate('application.name');
        const documentTitle = [stateTitle, appTitle].join(' - ');
        this.page.setTitle(documentTitle);
    }

    private _removeNoJs(): void {
        const rootKnot = new Query('html').getKnot();
        rootKnot.removeClass('no-js');
    }

    private _checkVersion(): void {
        const oldVersion = this.localDepot.get('api.version');
        const newVersion = this.getVersion();
        if (isNull(oldVersion)) {
            this._setVersion(newVersion);
        } else if (oldVersion !== newVersion) {
            this.cookie.clear();
            this.localDepot.clear();
            this.sessionDepot.clear();
        }
    }

    private _setVersion(version: string): void {
        this.localDepot.set('api.version', version);
    }

    getVersion(): string {
        return this.configService.get('api.metadata.version');
    }

    getCodename(): string {
        return this.configService.get('api.metadata.codename');
    }

    getHostname(): string {
        return this.configService.get('hostname');
    }
}

export const appService = app.service(
    resources.appService,
    [resources.instances, resources.languageService, resources.configService],
    AppService,
);
