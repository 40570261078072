import { Collection, Helper, Knot, Objekt, Promize } from '@siposdani87/sui-js';
import { Component, createSignal, onMount } from 'solid-js';
import { LanguageService } from '../services/languageService';

export const UsersOrganizationConnectionsComponent: Component<{
    eventDecline: (organization: Objekt) => Promize;
    eventAccept: (organization: Objekt) => Promize;
    $helper: Helper;
    organizationCollection: Collection<Objekt>;
    allowAccept: boolean;
    $languageService: LanguageService;
}> = (props) => {
    const [_counter, setCounter] = createSignal<number>(0);
    let listRef: HTMLDivElement;

    const _declineClick = (organization: Objekt): void => {
        props.eventDecline(organization).then(() => {
            setCounter((c) => c + 1);
        });
    };

    const _acceptClick = (organization: Objekt): void => {
        props.eventAccept(organization).then(() => {
            setCounter((c) => c + 1);
        });
    };

    const _initButtons = (dom: Knot): void => {
        props.$helper.multipleIconButton('.btn-accept', dom, [
            'mdl-button--primary',
            'mdl-button--fab',
            'mdl-button--mini-fab',
        ]);
        props.$helper.multipleIconButton('.btn-decline', dom);
        props.$helper.multipleIconButton('.btn-delete', dom);
    };

    onMount(() => {
        const listKnot = new Knot(listRef);
        _initButtons(listKnot);
    });

    return (
        <div class="mdl-list" ref={listRef}>
            {props.organizationCollection.getItems().map((organization) => (
                <div
                    class="mdl-list__item"
                    data-key={organization.get<string>('id')}
                >
                    <span class="mdl-list__item-primary-content">
                        <img
                            class="mdl-list__item-avatar"
                            src={organization.get('image.thumb.url')}
                            alt={organization.get('name')}
                        />
                        <span>{organization.get('name')}</span>
                    </span>
                    <span class="mdl-list__item-secondary-action">
                        {props.allowAccept && (
                            <button
                                onClick={() => _acceptClick(organization)}
                                class="btn-accept"
                                title={props.$languageService.translate(
                                    'buttons.accept',
                                )}
                            >
                                <em class="material-icons">done</em>
                            </button>
                        )}
                        {props.allowAccept && (
                            <button
                                onClick={() => _declineClick(organization)}
                                class="btn-decline"
                                title={props.$languageService.translate(
                                    'buttons.decline',
                                )}
                            >
                                <em class="material-icons">close</em>
                            </button>
                        )}
                        {!props.allowAccept && (
                            <button
                                onClick={() => _declineClick(organization)}
                                class="btn-delete"
                                title={props.$languageService.translate(
                                    'buttons.delete',
                                )}
                            >
                                <em class="material-icons">delete</em>
                            </button>
                        )}
                    </span>
                </div>
            ))}
        </div>
    );
};
