import { Form, Http, Instance, Objekt, Service } from '@siposdani87/sui-js';

export type EntityConfig = string;

export class EntityService extends Service {
    entityName: string;
    controllerName: string;
    nameField: string;
    http: Http;

    constructor(entityConfig: EntityConfig, instances: Instance) {
        super();

        const [entityName, controllerName, nameField] = entityConfig.split('-');
        this.entityName = entityName;
        this.controllerName = controllerName;
        this.nameField = nameField;

        this.http = instances.http;
    }

    protected _getPath(): string {
        return this.controllerName.replace(/_/g, '-');
    }

    protected _getId(entity: Objekt): string {
        return entity.get('id');
    }

    public handleForm(form: Form, opt_entityName?: string): void {
        // empty method
    }
}
