import {
    Deferred,
    EventBus,
    Instance,
    Script,
    Service,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { ConfigService } from './configService';
import { LanguageService } from './languageService';

export class GoogleTagManagerService extends Service {
    script: Script;
    eventBus: EventBus;

    constructor(
        instances: Instance,
        private languageService: LanguageService,
        private configService: ConfigService,
    ) {
        super();

        this.script = instances.script;
        this.eventBus = instances.eventBus;
    }

    enter(): void {
        this._loadSDK();
    }

    private _loadSDK() {
        const deferred = new Deferred();
        const tagManagerId = this.configService.get('google.tag_manager_id');

        window['dataLayer'] = window['dataLayer'] || [];
        window['dataLayer'].push({
            'gtm.start': new Date().getTime(),
            event: 'gtm.js',
        });

        this.script
            .load(
                'google-tag-manager',
                'https://www.googletagmanager.com/gtm.js',
                {
                    id: tagManagerId,
                    l: 'dataLayer',
                },
            )
            .then(
                () => {
                    deferred.resolve();
                },
                () => {
                    // If bocked by AdBlocker
                    deferred.reject();
                },
            );
        return deferred.promise();
    }
}

export const googleTagManagerService = app.service(
    resources.googleTagManagerService,
    [resources.instances, resources.languageService, resources.configService],
    GoogleTagManagerService,
);
