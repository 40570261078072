import {
    Async,
    Deferred,
    eq,
    GoogleMap,
    Instance,
    Knot,
    LatLng,
    noop,
    Objekt,
    pluck,
    Promize,
    Table,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { AreaService } from '../services/areaService';
import { BigGameTrophyService } from '../services/bigGameTrophyService';
import { HuntingPackagePreyService } from '../services/huntingPackagePreyService';
import { LanguageService } from '../services/languageService';
import { MapService } from '../services/mapService';
import { SmallGameTrophyService } from '../services/smallGameTrophyService';
import { TrophyService } from '../services/trophyService';
import { UserService } from '../services/userService';
import { UtilService } from '../services/utilService';
import { DashboardController } from './dashboardController';

export class HuntingPackagePreysShowController extends DashboardController {
    huntingPackageId: string;
    huntingPackagePreyId: string;
    huntingPackagePrey: Objekt;
    btnTrophiesNewKnot: Knot<HTMLElement>;
    trophiesTable: Table;
    hasAccessTrophyEdit: boolean;
    area: Objekt;
    trophies: Objekt[];
    map: GoogleMap;

    constructor(
        instances: Instance,
        private areaService: AreaService,
        private huntingPackagePreyService: HuntingPackagePreyService,
        private trophyService: TrophyService,
        private smallGameTrophyService: SmallGameTrophyService,
        private bigGameTrophyService: BigGameTrophyService,
        private mapService: MapService,
        private userService: UserService,
        private utilService: UtilService,
        private languageService: LanguageService,
    ) {
        super(instances);
    }

    protected override _initLayout(): void {
        this.huntingPackageId = this.state.getParam('huntingPackageId');
        this.huntingPackagePreyId = this.state.getParam('huntingPackagePreyId');
        this.huntingPackagePrey = new Objekt();

        this._initToolbarButtons();
        this._initTabPanel('trophy-heatmap');
        this._changeToolbarButtons();

        this._loadPrey().then(() => {
            this._initAccess();
            this._drawContent();
        });
    }

    private _initToolbarButtons(): void {
        this.helper.iconButton(
            '.btn-plans-show',
            this.dom,
            () => {
                this.state.goBack('plans.show', {
                    planId: this.huntingPackagePrey.get('plan.id'),
                });
            },
            '',
            true,
            [],
        );

        this.btnTrophiesNewKnot = this.helper.iconButton(
            '.btn-trophies-new',
            this.dom,
            () => {
                if (
                    eq(this.huntingPackagePrey.get('classification'), 'small')
                ) {
                    this.smallGameTrophyService
                        .newDialogByHuntingPackagePrey(
                            this.huntingPackagePreyId,
                        )
                        .then(() => {
                            this._drawContent();
                        });
                } else {
                    this.bigGameTrophyService
                        .newDialogByHuntingPackagePrey(
                            this.huntingPackagePreyId,
                        )
                        .then(() => {
                            this._drawContent();
                        });
                }
            },
        );
    }

    private _initTrophyTable(): void {
        if (eq(this.activeTab, 'trophy-table')) {
            if (!this.trophiesTable) {
                this.trophiesTable = new Table(
                    this.dom,
                    '.trophies-table',
                    this.trophyService.getTableOptions(),
                );

                this.trophiesTable.setActions([
                    {
                        style: (trophy) => {
                            return [
                                'info',
                                trophy.get('description'),
                                false,
                                !trophy.get('description'),
                            ];
                        },
                        click: noop(),
                    },
                    {
                        style: () => {
                            return [
                                'edit',
                                this.languageService.translate('buttons.edit'),
                                false,
                                !this.hasAccessTrophyEdit,
                            ];
                        },
                        click: (trophy) => {
                            this.trophyService.editDialog(trophy).then(() => {
                                this.trophiesTable.refresh();
                            });
                        },
                    },
                ]);

                this.trophiesTable.eventAction = (params) => {
                    params.set('fields', this.trophyService.getTableFields());
                    this.trophyService
                        .getAllByHuntingPackageAndHuntingPackagePrey(
                            this.huntingPackageId,
                            this.huntingPackagePreyId,
                            params,
                        )
                        .then((response) => {
                            const count = response.get<number>('count');
                            this.trophiesTable.setCount(count);
                            const trophies = response.get<Objekt[]>('trophies');
                            this.trophiesTable.setData(trophies);
                        });
                };

                this.trophiesTable.render();
            } else {
                this.trophiesTable.refresh();
            }
        }
    }

    private _loadArea(): Promize {
        const deferred = new Deferred();
        this.areaService
            .getAllByOrganization(
                this.huntingPackagePrey.get('plan.organization_id'),
            )
            .then(
                (response) => {
                    this.area = response.get('areas', [])[0] || new Objekt();
                    deferred.resolve();
                },
                () => {
                    deferred.reject();
                },
            );
        return deferred.promise();
    }

    private _loadTrophies(): Promize {
        const deferred = new Deferred();
        this.trophyService
            .getAllByHuntingPackageAndHuntingPackagePrey(
                this.huntingPackageId,
                this.huntingPackagePreyId,
                {
                    fields: 'location',
                },
            )
            .then(
                (response) => {
                    this.trophies = response.get('trophies');
                    deferred.resolve();
                },
                () => {
                    deferred.reject();
                },
            );
        return deferred.promise();
    }

    private _loadPrey(): Promize {
        const deferred = new Deferred();
        this.huntingPackagePreyService
            .getByHuntingPackage(
                this.huntingPackageId,
                this.huntingPackagePreyId,
                {
                    fields: 'id,classification,plan.id,plan.organization_id',
                },
            )
            .then(
                (response) => {
                    this.huntingPackagePrey = response.get(
                        'hunting_package_prey',
                    );
                    deferred.resolve();
                },
                () => {
                    deferred.reject();
                },
            );
        return deferred.promise();
    }

    private _initAccess(): void {
        this.hasAccessTrophyEdit = this.userService.hasAccessByOrganization(
            this.huntingPackagePrey.get('plan.organization_id'),
            'trophies_write',
        );
    }

    protected override _drawContent(): Promize | boolean {
        this._changeToolbarButtons();

        this._initTrophyTable();
        this._initHeatmap();
        return true;
    }

    private _changeToolbarButtons(): void {
        this.utilService.showButton(
            this.btnTrophiesNewKnot,
            this.activeTab === 'trophy-table',
        );
    }

    private _initHeatmap(): void {
        if (eq(this.activeTab, 'trophy-heatmap')) {
            if (!this.map) {
                this.map = this.mapService.createGoogleMap(this.dom, '.map', {
                    mapTypeId: 'custom',
                    mapTypeControlOptions: {
                        mapTypeIds: ['custom', google.maps.MapTypeId.SATELLITE],
                    },
                    mapTypeControl: true,
                    scrollwheel: true,
                });

                this.map.setPolygons(this.mapService.getAreaPolygonOptions());

                this.map.setHeatmap(this.mapService.getHeatMapOptions());
            }

            const async = new Async();
            async
                .parallel([
                    this._loadArea.bind(this),
                    this._loadTrophies.bind(this),
                ])
                .then(() => {
                    this._setMap();
                });
        }
    }

    private _setMap(): void {
        this.map.removeAllPolygon();
        this.map.removeHeatmap();

        const areaId = this.area.get<string>('id');
        const areaPoints = this.area.get<LatLng[]>('locations', []);
        this.map.createPolygon(areaId, '', areaPoints, this.area);
        this.map.fitPolygonToMap(areaId);

        const points = pluck<{ latitude: number; longitude: number }>(
            this.trophies,
            'location',
        );
        this.map.createHeatmap(this.mapService.getWeightedLocations(points));
    }

    exit(): void {
        super.exit();

        this.mapService.removeGoogleMap(this.map);
    }
}

export const huntingPackagePreysShowController = app.controller(
    resources.huntingPackagePreysShowController,
    [
        resources.instances,
        resources.areaService,
        resources.huntingPackagePreyService,
        resources.trophyService,
        resources.smallGameTrophyService,
        resources.bigGameTrophyService,
        resources.mapService,
        resources.userService,
        resources.utilService,
        resources.languageService,
    ],
    HuntingPackagePreysShowController,
);
