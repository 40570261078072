import {
    Controller,
    Helper,
    Http,
    Instance,
    Knot,
    Navigation,
    Query,
    State,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';

import { AssetService } from '../services/assetService';
import { LanguageService } from '../services/languageService';
import { ReportService } from '../services/reportService';

export class ReportsIndexController extends Controller {
    http: Http;
    navigation: Navigation;
    dom: Knot;
    state: State;
    helper: Helper;

    constructor(
        instances: Instance,
        private reportService: ReportService,
        private assetService: AssetService,
        private languageService: LanguageService,
    ) {
        super();

        this.http = instances.http;
        this.dom = instances.dom;
        this.state = instances.state;
        this.helper = instances.helper;
    }

    enter(): void {
        this._initLayout();
    }

    protected _initLayout(): void {
        this._initToolbarButtons();
        this._initMenu();
    }

    private _initToolbarButtons(): void {
        this.helper.iconButton(
            '.btn-site-home',
            this.dom,
            () => {
                this.state.goBack('site.home');
            },
            '',
            true,
            [],
        );
    }

    private _initMenu(): void {
        this.navigation = new Navigation(this.http);

        this.navigation.addImage(
            'person',
            this.assetService.getPath('images/icons/hat.svg'),
            this.languageService.translate('captions.people.index'),
            () => {
                this.reportService.detailsDialog('people');
            },
        );
        this.navigation.addImage(
            'person',
            this.assetService.getPath('images/icons/stand.svg'),
            this.languageService.translate('captions.buildings.index'),
            () => {
                this.reportService.detailsDialog('buildings');
            },
        );
        this.navigation.addImage(
            'person',
            this.assetService.getPath('images/icons/trophy.svg'),
            this.languageService.translate('captions.trophies.index'),
            () => {
                this.reportService.detailsDialog('trophies');
            },
        );

        const menuKnot = new Query('.link-menu', this.dom).getKnot();
        menuKnot.removeChildren();

        this.navigation.each((item) => {
            const node = item.get('node');
            menuKnot.appendChild(node);
        });
    }
}

export const reportsIndexController = app.controller(
    resources.reportsIndexController,
    [
        resources.instances,
        resources.reportService,
        resources.assetService,
        resources.languageService,
    ],
    ReportsIndexController,
);
