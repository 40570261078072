import { DateIO, Instance } from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { AssetService } from './assetService';
import { LanguageService } from './languageService';
import { ModelService } from './modelService';
import { PersonService } from './personService';
import { PlanService } from './planService';

export class HuntingPackageService extends ModelService {
    constructor(
        instances: Instance,
        $languageService: LanguageService,
        $assetService: AssetService,
        private personService: PersonService,
        private planService: PlanService,
    ) {
        super(
            'hunting_package-hunting_packages-title',
            instances,
            $languageService,
            $assetService,
        );
    }

    getAllByOrganization(organizationId, opt_params) {
        return this.getAllBy('organizations', organizationId, opt_params);
    }

    createByOrganization(organizationId, huntingPackage) {
        return this.createBy('organizations', organizationId, huntingPackage);
    }

    handleForm(form, opt_entityName) {
        const peopleField = form.findByModel('hunting_package.person_ids');
        const plansField = form.findByModel('hunting_package.plan_id');
        const organizationField = form.findByModel(
            'hunting_package.organization_id',
        );
        const startAtField = form.findByModel('hunting_package.start_at');
        const endAtField = form.findByModel('hunting_package.end_at');

        organizationField.eventChange = (organizationId) => {
            if (organizationId) {
                peopleField.showLoader();
                this.personService
                    .getAllByOrganization(organizationId, {
                        fields: 'id,name',
                    })
                    .then((response) => {
                        const people = response.get('people');
                        peopleField.setOptions(people, 'id', 'name');
                    });
                plansField.showLoader();
                this.planService
                    .getAllByOrganization(organizationId, {
                        fields: 'id,name',
                    })
                    .then((response) => {
                        const plans = response.get('plans');
                        plansField.setOptions(plans, 'id', 'full_name');
                    });
            } else {
                peopleField.setOptions([]);
                plansField.setOptions([]);
            }
        };

        startAtField.eventChange = (startAt) => {
            const endAt = DateIO.format(
                DateIO.addYears(DateIO.parse(startAt), 1),
            );
            endAtField.setValue(endAt);
        };
    }
}

export const huntingPackageService = app.service(
    resources.huntingPackageService,
    [
        resources.instances,
        resources.languageService,
        resources.assetService,
        resources.personService,
        resources.planService,
    ],
    HuntingPackageService,
);
