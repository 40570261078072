import {
    Confirm,
    Deferred,
    Dialog,
    Flash,
    Form,
    format,
    Helper,
    Instance,
    Knot,
    LocationField,
    Objekt,
    Promize,
    State,
    TableCalculation,
} from '@siposdani87/sui-js';
import { AssetService } from './assetService';
import { EntityConfig, EntityService } from './entityService';
import { LanguageService } from './languageService';
import { LocationService } from './locationService';
import { MapService } from './mapService';

export class ModelService extends EntityService {
    helper: Helper;
    state: State;
    flash: Flash;
    confirm: Confirm;
    dialog: Dialog;

    constructor(
        entityConfig: EntityConfig,
        instances: Instance,
        protected languageService: LanguageService,
        protected assetService: AssetService,
    ) {
        super(entityConfig, instances);

        this.helper = instances.helper;
        this.state = instances.state;

        this.flash = instances.flash;
        this.confirm = instances.confirm;
        this.dialog = instances.dialog;
    }

    getAll(opt_params?: object) {
        return this.http.get(
            format('/api/v1/{0}.json', [this._getPath()]),
            opt_params,
        );
    }

    getAllBy(foreignName: string, foreignId: string, opt_params?: object) {
        return this.http.get(
            format('/api/v1/{0}/{1}/{2}.json', [
                foreignName,
                foreignId,
                this._getPath(),
            ]),
            opt_params,
        );
    }

    get(entityId: string, opt_params?: object) {
        return this.http.get(
            format('/api/v1/{0}/{1}.json', [this._getPath(), entityId]),
            opt_params,
        );
    }

    create(entity: Objekt) {
        return this.http.post(
            format('/api/v1/{0}.json', [this._getPath()]),
            entity,
        );
    }

    createBy(foreignName: string, foreignId: string, entity: Objekt) {
        return this.http.post(
            format('/api/v1/{0}/{1}/{2}.json', [
                foreignName,
                foreignId,
                this._getPath(),
            ]),
            entity,
        );
    }

    update(entity: Objekt) {
        return this.http.put(
            format('/api/v1/{0}/{1}.json', [
                this._getPath(),
                this._getId(entity),
            ]),
            entity,
        );
    }

    remove(entity: Objekt) {
        return this.http.delete(
            format('/api/v1/{0}/{1}.json', [
                this._getPath(),
                this._getId(entity),
            ]),
        );
    }

    getTableOptions(): object {
        return {
            no_content: {
                image_url: this.assetService.getPath(
                    'images/others/no-content.png',
                ),
                text: this.languageService.translate('text.no_content'),
            },
            sort: {
                column: 'created_at',
                order: 'desc',
            },
            row_count: 12,
            columns: ['id', this.nameField],
            calculations: this.getTableCalculations(),
        };
    }

    getTableFields(): string {
        return ['id', this.nameField].join(',');
    }

    getTableCalculations(): TableCalculation {
        return {};
    }

    private _selectCreate(
        formData: Objekt,
        opt_foreignName: string | undefined,
        opt_foreignId: string | undefined,
    ) {
        let foreignId = opt_foreignId;
        if (opt_foreignName && foreignId.indexOf('_id') !== -1) {
            foreignId = formData.get(foreignId);
        }
        const condition = opt_foreignName && foreignId;
        return condition
            ? this.createBy(opt_foreignName, foreignId, formData)
            : this.create(formData);
    }

    newDialogByOrganization(
        organizationId: string,
        opt_data: (Objekt | null) | undefined = null,
    ) {
        return this.newDialogBy('organizations', organizationId, '', opt_data);
    }

    newDialogBy(
        foreignName: string,
        foreignId: string,
        opt_path: string | undefined = '',
        opt_data: (Objekt | null) | undefined = null,
    ) {
        const deferred = new Deferred<Objekt, undefined>();
        const prefix = opt_path ? opt_path : `/${foreignName}/${foreignId}/`;
        this.dialog
            .loadTemplate(
                format('/client/v1{0}{1}/new.html', [prefix, this._getPath()]),
            )
            .then((dialogKnot) => {
                this._newDialogForm(
                    deferred,
                    dialogKnot,
                    (formData) => {
                        return this._selectCreate(
                            formData,
                            foreignName,
                            foreignId,
                        );
                    },
                    opt_data,
                );
            });
        this.dialog.eventCancel = () => {
            deferred.reject();
        };
        return deferred.promise();
    }

    newDialog(
        opt_foreignName?: string,
        opt_foreignId?: string,
        opt_data: (Objekt | null) | undefined = null,
    ) {
        const deferred = new Deferred<Objekt, undefined>();
        this.dialog
            .loadTemplate(format('/client/v1/{0}/new.html', [this._getPath()]))
            .then((dialogKnot) => {
                this._newDialogForm(
                    deferred,
                    dialogKnot,
                    (formData) => {
                        return this._selectCreate(
                            formData,
                            opt_foreignName,
                            opt_foreignId,
                        );
                    },
                    opt_data,
                );
            });
        this.dialog.eventCancel = () => {
            deferred.reject();
        };
        return deferred.promise();
    }

    private _newDialogForm(
        deferred: Deferred<Objekt, Objekt>,
        dialogKnot: Knot,
        createMethod: (
            arg0: Objekt,
        ) => Promize<[Objekt, string], [Objekt, string]>,
        opt_data: (Objekt | null) | undefined = null,
    ): void {
        const form = new Form(dialogKnot);
        if (opt_data) {
            form.setModel(opt_data);
        }
        form.eventSubmit = (formData) => {
            form.lock();
            const data = formData.get<Objekt>(this.entityName);
            createMethod(data).then(
                (response) => {
                    this.flash.addMessage(response.get('message'));
                    this.dialog.close();
                    deferred.resolve(response.get(this.entityName));
                },
                (response) => {
                    this.flash.addMessage(response.get('message'));
                    form.unlock();
                    form.setErrors(response.get('errors'));
                },
            );
        };
        form.eventReset = () => {
            this.dialog.close();
            deferred.reject();
        };

        this.handleForm(form);
        this.dialog.open();
    }

    editDialog(
        entity: Objekt,
        opt_path: string | undefined = '',
        opt_entityName: string | undefined = '',
    ) {
        const deferred = new Deferred<[Objekt, boolean], undefined>();
        const entityName =
            entity.get<string>('class_type', opt_entityName) || this.entityName;
        const prefix = opt_path ? opt_path : '/';
        this.dialog
            .loadTemplate(
                format('/client/v1{0}{1}/{2}/edit.html', [
                    prefix,
                    this._getPath(),
                    this._getId(entity),
                ]),
            )
            .then((dialogKnot) => {
                const form = new Form(dialogKnot);
                const model = new Objekt();
                model.setRaw(entityName, entity);
                form.setModel(model);
                form.eventSubmit = (formData) => {
                    form.lock();
                    const data = formData.get<Objekt>(entityName);
                    this.update(data).then(
                        (response) => {
                            this.flash.addMessage(response.get('message'));
                            this.dialog.close();
                            deferred.resolve(response.get(this.entityName));
                        },
                        (response) => {
                            this.flash.addMessage(response.get('message'));
                            form.unlock();
                            form.setErrors(response.get('errors'));
                        },
                    );
                };

                form.eventButton = () => {
                    form.lock();
                    this.removeConfirm(entity).then(
                        (data) => {
                            form.unlock();
                            this.dialog.close();
                            deferred.resolve([data, true]);
                        },
                        () => {
                            form.unlock();
                        },
                    );
                };

                form.eventReset = () => {
                    deferred.reject();
                    this.dialog.close();
                };

                this.handleForm(form, entityName);
                this.dialog.open();
            });

        this.dialog.eventCancel = () => {
            deferred.reject();
        };
        return deferred.promise();
    }

    removeConfirm(entity: Objekt) {
        const deferred = new Deferred<Objekt, undefined>();
        this.confirm.load(
            this.languageService.translate(
                format('{0}_controller.destroy.confirm', [this.controllerName]),
                {
                    [format('{0}_{1}', [this.entityName, this.nameField])]:
                        entity.get(this.nameField),
                },
            ),
            this.languageService.translate('buttons.delete'),
            this.languageService.translate('buttons.cancel'),
            '',
            'warning',
        );
        this.confirm.eventOK = () => {
            this.remove(entity).then(
                (response) => {
                    this.flash.addMessage(response.get('message'));
                    deferred.resolve(response.get(this.entityName));
                },
                (response) => {
                    this.flash.addMessage(response.get('message'));
                },
            );
        };
        this.confirm.eventCancel = () => {
            deferred.reject();
        };
        this.confirm.open();
        return deferred.promise();
    }

    handleLocationField(
        mapService: MapService,
        locationField: LocationField,
    ): void {
        this._handleGoogleMapOnLocationField(mapService, locationField);

        locationField.eventSearch = () => {
            this._onSearch(locationField);
        };
    }

    handleLocationFieldByOrganization(
        mapService: MapService,
        locationService: LocationService,
        organizationId: string,
        locationField: LocationField,
    ): void {
        this._handleGoogleMapOnLocationField(mapService, locationField);

        locationField.eventSearch = () => {
            this._onSearch(locationField, locationService, organizationId);
        };
    }

    private _onSearch(
        locationField: LocationField,
        opt_locationService?: LocationService | undefined,
        opt_organizationId: string | undefined = '',
    ): void {
        const locationValue = locationField.getValue();
        const address = locationValue['address'];
        if (address) {
            if (opt_organizationId) {
                opt_locationService
                    ?.search(opt_organizationId, address)
                    .then((response) => {
                        const locations = response.get<Objekt[]>(
                            'locations',
                            [],
                        );
                        if (locations.length > 0 && locations[0]) {
                            const location = locations[0];
                            locationField.updatePosition(
                                location.get('latitude'),
                                location.get('longitude'),
                            );
                        } else {
                            locationField.search(address);
                        }
                    });
            } else {
                locationField.search(address);
            }
        }
    }

    private _handleGoogleMapOnLocationField(
        mapService: MapService,
        locationField: LocationField,
    ): void {
        mapService.handleColorSchemeOnLocationField(locationField);
    }
}
