import {
    Controller,
    Flash,
    Form,
    Helper,
    Instance,
    Knot,
    Objekt,
    State,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';

import { GoogleCaptchaService } from '../services/googleCaptchaService';
import { MilitaryService } from '../services/militaryService';
import { UserService } from '../services/userService';

export class UsersChangePasswordController extends Controller {
    dom: Knot;
    state: State;
    helper: Helper;
    flash: Flash;

    constructor(
        instances: Instance,
        private militaryService: MilitaryService,
        private googleCaptchaService: GoogleCaptchaService,
        private userService: UserService,
    ) {
        super();

        this.dom = instances.dom;
        this.state = instances.state;
        this.helper = instances.helper;
        this.flash = instances.flash;
    }

    enter(): void {
        this._initLayout();

        this.militaryService.draw(
            this.dom,
            ['#181b12', '#1d2015', '#1a2213', '#1c2415', '#1f2b17', '#223019'],
            0.7,
        );
    }

    protected _initLayout(): void {
        this._initButtons();
        this._initChangePasswordForm();
    }

    private _initButtons(): void {
        this.helper.link(
            '.btn-login',
            this.dom,
            (href) => {
                this.state.go(href);
            },
            this.state.resolveUrl('users.login'),
        );

        this.helper.iconButton(
            '.close',
            this.dom,
            () => {
                this.state.go('users.login');
            },
            '',
            true,
            [],
        );
    }

    private _initChangePasswordForm(): void {
        const form = new Form(this.dom);
        form.eventSubmit = (formData) => {
            form.lock();
            const userData = formData.get<Objekt>('user');
            const code = this.state.getParam('code');
            this.userService.changePassword(userData, code).then(
                (response) => {
                    this.flash.addMessage(response.get('message'));
                    this.state.go('users.login');
                },
                (response) => {
                    this.flash.addMessage(response.get('message'));
                    form.unlock();
                    form.setErrors(response.get('errors'));
                },
            );
            this.googleCaptchaService.reset();
        };
    }

    exit(): void {
        super.exit();

        this.militaryService.reset();
    }
}

export const usersChangePasswordController = app.controller(
    resources.usersChangePasswordController,
    [
        resources.instances,
        resources.militaryService,
        resources.googleCaptchaService,
        resources.userService,
    ],
    UsersChangePasswordController,
);
