import { Instance } from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { AssetService } from './assetService';
import { HuntService } from './huntService';
import { LanguageService } from './languageService';
import { LocationService } from './locationService';
import { MapService } from './mapService';
import { PersonService } from './personService';

export class SocialHuntService extends HuntService {
    constructor(
        instances: Instance,
        $languageService: LanguageService,
        $assetService: AssetService,
        $locationService: LocationService,
        $personService: PersonService,
        $mapService: MapService,
    ) {
        super(
            'social_hunt-social_hunts-title',
            instances,
            $languageService,
            $assetService,
            $locationService,
            $personService,
            $mapService,
        );
    }
}

export const socialHuntService = app.service(
    resources.socialHuntService,
    [
        resources.instances,
        resources.languageService,
        resources.assetService,
        resources.locationService,
        resources.personService,
        resources.mapService,
    ],
    SocialHuntService,
);
