import {
    Controller,
    Flash,
    Instance,
    Knot,
    Navigation,
    Objekt,
    Query,
    Tooltip,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';

import { MenuService } from '../services/menuService';
import { SiteService } from '../services/siteService';

export class SiteHomeController extends Controller {
    navigation: Navigation;
    private flash: Flash;
    private dom: Knot;

    constructor(
        instances: Instance,
        private siteService: SiteService,
        private menuService: MenuService,
    ) {
        super();

        this.flash = instances.flash;
        this.dom = instances.dom;
    }

    enter(): void {
        this._initLayout();
    }

    protected _initLayout(): void {
        this._loadAlerts();
        this._renderMenu();
    }

    private _loadAlerts(): void {
        this.siteService.getHome().then(
            (response) => {
                this.flash.addMessage(
                    response.get('message'),
                    0,
                    null,
                    'ALERT',
                );
                const alerts = response.get<Objekt>('alerts');
                this._renderAlerts(alerts);
            },
            (response) => {
                this.flash.addMessage(response.get('message'));
            },
        );
    }

    private _renderAlerts(alerts: Objekt): void {
        this.navigation.each((item) => {
            const stateId = item.get('state_id');
            const alertsByState = alerts.get<string[]>(stateId, [], true);
            if (alertsByState.length > 0) {
                const alertKnot = new Knot('span');
                alertKnot.addClass([
                    'material-icons',
                    'alert',
                    'animated',
                    'headShake',
                ]);
                alertKnot.setHtml('notifications');

                const node = item.get('node');
                node.beforeChild(alertKnot);

                const tooltip = new Tooltip(alertKnot);
                tooltip.render(alertsByState.join('. '));
                alertKnot.addEventListener('click', () => {
                    tooltip.toggle();
                });
            }
        });
    }

    private _renderMenu(): void {
        this.navigation = this.menuService.getMenuItems(false);

        const menuKnot = new Query('.link-menu', this.dom).getKnot();
        menuKnot.removeChildren();

        this.navigation.each((item) => {
            const node = item.get('node');
            menuKnot.appendChild(node);
        });
    }

    exit(): void {
        super.exit();

        this.flash.removeById('ALERT');
    }
}

export const siteHomeController = app.controller(
    resources.siteHomeController,
    [resources.instances, resources.siteService, resources.menuService],
    SiteHomeController,
);
