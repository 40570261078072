import { Confirm, Dialog, Flash, Instance } from '@siposdani87/sui-js';
import { app } from '../app';
import { OrganizationsShowComponent } from '../components/OrganizationsShowComponent';
import { resources } from '../resources';
import { AreaService } from '../services/areaService';
import { BudgetService } from '../services/budgetService';
import { BuildingService } from '../services/buildingService';
import { DistrictService } from '../services/districtService';
import { LanguageService } from '../services/languageService';
import { MapService } from '../services/mapService';
import { OrganizationService } from '../services/organizationService';
import { PageService } from '../services/pageService';
import { SecurityService } from '../services/securityService';
import { UserService } from '../services/userService';
import { UtilService } from '../services/utilService';
import { Dispose, renderSolid, unmountSolid } from '../utils/solidHelper';
import { DashboardController } from './dashboardController';

export class OrganizationsShowController extends DashboardController {
    private organizationId: string;
    private dispose: Dispose;
    private dialog: Dialog;
    private confirm: Confirm;
    private flash: Flash;

    constructor(
        instances: Instance,
        private organizationService: OrganizationService,
        private areaService: AreaService,
        private buildingService: BuildingService,
        private districtService: DistrictService,
        private mapService: MapService,
        private pageService: PageService,
        private budgetService: BudgetService,
        private securityService: SecurityService,
        private utilService: UtilService,
        private userService: UserService,
        private languageService: LanguageService,
    ) {
        super(instances);

        this.dialog = instances.dialog;
        this.confirm = instances.confirm;
        this.flash = instances.flash;
    }

    protected override _initLayout(): void {
        this.organizationId = this.state.getParam('organizationId');

        this._initComponent();
    }

    private _initComponent(): void {
        this.dispose = renderSolid(
            OrganizationsShowComponent,
            {
                organizationId: this.organizationId,
                $dom: this.dom,
                $helper: this.helper,
                $state: this.state,
                $dialog: this.dialog,
                $confirm: this.confirm,
                $flash: this.flash,
                $organizationService: this.organizationService,
                $securityService: this.securityService,
                $areaService: this.areaService,
                $buildingService: this.buildingService,
                $districtService: this.districtService,
                $mapService: this.mapService,
                $pageService: this.pageService,
                $languageService: this.languageService,
                $userService: this.userService,
                $budgetService: this.budgetService,
                $utilService: this.utilService,
            },
            this.dom,
        );
    }

    exit(): void {
        super.exit();
        unmountSolid(this.dispose);
    }
}

export const organizationsShowController = app.controller(
    resources.organizationsShowController,
    [
        resources.instances,
        resources.organizationService,
        resources.areaService,
        resources.buildingService,
        resources.districtService,
        resources.mapService,
        resources.pageService,
        resources.budgetService,
        resources.securityService,
        resources.utilService,
        resources.userService,
        resources.languageService,
    ],
    OrganizationsShowController,
);
