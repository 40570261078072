import { Form, Instance, Objekt } from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { AssetService } from './assetService';
import { LanguageService } from './languageService';
import { ModelService } from './modelService';
import { UserService } from './userService';

export class SecurityService extends ModelService {
    constructor(
        instances: Instance,
        $languageService: LanguageService,
        $assetService: AssetService,
        private userService: UserService,
    ) {
        super(
            'security-securities-title',
            instances,
            $languageService,
            $assetService,
        );
    }

    createByOrganization(organizationId: string, security: Objekt) {
        return this.createBy('organizations', organizationId, security);
    }

    handleForm(form: Form, opt_entityName?: string): void {
        const privateField = form.findByModel(
            'organization.security_attributes.private',
        );
        const organizationIdField = form.findByModel('organization.id');
        const organizationId = organizationIdField.getValue();

        if (
            privateField &&
            !this.userService.hasLicenseByType('premium', organizationId)
        ) {
            privateField.setDisabled(true);
        }
    }
}

export const securityService = app.service(
    resources.securityService,
    [
        resources.instances,
        resources.languageService,
        resources.assetService,
        resources.userService,
    ],
    SecurityService,
);
