import { Instance } from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { AssetService } from './assetService';
import { LanguageService } from './languageService';
import { ModelService } from './modelService';

export class CountryService extends ModelService {
    constructor(
        instances: Instance,
        $languageService: LanguageService,
        $assetService: AssetService,
    ) {
        super(
            'country-countries-name',
            instances,
            $languageService,
            $assetService,
        );
    }
}

export const countryService = app.service(
    resources.countryService,
    [resources.instances, resources.languageService, resources.assetService],
    CountryService,
);
