import {
    Form,
    format,
    Instance,
    Objekt,
    SelectField,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { AssetService } from './assetService';
import { GroupService } from './groupService';
import { LanguageService } from './languageService';
import { ModelService } from './modelService';

export class NoteService extends ModelService {
    constructor(
        instances: Instance,
        $languageService: LanguageService,
        $assetService: AssetService,
        private groupService: GroupService,
    ) {
        super('note-notes-title', instances, $languageService, $assetService);
    }

    getAllByOrganization(organizationId: string, opt_params?: object) {
        return this.getAllBy('organizations', organizationId, opt_params);
    }

    createByOrganization(organizationId: string, note: Objekt) {
        return this.createBy('organizations', organizationId, note);
    }

    publish(note: Objekt, opt_types: Array<any> | undefined) {
        const noteId = note.get('id');
        return this.http.post(
            format('/api/v1/notes/{0}/publish.json', [noteId]),
            {},
            {
                types: opt_types,
            },
        );
    }

    publishByPerson(
        note: Objekt,
        person: Objekt,
        opt_types: Array<any> | undefined = [],
    ) {
        const noteId = note.get('id');
        const personId = person.get('id');
        return this.http.post(
            format('/api/v1/people/{0}/notes/{1}/publish.json', [
                personId,
                noteId,
            ]),
            {},
            {
                types: opt_types,
            },
        );
    }

    exportList(note: Objekt, opt_type: string | undefined) {
        const noteId = note.get('id');
        return this.http.get(
            format('/api/v1/notes/{0}/export-list.docx', [noteId]),
            {
                type: opt_type,
            },
            {
                responseType: 'blob',
            },
        );
    }

    exportListByPerson(
        note: Objekt,
        person: Objekt,
        opt_type: string | undefined,
    ) {
        const noteId = note.get('id');
        const personId = person.get('id');
        return this.http.get(
            format('/api/v1/people/{0}/notes/{1}/export-list.docx', [
                personId,
                noteId,
            ]),
            {
                type: opt_type,
            },
            {
                responseType: 'blob',
            },
        );
    }

    handleForm(form: Form, opt_entityName?: string): void {
        const groupsField = form.findByModel<SelectField>('note.group_ids');
        const organizationField = form.findByModel<SelectField>(
            'note.organization_id',
        );

        organizationField.eventChange = (organizationId) => {
            if (organizationId) {
                groupsField.showLoader();
                this.groupService
                    .getAllByOrganization(organizationId, {
                        fields: 'id,name',
                    })
                    .then((response) => {
                        const groups = response.get<Objekt[]>('groups');
                        groupsField.setOptions(groups, 'id', 'name');
                    });
            } else {
                groupsField.setOptions([]);
            }
        };
    }
}

export const noteService = app.service(
    resources.noteService,
    [
        resources.instances,
        resources.languageService,
        resources.assetService,
        resources.groupService,
    ],
    NoteService,
);
