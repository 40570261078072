import {
    Async,
    Deferred,
    Depot,
    GoogleMap,
    Instance,
    LatLng,
    Objekt,
    Promize,
    WeightLatLng,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { AreaService } from '../services/areaService';
import { MapService } from '../services/mapService';
import { ReportService } from '../services/reportService';
import { DashboardController } from './dashboardController';

export class ReportsPeopleWhereHuntController extends DashboardController {
    localDepot: Depot;
    map: GoogleMap;
    area: Objekt;
    objects: WeightLatLng[];

    constructor(
        instances: Instance,
        private reportService: ReportService,
        private areaService: AreaService,
        private mapService: MapService,
    ) {
        super(instances);

        this.localDepot = instances.localDepot;
    }

    protected override _initLayout(): void {
        this._initToolbarButtons();

        this._drawContent();
    }

    protected override _drawContent(): Promize | boolean {
        this._initHeatmap();
        return true;
    }

    private _initToolbarButtons(): void {
        this.helper.iconButton(
            '.btn-reports-index',
            this.dom,
            () => {
                this.state.goBack('reports.index');
            },
            '',
            true,
            [],
        );

        this.helper.iconButton('.btn-reports-filter', this.dom, () => {
            this.reportService.detailsDialog('people');
        });
    }

    private _initHeatmap(): void {
        if (!this.map) {
            this.map = this.mapService.createGoogleMap(this.dom, '.map', {
                mapTypeId: 'custom',
                mapTypeControlOptions: {
                    mapTypeIds: ['custom', google.maps.MapTypeId.SATELLITE],
                },
                mapTypeControl: true,
                scrollwheel: true,
            });

            this.map.setPolygons(this.mapService.getAreaPolygonOptions());
            this.map.setHeatmap(this.mapService.getHeatMapOptions());
        }

        const async = new Async();
        async
            .parallel([this._loadArea.bind(this), this._loadObjects.bind(this)])
            .then(() => {
                this._setMap();
            });
    }

    private _loadArea(): Promize {
        const deferred = new Deferred();
        const peopleReport = new Objekt(
            this.localDepot.get(
                this.reportService.getStorageKeyReport('people'),
            ),
        );
        this.areaService
            .getAllByOrganization(peopleReport.get('organization_id'))
            .then(
                (response) => {
                    this.area = response.get('areas', [])[0] || new Objekt();
                    deferred.resolve();
                },
                () => {
                    deferred.reject();
                },
            );
        return deferred.promise();
    }

    private _loadObjects(): Promize {
        const deferred = new Deferred();
        const peopleReport = new Objekt(
            this.localDepot.get(
                this.reportService.getStorageKeyReport('people'),
            ),
        );
        this.reportService
            .getAllByPeople(
                peopleReport,
                new Objekt({
                    fields: 'latitude,longitude,weight',
                }),
            )
            .then(
                (response) => {
                    this.objects = response.get('objects');
                    deferred.resolve();
                },
                () => {
                    deferred.reject();
                },
            );
        return deferred.promise();
    }

    private _setMap(): void {
        this.map.removeAllPolygon();
        this.map.removeHeatmap();

        const areaId = this.area.get<string>('id');
        const areaPoints = this.area.get<LatLng[]>('locations', []);
        this.map.createPolygon(areaId, '', areaPoints, this.area);
        this.map.fitPolygonToMap(areaId);

        this.map.createHeatmap(this.objects);
    }

    exit(): void {
        super.exit();

        this.mapService.removeGoogleMap(this.map);
    }
}

export const reportsPeopleWhereHuntController = app.controller(
    resources.reportsPeopleWhereHuntController,
    [
        resources.instances,
        resources.reportService,
        resources.areaService,
        resources.mapService,
    ],
    ReportsPeopleWhereHuntController,
);
