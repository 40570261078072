import {
    Async,
    Deferred,
    EventBus,
    Instance,
    isNull,
    Knot,
    Query,
    Script,
    Service,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { ConfigService } from './configService';

export class GoogleCaptchaService extends Service {
    script: Script;
    eventBus: EventBus;
    widgetId: string;
    async: Async;

    constructor(
        instances: Instance,
        private configService: ConfigService,
    ) {
        super();

        this.script = instances.script;
        this.eventBus = instances.eventBus;

        this.widgetId = null;

        this.async = new Async(2);
        this.async.eventComplete = this._eventComplete.bind(this);
    }

    enter(): void {
        this.eventBus.set('controller.loaded', (opt_dom) => {
            this.widgetId = null;
            if (opt_dom) {
                const captchaKnot = new Query('#captcha', opt_dom).getKnot();
                if (!captchaKnot.isEmpty()) {
                    this.async.parallelFunction(
                        () => {
                            return captchaKnot;
                        },
                        null,
                        0,
                    );
                }
            }
        });

        this._loadSDK();
    }

    private _loadSDK() {
        const deferred = new Deferred();
        const languageCode = this.configService.getLocale().split('-', 2)[0];
        this.script
            .load(
                'google-recaptcha',
                'https://www.google.com/recaptcha/api.js',
                {
                    hl: languageCode,
                    render: 'explicit',
                },
                true,
                true,
            )
            .then(
                () => {
                    this.async.parallelFunction(
                        () => {
                            return true;
                        },
                        null,
                        1,
                    );
                    deferred.resolve();
                },
                () => {
                    deferred.reject();
                },
            );
        return deferred.promise();
    }

    private _eventComplete(isError: boolean, results: Array<any>): void {
        this.async.setStatus(1, isError, 0, results);
        if (!isError) {
            this.render(results[0], '#user_captcha');
        }
    }

    render(node: Knot, inputId: string): void {
        const type = this.configService.get('recaptcha.type');
        const isInvisible = type === 'invisible';
        const tickboxSiteKey = this.configService.get(
            'recaptcha.tickbox_site_key',
        );
        const invisibleSiteKey = this.configService.get(
            'recaptcha.invisible_site_key',
        );
        const siteKey = isInvisible ? invisibleSiteKey : tickboxSiteKey;
        const size = isInvisible ? 'invisible' : 'normal';
        const badge = isInvisible ? 'bottomright' : 'inline';
        // FIXME: Some users/registration on page load grecaptcha.render does not exists
        window['grecaptcha'].ready(() => {
            this.widgetId = window['grecaptcha']['render'](node.getNode(), {
                sitekey: siteKey,
                badge: badge,
                theme: 'light',
                size: size,
                callback: (response) => {
                    const input = new Query(inputId).getKnot();
                    input.setAttribute('value', response);
                    input.trigger('change');
                },
            });
            if (isInvisible) {
                this.execute();
            }
        });
    }

    execute(): void {
        if (!isNull(this.widgetId)) {
            window['grecaptcha'].ready(() => {
                window['grecaptcha']['execute'](this.widgetId);
            });
        }
    }

    reset(): void {
        if (!isNull(this.widgetId)) {
            window['grecaptcha'].ready(() => {
                window['grecaptcha']['reset'](this.widgetId);
            });
        }
    }
}

export const googleCaptchaService = app.service(
    resources.googleCaptchaService,
    [resources.instances, resources.configService],
    GoogleCaptchaService,
);
