import {
    DateIO,
    Instance,
    Knot,
    Objekt,
    Promize,
    Table,
    Viewer,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { AssetService } from '../services/assetService';
import { LanguageService } from '../services/languageService';
import { ObservationService } from '../services/observationService';
import { OrganizationService } from '../services/organizationService';
import { DashboardController } from './dashboardController';

export class ObservationsIndexByOrganizationController extends DashboardController {
    viewer: Viewer;
    organizationId: string;
    observationsTable: Table;

    constructor(
        instances: Instance,
        private observationService: ObservationService,
        private organizationService: OrganizationService,
        private assetService: AssetService,
        private languageService: LanguageService,
    ) {
        super(instances);

        this.viewer = instances.viewer;
    }

    protected override _initLayout(): void {
        this.organizationId = this.state.getParam('organizationId');

        this._initToolbarButtons();
        this._initFilterForm();

        this._drawContent();
    }

    protected override _drawContent(): Promize | boolean {
        this._initObservationCollection();
        return true;
    }

    private _initToolbarButtons(): void {
        this.helper.iconButton(
            '.btn-organizations-show',
            this.dom,
            () => {
                this.state.goBack('organizations.show', {});
            },
            '',
            true,
            [],
        );
    }

    private _initObservationCollection(): void {
        if (!this.observationsTable) {
            this.observationsTable = new Table(
                this.dom,
                '.observations-table',
                {
                    no_content: {
                        image_url: this.assetService.getPath(
                            'images/others/no-content.png',
                        ),
                        text: this.languageService.translate('text.no_content'),
                    },
                    sort: {
                        column: 'created_at',
                        order: 'asc',
                    },
                    row_count: 12,
                    column: 'short_description',
                    columns: [
                        'photo',
                        'short_description',
                        'location.address',
                        'user.name',
                        'created_at',
                        'checked',
                        'is_highlight',
                        'actions',
                    ],
                    sorted: ['created_at:asc'],
                    calculations: {
                        photo: (observation) => {
                            const imageWrapper = new Knot('div');
                            imageWrapper.addClass(['img-wrapper', 'clickable']);
                            const imageKnot = new Knot('img');
                            imageKnot.addClass('img-responsive');
                            imageKnot.setAttribute(
                                'src',
                                observation.get('photo.thumb.url'),
                            );
                            imageWrapper.appendChild(imageKnot);
                            imageWrapper.addEventListener('click', () => {
                                const photoUrl = observation.get('photo.url');
                                const name =
                                    observation.get('short_description');
                                this.viewer.loadImage(photoUrl, name);
                            });
                            return imageWrapper;
                        },
                        checked: (observation) => {
                            const checked = observation.get('checked');
                            const iconKnot = new Knot('em');
                            iconKnot.addClass([
                                'material-icons',
                                'text-success',
                            ]);
                            iconKnot.setHtml('check_circle');
                            return checked ? iconKnot : '';
                        },
                        is_highlight: (observation) => {
                            const isHighlight = observation.get('is_highlight');
                            const iconKnot = new Knot('em');
                            iconKnot.addClass([
                                'material-icons',
                                'text-success',
                            ]);
                            iconKnot.setHtml('check_circle');
                            return isHighlight ? iconKnot : '';
                        },
                        created_at: (observation) => {
                            const createdAt = DateIO.parse(
                                observation.get('created_at'),
                            );
                            return this.languageService.format(
                                createdAt,
                                'format.datetime',
                            );
                        },
                    },
                },
            );

            this.observationsTable.setActions([
                {
                    style: () => {
                        return [
                            'edit',
                            this.languageService.translate('buttons.edit'),
                        ];
                    },
                    click: (observation) => {
                        this.observationService
                            .editDialog(observation)
                            .then(() => {
                                this._drawContent();
                            });
                    },
                },
            ]);

            this.observationsTable.eventAction = (params) => {
                params.set('query', this.query);
                params.set(
                    'fields',
                    'id,short_description,photo,location.address,checked,is_highlight,created_at,user.name,organization_id',
                );
                this.observationService
                    .getAllByOrganization(this.organizationId, params)
                    .then((response) => {
                        const count = response.get<number>('count');
                        this.observationsTable.setCount(count);
                        const observations =
                            response.get<Objekt[]>('observations');
                        this.observationsTable.setData(observations);
                    });
            };
            this.observationsTable.render();
        } else {
            this.observationsTable.refresh();
        }
    }
}

export const observationsIndexByOrganizationController = app.controller(
    resources.observationsIndexByOrganizationController,
    [
        resources.instances,
        resources.observationService,
        resources.organizationService,
        resources.assetService,
        resources.languageService,
    ],
    ObservationsIndexByOrganizationController,
);
