import {
    Async,
    colorContrast,
    Confirm,
    consoleWarn,
    Deferred,
    eachArray,
    eq,
    Flash,
    GoogleMap,
    Instance,
    Knot,
    LatLng,
    noop,
    Objekt,
    Promize,
    Table,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { AreaService } from '../services/areaService';
import { DistrictService } from '../services/districtService';
import { LanguageService } from '../services/languageService';
import { MapService } from '../services/mapService';
import { OrganizationService } from '../services/organizationService';
import { UserService } from '../services/userService';
import { UtilService } from '../services/utilService';
import { DashboardController } from './dashboardController';

export class DistrictsIndexController extends DashboardController {
    organizationId: string;
    newDistrictId: string;
    selectedDistrict: Objekt;
    districtsTable: Table;
    hasAccessDistinctEdit: boolean;
    area: Objekt;
    districts: Objekt[];
    btnDistrictsNewKnot: Knot<HTMLElement>;
    btnDistrictsEditKnot: Knot<HTMLElement>;
    map: GoogleMap;
    organization: Objekt;
    private flash: Flash;
    private confirm: Confirm;

    constructor(
        instances: Instance,
        private organizationService: OrganizationService,
        private districtService: DistrictService,
        private areaService: AreaService,
        private mapService: MapService,
        private userService: UserService,
        private utilService: UtilService,
        private languageService: LanguageService,
    ) {
        super(instances);

        this.flash = instances.flash;
        this.confirm = instances.confirm;
    }

    protected override _initLayout(): void {
        this.organizationId = this.state.getParam('organizationId');

        this.newDistrictId = 'DISTRICT_ID';
        this.selectedDistrict = null;

        this._initToolbarButtons();
        this._initFilterForm();
        this._initTabPanel('district-map');
        this._changeToolbarButtons();

        this._loadOrganization().then(() => {
            this._initAccess();
            this._drawContent();
        });
    }

    private _initDistrictTable(): void {
        if (eq(this.activeTab, 'district-table')) {
            if (!this.districtsTable) {
                this.districtsTable = new Table(
                    this.dom,
                    '.districts-table',
                    this.districtService.getTableOptions(),
                );

                this.districtsTable.setActions([
                    {
                        style: () => {
                            return [
                                'chevron_right',
                                this.languageService.translate(
                                    'buttons.select',
                                ),
                                false,
                                !this.hasAccessDistinctEdit,
                            ];
                        },
                        click: this._selectDistrictToEdit.bind(this),
                    },
                ]);

                this.districtsTable.eventAction = (params) => {
                    params.set('query', this.query);
                    params.set('fields', this.districtService.getTableFields());
                    this.districtService
                        .getAllByOrganization(this.organizationId, params)
                        .then((response) => {
                            const count = response.get<number>('count');
                            this.districtsTable.setCount(count);
                            const districts =
                                response.get<Objekt[]>('districts');
                            this.districtsTable.setData(districts);
                        });
                };
                this.districtsTable.render();
            } else {
                this.districtsTable.refresh();
            }
        }
    }

    private _loadArea(): Promize {
        const deferred = new Deferred();
        this.areaService
            .getAllByOrganization(this.organizationId, {
                fields: 'id,compute_area,locations',
            })
            .then(
                (response) => {
                    this.area = response.get('areas', [])[0] || new Objekt();
                    deferred.resolve();
                },
                () => {
                    deferred.reject();
                },
            );
        return deferred.promise();
    }

    private _loadDistricts(): Promize {
        const deferred = new Deferred();
        this.districtService
            .getAllByOrganization(this.organizationId, {
                query: this.query,
            })
            .then(
                (response) => {
                    this.districts = response.get('districts', []);
                    if (this.districts.length === 0) {
                        this._showCreateInfo();
                    }
                    deferred.resolve();
                },
                () => {
                    deferred.reject();
                },
            );
        return deferred.promise();
    }

    private _initToolbarButtons(): void {
        this.helper.iconButton(
            '.btn-organizations-show',
            this.dom,
            () => {
                this.state.goBack('organizations.show', {
                    organizationId: this.organizationId,
                });
            },
            '',
            true,
            [],
        );

        this.btnDistrictsNewKnot = this.helper.iconButton(
            '.btn-districts-new',
            this.dom,
            () => {
                this._showCreateInfo();
            },
        );

        this.btnDistrictsEditKnot = this.helper.iconButton(
            '.btn-districts-edit',
            this.dom,
            () => {
                this.districtService
                    .editDialog(this.selectedDistrict)
                    .then(
                        this.utilService.handleEditResponse(
                            'organizations.show',
                            { organizationId: this.organizationId },
                        ),
                        this._deselectDistrict.bind(this),
                    );
            },
        );
    }

    private _deselectDistrict(): void {
        this._selectDistrict(null);
    }

    private _showCreateInfo(): void {
        this.flash.addInfo(
            this.languageService.translate('text.districts.create'),
            0,
            noop(),
        );
    }

    private _selectDistrictToEdit(district: Objekt): void {
        this.tabPanel.setActive('district-map').then(() => {
            this._selectDistrict(district);
        });
    }

    private _selectDistrict(district: Objekt | null): void {
        this.map.polygons.each((polygonData) => {
            const polygonId = polygonData.get<string>('id');
            const polygonTitle = polygonData.get<string>('title');
            const polygonPoints = polygonData.get<LatLng[]>('locations');
            this.map.updatePolygon(
                polygonId,
                polygonTitle,
                polygonPoints,
                polygonData,
                {
                    editable: false,
                },
            );
        });

        const isArea = district && district.get('id') === this.area.get('id');
        if (district && !isArea) {
            const selectedDistrict = this.map.getPolygon(district.get('id'));
            const districtId = selectedDistrict.get<string>('id');
            const districtTitle = selectedDistrict.get<string>('title');
            const districtPoints = selectedDistrict.get<LatLng[]>('locations');
            this.map.updatePolygon(
                districtId,
                districtTitle,
                districtPoints,
                selectedDistrict,
                {
                    editable: true,
                },
            );
        }
        if (!isArea) {
            this.selectedDistrict = district;
        }
        this._changeToolbarButtons();
    }

    private _loadOrganization(): Promize {
        const deferred = new Deferred();
        this.organizationService
            .get(this.organizationId, {
                fields: 'id,country.location',
            })
            .then(
                (response) => {
                    this.organization = response.get('organization');
                    deferred.resolve();
                },
                () => {
                    deferred.reject();
                },
            );
        return deferred.promise();
    }

    private _initAccess(): void {
        this.hasAccessDistinctEdit = this.userService.hasAccessByOrganization(
            this.organizationId,
            'districts_write',
        );
    }

    protected override _drawContent(): Promize | boolean {
        this._changeToolbarButtons();

        this._initDistrictTable();
        return this._initMap();
    }

    private _changeToolbarButtons(): void {
        this.utilService.showButton(
            this.btnDistrictsNewKnot,
            this.activeTab === 'district-map',
        );
        this.utilService.enableButton(
            this.btnDistrictsNewKnot,
            !this.selectedDistrict,
        );

        this.utilService.showButton(
            this.btnDistrictsEditKnot,
            this.activeTab === 'district-map',
        );
        this.utilService.enableButton(
            this.btnDistrictsEditKnot,
            !!this.selectedDistrict,
        );
    }

    private _initMap(): Promize {
        const deferred = new Deferred();
        if (eq(this.activeTab, 'district-map')) {
            if (!this.map) {
                this.map = this.mapService.createGoogleMap(this.dom, '.map', {
                    mapTypeId: 'custom',
                    mapTypeControlOptions: {
                        mapTypeIds: ['custom', google.maps.MapTypeId.SATELLITE],
                    },
                    mapTypeControl: true,
                    scrollwheel: true,
                });

                this.map.eventMapClick = this._eventMapClick.bind(this);

                this.map.eventPolygonClick = this._eventPolygonClick.bind(this);
                this.map.eventPolygonDoubleClick =
                    this._eventPolygonDoubleClick.bind(this);
                this.map.eventPolygonChanged =
                    this._eventPolygonChanged.bind(this);

                this.map.setPolygons(
                    this.mapService.getAreaPolygonOptions({
                        editable: false,
                    }),
                );
            }

            const async = new Async();
            async
                .parallel([
                    this._loadArea.bind(this),
                    this._loadDistricts.bind(this),
                ])
                .then(() => {
                    this._setMap();
                    deferred.resolve();
                });
        } else {
            deferred.resolve();
        }
        return deferred.promise();
    }

    private _setMap(): void {
        this.map.removeAllPolygon();

        if (this.area.get('compute_area')) {
            const areaId = this.area.get<string>('id');
            const areaPoints = this.area.get<LatLng[]>('locations', []);
            this.map.createPolygon(areaId, '', areaPoints, this.area);
            this.map.fitPolygonToMap(areaId);
        } else {
            const countryLocation =
                this.organization.get<Objekt>('country.location');
            this.map.setCenter(
                countryLocation.get('latitude'),
                countryLocation.get('longitude'),
            );
            this._noAreaConfirm();
        }
        eachArray(this.districts, this._createDistrictPolygon.bind(this));
    }

    private _noAreaConfirm(): void {
        this.confirm.load(
            this.languageService.translate('text.areas.create_first'),
            this.languageService.translate('buttons.ok'),
            '',
            '',
            'info',
        );
        this.confirm.eventOK = () => {
            this.state.go('areas.index', {
                organizationId: this.organizationId,
            });
        };
        this.confirm.open();
    }

    private _isSelectedDistrictId(districtId: string): boolean {
        return (
            !!this.selectedDistrict &&
            this.selectedDistrict.get('id') === districtId
        );
    }

    private _createDistrictPolygon(district: Objekt): void {
        const districtId = district.get<string>('id');
        const districtTitle = district.get<string>('title');
        const districtPoints = district.get<LatLng[]>('locations', []);
        const editable = this._isSelectedDistrictId(districtId);
        this.map.createPolygon(
            districtId,
            districtTitle,
            districtPoints,
            district,
            {
                strokeColor: colorContrast(district.get('color')),
                fillColor: district.get('color'),
                editable: editable,
            },
        );
    }

    private _eventPolygonChanged(
        district: Objekt,
        locations: Array<{ latitude: number; longitude: number }>,
    ): void {
        if (this.hasAccessDistinctEdit && this.selectedDistrict) {
            this.selectedDistrict.set('locations', locations);
        }
    }

    private _eventPolygonClick(
        district: Objekt,
        latitude: number,
        longitude: number,
    ): void {
        if (this.hasAccessDistinctEdit) {
            const areaId = this.area.get<string>('id');
            const districtId = district.get<string>('id');
            if (this.selectedDistrict) {
                const selectedDistrictId = this.selectedDistrict.get('id');
                if (selectedDistrictId === this.newDistrictId) {
                    if (
                        this.selectedDistrict.get<LatLng[]>('locations', [])
                            .length >= 3
                    ) {
                        const districtData = new Objekt({
                            district: this.selectedDistrict,
                        });
                        this.districtService
                            .newDialogByOrganization(
                                this.organizationId,
                                districtData,
                            )
                            .then(
                                () => {
                                    this.state.refresh(true);
                                },
                                () => {
                                    this.map.removePolygon(selectedDistrictId);
                                    this._deselectDistrict();
                                },
                            );
                    } else {
                        const districtData =
                            this.map.getPolygon(selectedDistrictId);
                        this.map.addPointToPolygon(
                            districtData,
                            latitude,
                            longitude,
                        );
                    }
                } else if (this._isSelectedDistrictId(districtId)) {
                    this.districtService
                        .editDialog(this.selectedDistrict)
                        .then(
                            this.utilService.handleEditResponse(
                                'organizations.show',
                                { organizationId: this.organizationId },
                            ),
                            this._deselectDistrict.bind(this),
                        );
                } else if (districtId === areaId) {
                    this._deselectDistrict();
                }
            } else {
                if (districtId === areaId) {
                    const newDistrict = new Objekt({
                        id: this.newDistrictId,
                        title: '',
                        locations: [{ latitude, longitude }],
                    });
                    this.map.createPolygon(
                        newDistrict.get('id'),
                        newDistrict.get('title'),
                        newDistrict.get('locations'),
                        newDistrict,
                        {
                            editable: true,
                        },
                    );
                    this.selectedDistrict = newDistrict;
                } else {
                    // this._deselectDistrict();
                }
            }
        }
    }

    private _eventPolygonDoubleClick(
        district: Objekt,
        _latitude: number,
        _longitude: number,
    ): void {
        if (this.hasAccessDistinctEdit) {
            const areaId = this.area.get<string>('id');
            const districtId = district.get<string>('id');
            if (
                !this._isSelectedDistrictId(districtId) &&
                districtId !== areaId
            ) {
                this._selectDistrict(district);
            } else if (districtId === areaId) {
                this._deselectDistrict();
            }
        }
    }

    private _eventMapClick(latitude: number, longitude: number): void {
        consoleWarn('_eventMapClick');
    }

    exit(): void {
        super.exit();

        this.mapService.removeGoogleMap(this.map);
    }
}

export const districtsIndexController = app.controller(
    resources.districtsIndexController,
    [
        resources.instances,
        resources.organizationService,
        resources.districtService,
        resources.areaService,
        resources.mapService,
        resources.userService,
        resources.utilService,
        resources.languageService,
    ],
    DistrictsIndexController,
);
