import { Deferred, Instance, Objekt, Promize } from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { GroupService } from '../services/groupService';
import { UtilService } from '../services/utilService';
import { DashboardController } from './dashboardController';

export class GroupsShowController extends DashboardController {
    groupId: string;
    group: Objekt;
    organizationId: string;

    constructor(
        instances: Instance,
        private groupService: GroupService,
        private utilService: UtilService,
    ) {
        super(instances);
    }

    protected override _initLayout(): void {
        this.groupId = this.state.getParam('groupId');
        this.group = new Objekt();

        this._initToolbarButtons();

        this._loadGroup().then(() => {
            this._drawContent();
        });
    }

    private _initToolbarButtons(): void {
        this.helper.iconButton(
            '.btn-groups-index',
            this.dom,
            () => {
                this.state.goBack('groups.index', {
                    organizationId: this.organizationId,
                });
            },
            '',
            true,
            [],
        );

        this.helper.iconButton('.btn-groups-edit', this.dom, () => {
            this.groupService.editDialog(this.group).then(
                this.utilService.handleEditResponse('groups.index', {
                    organizationId: this.organizationId,
                }),
            );
        });
    }

    private _loadGroup(): Promize {
        const deferred = new Deferred();
        this.groupService
            .get(this.groupId, {
                fields: 'id,name',
            })
            .then(
                (response) => {
                    this.group = response.get('group');
                    this.organizationId = this.group.get('organizationId');
                    deferred.resolve();
                },
                () => {
                    deferred.reject();
                },
            );
        return deferred.promise();
    }
}

export const groupsShowController = app.controller(
    resources.groupsShowController,
    [resources.instances, resources.groupService, resources.utilService],
    GroupsShowController,
);
