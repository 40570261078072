import {
    Deferred,
    eq,
    GoogleMap,
    Instance,
    Knot,
    LatLng,
    Objekt,
    Promize,
    Query,
    Viewer,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { AreaService } from '../services/areaService';
import { BuildingService } from '../services/buildingService';
import { MapService } from '../services/mapService';
import { UtilService } from '../services/utilService';
import { DashboardController } from './dashboardController';

export class BuildingsShowController extends DashboardController {
    viewer: Viewer;
    buildingId: string;
    building: Objekt;
    organizationId: string;
    btnBuildingsEditKnot: Knot<HTMLElement>;
    area: Objekt;
    map: GoogleMap;

    constructor(
        instances: Instance,
        private mapService: MapService,
        private buildingService: BuildingService,
        private areaService: AreaService,
        private utilService: UtilService,
    ) {
        super(instances);

        this.viewer = instances.viewer;
    }

    protected override _initLayout(): void {
        this.buildingId = this.state.getParam('buildingId');
        this.building = new Objekt();

        this._initToolbarButtons();
        this._initTabPanel('details');
        this._changeToolbarButtons();

        this._initViewer();
        this._initLinks('#details a');
        this._correctDates();

        this._loadBuilding().then(() => {
            this._drawContent();
        });
    }

    private _initToolbarButtons(): void {
        this.helper.iconButton(
            '.btn-buildings-index',
            this.dom,
            () => {
                this.state.goBack('buildings.index', {
                    organizationId: this.organizationId,
                });
            },
            '',
            true,
            [],
        );

        this.btnBuildingsEditKnot = this.helper.iconButton(
            '.btn-buildings-edit',
            this.dom,
            () => {
                this.buildingService.editDialog(this.building).then(
                    this.utilService.handleEditResponse('buildings.index', {
                        organizationId: this.organizationId,
                    }),
                );
            },
        );
    }

    protected override _drawContent(): Promize | boolean {
        this._changeToolbarButtons();

        this._initMap();
        return true;
    }

    private _changeToolbarButtons(): void {
        this.utilService.showButton(
            this.btnBuildingsEditKnot,
            this.activeTab === 'details',
        );
    }

    private _initViewer(): void {
        const imageBoxKnot = new Query('.image-box', this.dom).getKnot();
        if (imageBoxKnot.exists()) {
            imageBoxKnot.addEventListener('click', () => {
                const imageUrl = this.building.get<string>('photo.url');
                const title = this.building.get<string>('name');
                this.viewer.loadImage(imageUrl, title);
            });
        }
    }

    private _loadBuilding(): Promize {
        const deferred = new Deferred();
        this.buildingService
            .get(this.buildingId, {
                fields: 'id,name,photo,organization_id,location',
            })
            .then(
                (response) => {
                    this.building = response.get('building');
                    this.organizationId = this.building.get('organizationId');
                    deferred.resolve();
                },
                () => {
                    deferred.reject();
                },
            );
        return deferred.promise();
    }

    private _loadArea(): Promize {
        const deferred = new Deferred();
        this.areaService
            .getAllByOrganization(this.building.get<string>('organization_id'))
            .then(
                (response) => {
                    this.area = response.get('areas', [])[0] || new Objekt();
                    deferred.resolve();
                },
                () => {
                    deferred.reject();
                },
            );
        return deferred.promise();
    }

    private _initMap(): void {
        if (eq(this.activeTab, 'details')) {
            if (!this.map) {
                this.map = this.mapService.createGoogleMap(this.dom);

                this.map.setMarkerIcon(
                    'location',
                    this.mapService.getIcon('location', '#795548'),
                );
                this.map.eventMarkerClick = this._eventMarkerClick.bind(this);

                this.map.setPolygons(this.mapService.getAreaPolygonOptions());
            }

            this._loadArea().then(() => {
                this._setMap();
            });
        }
    }

    private _correctDates(): void {
        this.utilService.correctDate('.move-at', 'format.datetime', this.dom);
    }

    private _setMap(): void {
        this.map.removeAllPolygon();
        this.map.removeAllMarker();

        const areaId = this.area.get<string>('id');
        const areaPoints = this.area.get<LatLng[]>('locations', []);
        this.map.createPolygon(areaId, '', areaPoints, this.area);
        this.map.fitPolygonToMap(areaId);

        const buildingId = this.building.get<string>('id');
        const location = this.building.get<Objekt>('location', new Objekt());
        this.map.createMarker(
            buildingId,
            location.get<string>('address'),
            'location',
            location.get<number>('latitude'),
            location.get<number>('longitude'),
            this.building,
        );
    }

    private _eventMarkerClick(building: Objekt): void {
        const buildingId = building.get<string>('id');
        const address = building.get<string>('location.address');
        this.map.openInfoWindow(buildingId, address);
    }

    exit(): void {
        super.exit();

        this.mapService.removeGoogleMap(this.map);
    }
}

export const buildingsShowController = app.controller(
    resources.buildingsShowController,
    [
        resources.instances,
        resources.mapService,
        resources.buildingService,
        resources.areaService,
        resources.utilService,
    ],
    BuildingsShowController,
);
