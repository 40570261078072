import { Instance, Knot, TableCalculation } from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { AdminService } from './adminService';
import { AssetService } from './assetService';
import { LanguageService } from './languageService';

export class AdminSubscriberService extends AdminService {
    constructor(
        instances: Instance,
        $languageService: LanguageService,
        $assetService: AssetService,
    ) {
        super(
            'subscriber-subscribers-name',
            instances,
            $languageService,
            $assetService,
        );
    }

    getTableOptions(): object {
        return {
            no_content: {
                image_url: this.assetService.getPath(
                    'images/others/no-content.png',
                ),
                text: this.languageService.translate('text.no_content'),
            },
            sort: {
                column: 'name',
                order: 'asc',
            },
            row_count: 12,
            column: 'last_name',
            columns: [
                'avatar',
                'last_name',
                'email',
                'validator_text',
                'is_valid',
                'allow_newsletter',
                'actions',
            ],
            calculations: this.getTableCalculations(),
        };
    }

    getTableFields(): string {
        return 'id,avatar,name,email,validator_text,is_valid,allow_newsletter';
    }

    getTableCalculations(): TableCalculation {
        return {
            avatar: (subscriber) => {
                const imageWrapper = new Knot('div');
                imageWrapper.addClass('img-wrapper');
                const imageKnot = new Knot('img');
                imageKnot.addClass('img-responsive');
                this.helper.setGravatar(
                    imageKnot,
                    this.assetService.getPath('images/avatars/male.png'),
                    subscriber.get('email'),
                    50,
                );
                imageWrapper.appendChild(imageKnot);
                return imageWrapper;
            },
            last_name: (subscriber) => {
                return subscriber.get('name');
            },
            is_valid: (subscriber) => {
                const isValid = subscriber.get('is_valid');
                const iconKnot = new Knot('em');
                iconKnot.addClass(['material-icons', 'text-success']);
                iconKnot.setHtml('check_circle');
                return isValid ? iconKnot : '';
            },
            allow_newsletter: (subscriber) => {
                const isValid = subscriber.get('allow_newsletter');
                const iconKnot = new Knot('em');
                iconKnot.addClass(['material-icons', 'text-success']);
                iconKnot.setHtml('check_circle');
                return isValid ? iconKnot : '';
            },
        };
    }
}

export const adminSubscriberService = app.service(
    resources.adminSubscriberService,
    [resources.instances, resources.languageService, resources.assetService],
    AdminSubscriberService,
);
