import {
    Form,
    format,
    Instance,
    LocationField,
    Objekt,
    SelectField,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { AssetService } from './assetService';
import { GroupService } from './groupService';
import { LanguageService } from './languageService';
import { LocationService } from './locationService';
import { MapService } from './mapService';
import { ModelService } from './modelService';
import { PersonService } from './personService';

export class EventService extends ModelService {
    constructor(
        instances: Instance,
        $languageService: LanguageService,
        $assetService: AssetService,
        private personService: PersonService,
        private groupService: GroupService,
        private locationService: LocationService,
        private mapService: MapService,
    ) {
        super('event-events-title', instances, $languageService, $assetService);
    }

    getAllByOrganization(organizationId: string, opt_params?: object) {
        return this.getAllBy('organizations', organizationId, opt_params);
    }

    createByOrganization(organizationId: string, event: Objekt) {
        return this.createBy('organizations', organizationId, event);
    }

    publish(event: Objekt, opt_types: Array<any> | undefined = []) {
        const eventId = event.get('id');
        return this.http.post(
            format('/api/v1/events/{0}/publish.json', [eventId]),
            {},
            {
                types: opt_types,
            },
        );
    }

    publishByPerson(
        event: Objekt,
        person: Objekt,
        opt_types: Array<any> | undefined = [],
    ) {
        const eventId = event.get('id');
        const personId = person.get('id');
        return this.http.post(
            format('/api/v1/people/{0}/events/{1}/publish.json', [
                personId,
                eventId,
            ]),
            {},
            {
                types: opt_types,
            },
        );
    }

    exportList(event: Objekt, opt_type: string | undefined) {
        const eventId = event.get('id');
        return this.http.get(
            format('/api/v1/events/{0}/export-list.docx', [eventId]),
            {
                type: opt_type,
            },
            {
                responseType: 'blob',
            },
        );
    }

    exportListByPerson(
        event: Objekt,
        person: Objekt,
        opt_type: string | undefined,
    ) {
        const eventId = event.get('id');
        const personId = person.get('id');
        return this.http.get(
            format('/api/v1/people/{0}/events/{1}/export-list.docx', [
                personId,
                eventId,
            ]),
            {
                type: opt_type,
            },
            {
                responseType: 'blob',
            },
        );
    }

    handleForm(form: Form, opt_entityName?: string): void {
        const participantsField = form.findByModel<SelectField>(
            'event.participant_ids',
        );
        const groupsField = form.findByModel<SelectField>('event.group_ids');
        const organizationField = form.findByModel<SelectField>(
            'event.organization_id',
        );
        const locationField = form.findByModel<LocationField>('event.location');

        organizationField.eventChange = (organizationId) => {
            if (organizationId) {
                this.handleLocationFieldByOrganization(
                    this.mapService,
                    this.locationService,
                    organizationId,
                    locationField,
                );

                participantsField.showLoader();
                this.personService
                    .getAllByOrganization(organizationId, {
                        fields: 'id,name',
                    })
                    .then((response) => {
                        const people = response.get<Objekt[]>('people');
                        participantsField.setOptions(people, 'id', 'name');
                    });
                groupsField.showLoader();
                this.groupService
                    .getAllByOrganization(organizationId, {
                        fields: 'id,name',
                    })
                    .then((response) => {
                        const groups = response.get<Objekt[]>('groups');
                        groupsField.setOptions(groups, 'id', 'name');
                    });
            } else {
                participantsField.setOptions([]);
                groupsField.setOptions([]);
            }
        };

        const organizationId = organizationField.getValue();
        this.handleLocationFieldByOrganization(
            this.mapService,
            this.locationService,
            organizationId,
            locationField,
        );
    }
}

export const eventService = app.service(
    resources.eventService,
    [
        resources.instances,
        resources.languageService,
        resources.assetService,
        resources.personService,
        resources.groupService,
        resources.locationService,
        resources.mapService,
    ],
    EventService,
);
