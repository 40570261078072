import {
    EventBus,
    Header,
    Instance,
    Service,
    State,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { LanguageService } from './languageService';

export class HeaderService extends Service {
    eventBus: EventBus;
    header: Header;
    state: State;

    constructor(
        instances: Instance,
        private languageService: LanguageService,
    ) {
        super();

        this.eventBus = instances.eventBus;
        this.header = instances.header;
        this.state = instances.state;
    }

    enter(): void {
        const appTitle = this.languageService.translate('application.name');
        this.header.setTitle(appTitle);

        const [id, params] = this.state.getRoot();
        this.header.setUrl(this.state.resolveUrl(id, params));

        this.header.eventLogoClick = () => {
            this.state.goRoot();
        };
    }
}

export const headerService = app.service(
    resources.headerService,
    [resources.instances, resources.languageService],
    HeaderService,
);
