import {
    EventBus,
    Helper,
    Instance,
    Query,
    Service,
    State,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';

export class PageHeaderService extends Service {
    helper: Helper;
    state: State;
    eventBus: EventBus;

    constructor(instances: Instance) {
        super();

        this.helper = instances.helper;
        this.state = instances.state;
        this.eventBus = instances.eventBus;
    }

    enter(): void {
        this.eventBus.set('dom.change', (state, dom) => {
            const breadcrumbs = new Query('.page-header a', dom);
            breadcrumbs.each((breadcrumb) => {
                this.helper.linkElement(breadcrumb, (href) => {
                    this.state.go(href);
                });
            });
        });
    }
}

export const pageHeaderService = app.service(
    resources.pageHeaderService,
    [resources.instances],
    PageHeaderService,
);
