import {
    Collection,
    contain,
    Deferred,
    Depot,
    EventBus,
    Form,
    format,
    inArray,
    Instance,
    neq,
    Objekt,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { AssetService } from './assetService';
import { AuthService } from './authService';
import { GoogleAnalytics4Service } from './googleAnalytics4Service';
import { LanguageService } from './languageService';
import { ModelService } from './modelService';
import { TokenService } from './tokenService';

export class UserService extends ModelService {
    eventBus: EventBus;
    sessionDepot: Depot;
    timezone: string;

    constructor(
        instances: Instance,
        $languageService: LanguageService,
        $assetService: AssetService,
        private authService: AuthService,
        private tokenService: TokenService,
        private googleAnalytics4Service: GoogleAnalytics4Service,
    ) {
        super('user-users-name', instances, $languageService, $assetService);

        this.eventBus = instances.eventBus;
        this.sessionDepot = instances.sessionDepot;

        this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

    enter(): void {
        this.eventBus.set('state.change', (state: Objekt) => {
            const refreshToken = state.get<string>('params.refresh-token');
            if (refreshToken) {
                this.sessionDepot.set('user.refresh_token', refreshToken);
                state.remove('params.refresh-token');
                this.sessionDepot.set('user.referer', state);
                this.logout(true);
                return false;
            }
            return this._autoLogin(state);
        });

        this.eventBus.set('http.beforeRequest', () => {
            this._setHttpAuthorization();
        });

        this.eventBus.set(
            'http.afterRequest',
            (httpRequest: XMLHttpRequest) => {
                const currentState = this.state.getCurrent<Objekt>();
                const stateId = currentState.get('id');
                const refreshToken = this.state.getParam('refresh-token');
                if (
                    inArray([401], httpRequest.status) &&
                    neq(stateId, 'users.login') &&
                    !refreshToken
                ) {
                    this.sessionDepot.set('user.referer', currentState);
                    this.logout(true);
                }
            },
        );
    }

    private _setHttpAuthorization(): void {
        const token = this.tokenService.getToken();
        this.http.setBearerAuthorization(token);
    }

    getUser<T = Objekt>(opt_attributes?: string): T {
        return this.authService.getUser<T>(opt_attributes);
    }

    isLoggedIn(): boolean {
        const token = this.tokenService.getToken();
        return !!token;
    }

    private _autoLogin(state: Objekt) {
        if (state.get('public', false)) {
            if (
                inArray(
                    ['users.login', 'users.registration', 'users.activation'],
                    state.get('id'),
                ) &&
                this.isLoggedIn()
            ) {
                this.state.go('site.home');
                return false;
            }
            return;
        }
        return this.reload();
    }

    reload() {
        const deferred = new Deferred();
        this.http.get('/api/v1/users/profile.json').then(
            (response) => {
                this.authService.setUser(response.get('user'));
                deferred.resolve();
            },
            () => {
                deferred.reject();
            },
        );
        return deferred.promise();
    }

    loginDialog() {
        const deferred = new Deferred<Objekt>();
        this.dialog
            .loadTemplate(format('/client/v1/users/auth.html', []))
            .then((dialogKnot) => {
                const form = new Form(dialogKnot);
                form.eventSubmit = (formData) => {
                    const authUserData = formData.get<Objekt>('auth_user');
                    this.dialog.close();
                    deferred.resolve(authUserData);
                };
                form.eventReset = () => {
                    this.dialog.close();
                    deferred.reject();
                };
                this.dialog.open();
            });
        this.dialog.eventCancel = () => {
            deferred.reject();
        };
        return deferred.promise();
    }

    loginWithToken(token: string, opt_auth: boolean | undefined = true) {
        const deferred = new Deferred<Objekt, Objekt>();
        const rememberMe = false;
        const user = new Objekt({
            token: token,
            timezone: this.timezone,
        });
        this.http.post('/api/v1/users/auth/token.json', user).then(
            (response) => {
                this._handleAuthUser(
                    response.get<Objekt>('user').merge({
                        remember_me: rememberMe,
                    }),
                    opt_auth,
                );
                deferred.resolve(response);
            },
            (response) => {
                deferred.reject(response);
            },
        );
        return deferred.promise();
    }

    loginWithEmail(user: Objekt, opt_auth: boolean | undefined = true) {
        const deferred = new Deferred<Objekt, Objekt>();
        const rememberMe = user.get('remember_me', false);
        user.set('timezone', this.timezone);
        this.http.post('/api/v1/users/auth.json', user).then(
            (response) => {
                this._handleAuthUser(
                    response.get<Objekt>('user').merge({
                        remember_me: rememberMe,
                    }),
                    opt_auth,
                );
                deferred.resolve(response);
            },
            (response) => {
                deferred.reject(response);
            },
        );
        return deferred.promise();
    }

    loginWithFacebook(
        token: string,
        opt_type: string | undefined = 'access_token',
        opt_auth: boolean | undefined = true,
    ) {
        return this.loginWithAuthToken('FACEBOOK', token, opt_type, opt_auth);
    }

    loginWithGoogle(
        token: string,
        opt_type: string | undefined = 'access_token',
        opt_auth: boolean | undefined = true,
    ) {
        return this.loginWithAuthToken('GOOGLE', token, opt_type, opt_auth);
    }

    loginWithApple(
        token: string,
        email: string,
        firstName: string,
        lastName: string,
        opt_type: string | undefined = 'id_token',
        opt_auth: boolean | undefined = true,
    ) {
        const provider = 'APPLE';
        const deferred = new Deferred<Objekt, Objekt>();
        this.http
            .post(
                format('/api/v1/users/auth/{0}.json', [provider.toLowerCase()]),
                {
                    email: email,
                    first_name: firstName,
                    last_name: lastName,
                    remember_me: true,
                    timezone: this.timezone,
                    [opt_type]: token,
                },
            )
            .then(
                (response) => {
                    const user = response.get<Objekt>('user');
                    this._handleSocialAuthUser(provider, user, opt_auth);
                    deferred.resolve(response);
                },
                (response) => {
                    deferred.reject(response);
                },
            );
        return deferred.promise();
    }

    loginWithAuthToken(
        provider: string,
        token: string,
        opt_type: string | undefined = 'access_token',
        opt_auth: boolean | undefined = true,
    ) {
        const deferred = new Deferred<Objekt, Objekt>();
        this.http
            .post(
                format('/api/v1/users/auth/{0}.json', [provider.toLowerCase()]),
                {
                    remember_me: true,
                    timezone: this.timezone,
                    [opt_type]: token,
                },
            )
            .then(
                (response) => {
                    const user = response.get<Objekt>('user');
                    this._handleSocialAuthUser(provider, user, opt_auth);
                    deferred.resolve(response);
                },
                (response) => {
                    deferred.reject(response);
                },
            );
        return deferred.promise();
    }

    loginWithMicrosoft(
        code: string,
        redirectURI: string,
        opt_auth: boolean | undefined = true,
    ) {
        return this.loginWithAuthCode('MICROSOFT', code, redirectURI, opt_auth);
    }

    loginWithAuthCode(
        provider: string,
        code: string,
        redirectURI: string,
        opt_auth: boolean | undefined = true,
    ) {
        const deferred = new Deferred<Objekt, Objekt>();
        this.http
            .post(
                format('/api/v1/users/auth/{0}.json', [provider.toLowerCase()]),
                {
                    remember_me: true,
                    timezone: this.timezone,
                    code: code,
                    redirect_uri: redirectURI,
                },
            )
            .then(
                (response) => {
                    const user = response.get<Objekt>('user');
                    this._handleSocialAuthUser(provider, user, opt_auth);
                    deferred.resolve(response);
                },
                (response) => {
                    deferred.reject(response);
                },
            );
        return deferred.promise();
    }

    private _handleSocialAuthUser(
        provider: string,
        user: Objekt,
        opt_auth: boolean | undefined = true,
    ): void {
        const url = this.state.getCurrent<string>('url');
        this.googleAnalytics4Service.sendSocial(provider, 'auth', url);
        user.set('remember_me', true);
        this._handleAuthUser(user, opt_auth);
    }

    private _handleAuthUser(
        user: Objekt,
        opt_auth: boolean | undefined = true,
    ): void {
        if (opt_auth) {
            const token = user.get<string>('token');
            const hours = user.get<number>('hours');
            const rememberMe = user.get<boolean>('remember_me');
            this.tokenService.setToken(token, hours, rememberMe);

            this.authService.setUser(user);
        }
    }

    logout(opt_withReferer: boolean | undefined = false): void {
        if (!opt_withReferer) {
            this.sessionDepot.remove('user.refresh_token');
            this.sessionDepot.remove('user.referer');
        }
        this.authService.removeUser();
        this.tokenService.removeToken();
        this.state.go('users.login');
    }

    activation(code: string) {
        return this.http.post(
            '/api/v1/users/activation.json',
            {},
            {},
            {
                Authorization: format('Bearer {0}', [code]),
            },
        );
    }

    invite(user: Objekt) {
        return this.http.post('/api/v1/users/invite.json', user);
    }

    recoveryPassword(user: Objekt) {
        return this.http.put('/api/v1/users/recovery-password.json', user);
    }

    changePassword(user: Objekt, code: string) {
        return this.http.put(
            '/api/v1/users/change-password.json',
            user,
            {},
            {
                Authorization: format('Bearer {0}', [code]),
            },
        );
    }

    merge(token: string) {
        return this.http.post('/api/v1/users/merge.json', {
            token,
        });
    }

    removeSocial(social: string) {
        return this.http.delete(
            format('/api/v1/users/socials/{0}.json', [
                social.replace(/_/g, '-'),
            ]),
        );
    }

    hasLicense(): boolean {
        const licenses = this.authService.getUser('licenses', []);
        return licenses.length > 0;
    }

    hasLicenseByType(
        licenseType: string,
        opt_organizationId: string | undefined,
    ): boolean {
        const licenses = this.authService.getUser<Objekt[]>('licenses', []);
        const licenseCollection = new Collection(licenses);
        const result = licenseCollection.findByCondition((license) => {
            const typeCondition = license.get('license_type') === licenseType;
            return typeCondition && opt_organizationId
                ? license.get('organization_id') === opt_organizationId
                : true;
        });
        return !!result;
    }

    hasAccessByEndpoint(
        entityType: string,
        entityId: string,
        method: string,
        opt_key_id: string | undefined,
    ) {
        const deferred = new Deferred();
        this.http
            .get(
                format('/api/v1/users/access/{0}/{1}/{2}.json', [
                    entityType,
                    entityId,
                    method,
                ]),
                {
                    key_id: opt_key_id,
                },
            )
            .then(
                (response) => {
                    deferred.resolve(response.get('access'));
                },
                () => {
                    deferred.resolve(false);
                },
            );
        return deferred.promise();
    }

    hasAccessByRole(roleId: string): boolean {
        const roles = this.authService.getUser<string[]>('roles', []);
        let result = inArray(roles, roleId);
        if (!result) {
            let i = 0;
            while (i < roles.length && !result) {
                if (contain(roles[i], roleId)) {
                    result = true;
                }
                i++;
            }
        }
        return result;
    }

    hasAccessByOrganization(
        organizationId: string,
        opt_roleId: string | undefined = '',
    ): boolean {
        const roleId = [organizationId, opt_roleId].join('.');
        return this.hasAccessByRole(roleId);
    }
}

export const userService = app.service(
    resources.userService,
    [
        resources.instances,
        resources.languageService,
        resources.assetService,
        resources.authService,
        resources.tokenService,
        resources.googleAnalytics4Service,
    ],
    UserService,
);
