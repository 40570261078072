import {
    DateIO,
    Instance,
    Objekt,
    TableCalculation,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { createHunterTicketIdentifierCell } from '../utils/tableHelper';
import { AssetService } from './assetService';
import { LanguageService } from './languageService';
import { ModelService } from './modelService';

export class HunterTicketService extends ModelService {
    constructor(
        instances: Instance,
        $languageService: LanguageService,
        $assetService: AssetService,
    ) {
        super(
            'hunter_ticket-hunter_tickets-identifier',
            instances,
            $languageService,
            $assetService,
        );
    }

    getAllByUser(userId: string, opt_params?: object) {
        return this.getAllBy('users', userId, opt_params);
    }

    createByUser(userId: string, hunterTicket: Objekt) {
        return this.createBy('users', userId, hunterTicket);
    }

    newDialogByUser(userId: string) {
        return this.newDialogBy('users', userId);
    }

    getTableOptions(): object {
        return {
            no_content: {
                image_url: this.assetService.getPath(
                    'images/others/no-content.png',
                ),
                text: this.languageService.translate('text.no_content'),
            },
            sort: {
                column: 'expired',
                order: 'desc',
            },
            row_count: 12,
            column: 'identifier',
            columns: ['identifier', 'country.name', 'expired', 'actions'],
            sorted: ['expired:desc'],
            calculations: this.getTableCalculations(),
        };
    }

    getTableFields(): string {
        return 'id,identifier,validity,country.name,expired';
    }

    getTableCalculations(): TableCalculation {
        return {
            identifier: (hunterTicket) => {
                const identifier = hunterTicket.get<string>('identifier');
                const validity = hunterTicket.get('validity', null);

                return createHunterTicketIdentifierCell(identifier, validity);
            },
            expired: (hunterTicket) => {
                const expired = DateIO.parse(hunterTicket.get('expired'));
                return this.languageService.format(expired, 'format.date');
            },
        };
    }
}

export const hunterTicketService = app.service(
    resources.hunterTicketService,
    [resources.instances, resources.languageService, resources.assetService],
    HunterTicketService,
);
