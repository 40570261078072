import { Instance } from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { AssetService } from './assetService';
import { LanguageService } from './languageService';
import { ModelService } from './modelService';

export class LocationService extends ModelService {
    constructor(
        instances: Instance,
        $languageService: LanguageService,
        $assetService: AssetService,
    ) {
        super(
            'location-locations-title',
            instances,
            $languageService,
            $assetService,
        );
    }

    getAllByOrganization(organizationId: string, opt_params?: object) {
        return this.getAllBy('organizations', organizationId, opt_params);
    }

    search(organizationId: string, address: string) {
        return this.getAllByOrganization(organizationId, {
            query: address,
            fields: 'latitude,longitude',
            limit: 1,
            column: 'created_at',
            order: 'desc',
        });
    }
}

export const locationService = app.service(
    resources.locationService,
    [resources.instances, resources.languageService, resources.assetService],
    LocationService,
);
