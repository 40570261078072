import { Depot, Instance, Objekt, Promize, Table } from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { ReportService } from '../services/reportService';
import { TrophyService } from '../services/trophyService';
import { DashboardController } from './dashboardController';

export class ReportsBuildingsWhatTheyShootController extends DashboardController {
    localDepot: Depot;
    table: Table;

    constructor(
        instances: Instance,
        private reportService: ReportService,
        private trophyService: TrophyService,
    ) {
        super(instances);

        this.localDepot = instances.localDepot;
    }

    protected override _initLayout(): void {
        this._initToolbarButtons();

        this._drawContent();
    }

    protected override _drawContent(): Promize | boolean {
        this._initTrophyTable();
        return true;
    }

    private _initToolbarButtons(): void {
        this.helper.iconButton(
            '.btn-reports-index',
            this.dom,
            () => {
                this.state.goBack('reports.index');
            },
            '',
            true,
            [],
        );

        this.helper.iconButton('.btn-reports-filter', this.dom, () => {
            this.reportService.detailsDialog('buildings');
        });
    }

    private _initTrophyTable(): void {
        if (!this.table) {
            this.table = new Table(
                this.dom,
                '.trophies-table',
                this.trophyService.getTableOptions(),
            );

            this.table.eventAction = (params) => {
                params.set('fields', this.trophyService.getTableFields());
                const buildingsReport = new Objekt(
                    this.localDepot.get(
                        this.reportService.getStorageKeyReport('buildings'),
                    ),
                );
                this.reportService
                    .getAllByBuildings(buildingsReport, params)
                    .then((response) => {
                        const count = response.get<number>('count');
                        this.table.setCount(count);
                        const trophies = response.get<Objekt[]>('trophies');
                        this.table.setData(trophies);
                    });
            };

            this.table.render();
        } else {
            this.table.refresh();
        }
    }
}

export const reportsBuildingsWhatTheyShootController = app.controller(
    resources.reportsBuildingsWhatTheyShootController,
    [resources.instances, resources.reportService, resources.trophyService],
    ReportsBuildingsWhatTheyShootController,
);
