import {
    CardCollection,
    Instance,
    Knot,
    Objekt,
    Promize,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { AssetService } from '../services/assetService';
import { ChamberService } from '../services/chamberService';
import { LanguageService } from '../services/languageService';
import { DashboardController } from './dashboardController';

export class ChambersIndexController extends DashboardController {
    chamberCardCollection: CardCollection;

    constructor(
        instances: Instance,
        private chamberService: ChamberService,
        private assetService: AssetService,
        private languageService: LanguageService,
    ) {
        super(instances);
    }

    protected override _initLayout(): void {
        this._initToolbarButtons();
        this._initFilterForm();

        this._drawContent();
    }

    protected override _drawContent(): Promize | boolean {
        this._initChamberCardCollection();
        return true;
    }

    private _initToolbarButtons(): void {
        this.helper.iconButton(
            '.btn-site-home',
            this.dom,
            () => {
                this.state.goBack('site.home');
            },
            '',
            true,
            [],
        );
    }

    private _initChamberCardCollection(): void {
        if (!this.chamberCardCollection) {
            this.chamberCardCollection = new CardCollection(
                this.dom,
                '.chamber-collection',
                {},
                {
                    no_content: {
                        image_url: this.assetService.getPath(
                            'images/others/no-content.png',
                        ),
                        text: this.languageService.translate('text.no_content'),
                    },
                    row_count: 12,
                    sort: {
                        column: 'name',
                        order: 'asc',
                    },
                },
            );
            this.chamberCardCollection.eventCardKnot =
                this._eventCardKnot.bind(this);
            this.chamberCardCollection.eventAction = (params) => {
                params.set('query', this.query);
                params.set('fields', 'id,name,logo');
                this.chamberService.getAll(params).then((response) => {
                    const count = response.get<number>('count');
                    this.chamberCardCollection.setCount(count);
                    const chambers = response.get<Objekt[]>('chambers');
                    this.chamberCardCollection.setData(chambers);
                });
            };
            this.chamberCardCollection.render();
        } else {
            this.chamberCardCollection.refresh(1);
        }
    }

    private _eventCardKnot(cardKnot: Knot, chamber: Objekt): void {
        this._initChamberShowButton(cardKnot, chamber);
    }

    private _initChamberShowButton(cardKnot: Knot, chamber: Objekt): void {
        this.helper.link(
            'a.card',
            cardKnot,
            (href) => {
                this.state.go(href);
            },
            this.state.resolveUrl('chambers.show', {
                chamberId: chamber.get('id'),
            }),
        );
    }
}

export const chambersIndexController = app.controller(
    resources.chambersIndexController,
    [
        resources.instances,
        resources.chamberService,
        resources.assetService,
        resources.languageService,
    ],
    ChambersIndexController,
);
