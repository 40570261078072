import { Instance, Query } from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { DashboardController } from './dashboardController';

export class AdminIndexController extends DashboardController {
    constructor(instances: Instance) {
        super(instances);
    }

    protected override _initLayout(): void {
        this._initMenu();
    }

    private _initMenu(): void {
        const contentNodes = new Query('a', this.dom);
        contentNodes.each((node) => {
            this.helper.linkElement(
                node,
                (href) => {
                    this.state.go(href);
                },
                this.state.resolveUrl(node.getAttribute('data-state-id')),
            );
        });
    }
}

export const adminIndexController = app.controller(
    resources.adminIndexController,
    [resources.instances],
    AdminIndexController,
);
