import { format, Http, Instance, Service } from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';

export class SiteService extends Service {
    http: Http;

    constructor(instances: Instance) {
        super();

        this.http = instances.http;
    }

    getHome() {
        return this.http.get(format('/api/v1/site/home.json', []));
    }

    getCookies() {
        return this.http.get(format('/api/v1/site/cookies.json', []));
    }

    getPersonalData() {
        return this.http.get(format('/api/v1/site/personal-data.json', []));
    }

    getSoftwareData() {
        return this.http.get(format('/api/v1/site/software-data.json', []));
    }

    getExchangeRate(opt_price: number | undefined = 0) {
        return this.http.get(format('/api/v1/site/exchange-rate.json', []), {
            price: opt_price,
        });
    }
}

export const siteService = app.service(
    resources.siteService,
    [resources.instances],
    SiteService,
);
