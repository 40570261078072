import { Instance } from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { AssetService } from './assetService';
import { HuntService } from './huntService';
import { LanguageService } from './languageService';
import { LocationService } from './locationService';
import { MapService } from './mapService';
import { PersonService } from './personService';
import { TrophyService } from './trophyService';
import { WildService } from './wildService';

export class BigGameTrophyService extends TrophyService {
    constructor(
        instances: Instance,
        $languageService: LanguageService,
        $assetService: AssetService,
        $personService: PersonService,
        $huntService: HuntService,
        $wildService: WildService,
        $locationService: LocationService,
        $mapService: MapService,
    ) {
        super(
            'big_game_trophy-big_game_trophies-full_name',
            instances,
            $languageService,
            $assetService,
            $personService,
            $huntService,
            $wildService,
            $locationService,
            $mapService,
        );
    }
}

export const bigGameTrophyService = app.service(
    resources.bigGameTrophyService,
    [
        resources.instances,
        resources.languageService,
        resources.assetService,
        resources.personService,
        resources.huntService,
        resources.wildService,
        resources.locationService,
        resources.mapService,
    ],
    BigGameTrophyService,
);
