import {
    Confirm,
    Deferred,
    Dialog,
    eq,
    Flash,
    Form,
    format,
    GoogleMap,
    Instance,
    Knot,
    Objekt,
    Promize,
    Table,
} from '@siposdani87/sui-js';
import { saveAs } from 'file-saver';
import { app } from '../app';
import { resources } from '../resources';
import { EventService } from '../services/eventService';
import { ItemService } from '../services/itemService';
import { LanguageService } from '../services/languageService';
import { MapService } from '../services/mapService';
import { NotificationHistoryService } from '../services/notificationHistoryService';
import { NotificationService } from '../services/notificationService';
import { UserService } from '../services/userService';
import { UtilService } from '../services/utilService';
import { DashboardController } from './dashboardController';

export class EventsShowController extends DashboardController {
    eventId: string;
    event: Objekt;
    btnNotificationsExportKnot: Knot<HTMLElement>;
    btnEventsEditKnot: Knot<HTMLElement>;
    btnEventsPublishKnot: Knot<HTMLElement>;
    btnItemsNewKnot: Knot<HTMLElement>;
    organizationId: string;
    map: GoogleMap;
    notificationsTable: Table;
    itemsTable: Table;
    private flash: Flash;
    private confirm: Confirm;
    private dialog: Dialog;

    constructor(
        instances: Instance,
        private mapService: MapService,
        private eventService: EventService,
        private notificationService: NotificationService,
        private notificationHistoryService: NotificationHistoryService,
        private itemService: ItemService,
        private userService: UserService,
        private utilService: UtilService,
        private languageService: LanguageService,
    ) {
        super(instances);

        this.flash = instances.flash;
        this.confirm = instances.confirm;
        this.dialog = instances.dialog;
    }

    protected override _initLayout(): void {
        this.eventId = this.state.getParam('eventId');
        this.event = new Objekt();

        this._initToolbarButtons();
        this._initTabPanel('details');
        this._changeToolbarButtons();

        this._initLinks('#details a');
        this._correctDates();

        this._loadEvent().then(() => {
            this._drawContent();
        });
    }

    private _initToolbarButtons(): void {
        this.helper.iconButton(
            '.btn-events-index',
            this.dom,
            () => {
                this.state.goBack('events.index');
            },
            '',
            true,
            [],
        );

        this.btnNotificationsExportKnot = this.helper.iconButton(
            '.btn-notifications-export',
            this.dom,
            () => {
                this._exportNotificationsDialog();
            },
        );

        this.btnEventsEditKnot = this.helper.iconButton(
            '.btn-events-edit',
            this.dom,
            () => {
                this.eventService
                    .editDialog(this.event)
                    .then(this.utilService.handleEditResponse('events.index'));
            },
        );

        this.btnEventsPublishKnot = this.helper.iconButton(
            '.btn-events-publish',
            this.dom,
            () => {
                this._publishEvent(this.event);
            },
        );

        this.btnItemsNewKnot = this.helper.iconButton(
            '.btn-items-new',
            this.dom,
            () => {
                this.itemService.newDialogByEvent(this.eventId).then(() => {
                    this.state.refresh(true);
                });
            },
        );
    }

    protected override _drawContent(): Promize | boolean {
        this._changeToolbarButtons();

        this._initMap();
        this._initNotificationsTable();
        this._initItemsTable();
        return true;
    }

    private _changeToolbarButtons(): void {
        this.utilService.showButton(
            this.btnEventsEditKnot,
            this.activeTab === 'details',
        );

        this.utilService.showButton(
            this.btnEventsPublishKnot,
            this.activeTab === 'notifications-table',
        );

        this.utilService.showButton(
            this.btnNotificationsExportKnot,
            this.activeTab === 'notifications-table',
        );

        this.utilService.showButton(
            this.btnItemsNewKnot,
            this.activeTab === 'item-table',
        );
    }

    private _correctDates(): void {
        this.utilService.correctDate('.start-at', 'format.datetime', this.dom);
    }

    private _loadEvent(): Promize {
        const deferred = new Deferred();
        this.eventService
            .get(this.eventId, {
                fields: 'id,title,location',
            })
            .then(
                (response) => {
                    this.event = response.get('event');
                    this.organizationId = this.event.get('organization_id');
                    deferred.resolve();
                },
                () => {
                    deferred.reject();
                },
            );
        return deferred.promise();
    }

    private _initMap(): void {
        if (eq(this.activeTab, 'details')) {
            if (!this.map) {
                this.map = this.mapService.createGoogleMap(this.dom);

                this.map.setMarkerIcon(
                    'location',
                    this.mapService.getIcon('location', '#795548'),
                );
                this.map.eventMarkerClick = this._eventMarkerClick.bind(this);
            }

            this._setMap();
        }
    }

    private _setMap(): void {
        this.map.removeAllMarker();

        const eventId = this.event.get<string>('id');
        const location = this.event.get<Objekt>('location', new Objekt());
        const latitude = location.get<number>('latitude');
        const longitude = location.get<number>('longitude');

        this.map.createMarker(
            eventId,
            '',
            'location',
            latitude,
            longitude,
            this.event,
        );
        this.map.setCenter(latitude, longitude);
    }

    private _eventMarkerClick(event: Objekt): void {
        const eventId = event.get<string>('id');
        const address = this.event.get<string>('location.address');
        this.map.openInfoWindow(eventId, address);
    }

    private _initNotificationsTable(): void {
        if (eq(this.activeTab, 'notifications-table')) {
            if (!this.notificationsTable) {
                this.notificationsTable = new Table(
                    this.dom,
                    '.notifications-table',
                    this.notificationHistoryService.getTableOptions(),
                );

                this.notificationsTable.setActions([
                    {
                        style: () => {
                            return [
                                'file_download',
                                this.languageService.translate(
                                    'buttons.download',
                                ),
                            ];
                        },
                        click: (notification) => {
                            const person = notification.get<Objekt>('person');
                            this._exportNotificationsDialog(person).then(() => {
                                this.notificationsTable.refresh();
                            });
                        },
                    },
                    {
                        style: () => {
                            return [
                                'send',
                                this.languageService.translate(
                                    'buttons.resend',
                                ),
                            ];
                        },
                        click: (notification) => {
                            this._publishNoteByPersonConfirm(notification).then(
                                () => {
                                    this.notificationsTable.refresh();
                                },
                            );
                        },
                    },
                ]);

                this.notificationsTable.eventAction = (params) => {
                    params.set(
                        'fields',
                        this.notificationHistoryService.getTableFields(),
                    );
                    this.notificationService
                        .getAllByEvent(this.eventId, params)
                        .then((response) => {
                            const count = response.get<number>('count');
                            this.notificationsTable.setCount(count);
                            const notifications =
                                response.get<Objekt[]>('notifications');
                            this.notificationsTable.setData(notifications);
                        });
                };

                this.notificationsTable.render();
            } else {
                this.notificationsTable.refresh();
            }
        }
    }

    private _publishNoteByPersonConfirm(notification: Objekt): Promize {
        const deferred = new Deferred();
        this.notificationHistoryService
            .openChooseTypeDialog(
                this.notificationHistoryService.getTypes(notification),
            )
            .then(
                (types) => {
                    this.confirm.load(
                        this.languageService.translate(
                            'events_controller.publish.confirm',
                            {
                                event_title: this.event.get('title'),
                                person_name: notification.get('person.name'),
                            },
                        ),
                        this.languageService.translate('buttons.resend'),
                        this.languageService.translate('buttons.cancel'),
                        '',
                        'warning',
                    );
                    this.confirm.eventOK = () => {
                        const person = notification.get<Objekt>('person');
                        this.eventService
                            .publishByPerson(this.event, person, types)
                            .then(
                                (response) => {
                                    this.flash.addMessage(
                                        response.get('message'),
                                    );
                                    deferred.resolve();
                                },
                                (response) => {
                                    this.flash.addMessage(
                                        response.get('message'),
                                    );
                                    deferred.reject();
                                },
                            );
                    };
                    this.confirm.eventCancel = () => {
                        deferred.reject();
                    };
                    this.confirm.open();
                },
                () => {
                    deferred.reject();
                },
            );
        return deferred.promise();
    }

    private _publishEvent(event: Objekt): void {
        this.notificationHistoryService.openChooseTypeDialog().then((types) => {
            this.eventService.publish(event, types).then(
                (response) => {
                    this.flash.addMessage(response.get('message'));
                    this._drawContent();
                },
                (response) => {
                    this.flash.addMessage(response.get('message'));
                },
            );
        });
    }

    private _initItemsTable(): void {
        if (eq(this.activeTab, 'item-table')) {
            if (!this.itemsTable) {
                this.itemsTable = new Table(
                    this.dom,
                    '.items-table',
                    this.itemService.getTableOptions(),
                );

                this.itemsTable.setActions([
                    {
                        style: () => {
                            return [
                                'edit',
                                this.languageService.translate('buttons.edit'),
                                false,
                                !this.userService.hasAccessByOrganization(
                                    this.organizationId,
                                    'items_write',
                                ),
                            ];
                        },
                        click: (item) => {
                            this.itemService.editDialog(item).then(() => {
                                this.state.refresh(true);
                            });
                        },
                    },
                ]);

                this.itemsTable.eventAction = (params) => {
                    params.set('fields', this.itemService.getTableFields());
                    this.itemService
                        .getAllByEvent(this.eventId, params)
                        .then((response) => {
                            const count = response.get<number>('count');
                            this.itemsTable.setCount(count);
                            const items = response.get<Objekt[]>('items');
                            this.itemsTable.setData(items);
                        });
                };
                this.itemsTable.render();
            } else {
                this.itemsTable.refresh();
            }
        }
    }

    private _exportNotificationsDialog(opt_person?: Objekt): Promize {
        const deferred = new Deferred();
        this.dialog
            .loadTemplate(
                format('/client/v1/events/{0}/export.html', [this.eventId]),
            )
            .then((dialogKnot) => {
                const form = new Form(dialogKnot);
                form.eventSubmit = (formData) => {
                    const eventData = formData.get<Objekt>('event');
                    const type = eventData.get<string>('export');
                    if (opt_person) {
                        this._exportNotificationsByPerson(
                            this.event,
                            opt_person,
                            type,
                        ).defer(deferred);
                    } else {
                        this._exportNotifications(this.event, type).defer(
                            deferred,
                        );
                    }
                };
                form.eventReset = () => {
                    this.dialog.close();
                    deferred.reject();
                };
                this.dialog.open();
            });
        return deferred.promise();
    }

    private _exportNotifications(event: Objekt, type: string): Promize {
        const deferred = new Deferred();
        this.eventService.exportList(event, type).then(
            (response, filename) => {
                saveAs(response.get('raw'), filename);
                this.dialog.close();
                deferred.resolve();
            },
            (response) => {
                this.flash.addMessage(response.get('message'));
                deferred.reject();
            },
        );
        return deferred.promise();
    }

    private _exportNotificationsByPerson(
        event: Objekt,
        person: Objekt,
        type: string,
    ): Promize {
        const deferred = new Deferred();
        this.eventService.exportListByPerson(event, person, type).then(
            (response, filename) => {
                saveAs(response.get('raw'), filename);
                this.dialog.close();
                deferred.resolve();
            },
            (response) => {
                this.flash.addMessage(response.get('message'));
                deferred.reject();
            },
        );
        return deferred.promise();
    }

    exit(): void {
        super.exit();

        this.mapService.removeGoogleMap(this.map);
    }
}

export const eventsShowController = app.controller(
    resources.eventsShowController,
    [
        resources.instances,
        resources.mapService,
        resources.eventService,
        resources.notificationService,
        resources.notificationHistoryService,
        resources.itemService,
        resources.userService,
        resources.utilService,
        resources.languageService,
    ],
    EventsShowController,
);
