import { Cookie, Depot, Instance, Service } from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';

export class TokenService extends Service {
    localDepot: Depot;
    sessionDepot: Depot;
    cookie: Cookie;
    tokenKey: string;

    constructor(instances: Instance) {
        super();

        this.localDepot = instances.localDepot;
        this.sessionDepot = instances.sessionDepot;
        this.cookie = instances.cookie;

        this.tokenKey = 'user.token';
    }

    setToken(
        token: string,
        opt_hours: number | undefined = Infinity,
        opt_rememberMe: boolean | undefined = false,
    ): void {
        if (opt_rememberMe) {
            this.localDepot.set(this.tokenKey, token, opt_hours);
        } else {
            this.sessionDepot.set(this.tokenKey, token, opt_hours);
        }
        this.cookie.set(this.tokenKey, token, opt_hours);
    }

    getToken(): string {
        let token = this.localDepot.get(this.tokenKey);
        if (!token) {
            token = this.sessionDepot.get(this.tokenKey);
        }
        return token;
    }

    removeToken(): void {
        this.localDepot.remove(this.tokenKey);
        this.sessionDepot.remove(this.tokenKey);
        this.cookie.remove(this.tokenKey);
    }
}

export const tokenService = app.service(
    resources.tokenService,
    [resources.instances],
    TokenService,
);
