import {
    Controller,
    Deferred,
    Http,
    Instance,
    Knot,
    Navigation,
    Objekt,
    Promize,
    Query,
    State,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { AssetService } from '../services/assetService';
import { LanguageService } from '../services/languageService';
import { UserService } from '../services/userService';

export class UsersHomeController extends Controller {
    http: Http;
    userId: string;
    user: Objekt;
    navigation: Navigation;
    state: State;
    dom: Knot;

    constructor(
        instances: Instance,
        private assetService: AssetService,
        private languageService: LanguageService,
        private userService: UserService,
    ) {
        super();

        this.http = instances.http;
        this.state = instances.state;
        this.dom = instances.dom;
    }

    enter(): void {
        this._initLayout();
    }

    protected _initLayout(): void {
        this.userId = this.userService.getUser('id');
        this.user = new Objekt();

        this._loadUser().then(() => {
            this._renderMenu();
        });
    }

    private _loadUser(): Promize {
        const deferred = new Deferred();
        this.userService
            .get(this.userId, {
                fields: 'name,avatar',
            })
            .then(
                (response) => {
                    this.user = response.get('user');
                    deferred.resolve();
                },
                () => {
                    deferred.reject();
                },
            );
        return deferred.promise();
    }

    private _renderMenu(): void {
        this.navigation = new Navigation(this.http);

        this.navigation.addImage(
            'users.profile',
            this.user.get('avatar.thumb.url'),
            this.user.get('name'),
            (href) => {
                this.state.go(href);
            },
            this.state.resolveUrl('users.profile'),
        );

        this.navigation.addImage(
            'observations.index-by-user',
            this.assetService.getPath('images/icons/telescope.svg'),
            this.languageService.translate('captions.observations.my_index'),
            (href) => {
                this.state.go(href);
            },
            this.state.resolveUrl('observations.index-by-user', {
                userId: this.userId,
            }),
        );

        this.navigation.addImage(
            'memberships.index',
            this.assetService.getPath('images/icons/organization.svg'),
            this.languageService.translate('captions.memberships.my_index'),
            (href) => {
                this.state.go(href);
            },
            this.state.resolveUrl('memberships.index', {
                userId: this.userId,
            }),
        );
        if (!this.userService.hasAccessByRole('.organizations_read')) {
            this.navigation.setDisabled('memberships.index');
        }

        this.navigation.addImage(
            'items.index-by-user',
            this.assetService.getPath('images/icons/dollar.svg'),
            this.languageService.translate('captions.items.my_index'),
            (href) => {
                this.state.go(href);
            },
            this.state.resolveUrl('items.index-by-user', {
                userId: this.userId,
            }),
        );
        if (!this.userService.hasAccessByRole('.organizations_read')) {
            this.navigation.setDisabled('items.index-by-user');
        }

        this.navigation.addImage(
            'hunts.index-by-user',
            this.assetService.getPath('images/icons/duck.svg'),
            this.languageService.translate('captions.hunts.my_index'),
            (href) => {
                this.state.go(href);
            },
            this.state.resolveUrl('hunts.index-by-user', {
                userId: this.userId,
            }),
        );
        if (!this.userService.hasAccessByRole('.organizations_read')) {
            this.navigation.setDisabled('hunts.index-by-user');
        }

        this.navigation.addImage(
            'trophies.index-by-user',
            this.assetService.getPath('images/icons/trophy.svg'),
            this.languageService.translate('captions.trophies.my_index'),
            (href) => {
                this.state.go(href);
            },
            this.state.resolveUrl('trophies.index-by-user', {
                userId: this.userId,
            }),
        );
        if (!this.userService.hasAccessByRole('.organizations_read')) {
            this.navigation.setDisabled('trophies.index-by-user');
        }

        const menuKnot = new Query('.link-menu', this.dom).getKnot();
        menuKnot.removeChildren();

        this.navigation.each((item) => {
            const node = item.get('node');
            menuKnot.appendChild(node);
        });
    }
}

export const usersHomeController = app.controller(
    resources.usersHomeController,
    [
        resources.instances,
        resources.assetService,
        resources.languageService,
        resources.userService,
    ],
    UsersHomeController,
);
