import {
    Controller,
    Flash,
    Form,
    Helper,
    Instance,
    Knot,
    Objekt,
    State,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { MilitaryService } from '../services/militaryService';
import { OrganizationService } from '../services/organizationService';
import { OrganizationUserService } from '../services/organizationUserService';
import { UserService } from '../services/userService';

export class OrganizationsConnectController extends Controller {
    organizationId: string;
    organization: Objekt;
    page: Objekt;
    private helper: Helper;
    private state: State;
    private dom: Knot;
    private flash: Flash;

    constructor(
        instances: Instance,
        private organizationUserService: OrganizationUserService,
        private organizationService: OrganizationService,
        private militaryService: MilitaryService,
        private userService: UserService,
    ) {
        super();

        this.helper = instances.helper;
        this.state = instances.state;
        this.dom = instances.dom;
        this.flash = instances.flash;
    }

    enter(): void {
        this._initLayout();

        this.militaryService.draw(
            this.dom,
            ['#181b12', '#1d2015', '#1a2213', '#1c2415', '#1f2b17', '#223019'],
            0.7,
        );
    }

    protected _initLayout(): void {
        this.organizationId = this.state.getParam('organizationId');

        this.organization = new Objekt();
        this.page = new Objekt();

        this._initButtons();
        this._initConnectionForm();

        this._loadPublic();
    }

    private _initConnectionForm(): void {
        const form = new Form(this.dom);
        form.eventSubmit = (formData) => {
            form.lock();
            const user = this.userService.getUser();
            const pinCode = formData.get<string>(
                'organization.security.pin_code',
            );
            this.organizationUserService
                .connect(this.organization, user, pinCode)
                .then(
                    (response) => {
                        this.flash.addMessage(response.get('message'));
                        this.state.go('organizations.index');
                    },
                    (response) => {
                        this.flash.addMessage(response.get('message'));
                        form.unlock();
                        form.setErrors(response.get('errors'));
                    },
                );
        };
    }

    private _loadPublic(): void {
        this.organizationService
            .getPublic(this.organizationId, {
                // fields: '',
            })
            .then((response) => {
                this.organization = response.get('organization');
                this.page = response.get('page');
            });
    }

    private _initButtons(): void {
        this.helper.iconButton(
            '.close',
            this.dom,
            () => {
                this.state.go('organizations.index');
            },
            '',
            true,
            [],
        );

        this.helper.link(
            '.btn-cancel',
            this.dom,
            (href) => {
                this.state.go(href);
            },
            this.state.resolveUrl('organizations.index'),
        );
    }

    exit(): void {
        super.exit();

        this.militaryService.reset();
    }
}

export const organizationsConnectController = app.controller(
    resources.organizationsConnectController,
    [
        resources.instances,
        resources.organizationUserService,
        resources.organizationService,
        resources.militaryService,
        resources.userService,
    ],
    OrganizationsConnectController,
);
