import {
    Async,
    Deferred,
    Flash,
    GoogleMap,
    Instance,
    Knot,
    LatLng,
    noop,
    Objekt,
    Promize,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { AreaService } from '../services/areaService';
import { LanguageService } from '../services/languageService';
import { MapService } from '../services/mapService';
import { OrganizationService } from '../services/organizationService';
import { UserService } from '../services/userService';
import { UtilService } from '../services/utilService';
import { DashboardController } from './dashboardController';

export class AreasIndexController extends DashboardController {
    private organizationId: string;
    private selectedArea: Objekt;
    private newAreaId: string;
    private organization: Objekt;
    private area: Objekt = new Objekt();
    private map: GoogleMap;
    private hasAccessAreaEdit: boolean;
    private btnAreasNewKnot: Knot;
    private btnAreasEditKnot: Knot;
    private flash: Flash;

    constructor(
        instances: Instance,
        private organizationService: OrganizationService,
        private areaService: AreaService,
        private mapService: MapService,
        private utilService: UtilService,
        private userService: UserService,
        private languageService: LanguageService,
    ) {
        super(instances);

        this.flash = instances.flash;
    }

    protected override _initLayout(): void {
        this.organizationId = this.state.getParam('organizationId');

        this.selectedArea = null;
        this.newAreaId = 'AREA_ID';

        this._initToolbarButtons();
        this._initAccess();
        this._changeToolbarButtons();

        this._drawContent();
    }

    protected override _drawContent(): Promize | boolean {
        this._initMap();
        return true;
    }

    private _changeToolbarButtons(): void {
        this.utilService.showButton(this.btnAreasNewKnot, this.area.isEmpty());

        this.utilService.enableButton(
            this.btnAreasEditKnot,
            !!this.selectedArea,
        );
    }

    private _loadOrganization(): Promize {
        const deferred = new Deferred();
        this.organizationService
            .get(this.organizationId, {
                fields: 'id,country.location',
            })
            .then(
                (response) => {
                    this.organization = response.get('organization');
                    deferred.resolve();
                },
                () => {
                    deferred.reject();
                },
            );
        return deferred.promise();
    }

    private _loadArea(): Promize {
        const deferred = new Deferred();
        this.areaService
            .getAllByOrganization(this.organizationId, {
                fields: 'id,code,compute_area,center,locations',
            })
            .then(
                (response) => {
                    this.area = response.get('areas', [])[0] || new Objekt();
                    this._changeToolbarButtons();
                    deferred.resolve();
                },
                () => {
                    deferred.reject();
                },
            );
        return deferred.promise();
    }

    private _initToolbarButtons(): void {
        this.helper.iconButton(
            '.btn-organizations-show',
            this.dom,
            () => {
                this.state.goBack('organizations.show', {
                    organizationId: this.organizationId,
                });
            },
            '',
            true,
            [],
        );

        this.btnAreasNewKnot = this.helper.iconButton(
            '.btn-areas-new',
            this.dom,
            () => {
                this._showCreateInfo();
            },
        );

        this.btnAreasEditKnot = this.helper.iconButton(
            '.btn-areas-edit',
            this.dom,
            () => {
                this.areaService
                    .editDialog(this.selectedArea)
                    .then(
                        this.utilService.handleEditResponse(
                            'organizations.show',
                            { organizationId: this.organizationId },
                        ),
                        this._disableAreaSelection.bind(this),
                    );
            },
        );
    }

    private _disableAreaSelection(): void {
        if (this.selectedArea) {
            this.map.updatePolygon(
                this.selectedArea.get('id'),
                this.selectedArea.get('code'),
                this.selectedArea.get('locations'),
                this.selectedArea,
                {
                    editable: false,
                },
            );
            this.selectedArea = null;
            this._changeToolbarButtons();
        }
    }

    private _showCreateInfo(): void {
        this.flash.addInfo(
            this.languageService.translate('text.areas.create'),
            0,
            noop(),
        );
    }

    private _initAccess(): void {
        this.hasAccessAreaEdit = this.userService.hasAccessByOrganization(
            this.organizationId,
            'areas_write',
        );
    }

    private _initMap(): void {
        if (!this.map) {
            this.map = this.mapService.createGoogleMap(this.dom, '.map', {
                mapTypeId: google.maps.MapTypeId.SATELLITE,
                mapTypeControlOptions: {
                    mapTypeIds: ['custom', google.maps.MapTypeId.SATELLITE],
                },
                mapTypeControl: true,
                scrollwheel: true,
            });

            this.map.eventMapClick = this._eventMapClick.bind(this);

            this.map.eventPolygonClick = this._eventPolygonClick.bind(this);
            this.map.eventPolygonChanged = this._eventPolygonChanged.bind(this);

            this.map.setPolygons(
                this.mapService.getAreaPolygonOptions({
                    editable: false,
                }),
            );
        }

        const async = new Async();
        async
            .parallel([
                this._loadOrganization.bind(this),
                this._loadArea.bind(this),
            ])
            .then(() => {
                this._setMap();
            });
    }

    private _setMap(): void {
        this.map.removeAllPolygon();

        if (this.area.get('id')) {
            const areaId = this.area.get<string>('id');
            const points = this.area.get<LatLng[]>('locations', []);
            this.map.createPolygon(
                areaId,
                this.area.get('code'),
                points,
                this.area,
            );
            this.map.fitPolygonToMap(areaId);
        } else {
            const countryLocation =
                this.organization.get<Objekt>('country.location');
            this.map.setCenter(
                countryLocation.get('latitude'),
                countryLocation.get('longitude'),
            );
        }
    }

    private _eventMapClick(latitude: number, longitude: number): void {
        if (this.hasAccessAreaEdit) {
            const areaId = this.area.get('id');
            if (!areaId) {
                this.area = new Objekt({
                    id: this.newAreaId,
                    code: '',
                    locations: [{ latitude, longitude }],
                });
                this.map.createPolygon(
                    this.area.get('id'),
                    this.area.get('code'),
                    this.area.get('locations'),
                    this.area,
                    {
                        editable: true,
                    },
                );
                this.selectedArea = this.area;
            } else if (this.selectedArea) {
                const selectedAreaId = this.selectedArea.get<string>('id');
                const areaPolygon = this.map.getPolygon(selectedAreaId);
                this.map.addPointToPolygon(areaPolygon, latitude, longitude);
            }
        }
    }

    private _eventPolygonClick(
        area: Objekt,
        _latitude: number,
        _longitude: number,
    ): void {
        if (this.hasAccessAreaEdit) {
            if (this.selectedArea) {
                const selectedAreaId = this.selectedArea.get('id');
                if (selectedAreaId === this.newAreaId) {
                    const areaData = new Objekt({
                        area: this.selectedArea,
                    });
                    this.areaService
                        .newDialogByOrganization(this.organizationId, areaData)
                        .then(
                            () => {
                                this.state.refresh(true);
                            },
                            () => {
                                this._showCreateInfo();
                            },
                        );
                } else {
                    this.areaService
                        .editDialog(this.selectedArea)
                        .then(
                            this.utilService.handleEditResponse(
                                'organizations.show',
                                { organizationId: this.organizationId },
                            ),
                            this._disableAreaSelection.bind(this),
                        );
                }
            } else {
                this.map.updatePolygon(
                    area.get('id'),
                    area.get('code'),
                    area.get('locations'),
                    area,
                    {
                        editable: true,
                    },
                );
                this.selectedArea = area;
                this._changeToolbarButtons();
            }
        }
    }

    private _eventPolygonChanged(
        area: Objekt,
        locations: Array<{ latitude: number; longitude: number }>,
    ): void {
        if (this.hasAccessAreaEdit && this.selectedArea) {
            this.selectedArea.set('locations', locations);
        }
    }

    exit(): void {
        super.exit();

        this.mapService.removeGoogleMap(this.map);
    }
}

export const areasIndexController = app.controller(
    resources.areasIndexController,
    [
        resources.instances,
        resources.organizationService,
        resources.areaService,
        resources.mapService,
        resources.utilService,
        resources.userService,
        resources.languageService,
    ],
    AreasIndexController,
);
