import {
    Controller,
    Helper,
    Instance,
    Knot,
    Objekt,
    State,
    Table,
    TabPanel,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';

import { AssetService } from '../services/assetService';
import { LanguageService } from '../services/languageService';
import { SiteService } from '../services/siteService';

export class SitePoliciesController extends Controller {
    tabPanel: TabPanel;
    activeTab: string;
    dom: Knot;
    state: State;
    helper: Helper;

    constructor(
        instances: Instance,
        private siteService: SiteService,
        private languageService: LanguageService,
        private assetService: AssetService,
    ) {
        super();

        this.dom = instances.dom;
        this.state = instances.state;
        this.helper = instances.helper;
    }

    enter(): void {
        this._initLayout();
    }

    protected _initLayout(): void {
        this._initToolbarButtons();
        this._initTabPanel('terms-of-service');

        this._initCookieTable();
        this._initPersonalDataTable();
        this._initSoftwareDataTable();
    }

    private _initSoftwareDataTable(): void {
        const table = new Table(this.dom, '.software-data-table', {
            no_content: {
                image_url: this.assetService.getPath(
                    'images/others/no-content.png',
                ),
                text: this.languageService.translate('text.no_content'),
            },
            sort: {
                column: 'id',
                order: 'asc',
            },
            row_count: 12,
            columns: ['id', 'goal', 'claim'],
        });

        table.eventAction = () => {
            this.siteService.getSoftwareData().then((response) => {
                const count = response.get<number>('count');
                table.setCount(count);
                const softwareData = response.get<Objekt[]>('software_data');
                table.setData(softwareData);
            });
        };
        table.render();
    }

    private _initPersonalDataTable(): void {
        const table = new Table(this.dom, '.personal-data-table', {
            no_content: {
                image_url: this.assetService.getPath(
                    'images/others/no-content.png',
                ),
                text: this.languageService.translate('text.no_content'),
            },
            sort: {
                column: 'id',
                order: 'asc',
            },
            row_count: 12,
            columns: ['id', 'goal', 'claim'],
        });

        table.eventAction = () => {
            this.siteService.getPersonalData().then((response) => {
                const count = response.get<number>('count');
                table.setCount(count);
                const personalData = response.get<Objekt[]>('personal_data');
                table.setData(personalData);
            });
        };
        table.render();
    }

    private _initCookieTable(): void {
        const table = new Table(this.dom, '.cookies-table', {
            no_content: {
                image_url: this.assetService.getPath(
                    'images/others/no-content.png',
                ),
                text: this.languageService.translate('text.no_content'),
            },
            sort: {
                column: 'id',
                order: 'asc',
            },
            row_count: 12,
            columns: ['id', 'expires', 'desc'],
        });

        table.eventAction = () => {
            this.siteService.getCookies().then((response) => {
                const count = response.get<number>('count');
                table.setCount(count);
                const cookies = response.get<Objekt[]>('cookies');
                table.setData(cookies);
            });
        };
        table.render();
    }

    private _initToolbarButtons(): void {
        this.helper.iconButton(
            '.btn-landing-page',
            this.dom,
            () => {
                this.state.goBack('site.landing-page');
            },
            '',
            true,
            [],
        );
    }

    private _initTabPanel(defaultTab: string): void {
        const tabParam = this.state.getParam('tab', defaultTab);
        this.tabPanel = new TabPanel(this.dom, '.tab-panel', tabParam);
        this.tabPanel.eventChange = (tab) => {
            this.activeTab = tab;
            this.state.setParam('tab', tab);
        };
        this.activeTab = this.tabPanel.getActive();
    }
}

export const sitePoliciesController = app.controller(
    resources.sitePoliciesController,
    [
        resources.instances,
        resources.siteService,
        resources.languageService,
        resources.assetService,
    ],
    SitePoliciesController,
);
