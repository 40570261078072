import { DateIO, Instance, TableCalculation } from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { AdminService } from './adminService';
import { AssetService } from './assetService';
import { LanguageService } from './languageService';

export class AdminOpenSeasonService extends AdminService {
    constructor(
        instances: Instance,
        $languageService: LanguageService,
        $assetService: AssetService,
    ) {
        super(
            'open_season-open_seasons-name',
            instances,
            $languageService,
            $assetService,
        );
    }

    getTableOptions(): object {
        return {
            no_content: {
                image_url: this.assetService.getPath(
                    'images/others/no-content.png',
                ),
                text: this.languageService.translate('text.no_content'),
            },
            sort: {
                column: 'end_at',
                order: 'desc',
            },
            row_count: 12,
            columns: ['name', 'start_at', 'end_at', 'country.name', 'actions'],
            calculations: this.getTableCalculations(),
        };
    }

    getTableFields(): string {
        return ['id', 'name', 'start_at', 'end_at', 'country.name'].join(',');
    }

    getTableCalculations(): TableCalculation {
        return {
            name: (openSeason) => {
                return this.helper.createLink(
                    openSeason.get('name'),
                    (href) => {
                        this.state.go(href);
                    },
                    this.state.resolveUrl('admin.games.index', {
                        openSeasonId: openSeason.get('id'),
                    }),
                );
            },
            start_at: (openSeason) => {
                const startAt = DateIO.parse(openSeason.get('start_at'));
                return this.languageService.format(startAt, 'format.date');
            },
            end_at: (openSeason) => {
                const endAt = DateIO.parse(openSeason.get('end_at'));
                return this.languageService.format(endAt, 'format.date');
            },
        };
    }
}

export const adminOpenSeasonService = app.service(
    resources.adminOpenSeasonService,
    [resources.instances, resources.languageService, resources.assetService],
    AdminOpenSeasonService,
);
