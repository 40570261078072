import {
    ChannelNameWithParams,
    Consumer,
    Subscription,
    createConsumer,
} from '@rails/actioncable';
import {
    Deferred,
    EventBus,
    Instance,
    Objekt,
    Promize,
    Query,
    Service,
    clearArray,
    eachArray,
    inArray,
    md5,
    remove,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';

export class ActionCableService extends Service {
    cable: Consumer;
    clients: ActionCableClient[] = [];
    identifiers: string[] = [];
    eventBus: EventBus;

    constructor(instances: Instance) {
        super();

        this.cable = createConsumer();

        this.eventBus = instances.eventBus;
    }

    enter(): void {
        this.eventBus.set('state.change', (_state) => {
            this.unsubscribeAll();
        });
    }

    getUrl(): string {
        let url = '';
        const cableMetaKnot = new Query(
            'meta[name="action-cable-url"]',
        ).getKnot();
        if (!cableMetaKnot.isEmpty()) {
            url = cableMetaKnot.getAttribute('content');
        }
        return url;
    }

    subscribe(channel: string, room: string) {
        const options = { channel, room };
        const identifier = this._generateIdentifier(options);
        if (!inArray(this.identifiers, identifier)) {
            this.identifiers.push(identifier);
            const client = new ActionCableClient(this, options);
            client.identifier = identifier;
            this.clients.push(client);
            return client.subscribe();
        }

        const deferred = new Deferred<Objekt, undefined>();
        return deferred.promise();
    }

    unsubscribeAll(): void {
        eachArray(this.clients, (client) => {
            client.unsubscribe();
            remove(this.identifiers, client.identifier);
        });
        clearArray(this.clients);
    }

    protected _generateIdentifier(options: object): string {
        return md5(JSON.stringify(options));
    }
}

class ActionCableClient {
    parent: ActionCableService;
    subscription: Promize<Objekt, undefined>;
    client: Subscription<Consumer>;
    identifier: string;

    constructor(parent: ActionCableService, options: ChannelNameWithParams) {
        this.parent = parent;
        this._init(options);
    }

    private _init(options: ChannelNameWithParams): void {
        this.subscription = this._getSubscription(options);
    }

    private _getSubscription(options: ChannelNameWithParams) {
        const deferred = new Deferred<Objekt, undefined>();
        this.client = this.parent.cable.subscriptions.create(options, {
            received: (payload) => {
                const response = new Objekt(JSON.parse(payload['message']));
                deferred.resolve(response);
            },
        });

        return deferred.promise();
    }

    subscribe() {
        return this.subscription;
    }

    send(message: string, opt_data: object | undefined = {}): void {
        opt_data['message'] = message;
        this.client.send(opt_data);
    }

    unsubscribe(): void {
        this.client.unsubscribe();
    }
}

export const actionCableService = app.service(
    resources.actionCableService,
    [resources.instances],
    ActionCableService,
);
