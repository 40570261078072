import {
    DateIO,
    DateTimeRangeField,
    Form,
    Instance,
    Objekt,
    SelectField,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { AssetService } from './assetService';
import { LanguageService } from './languageService';
import { ModelService } from './modelService';
import { PlanService } from './planService';

export class BudgetService extends ModelService {
    constructor(
        instances: Instance,
        $languageService: LanguageService,
        $assetService: AssetService,
        private planService: PlanService,
    ) {
        super(
            'budget-budgets-name',
            instances,
            $languageService,
            $assetService,
        );
    }

    getAllByOrganization(organizationId: string, opt_params?: object) {
        return this.getAllBy('organizations', organizationId, opt_params);
    }

    createByOrganization(organizationId: string, budget: Objekt) {
        return this.createBy('organizations', organizationId, budget);
    }

    handleForm(form: Form, opt_entityName?: string): void {
        const organizationField = form.findByModel<SelectField>(
            'budget.organization_id',
        );
        const planField = form.findByModel<SelectField>('budget.plan_id');
        const startAtField =
            form.findByModel<DateTimeRangeField>('budget.start_at');
        const endAtField =
            form.findByModel<DateTimeRangeField>('budget.end_at');

        if (organizationField) {
            organizationField.eventChange = (organizationId) => {
                if (organizationId) {
                    planField.showLoader();
                    this.planService
                        .getAllByOrganization(organizationId, {
                            fields: 'id,name,start_at,end_at',
                        })
                        .then((response) => {
                            const plans = response.get<Objekt[]>('plans');
                            planField.setOptions(plans, 'id', 'name');
                        });
                } else {
                    planField.setOptions([]);
                }
            };
        }

        planField.eventChange = (planId) => {
            if (planId) {
                const startAt = planField.getOptionValue('start_at');
                startAtField.setValue(startAt);
                const endAt = planField.getOptionValue('end_at');
                endAtField.setValue(endAt);
            }
        };

        startAtField.eventChange = (startAt) => {
            const endAt = DateIO.format(
                DateIO.addYears(DateIO.parse(startAt), 1),
            );
            endAtField.setValue(endAt);
        };
    }
}

export const budgetService = app.service(
    resources.budgetService,
    [
        resources.instances,
        resources.languageService,
        resources.assetService,
        resources.planService,
    ],
    BudgetService,
);
