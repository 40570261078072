import {
    Confirm,
    Dialog,
    eq,
    Flash,
    Form,
    format,
    Instance,
    Objekt,
    Promize,
    Table,
} from '@siposdani87/sui-js';
import { saveAs } from 'file-saver';
import { app } from '../app';
import { resources } from '../resources';
import { LanguageService } from '../services/languageService';
import { MemberService } from '../services/memberService';
import { UserService } from '../services/userService';
import { DashboardController } from './dashboardController';

export class MembersIndexController extends DashboardController {
    organizationId: string;
    hasAccessMemberEdit: boolean;
    membersTable: Table;
    private flash: Flash;
    private dialog: Dialog;
    private confirm: Confirm;

    constructor(
        instances: Instance,
        private memberService: MemberService,
        private userService: UserService,
        private languageService: LanguageService,
    ) {
        super(instances);

        this.flash = instances.flash;
        this.dialog = instances.dialog;
        this.confirm = instances.confirm;
    }

    protected override _initLayout(): void {
        this.organizationId = this.state.getParam('organizationId');

        this._initToolbarButtons();
        this._initFilterForm();
        this._initAccess();

        this._drawContent();
    }

    protected override _drawContent(): Promize | boolean {
        this._initMemberTable();
        return true;
    }

    private _initAccess(): void {
        this.hasAccessMemberEdit = this.userService.hasAccessByOrganization(
            this.organizationId,
            'members_write',
        );
    }

    private _initMemberTable(): void {
        if (!this.membersTable) {
            this.membersTable = new Table(
                this.dom,
                '.members-table',
                this.memberService.getTableOptionsByOrganization(
                    this.organizationId,
                ),
            );

            this.membersTable.setActions([
                {
                    style: () => {
                        return [
                            'archive',
                            this.languageService.translate(
                                'buttons.move_to_guests',
                            ),
                            false,
                            !this.hasAccessMemberEdit,
                        ];
                    },
                    click: this._downgradeMemberConfirm.bind(this),
                },
                {
                    style: () => {
                        return [
                            'edit',
                            this.languageService.translate('buttons.edit'),
                            false,
                            !this.hasAccessMemberEdit,
                        ];
                    },
                    click: (member) => {
                        this.memberService.editDialog(member).then(() => {
                            this._drawContent();
                        });
                    },
                },
            ]);

            this.membersTable.eventAction = (params) => {
                params.set('fields', this.memberService.getTableFields());
                params.set('query', this.query);
                params.set('group_ids', this.groupIds);
                this.memberService
                    .getAllByOrganization(this.organizationId, params)
                    .then((response) => {
                        const count = response.get<number>('count');
                        this.membersTable.setCount(count);
                        const members = response.get<Objekt[]>('members');
                        this.membersTable.setData(members);
                    });
            };

            this.membersTable.render();
        } else {
            this.membersTable.refresh();
        }
    }

    private _initToolbarButtons(): void {
        this.helper.iconButton('.btn-members-export', this.dom, () => {
            this._exportMembersDialog();
        });

        this.helper.iconButton('.btn-members-new', this.dom, () => {
            this.memberService
                .newDialogByOrganization(this.organizationId)
                .then(() => {
                    this._drawContent();
                });
        });

        this.helper.iconButton(
            '.btn-organizations-show',
            this.dom,
            () => {
                this.state.goBack('organizations.show', {
                    organizationId: this.organizationId,
                });
            },
            '',
            true,
            [],
        );
    }

    private _exportMembersDialog(): void {
        this.dialog
            .loadTemplate(
                format('/client/v1/organizations/{0}/members/export.html', [
                    this.organizationId,
                ]),
            )
            .then((dialogKnot) => {
                const form = new Form(dialogKnot);
                form.eventSubmit = (formData) => {
                    const memberData = formData.get<Objekt>('member');
                    if (eq(memberData.get('export'), 'list')) {
                        this._exportMembersList(memberData);
                    } else if (eq(memberData.get('export'), 'contact')) {
                        this._exportMembersContact(memberData);
                    }
                };
                form.eventReset = () => {
                    this.dialog.close();
                };
                this.dialog.open();
            });
    }

    private _exportMembersList(member: Objekt): void {
        this.memberService.exportList(this.organizationId, member).then(
            (response, filename) => {
                saveAs(response.get('raw'), filename);
                this.dialog.close();
            },
            (response) => {
                this.flash.addMessage(response.get('message'));
            },
        );
    }

    private _exportMembersContact(member: Objekt): void {
        this.memberService.exportContact(this.organizationId, member).then(
            (response, filename) => {
                saveAs(response.get('raw'), filename);
                this.dialog.close();
            },
            (response) => {
                this.flash.addMessage(response.get('message'));
            },
        );
    }

    private _downgradeMemberConfirm(member: Objekt): void {
        this.confirm.load(
            this.languageService.translate(
                'members_controller.downgrade.confirm',
                {
                    member_name: member.get('name'),
                },
            ),
            this.languageService.translate('buttons.move'),
            this.languageService.translate('buttons.cancel'),
            '',
            'warning',
        );
        this.confirm.eventOK = () => {
            this.memberService.downgrade(member).then(
                (response) => {
                    this.flash.addMessage(response.get('message'));
                    this.membersTable.refresh();
                },
                (response) => {
                    this.flash.addMessage(response.get('message'));
                },
            );
        };
        this.confirm.open();
    }
}

export const membersIndexController = app.controller(
    resources.membersIndexController,
    [
        resources.instances,
        resources.memberService,
        resources.userService,
        resources.languageService,
    ],
    MembersIndexController,
);
