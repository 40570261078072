import { Instance, Promize } from '@siposdani87/sui-js';
import { DashboardController } from './dashboardController';

export class AdminController extends DashboardController {
    constructor(instances: Instance) {
        super(instances);
    }

    protected override _initLayout(): void {
        this._initToolbarButtons();
        this._initFilterForm();

        this._drawContent();
    }

    protected _initToolbarButtons(): void {
        // empty method
    }

    protected override _drawContent(): Promize | boolean {
        this._initTable();
        return true;
    }

    protected _initTable(): void {
        // empty method
    }
}
