import {
    EventBus,
    Footer,
    Header,
    Instance,
    NavBar,
    Service,
    State,
    inArray,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { UserService } from './userService';

export class LayoutService extends Service {
    eventBus: EventBus;
    header: Header;
    footer: Footer;
    state: State;
    navBar: NavBar;

    constructor(
        instances: Instance,
        private userService: UserService,
    ) {
        super();

        this.eventBus = instances.eventBus;
        this.header = instances.header;
        this.footer = instances.footer;
        this.state = instances.state;
        this.navBar = instances.navBar;
    }

    enter(): void {
        this.eventBus.set('dom.change', (state) => {
            this.header.showTopMenuButton();

            if (this.userService.isLoggedIn()) {
                this.header.showLeftMenuButton();
            } else {
                this.header.hideLeftMenuButton();
            }

            if (!inArray(['site.maintenance'], state.get('id'))) {
                this.footer.show();
            } else {
                this.footer.hide();
            }

            if (
                !inArray(
                    [
                        'users.login',
                        'users.registration',
                        'users.activation',
                        'users.recovery-password',
                        'users.change-password',
                        'site.landing-page',
                        'site.maintenance',
                        'pages.show',
                        'organizations.connect',
                    ],
                    state.get('id'),
                )
            ) {
                this.header.show();
            } else {
                this.header.hide();
            }

            scrollTo(0, 0);
        });

        this.eventBus.set('window.scroll', (scrollTop) => {
            if (scrollTop > 0) {
                this.header.showShadow();
                this.navBar.showShadow();
            } else {
                this.header.hideShadow();
                this.navBar.hideShadow();
            }
        });
    }
}

export const layoutService = app.service(
    resources.layoutService,
    [resources.instances, resources.userService],
    LayoutService,
);
