import { Instance } from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { AssetService } from './assetService';
import { LanguageService } from './languageService';
import { ModelService } from './modelService';

export class DiscoveryService extends ModelService {
    constructor(
        instances: Instance,
        $languageService: LanguageService,
        $assetService: AssetService,
    ) {
        super(
            'discovery-discoveries-full_name',
            instances,
            $languageService,
            $assetService,
        );
    }
}

export const discoveryService = app.service(
    resources.discoveryService,
    [resources.instances, resources.languageService, resources.assetService],
    DiscoveryService,
);
