import {
    DateIO,
    Deferred,
    Dialog,
    Form,
    Helper,
    Instance,
    Knot,
    Objekt,
    Service,
    State,
    eachArray,
    eq,
    format,
    inArray,
    pluckKeys,
    TableCalculation,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { AssetService } from './assetService';
import { LanguageService } from './languageService';

export class NotificationHistoryService extends Service {
    helper: Helper;
    state: State;
    dialog: Dialog;
    statusCssClasses: {
        failed: string;
        prepared: string;
        downloaded: string;
        sent: string;
        received: string;
        opened: string;
    };

    constructor(
        instances: Instance,
        private languageService: LanguageService,
        private assetService: AssetService,
    ) {
        super();

        this.helper = instances.helper;
        this.state = instances.state;
        this.dialog = instances.dialog;

        this._init();
    }

    private _init(): void {
        this.statusCssClasses = {
            failed: 'text-error',
            prepared: 'text-warning',
            downloaded: 'text-info',
            sent: 'text-info',
            received: 'text-success',
            opened: 'text-success',
        };
    }

    private _getStatusByType(
        type: string,
        notificationHistories: Array<any>,
    ): Knot | string {
        const histories = [];
        eachArray(notificationHistories, (notificationHistory) => {
            if (eq(notificationHistory.get('type'), type)) {
                histories.push(notificationHistory);
            }
        });
        const historyItem = histories[histories.length - 1];
        if (historyItem) {
            const status = historyItem.get('status');
            const message = historyItem.get('message');
            const createdAt = this.languageService.format(
                DateIO.parse(historyItem.get('created_at')),
                'format.datetime',
            );

            const statusKnot = new Knot('span');
            statusKnot.setAttribute(
                'title',
                format('{0} ({1})', [message, createdAt]),
            );
            const statusCssClass = this.statusCssClasses[status];
            if (statusCssClass) {
                statusKnot.addClass(['tag', statusCssClass]);
            }
            statusKnot.setHtml(
                this.languageService.translate(
                    format('notification_history.status.{0}', [status]),
                    [],
                    null,
                ),
            );
            return statusKnot;
        }
        return '';
    }

    getTableOptions(): object {
        return {
            no_content: {
                image_url: this.assetService.getPath(
                    'images/others/no-content.png',
                ),
                text: this.languageService.translate('text.no_content'),
            },
            sort: {
                column: 'created_at',
                order: 'asc',
            },
            row_count: 12,
            column: 'person.name',
            columns: [
                'person.name',
                'notificationHistoriesMail',
                'notificationHistoriesEmail',
                'notificationHistoriesSMS',
                'notificationHistoriesPush',
                'actions',
            ],
            sorted: [],
            calculations: this.getTableCalculations(),
        };
    }

    getTableFields(): string {
        return 'id,person.id,person.name,notification_histories.type,notification_histories.status,notification_histories.message,notification_histories.created_at';
    }

    getTableCalculations(): TableCalculation {
        return {
            'person.name': (notification) => {
                return this.helper.createLink(
                    notification.get('person.name'),
                    (href) => {
                        this.state.go(href);
                    },
                    this.state.resolveUrl('people.show', {
                        personId: notification.get('person.id'),
                    }),
                );
            },
            notificationHistoriesMail: (notification) => {
                return this._getStatusByType(
                    'mail',
                    notification.get('notification_histories'),
                );
            },
            notificationHistoriesEmail: (notification) => {
                return this._getStatusByType(
                    'email',
                    notification.get('notification_histories'),
                );
            },
            notificationHistoriesPush: (notification) => {
                return this._getStatusByType(
                    'push',
                    notification.get('notification_histories'),
                );
            },
            notificationHistoriesSMS: (notification) => {
                return this._getStatusByType(
                    'sms',
                    notification.get('notification_histories'),
                );
            },
        };
    }

    getTypes(notification: Objekt): Array<any> {
        const types = [];
        eachArray(
            notification.get<Objekt[]>('notification_histories', []),
            (notification_history) => {
                const type = notification_history.get('type');
                if (!inArray(types, type)) {
                    types.push(type);
                }
            },
        );
        return types;
    }

    openChooseTypeDialog(opt_types: Array<any> | undefined = []) {
        const deferred = new Deferred<string[][]>();
        this.dialog
            .loadTemplate(format('/client/v1/notifications/choose.html', []))
            .then((dialogKnot) => {
                const form = new Form(dialogKnot);
                form.setModel(
                    new Objekt({
                        types: {
                            mail: inArray(opt_types, 'mail'),
                            email: inArray(opt_types, 'email'),
                            push: inArray(opt_types, 'push'),
                            sms: inArray(opt_types, 'sms'),
                        },
                    }),
                );
                form.eventSubmit = (formData) => {
                    this.dialog.close();
                    const typesObject = formData.get('types');
                    const types = pluckKeys(typesObject, (value) => value);
                    deferred.resolve([types]);
                };
                form.eventReset = () => {
                    this.dialog.close();
                    deferred.reject();
                };
                this.dialog.open();
            });
        return deferred.promise();
    }
}

export const notificationHistoryService = app.service(
    resources.notificationHistoryService,
    [resources.instances, resources.languageService, resources.assetService],
    NotificationHistoryService,
);
