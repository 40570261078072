import { contain, eq, Objekt } from '@siposdani87/sui-js';

const supportedLocales = {
    en: 'en-US',
    hu: 'hu-HU',
};
const navigatorLanguage = navigator.language.replace('-', '_').split('_', 2)[0];
const locale = supportedLocales[navigatorLanguage] || supportedLocales.en;

const hostName = window.location.hostname;

let backend = window.location.origin;
let production = true;

if (eq(hostName, 'localhost') || eq(hostName, '127.0.0.1')) {
    production = false;
}

if (contain(hostName, 'trophymap.org')) {
    backend = 'https://trophymap.org';
}

export const config = new Objekt({
    app_id: 'trophymap',
    locale,
    backend,
    production,
    secret: '4fkx1hl4f704he78khw321q93gh789l74jf',
    theme_color: '#795548',
});
