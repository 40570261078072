import {
    Deferred,
    eachArray,
    Form,
    format,
    Instance,
    Knot,
    Objekt,
    TableCalculation,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { AssetService } from './assetService';
import { LanguageService } from './languageService';
import { ModelService } from './modelService';

export class OrganizationUserService extends ModelService {
    constructor(
        instances: Instance,
        $languageService: LanguageService,
        $assetService: AssetService,
    ) {
        super(
            'organization_user-organization_users-id',
            instances,
            $languageService,
            $assetService,
        );
    }

    getAllByOrganization(organizationId: string, opt_params?: object) {
        return this.http.get(
            format('/api/v1/organizations/{0}/users.json', [organizationId]),
            opt_params,
        );
    }

    getByOrganizationAndUser(
        organizationId: string,
        userId: string,
        opt_params?: object,
    ) {
        return this.http.get(
            format('/api/v1/organizations/{0}/users/{1}.json', [
                organizationId,
                userId,
            ]),
            opt_params,
        );
    }

    update(organizationUser: Objekt) {
        const organizationId = organizationUser.get('organization_id');
        const userId = organizationUser.get('user_id');
        return this.http.put(
            format('/api/v1/organizations/{0}/users/{1}.json', [
                organizationId,
                userId,
            ]),
            organizationUser,
        );
    }

    remove(organizationUser: Objekt) {
        const organizationId = organizationUser.get('organization_id');
        const userId = organizationUser.get('user_id');
        return this.http.delete(
            format('/api/v1/organizations/{0}/users/{1}.json', [
                organizationId,
                userId,
            ]),
        );
    }

    connect(organization: Objekt, user: Objekt, pinCode: string) {
        const organizationId = organization.get('id');
        const userId = user.get('id');
        return this.http.post(
            format('/api/v1/organizations/{0}/users/{1}/connect.json', [
                organizationId,
                userId,
            ]),
            {
                pin_code: pinCode,
            },
        );
    }

    reconnect(
        organization: Objekt,
        user: Objekt,
        opt_roles: Array<any> | undefined = ['basic'],
        opt_active: boolean | undefined = false,
    ) {
        const organizationId = organization.get('id');
        const userId = user.get('id');
        return this.http.put(
            format('/api/v1/organizations/{0}/users/{1}/reconnect.json', [
                organizationId,
                userId,
            ]),
            {
                roles: opt_roles,
                active: opt_active,
            },
        );
    }

    disconnect(organization: Objekt, user: Objekt) {
        const organizationId = organization.get('id');
        const userId = user.get('id');
        return this.http.delete(
            format('/api/v1/organizations/{0}/users/{1}/disconnect.json', [
                organizationId,
                userId,
            ]),
            {},
        );
    }

    editDialog(
        organizationUser: Objekt,
        opt_path: string | undefined = '',
        opt_entityName: string | undefined = '',
    ) {
        const deferred = new Deferred<[Objekt, boolean], undefined>();
        const organizationId = organizationUser.get('organization_id');
        const userId = organizationUser.get('user_id');
        this.dialog
            .loadTemplate(
                format('/client/v1/organizations/{0}/users/{1}/edit.html', [
                    organizationId,
                    userId,
                ]),
            )
            .then((dialogKnot) => {
                const form = new Form(dialogKnot);
                form.eventSubmit = (formData) => {
                    form.lock();
                    const organizationUserData =
                        formData.get<Objekt>('organization_user');
                    this.update(organizationUserData).then(
                        (response) => {
                            this.flash.addMessage(response.get('message'));
                            this.dialog.close();
                            deferred.resolve([
                                response.get('organization_user'),
                                false,
                            ]);
                        },
                        (response) => {
                            this.flash.addMessage(response.get('message'));
                            form.unlock();
                            form.setErrors(response.get('errors'));
                        },
                    );
                };

                form.eventButton = () => {
                    form.lock();
                    this.removeConfirm(organizationUser).then(
                        (data) => {
                            form.unlock();
                            this.dialog.close();
                            deferred.resolve([data, true]);
                        },
                        () => {
                            form.unlock();
                        },
                    );
                };

                form.eventReset = () => {
                    this.dialog.close();
                    deferred.reject();
                };

                this.handleForm(form);
                this.dialog.open();
            });
        return deferred.promise();
    }

    removeConfirm(organizationUser: Objekt) {
        const deferred = new Deferred<Objekt, undefined>();
        this.confirm.load(
            this.languageService.translate(
                'organization_users_controller.destroy.confirm',
                {
                    user_name: organizationUser.get('user.name'),
                },
            ),
            this.languageService.translate('buttons.delete'),
            this.languageService.translate('buttons.cancel'),
            '',
            'warning',
        );
        this.confirm.eventOK = () => {
            this.remove(organizationUser).then(
                (response) => {
                    this.flash.addMessage(response.get('message'));
                    deferred.resolve(response.get('organization_user'));
                },
                (response) => {
                    this.flash.addMessage(response.get('message'));
                },
            );
        };
        this.confirm.eventCancel = () => {
            deferred.reject();
        };
        this.confirm.open();
        return deferred.promise();
    }

    getTableCalculations(): TableCalculation {
        return {
            avatar: (organizationUser) => {
                const avatarUrl = organizationUser.get('user.avatar.thumb.url');
                const imageWrapper = new Knot('div');
                imageWrapper.addClass('img-wrapper');
                if (avatarUrl) {
                    const imageKnot = new Knot('img');
                    imageKnot.addClass(['img-responsive', 'img-circle']);
                    imageKnot.setAttribute('src', avatarUrl);
                    imageWrapper.appendChild(imageKnot);
                }
                return imageWrapper;
            },
            name: (organizationUser) => {
                return organizationUser.get('user.name');
            },
            email: (organizationUser) => {
                const userEmail = organizationUser.get<string>('user.email');
                return this.helper.createLink(
                    userEmail,
                    undefined,
                    format('mailto:{0}', [userEmail]),
                );
            },
            roles: (organizationUser) => {
                const roles = organizationUser.get('roles', []);
                const translatedRoles = roles.map((role) => {
                    return this.languageService.translate(
                        format('organization_user.role_group.{0}', [role]),
                    );
                });
                return translatedRoles.join(', ');
            },
            people: (organizationUser) => {
                const links: Knot[] = [];
                eachArray(organizationUser.get('people', []), (person) => {
                    const linkKnot = this.helper.createLink(
                        person.get('name'),
                        (href) => {
                            this.state.go(href);
                        },
                        this.state.resolveUrl('people.show', {
                            personId: person.get('id'),
                        }),
                    );
                    links.push(linkKnot);
                });
                return links;
            },
            active: (organizationUser) => {
                const active = organizationUser.get('active');
                const iconKnot = new Knot('em');
                iconKnot.addClass('material-icons');
                if (active) {
                    iconKnot.addClass('text-success');
                    iconKnot.setHtml('check_circle');
                } else {
                    iconKnot.addClass('text-error');
                    iconKnot.setHtml('cancel');
                }
                return iconKnot;
            },
        };
    }
}

export const organizationUserService = app.service(
    resources.organizationUserService,
    [resources.instances, resources.languageService, resources.assetService],
    OrganizationUserService,
);
