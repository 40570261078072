import { Controller, Flash, Instance, State } from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { UserService } from '../services/userService';

export class UsersActivationController extends Controller {
    private state: State;
    private flash: Flash;

    constructor(
        instances: Instance,
        private userService: UserService,
    ) {
        super();

        this.state = instances.state;
        this.flash = instances.flash;
    }

    enter(): void {
        this._initLayout();
    }

    protected _initLayout(): void {
        const activationCode = this.state.getParam('code');
        this.userService.activation(activationCode).then(
            (response) => {
                this.flash.addMessage(response.get('message'));
            },
            (response) => {
                this.flash.addMessage(response.get('message'));
            },
        );
        this.state.go('users.login');
    }
}

export const usersActivationController = app.controller(
    resources.usersActivationController,
    [resources.instances, resources.userService],
    UsersActivationController,
);
