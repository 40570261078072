import {
    Depot,
    eachArray,
    Instance,
    Objekt,
    Promize,
    Query,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { GoogleChartService } from '../services/googleChartService';
import { LanguageService } from '../services/languageService';
import { ReportService } from '../services/reportService';
import { DashboardController } from './dashboardController';

export class ReportsBuildingsWhenTheyShootController extends DashboardController {
    localDepot: Depot;
    chart: google.visualization.ColumnChart;

    constructor(
        instances: Instance,
        private reportService: ReportService,
        private googleChartService: GoogleChartService,
        private languageService: LanguageService,
    ) {
        super(instances);

        this.localDepot = instances.localDepot;
    }

    protected override _initLayout(): void {
        this._initToolbarButtons();

        this._drawContent();
    }

    protected override _drawContent(): Promize | boolean {
        this._initChart();
        return true;
    }

    private _initToolbarButtons(): void {
        this.helper.iconButton(
            '.btn-reports-index',
            this.dom,
            () => {
                this.state.goBack('reports.index');
            },
            '',
            true,
            [],
        );

        this.helper.iconButton('.btn-reports-filter', this.dom, () => {
            this.reportService.detailsDialog('buildings');
        });
    }

    private _initChart(): void {
        if (!this.chart) {
            this.googleChartService.loadSDK(['corechart', 'bar']).then(() => {
                const chartKnot = new Query('.chart', this.dom).getKnot();

                this.chart = new google.visualization.ColumnChart(
                    chartKnot.getNode(),
                );
                const chartOptions: google.visualization.ColumnChartOptions = {
                    // width: '100%',
                    height: 460,
                    legend: { position: 'bottom' },
                    colors: ['#795548', '#FF9800'],
                };

                const buildingsReport = new Objekt(
                    this.localDepot.get(
                        this.reportService.getStorageKeyReport('buildings'),
                    ),
                );
                this.reportService
                    .getAllByBuildings(
                        buildingsReport,
                        new Objekt({
                            fields: 'id,killing_at,count,count_prev',
                        }),
                    )
                    .then((response) => {
                        const objects = response.get<Objekt[]>('objects');

                        const data = new google.visualization.DataTable();
                        data.addColumn('string', '');
                        data.addColumn(
                            'number',
                            this.languageService.translate(
                                'captions.trophies.prev_index',
                            ),
                        );
                        data.addColumn(
                            'number',
                            this.languageService.translate(
                                'captions.trophies.index',
                            ),
                        );
                        eachArray(objects, (object) => {
                            data.addRow([
                                object.get('killing_at'),
                                object.get('count_prev'),
                                object.get('count'),
                            ]);
                        });
                        this.chart.draw(data, chartOptions);
                    });
            });
        }
    }

    exit(): void {
        super.exit();

        this.googleChartService.reset();
    }
}

export const reportsBuildingsWhenTheyShootController = app.controller(
    resources.reportsBuildingsWhenTheyShootController,
    [
        resources.instances,
        resources.reportService,
        resources.googleChartService,
        resources.languageService,
    ],
    ReportsBuildingsWhenTheyShootController,
);
