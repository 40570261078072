import {
    CheckboxField,
    Form,
    Instance,
    isUndefined,
    LocationField,
    SelectField,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { AssetService } from './assetService';
import { LanguageService } from './languageService';
import { MapService } from './mapService';
import { ModelService } from './modelService';
import { UserService } from './userService';

export class ObservationService extends ModelService {
    constructor(
        instances: Instance,
        $languageService: LanguageService,
        $assetService: AssetService,
        private userService: UserService,
        private mapService: MapService,
    ) {
        super(
            'observation-observations-short_description',
            instances,
            $languageService,
            $assetService,
        );
    }

    getAllByUser(userId: string, opt_params?: object) {
        return this.getAllBy('users', userId, opt_params);
    }

    getAllByOrganization(organizationId: string, opt_params?: object) {
        return this.getAllBy('organizations', organizationId, opt_params);
    }

    handleForm(form: Form, opt_entityName?: string): void {
        const organizationField = form.findByModel<SelectField>(
            'observation.organization_id',
        );
        const checkedField = form.findByModel<CheckboxField>(
            'observation.checked',
        );
        const locationField = form.findByModel<LocationField>(
            'observation.location',
        );

        if (organizationField) {
            organizationField.eventChange = (organizationId) => {
                this._handleCheckedField(organizationId, checkedField, false);
            };
            this._handleCheckedField(
                organizationField.getValue(),
                checkedField,
            );
        }

        this.handleLocationField(this.mapService, locationField);
    }

    private _handleCheckedField(
        organizationId: string,
        checkedField: CheckboxField,
        opt_value?: boolean,
    ): void {
        if (
            this.userService.hasAccessByOrganization(
                organizationId || '-',
                'observations_write',
            )
        ) {
            checkedField.setDisabled(false);
        } else {
            checkedField.setDisabled(true);
        }
        if (!isUndefined(opt_value)) {
            checkedField.setValue(opt_value);
        }
    }
}

export const observationService = app.service(
    resources.observationService,
    [
        resources.instances,
        resources.languageService,
        resources.assetService,
        resources.userService,
        resources.mapService,
    ],
    ObservationService,
);
