import {
    Controller,
    Form,
    Helper,
    Instance,
    Knot,
    Promize,
    SearchField,
    SelectField,
    State,
    TabPanel,
} from '@siposdani87/sui-js';

export class DashboardController extends Controller {
    dom: Knot;
    state: State;
    helper: Helper;
    query: string;
    organizationIds: string[];
    countryId: string;
    buildingTypes: string[];
    groupIds: string[];
    tabPanel: TabPanel;
    activeTab: string;

    constructor(instances: Instance) {
        super();

        this.dom = instances.dom;
        this.state = instances.state;
        this.helper = instances.helper;
    }

    override enter(): void {
        this._initLayout();
    }

    protected _drawContent(): Promize | boolean {
        return true;
    }

    protected _initLayout(): void {
        // empty method
    }

    protected _initFilterForm(): void {
        const form = new Form(this.dom);

        this.query = '';
        const searchField = form.findByModel<SearchField>('query');
        searchField.eventEnter = (value) => {
            this.query = value;
            this._drawContent();
        };

        this.organizationIds = [];
        const organizationsField =
            form.findByModel<SelectField>('organization_ids');
        if (organizationsField) {
            const organizationId = this.state.getParam('organizationId');
            if (organizationId) {
                this.organizationIds = [organizationId];
                organizationsField.setValue(this.organizationIds[0]);
            }
            organizationsField.eventChange = (value) => {
                this.organizationIds = value ? [value] : [];
                this.state.setParam('organizationId', this.organizationIds[0]);
                this._drawContent();
            };
        }

        this.countryId = null;
        const countryField = form.findByModel<SelectField>('country_id');
        if (countryField) {
            const countryId = this.state.getParam('countryId');
            if (countryId) {
                this.countryId = countryId;
                countryField.setValue(this.countryId);
            }
            countryField.eventChange = (value) => {
                this.countryId = value;
                this.state.setParam('countryId', this.countryId);
                this._drawContent();
            };
        }

        this.buildingTypes = [];
        const buildingTypesField =
            form.findByModel<SelectField>('building_types');
        if (buildingTypesField) {
            const buildingTypes =
                this.state.getParam<string[]>('buildingTypes');
            if (buildingTypes) {
                this.buildingTypes = buildingTypes;
                buildingTypesField.setValue(this.buildingTypes);
            }
            buildingTypesField.eventChange = (value) => {
                this.buildingTypes = value;
                this.state.setParam('buildingTypes', this.buildingTypes);
                this._drawContent();
            };
        }

        this.groupIds = [];
        const groupsField = form.findByModel<SelectField>('group_ids');
        if (groupsField) {
            const groupIds = this.state.getParam<string[]>('groupIds');
            if (groupIds) {
                this.groupIds = groupIds;
                groupsField.setValue(this.groupIds);
            }
            groupsField.eventChange = (value) => {
                this.groupIds = value;
                this.state.setParam('groupIds', this.groupIds);
                this._drawContent();
            };
        }
    }

    protected _initLinks(selector: string): void {
        this.helper.multipleLink(selector, this.dom, (href) => {
            this.state.go(href);
        });
    }

    protected _initTabPanel(defaultTab: string): void {
        const tabParam = this.state.getParam('tab', defaultTab);
        this.tabPanel = new TabPanel(this.dom, '.tab-panel', tabParam);
        this.tabPanel.eventChange = (tab) => {
            this.activeTab = tab;
            this.state.setParam('tab', tab);
            return this._drawContent();
        };
        this.activeTab = this.tabPanel.getActive();
    }
}
