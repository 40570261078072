import {
    CardCollection,
    Collection,
    Deferred,
    Dialog,
    Flash,
    Instance,
    Knot,
    Objekt,
    Promize,
    Query,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { UsersOrganizationConnectionsComponent } from '../components/UsersOrganizationConnectionsComponent';
import { resources } from '../resources';
import { AssetService } from '../services/assetService';
import { LanguageService } from '../services/languageService';
import { OrganizationUserService } from '../services/organizationUserService';
import { UserService } from '../services/userService';
import { renderSolid, unmountSolid, type Dispose } from '../utils/solidHelper';
import { DashboardController } from './dashboardController';

export class UsersIndexController extends DashboardController {
    userCardCollection: CardCollection;
    private dialog: Dialog;
    private flash: Flash;

    constructor(
        instances: Instance,
        private organizationUserService: OrganizationUserService,
        private languageService: LanguageService,
        private userService: UserService,
        private assetService: AssetService,
    ) {
        super(instances);

        this.dialog = instances.dialog;
        this.flash = instances.flash;
    }

    protected override _initLayout(): void {
        this._initToolbarButtons();
        this._initFilterForm();

        this._drawContent();
    }

    protected override _drawContent(): Promize | boolean {
        this._initUserCollection();
        return true;
    }

    private _initToolbarButtons(): void {
        this.helper.iconButton(
            '.btn-site-home',
            this.dom,
            () => {
                this.state.goBack('site.home');
            },
            '',
            true,
            [],
        );
    }

    private _initUserCollection(): void {
        if (!this.userCardCollection) {
            this.userCardCollection = new CardCollection(
                this.dom,
                '.user-collection',
                {},
                {
                    no_content: {
                        image_url: this.assetService.getPath(
                            'images/others/no-content.png',
                        ),
                        text: this.languageService.translate('text.no_content'),
                    },
                    row_count: 12,
                    sort: {
                        column: 'created_at',
                        order: 'desc',
                    },
                },
            );
            this.userCardCollection.eventCardKnot =
                this._eventCardKnot.bind(this);
            this.userCardCollection.eventAction = (params) => {
                params.set('query', this.query);
                params.set('organization_ids', this.organizationIds);
                params.set(
                    'fields',
                    'id,name,email,avatar,dependent_organizations.id,dependent_organizations.name,dependent_organizations.image,existing_organizations.id,existing_organizations.name,existing_organizations.image',
                );
                this.userService.getAll(params).then((response) => {
                    const count = response.get<number>('count');
                    this.userCardCollection.setCount(count);
                    const users = response.get<Objekt[]>('users');
                    this.userCardCollection.setData(users);
                });
            };
            this.userCardCollection.render();
        } else {
            this.userCardCollection.refresh(1);
        }
    }

    private _eventCardKnot(cardKnot: Knot, user: Objekt): void {
        this._initUserConnectionAcceptButtons(cardKnot, user);
        // this._setAvatar(cardKnot, user);
    }

    private _setAvatar(cardKnot: Knot, user: Objekt): void {
        const imageKnot = new Query('img.avatar', cardKnot).getKnot();
        const defaultImageUrl = user.get<string>('avatar.url');
        const email = user.get<string>('email');

        this.helper.setGravatar(imageKnot, defaultImageUrl, email, 120);
    }

    private _initUserConnectionAcceptButtons(
        cardKnot: Knot,
        user: Objekt,
    ): void {
        const actionsKnot = new Query('.actions', cardKnot).getKnot();

        const dependentOrganizations = new Collection(
            user.get('dependent_organizations', []),
        );
        const existingOrganizations = new Collection(
            user.get('existing_organizations', []),
        );

        if (dependentOrganizations.size() > 0) {
            const dependentConnectionLinkKnot = this.helper.createIconButton(
                'warning',
                () => {
                    this._showUserConnectToOrganizationDialog(
                        user,
                        dependentOrganizations,
                        true,
                    );
                },
                this.languageService.translate(
                    'captions.users.dependent_connections',
                ),
                true,
                ['mdl-button--accent'],
            );
            actionsKnot.appendChild(dependentConnectionLinkKnot);
        }

        if (existingOrganizations.size() > 0) {
            const existingConnectionLinkKnot = this.helper.createIconButton(
                'link',
                () => {
                    this._showUserConnectToOrganizationDialog(
                        user,
                        existingOrganizations,
                        false,
                    );
                },
                this.languageService.translate(
                    'captions.users.existing_connections',
                ),
                true,
                ['mdl-button--primary'],
            );
            actionsKnot.appendChild(existingConnectionLinkKnot);
        }
    }

    private _showUserConnectToOrganizationDialog(
        user: Objekt,
        organizationCollection: Collection<Objekt>,
        opt_allowAccept: boolean | undefined = false,
    ): void {
        let dispose: Dispose;
        const templateUrl = opt_allowAccept
            ? '/client/v1/users/dependent-connections.html'
            : '/client/v1/users/existing-connections.html';
        this.dialog.loadTemplate(templateUrl).then((dialogKnot) => {
            dispose = renderSolid(
                UsersOrganizationConnectionsComponent,
                {
                    $helper: this.helper,
                    $languageService: this.languageService,
                    allowAccept: opt_allowAccept,
                    organizationCollection: organizationCollection,
                    eventAccept: (organization) => {
                        const deferred = new Deferred();
                        this.organizationUserService
                            .reconnect(organization, user, ['basic'], true)
                            .then(
                                (response) => {
                                    this.flash.addMessage(
                                        response.get('message'),
                                    );
                                    this.userCardCollection.refresh(1);
                                    this.dialog.close();
                                    deferred.resolve();
                                },
                                (response) => {
                                    this.flash.addMessage(
                                        response.get('message'),
                                    );
                                    deferred.reject();
                                },
                            );
                        return deferred.promise();
                    },
                    eventDecline: (organization) => {
                        const deferred = new Deferred();
                        this.organizationUserService
                            .disconnect(organization, user)
                            .then(
                                (response) => {
                                    this.flash.addMessage(
                                        response.get('message'),
                                    );
                                    this.userCardCollection.refresh(1);
                                    this.dialog.close();
                                    deferred.resolve();
                                },
                                (response) => {
                                    this.flash.addMessage(
                                        response.get('message'),
                                    );
                                    deferred.reject();
                                },
                            );
                        return deferred.promise();
                    },
                },
                dialogKnot,
            );
            this.dialog.open();
        });
        this.dialog.eventCancel = () => {
            unmountSolid(dispose);
        };
    }
}

export const usersIndexController = app.controller(
    resources.usersIndexController,
    [
        resources.instances,
        resources.organizationUserService,
        resources.languageService,
        resources.userService,
        resources.assetService,
    ],
    UsersIndexController,
);
