import {
    Async,
    Confirm,
    Deferred,
    Dialog,
    each,
    eq,
    Flash,
    Form,
    format,
    GoogleMap,
    Instance,
    Knot,
    LatLng,
    noop,
    Objekt,
    Promize,
    Query,
    Table,
} from '@siposdani87/sui-js';
import { saveAs } from 'file-saver';
import { app } from '../app';
import { resources } from '../resources';
import { ActionCableService } from '../services/actionCableService';
import { AreaService } from '../services/areaService';
import { BuildingService } from '../services/buildingService';
import { LanguageService } from '../services/languageService';
import { MapService } from '../services/mapService';
import { OrganizationService } from '../services/organizationService';
import { UserService } from '../services/userService';
import { UtilService } from '../services/utilService';
import { DashboardController } from './dashboardController';

export class BuildingsIndexController extends DashboardController {
    organizationId: string;
    organization: Objekt;
    selectedBuilding: Objekt;
    buildingsTable: Table;
    hasAccessBuildingEdit: boolean;
    area: Objekt;
    buildings: Objekt[];
    btnBuildingsNewKnot: Knot<HTMLElement>;
    btnBuildingsEditKnot: Knot<HTMLElement>;
    btnBuildingsImportListKnot: Knot<HTMLElement>;
    map: GoogleMap;
    private flash: Flash;
    private dialog: Dialog;
    private confirm: Confirm;

    constructor(
        instances: Instance,
        private mapService: MapService,
        private organizationService: OrganizationService,
        private areaService: AreaService,
        private buildingService: BuildingService,
        private actionCableService: ActionCableService,
        private userService: UserService,
        private utilService: UtilService,
        private languageService: LanguageService,
    ) {
        super(instances);

        this.flash = instances.flash;
        this.dialog = instances.dialog;
        this.confirm = instances.confirm;
    }

    protected override _initLayout(): void {
        this.organizationId = this.state.getParam('organizationId');
        this.organization = new Objekt();

        this.selectedBuilding = null;

        this._initToolbarButtons();
        this._initFilterForm();
        this._initTabPanel('building-map');
        this._changeToolbarButtons();

        this._loadOrganization().then(() => {
            this._initAccess();
            this._drawContent();
        });
    }

    private _initBuildingTable(): void {
        if (eq(this.activeTab, 'building-table')) {
            if (!this.buildingsTable) {
                this.buildingsTable = new Table(
                    this.dom,
                    '.buildings-table',
                    this.buildingService.getTableOptions(),
                );

                this.buildingsTable.setActions([
                    {
                        style: () => {
                            return [
                                'chevron_right',
                                this.languageService.translate(
                                    'buttons.select',
                                ),
                                false,
                                !this.hasAccessBuildingEdit,
                            ];
                        },
                        click: this._selectBuildingToEdit.bind(this),
                    },
                ]);

                this.buildingsTable.eventAction = (params) => {
                    params.set('types', this.buildingTypes);
                    params.set('query', this.query);
                    params.set('fields', this.buildingService.getTableFields());
                    this.buildingService
                        .getAllByOrganization(this.organizationId, params)
                        .then((response) => {
                            const count = response.get<number>('count');
                            this.buildingsTable.setCount(count);
                            const buildings =
                                response.get<Objekt[]>('buildings');
                            this.buildingsTable.setData(buildings);
                        });
                };
                this.buildingsTable.render();
            } else {
                this.buildingsTable.refresh();
            }
        }
    }

    private _loadArea(): Promize {
        const deferred = new Deferred();
        this.areaService
            .getAllByOrganization(this.organizationId, {
                fields: 'id,compute_area,locations',
            })
            .then(
                (response) => {
                    this.area = response.get('areas', [])[0] || new Objekt();
                    deferred.resolve();
                },
                () => {
                    deferred.reject();
                },
            );
        return deferred.promise();
    }

    private _loadBuildings(): Promize {
        const deferred = new Deferred();
        this.buildingService
            .getAllByOrganization(this.organizationId, {
                types: this.buildingTypes,
                query: this.query,
                fields: 'id,name,type,location,photo,visible',
            })
            .then(
                (response) => {
                    this.buildings = response.get('buildings');
                    if (this.buildings.length === 0) {
                        this._showCreateInfo();
                    }
                    deferred.resolve();
                },
                () => {
                    deferred.reject();
                },
            );
        return deferred.promise();
    }

    private _initToolbarButtons(): void {
        this.helper.iconButton(
            '.btn-organizations-show',
            this.dom,
            () => {
                this.state.goBack('organizations.show', {
                    organizationId: this.organizationId,
                });
            },
            '',
            true,
            [],
        );

        this.btnBuildingsNewKnot = this.helper.iconButton(
            '.btn-buildings-new',
            this.dom,
            () => {
                this._showCreateInfo();
            },
        );

        this.btnBuildingsEditKnot = this.helper.iconButton(
            '.btn-buildings-edit',
            this.dom,
            () => {
                this.buildingService
                    .editDialog(this.selectedBuilding)
                    .then(
                        this.utilService.handleEditResponse(
                            'organizations.show',
                            { organizationId: this.organizationId },
                        ),
                        this._deselectBuilding.bind(this),
                    );
            },
        );

        this.btnBuildingsImportListKnot = this.helper.iconButton(
            '.btn-buildings-import-list',
            this.dom,
            () => {
                this._importBuildingsDialog();
            },
        );

        /* this.buildingsCheckinKnot = this.helper.iconButton('.btn-buildings-checkin', this.dom, () => {
this._positionBuildingDialog();
});*/
    }

    private _showCreateInfo(): void {
        this.flash.addInfo(
            this.languageService.translate('text.buildings.create'),
            0,
            noop(),
        );
    }

    private _importBuildingsDialog(): void {
        this.dialog
            .loadTemplate(
                format(
                    '/client/v1/organizations/{0}/buildings/import-list.html',
                    [this.organizationId],
                ),
            )
            .then((dialogKnot) => {
                const form = new Form(dialogKnot);
                const exportLink = new Query(
                    '.export-buildings',
                    dialogKnot,
                ).getKnot();
                const linkKnot = this.helper.createLink(
                    this.languageService.translate('captions.buildings.export'),
                    () => {
                        this.buildingService
                            .exportList(this.organizationId)
                            .then((response, filename) => {
                                saveAs(response.get('raw'), filename);
                            });
                    },
                );
                exportLink.appendChild(linkKnot);

                form.eventSubmit = (formData) => {
                    form.lock();
                    this.buildingService
                        .importList(this.organizationId, formData)
                        .then(
                            (response) => {
                                this.flash.addMessage(response.get('message'));
                                this._drawContent();
                                this.dialog.close();
                            },
                            (response) => {
                                this.flash.addMessage(response.get('message'));
                                form.unlock();
                                form.setErrors(response.get('errors'));
                            },
                        );
                };
                form.eventReset = () => {
                    this.dialog.close();
                };
                this.dialog.open();
            });
    }

    // buildingsIndexController.prototype._positionBuildingDialog = function() {
    //   this.dialog.loadTemplate(format('/client/v1/organizations/{0}/buildings/checkin.html', [this.organizationId])).then((dialogKnot) => {
    //     const form = new Form(dialogKnot);
    //     form.eventSubmit = (formData) => {
    //       form.lock();
    //       const buildingData =  (formData.get('building'));
    //       this.geoLocation.getPosition().then((latitude, longitude) => {
    //         buildingData.set('location.latitude', latitude);
    //         buildingData.set('location.longitude', longitude);
    //         this.buildingService.update(buildingData).then((response) => {
    //           this.flash.addMessage(response.get('message'));
    //           const buildingId =  (buildingData.get('id'));
    //           const marker = this.map.getMarker(buildingId);
    //           this.map.updateMarker(buildingId, marker.get('name'), marker.get('type'), latitude, longitude, marker);
    //           this.dialog.close();
    //         }, (response) => {
    //           this.flash.addMessage(response.get('message'));
    //           form.unlock();
    //           form.setErrors(response.get('errors'));
    //         });
    //       });
    //     };
    //
    //     form.eventReset = () => {
    //       this.dialog.close();
    //     };
    //
    //     this.dialog.open();
    //   });
    // };

    private _selectBuildingToEdit(building: Objekt): void {
        this.tabPanel.setActive('building-map').then(() => {
            this._selectBuilding(building);
        });
    }

    private _selectBuilding(
        building: Objekt | null,
        opt_map: (GoogleMap | null) | undefined = null,
    ): void {
        const map = opt_map ?? this.map;
        map.markers.each((markerData) => {
            const postfix = this._getBuildingPostfix(markerData, false);
            const markerId = markerData.get<string>('id');
            const markerName = markerData.get<string>('name');
            const markerIconName = markerData.get('type') + postfix;
            const markerLatitude = markerData.get<number>('location.latitude');
            const markerLongitude =
                markerData.get<number>('location.longitude');
            map.updateMarker(
                markerId,
                markerName,
                markerIconName,
                markerLatitude,
                markerLongitude,
                markerData,
                {
                    draggable: false,
                },
            );
        });

        if (building) {
            const selectedBuilding = map.getMarker(building.get('id'));
            const buildingId = selectedBuilding.get<string>('id');
            const buildingName = selectedBuilding.get<string>('name');
            const buildingIconName = selectedBuilding.get('type') + '_selected';
            const buildingLatitude =
                selectedBuilding.get<number>('location.latitude');
            const buildingLongitude =
                selectedBuilding.get<number>('location.longitude');
            const buildingData = map.getPolygon(buildingId);
            map.updateMarker(
                buildingId,
                buildingName,
                buildingIconName,
                buildingLatitude,
                buildingLongitude,
                buildingData,
                {
                    draggable: true,
                },
            );
        }
        this.selectedBuilding = building;

        this._changeToolbarButtons();
    }

    private _showBuildingInfoWindow(building: Objekt): void {
        const buildingId = building.get<string>('id');

        const node = new Knot('div');
        const imageKnot = new Knot('img');
        imageKnot.addClass('img-fluid');
        imageKnot.setStyle({
            width: '150px',
            height: '150px',
            'object-fit': 'cover',
        });
        imageKnot.setAttribute('src', building.get('photo.thumb.url'));
        node.appendChild(imageKnot);

        const nameKnot = new Knot('h5');
        nameKnot.addClass('text-center');
        nameKnot.setHtml(building.get('name'));
        node.appendChild(nameKnot);

        this.map.openInfoWindow(buildingId, node.getHtml());
    }

    private _loadOrganization(): Promize {
        const deferred = new Deferred();
        this.organizationService
            .get(this.organizationId, {
                fields: 'id,country.location',
            })
            .then(
                (response) => {
                    this.organization = response.get('organization');
                    deferred.resolve();
                },
                () => {
                    deferred.reject();
                },
            );
        return deferred.promise();
    }

    private _initAccess(): void {
        this.hasAccessBuildingEdit = this.userService.hasAccessByOrganization(
            this.organizationId,
            'buildings_write',
        );
    }

    protected override _drawContent(): Promize | boolean {
        this._changeToolbarButtons();

        this._initBuildingTable();
        return this._initBuildingMap();
    }

    private _changeToolbarButtons(): void {
        this.utilService.showButton(
            this.btnBuildingsNewKnot,
            this.activeTab === 'building-map',
        );
        this.utilService.enableButton(
            this.btnBuildingsNewKnot,
            !this.selectedBuilding,
        );

        this.utilService.showButton(
            this.btnBuildingsEditKnot,
            this.activeTab === 'building-map',
        );
        this.utilService.enableButton(
            this.btnBuildingsEditKnot,
            !!this.selectedBuilding,
        );

        this.utilService.showButton(
            this.btnBuildingsImportListKnot,
            this.activeTab === 'building-table',
        );
    }

    private _initBuildingMap(): Promize {
        const deferred = new Deferred();
        if (eq(this.activeTab, 'building-map')) {
            if (!this.map) {
                this.map = this.mapService.createGoogleMap(
                    this.dom,
                    '.map',
                    {
                        mapTypeId: 'custom',
                        mapTypeControlOptions: {
                            mapTypeIds: [
                                'custom',
                                google.maps.MapTypeId.SATELLITE,
                            ],
                        },
                        mapTypeControl: true,
                        scrollwheel: true,
                    },
                    (map, colorScheme) => {
                        if (
                            colorScheme === 'dark' ||
                            map.getMapType() === google.maps.MapTypeId.SATELLITE
                        ) {
                            this.mapService.setBuildingIcons(map, '#FAFAFA');
                        } else {
                            this.mapService.setBuildingIcons(map, '#795548');
                        }
                        this.mapService.setBuildingIcons(
                            map,
                            '#ff9800',
                            '_selected',
                        );
                        this.mapService.setBuildingIcons(
                            map,
                            '#9E9E9E',
                            '_invisible',
                        );

                        this._selectBuilding(this.selectedBuilding, map);
                    },
                );

                this.map.setMarkers({
                    draggable: false,
                });

                this.map.eventPolygonClick = this._eventPolygonClick.bind(this);
                this.map.eventMarkerClick = this._eventMarkerClick.bind(this);
                this.map.eventMarkerDoubleClick =
                    this._eventMarkerDoubleClick.bind(this);
                this.map.eventMarkerChanged =
                    this._eventMarkerChanged.bind(this);

                this.map.setPolygons(this.mapService.getAreaPolygonOptions());
            }

            const async = new Async();
            async
                .parallel([
                    this._loadArea.bind(this),
                    this._loadBuildings.bind(this),
                ])
                .then(() => {
                    this._setMap();
                    deferred.resolve();
                });
        } else {
            deferred.resolve();
        }
        return deferred.promise();
    }

    private _setMap(): void {
        this.actionCableService.unsubscribeAll();
        this.map.removeAllPolygon();
        this.map.removeAllMarker();

        const areaId = this.area.get<string>('id');
        if (this.area.get('compute_area')) {
            const areaPoints = this.area.get<LatLng[]>('locations', []);
            this.map.createPolygon(areaId, '', areaPoints, this.area);
            this.map.fitPolygonToMap(areaId);
        } else {
            const countryLocation =
                this.organization.get<Objekt>('country.location');
            this.map.setCenter(
                countryLocation.get('latitude'),
                countryLocation.get('longitude'),
            );
            this._noAreaConfirm();
        }

        each(this.buildings, this._handleBuildingChanges.bind(this));
        this.actionCableService
            .subscribe('BuildingChannel', 'create')
            .then(this._handleBuildingChanges.bind(this));
    }

    private _noAreaConfirm(): void {
        this.confirm.load(
            this.languageService.translate('text.areas.create_first'),
            this.languageService.translate('buttons.ok'),
            '',
            '',
            'info',
        );
        this.confirm.eventOK = () => {
            this.state.go('areas.index', {
                organizationId: this.organizationId,
            });
        };
        this.confirm.open();
    }

    private _handleBuildingChanges(building: Objekt): void {
        const buildingId = building.get<string>('id');
        const draggable = this._isSelectedBuildingId(buildingId);
        const postfix = this._getBuildingPostfix(building);
        const buildingIconName = building.get('type') + postfix;
        this.map.createOrUpdateMarker(
            buildingId,
            building.get('name'),
            buildingIconName,
            building.get('location.latitude'),
            building.get('location.longitude'),
            building,
            {
                draggable: draggable,
            },
        );
        this._subscriberBuildingChanges(buildingId);
    }

    private _getBuildingPostfix(
        building: Objekt,
        opt_isSelected: boolean | undefined = true,
    ): string {
        const buildingId = building.get<string>('id');
        const visible = building.get<boolean>('visible');
        const draggable =
            opt_isSelected && this._isSelectedBuildingId(buildingId);
        return draggable ? '_selected' : visible ? '' : '_invisible';
    }

    private _isSelectedBuildingId(buildingId: string): boolean {
        return this.selectedBuilding?.get('id') === buildingId;
    }

    private _subscriberBuildingChanges(buildingId: string): void {
        this.actionCableService
            .subscribe('BuildingChannel', format('{0}/update', [buildingId]))
            .then((building) => {
                const draggable = this._isSelectedBuildingId(buildingId);
                const postfix = this._getBuildingPostfix(building);
                const buildingIconName = building.get('type') + postfix;
                this.map.createOrUpdateMarker(
                    building.get('id'),
                    building.get('name'),
                    buildingIconName,
                    building.get('location.latitude'),
                    building.get('location.longitude'),
                    building,
                    {
                        draggable: draggable,
                    },
                );
            });
        this.actionCableService
            .subscribe('BuildingChannel', format('{0}/remove', [buildingId]))
            .then((building) => {
                this.map.removeMarker(building.get('id'));
            });
    }

    private _eventMarkerClick(building: Objekt): void {
        const buildingId = building.get<string>('id');
        if (this.hasAccessBuildingEdit) {
            if (this._isSelectedBuildingId(buildingId)) {
                this.buildingService.editDialog(building).then(
                    (_editedBuilding, isDeleted) => {
                        if (isDeleted) {
                            this.map.removeMarker(buildingId);
                        } else {
                            this._deselectBuilding();
                        }
                    },
                    () => {
                        this._deselectBuilding();
                    },
                );
            } else {
                this._showBuildingInfoWindow(building);
            }
        } else {
            this._showBuildingInfoWindow(building);
        }
    }

    private _eventMarkerDoubleClick(building: Objekt): void {
        const buildingId = building.get<string>('id');
        if (this.hasAccessBuildingEdit) {
            if (!this._isSelectedBuildingId(buildingId)) {
                this._selectBuilding(building);
            } else {
                this._deselectBuilding();
            }
        }
    }

    private _deselectBuilding(): void {
        this._selectBuilding(null);
    }

    private _eventMarkerChanged(
        building: Objekt,
        latitude: number,
        longitude: number,
    ): void {
        if (this.hasAccessBuildingEdit && this.selectedBuilding) {
            const newBuilding = building.allowKeys(['id']);
            newBuilding.set('location.latitude', latitude);
            newBuilding.set('location.longitude', longitude);
            this.buildingService.update(newBuilding).then(
                (response) => {
                    this.flash.addMessage(response.get('message'));
                },
                (response) => {
                    this.flash.addMessage(response.get('message'));
                },
            );
        }
    }

    private _eventPolygonClick(
        polygonData: Objekt,
        latitude: number,
        longitude: number,
    ): void {
        if (this.hasAccessBuildingEdit) {
            if (this.selectedBuilding) {
                this._deselectBuilding();
            } else {
                const data = new Objekt({
                    building: {
                        location: {
                            latitude: latitude,
                            longitude: longitude,
                        },
                    },
                });
                this.buildingService
                    .newDialogByOrganization(this.organizationId, data)
                    .then(() => {
                        this._drawContent();
                    });
            }
        }
    }

    exit(): void {
        super.exit();

        this.mapService.removeGoogleMap(this.map);
    }
}

export const buildingsIndexController = app.controller(
    resources.buildingsIndexController,
    [
        resources.instances,
        resources.mapService,
        resources.organizationService,
        resources.areaService,
        resources.buildingService,
        resources.actionCableService,
        resources.userService,
        resources.utilService,
        resources.languageService,
    ],
    BuildingsIndexController,
);
