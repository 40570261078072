import {
    CardCollection,
    Instance,
    Knot,
    Objekt,
    Promize,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { AssetService } from '../services/assetService';
import { LanguageService } from '../services/languageService';
import { OrganizationService } from '../services/organizationService';
import { UtilService } from '../services/utilService';
import { VehicleService } from '../services/vehicleService';
import { DashboardController } from './dashboardController';

export class VehiclesIndexController extends DashboardController {
    vehicleCardCollection: CardCollection;

    constructor(
        instances: Instance,
        private vehicleService: VehicleService,
        private organizationService: OrganizationService,
        private utilService: UtilService,
        private assetService: AssetService,
        private languageService: LanguageService,
    ) {
        super(instances);
    }

    protected override _initLayout(): void {
        this._initToolbarButtons();
        this._initFilterForm();

        this._drawContent();
    }

    protected override _drawContent(): Promize | boolean {
        this._initVehicleCollection();
        return true;
    }

    private _initToolbarButtons(): void {
        this.helper.iconButton('.btn-vehicles-new', this.dom, () => {
            this.vehicleService
                .newDialog('organizations', 'organization_id')
                .then(() => {
                    this._drawContent();
                });
        });

        this.helper.iconButton(
            '.btn-site-home',
            this.dom,
            () => {
                this.state.goBack('site.home');
            },
            '',
            true,
            [],
        );
    }

    private _initVehicleCollection(): void {
        if (!this.vehicleCardCollection) {
            this.vehicleCardCollection = new CardCollection(
                this.dom,
                '.vehicle-collection',
                {},
                {
                    no_content: {
                        image_url: this.assetService.getPath(
                            'images/others/no-content.png',
                        ),
                        text: this.languageService.translate('text.no_content'),
                    },
                    row_count: 12,
                    sort: {
                        column: 'name',
                        order: 'asc',
                    },
                },
            );
            this.vehicleCardCollection.eventCardKnot =
                this._vehicleCardKnot.bind(this);
            this.vehicleCardCollection.eventAction = (params) => {
                params.set('query', this.query);
                params.set('organization_ids', this.organizationIds);
                params.set(
                    'fields',
                    'id,name,photo,plate_number,traveled_distance_with_unit,unit,' +
                        this.organizationService.getAssociationTableFields(),
                );
                this.vehicleService.getAll(params).then((response) => {
                    const count = response.get<number>('count');
                    this.vehicleCardCollection.setCount(count);
                    const vehicles = response.get<Objekt[]>('vehicles');
                    this.vehicleCardCollection.setData(vehicles);
                });
            };
            this.vehicleCardCollection.render();
        } else {
            this.vehicleCardCollection.refresh(1);
        }
    }

    private _vehicleCardKnot(cardKnot: Knot, vehicle: Objekt): void {
        this._initVehicleShowButton(cardKnot, vehicle);
        this._initCardButtons(cardKnot, vehicle);
        this.utilService.initOrganizationLink(cardKnot, vehicle);
    }

    private _initVehicleShowButton(cardKnot: Knot, vehicle: Objekt): void {
        this.helper.link(
            'a.card',
            cardKnot,
            (href) => {
                this.state.go(href);
            },
            this.state.resolveUrl('vehicles.show', {
                vehicleId: vehicle.get('id'),
            }),
        );
    }

    private _initCardButtons(cardKnot: Knot, vehicle: Objekt): void {
        // let actionsKnot = new Query('.actions', cardKnot).getKnot();
    }
}

export const vehiclesIndexController = app.controller(
    resources.vehiclesIndexController,
    [
        resources.instances,
        resources.vehicleService,
        resources.organizationService,
        resources.utilService,
        resources.assetService,
        resources.languageService,
    ],
    VehiclesIndexController,
);
