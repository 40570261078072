import {
    CardCollection,
    Confirm,
    Deferred,
    Dialog,
    eq,
    Flash,
    Form,
    format,
    Instance,
    Knot,
    Objekt,
    Promize,
    Query,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { AssetService } from '../services/assetService';
import { LanguageService } from '../services/languageService';
import { OrganizationService } from '../services/organizationService';
import { OrganizationUserService } from '../services/organizationUserService';
import { UserService } from '../services/userService';
import { UtilService } from '../services/utilService';
import { DashboardController } from './dashboardController';

export class OrganizationsIndexController extends DashboardController {
    userId: string;
    myOrganizationCardCollection: CardCollection;
    publicOrganizationCardCollection: CardCollection;
    btnOrganizationsNewKnot: Knot;
    private dialog: Dialog;
    private confirm: Confirm;
    private flash: Flash;

    constructor(
        instances: Instance,
        private organizationService: OrganizationService,
        private organizationUserService: OrganizationUserService,
        private userService: UserService,
        private utilService: UtilService,
        private assetService: AssetService,
        private languageService: LanguageService,
    ) {
        super(instances);

        this.dialog = instances.dialog;
        this.confirm = instances.confirm;
        this.flash = instances.flash;
    }

    protected override _initLayout(): void {
        this.userId = this.userService.getUser('id');

        this._initToolbarButtons();
        this._initFilterForm();
        this._initTabPanel('my-organizations');
        this._changeToolbarButtons();

        this._drawContent();
    }

    protected override _drawContent(): Promize | boolean {
        this._changeToolbarButtons();

        this._initMyOrganizations();
        this._initPublicOrganizations();
        return true;
    }

    private _changeToolbarButtons(): void {
        this.utilService.showButton(
            this.btnOrganizationsNewKnot,
            this.activeTab === 'my-organizations',
        );
    }

    private _initMyOrganizations(): void {
        if (eq(this.activeTab, 'my-organizations')) {
            if (!this.myOrganizationCardCollection) {
                this.myOrganizationCardCollection = new CardCollection(
                    this.dom,
                    '.my-organization-collection',
                    {},
                    {
                        no_content: {
                            image_url: this.assetService.getPath(
                                'images/others/no-content.png',
                            ),
                            text: this.languageService.translate(
                                'text.no_content',
                            ),
                        },
                        row_count: 12,
                        sort: {
                            column: 'name',
                            order: 'asc',
                        },
                    },
                );
                this.myOrganizationCardCollection.eventCardKnot =
                    this._eventCardKnot.bind(this);
                this.myOrganizationCardCollection.eventAction = (params) => {
                    params.set('query', this.query);
                    params.set('fields', 'id,name,image,page_url');
                    this.organizationService
                        .getAllByUser(this.userId, params)
                        .then((response) => {
                            const count = response.get<number>('count');
                            this.myOrganizationCardCollection.setCount(count);
                            const organizations =
                                response.get<Objekt[]>('organizations');
                            this.myOrganizationCardCollection.setData(
                                organizations,
                            );
                            if (
                                params.get('query') === '' &&
                                params.get('offset') === 0 &&
                                count === 0
                            ) {
                                this._noOrganizationConfirm();
                            }
                        });
                };
                this.myOrganizationCardCollection.render();
            } else {
                this.myOrganizationCardCollection.refresh(1);
            }
        }
    }

    private _initPublicOrganizations(): void {
        if (eq(this.activeTab, 'public-organizations')) {
            if (!this.publicOrganizationCardCollection) {
                this.publicOrganizationCardCollection = new CardCollection(
                    this.dom,
                    '.public-organization-collection',
                    {},
                    {
                        no_content: {
                            image_url: this.assetService.getPath(
                                'images/others/no-content.png',
                            ),
                            text: this.languageService.translate(
                                'text.no_content',
                            ),
                        },
                        row_count: 12,
                        sort: {
                            column: 'name',
                            order: 'asc',
                        },
                    },
                );
                this.publicOrganizationCardCollection.eventCardKnot =
                    this._eventCardKnot.bind(this);
                this.publicOrganizationCardCollection.eventAction = (
                    params,
                ) => {
                    params.set('query', this.query);
                    params.set('fields', 'id,name,image,page_url');
                    this.organizationService.getAll(params).then((response) => {
                        const count = response.get<number>('count');
                        this.publicOrganizationCardCollection.setCount(count);
                        const organizations =
                            response.get<Objekt[]>('organizations');
                        this.publicOrganizationCardCollection.setData(
                            organizations,
                        );
                    });
                };
                this.publicOrganizationCardCollection.render();
            } else {
                this.publicOrganizationCardCollection.refresh(1);
            }
        }
    }

    private _initToolbarButtons(): void {
        this.btnOrganizationsNewKnot = this.helper.iconButton(
            '.btn-organizations-new',
            this.dom,
            () => {
                this._showRequiredPoliciesDialog().then(() => {
                    this.organizationService.newDialog().then(() => {
                        this.userService.reload().then(() => {
                            this._drawContent();
                        });
                    });
                });
            },
        );

        this.helper.iconButton(
            '.btn-site-home',
            this.dom,
            () => {
                this.state.goBack('site.home');
            },
            '',
            true,
            [],
        );
    }

    private _showRequiredPoliciesDialog(): Promize {
        const deferred = new Deferred();
        this.dialog
            .loadTemplate('/client/v1/organizations/required-policies.html')
            .then((dialogKnot) => {
                const form = new Form(dialogKnot);
                form.eventSubmit = (formData) => {
                    this.dialog.close();
                    deferred.resolve();
                };
                form.eventReset = () => {
                    this.dialog.close();
                    deferred.reject();
                };
                this.dialog.open();
            });
        return deferred.promise();
    }

    private _getConnectionIconAndDescription(
        organization: Objekt,
    ): Array<string> {
        const organizationId = organization.get<string>('id');
        const isMember =
            this.userService.hasAccessByOrganization(organizationId);
        const isOwner = this.userService.hasAccessByOrganization(
            organizationId,
            'owner',
        );
        const isInactiveMember = this.userService.hasAccessByOrganization(
            organizationId,
            '_basic',
        );
        const isInactiveOwner = this.userService.hasAccessByOrganization(
            organizationId,
            '_owner',
        );

        let results = ['heart_plus', 'captions.organizations.connect'];
        if (isInactiveMember) {
            results = ['heart_broken', 'captions.organizations.disconnect'];
        } else if (isInactiveOwner) {
            results = ['delete', 'captions.organizations.destroy'];
        } else if (isMember || isOwner) {
            results = ['favorite', 'captions.organizations.disconnect'];
        }
        return results;
    }

    private _eventCardKnot(cardKnot: Knot, organization: Objekt): void {
        this._initOrganizationButton(cardKnot, organization);
        this._initOrganizationConnectionButton(cardKnot, organization);
        this._initOrganizationPageUrl(cardKnot, organization);
    }

    private _initOrganizationPageUrl(
        cardKnot: Knot,
        organization: Objekt,
    ): void {
        const pageUrl = organization.get<string>('page_url');
        this.helper.link(
            'a.organization-link',
            cardKnot,
            (href) => {
                this.state.redirect(href, true);
            },
            pageUrl,
            '',
            !!pageUrl,
        );
    }

    private _initOrganizationButton(node: Knot, organization: Objekt): void {
        const organizationId = organization.get<string>('id');
        const isMember =
            this.userService.hasAccessByOrganization(organizationId);
        const isOwner = this.userService.hasAccessByOrganization(
            organizationId,
            'owner',
        );
        const isInactiveMember = this.userService.hasAccessByOrganization(
            organizationId,
            '_basic',
        );
        const isInactiveOwner = this.userService.hasAccessByOrganization(
            organizationId,
            '_owner',
        );

        if (isInactiveMember) {
            node.addEventListener('click', () => {
                this._showConnectMemberInProgressDialog(organization);
            });
        } else if (isInactiveOwner) {
            node.addEventListener('click', () => {
                this._showConnectOwnerInProgressDialog(organization);
            });
        } else if (isMember || isOwner) {
            this._initOrganizationShowButton(node, organization);
        } else {
            node.addEventListener('click', () => {
                this._showOrganizationConnectDialog(organization);
            });
        }
    }

    private _initOrganizationShowButton(
        cardKnot: Knot,
        organization: Objekt,
    ): void {
        this.helper.link(
            'a.card',
            cardKnot,
            (href) => {
                this.state.go(href);
            },
            this.state.resolveUrl('organizations.show', {
                organizationId: organization.get('id'),
            }),
        );
    }

    private _initOrganizationConnectionButton(
        node: Knot,
        organization: Objekt,
    ): void {
        const actionsKnot = new Query('.actions', node).getKnot();

        const [icon, description] =
            this._getConnectionIconAndDescription(organization);

        const connectionLinkKnot = this.helper.createIconButton(
            icon,
            () => {
                const organizationId = organization.get<string>('id');
                const isOwner = this.userService.hasAccessByOrganization(
                    organizationId,
                    'owner',
                );
                const isConnectOwner = this.userService.hasAccessByOrganization(
                    organizationId,
                    '_owner',
                );
                const isMember =
                    this.userService.hasAccessByOrganization(organizationId);
                const isConnectMember =
                    this.userService.hasAccessByOrganization(
                        organizationId,
                        '_basic',
                    );

                if (isOwner) {
                    this._showOrganizationConfirmDialog(organization);
                } else if (isConnectOwner) {
                    this._showOrganizationDeleteDialog(organization);
                } else if (isMember || isConnectMember) {
                    this._showOrganizationDisconnectDialog(organization);
                } else {
                    this._showOrganizationConnectDialog(organization);
                }
            },
            this.languageService.translate(description),
            true,
            ['mdl-button--primary'],
        );
        actionsKnot.appendChild(connectionLinkKnot);
    }

    private _showConnectOwnerInProgressDialog(organization: Objekt): void {
        this.confirm.load(
            this.languageService.translate(
                'organization_users_controller.connect.owner',
            ),
            this.languageService.translate('buttons.ok'),
            '',
            '',
            'info',
        );
        this.confirm.open();
    }

    private _showConnectMemberInProgressDialog(organization: Objekt): void {
        this.confirm.load(
            this.languageService.translate(
                'organization_users_controller.connect.basic',
            ),
            this.languageService.translate('buttons.ok'),
            '',
            '',
            'info',
        );
        this.confirm.open();
    }

    private _showOrganizationConfirmDialog(organization: Objekt): void {
        this.confirm.load(
            this.languageService.translate(
                'organization_users_controller.disconnect.deny',
            ),
            this.languageService.translate('buttons.ok'),
            '',
            '',
            'error',
        );
        this.confirm.open();
    }

    private _showOrganizationConnectDialog(organization: Objekt): void {
        const organizationId = organization.get('id');
        this.dialog
            .loadTemplate(
                format('/client/v1/organizations/{0}/connect.html', [
                    organizationId,
                ]),
            )
            .then((dialogKnot) => {
                const form = new Form(dialogKnot);
                form.eventSubmit = (formData) => {
                    form.lock();
                    const user = this.userService.getUser();
                    const pinCode = formData.get<string>(
                        'organization.security_attributes.pin_code',
                    );
                    this.organizationUserService
                        .connect(organization, user, pinCode)
                        .then(
                            (response) => {
                                this.flash.addMessage(response.get('message'));
                                this.userService.reload().then(() => {
                                    this._drawContent();
                                    this.dialog.close();
                                });
                            },
                            (response) => {
                                this.flash.addMessage(response.get('message'));
                                form.unlock();
                            },
                        );
                };

                form.eventReset = () => {
                    this.dialog.close();
                };

                this.dialog.open();
            });
    }

    private _showOrganizationDisconnectDialog(organization: Objekt): void {
        this.confirm.load(
            this.languageService.translate(
                'organization_users_controller.disconnect.confirm',
                {
                    organization_name: organization.get('name'),
                },
            ),
            this.languageService.translate('buttons.disjoin'),
            this.languageService.translate('buttons.cancel'),
            '',
            'warning',
        );
        this.confirm.eventOK = () => {
            const user = this.userService.getUser();
            this.organizationUserService.disconnect(organization, user).then(
                (response) => {
                    this.flash.addMessage(response.get('message'));
                    this.userService.reload().then(() => {
                        this._drawContent();
                    });
                },
                (response) => {
                    this.flash.addMessage(response.get('message'));
                },
            );
        };
        this.confirm.open();
    }

    private _showOrganizationDeleteDialog(organization: Objekt): void {
        this.confirm.load(
            this.languageService.translate(
                'organizations_controller.destroy.confirm',
                {
                    organization_name: organization.get('name'),
                },
            ),
            this.languageService.translate('buttons.delete'),
            this.languageService.translate('buttons.cancel'),
            '',
            'warning',
        );
        this.confirm.eventOK = () => {
            this.organizationService.remove(organization).then(
                (response) => {
                    this.flash.addMessage(response.get('message'));
                    this.userService.reload().then(() => {
                        this._drawContent();
                    });
                },
                (response) => {
                    this.flash.addMessage(response.get('message'));
                },
            );
        };
        this.confirm.open();
    }

    private _noOrganizationConfirm(): void {
        this.confirm.load(
            this.languageService.translate(
                'site_controller.have_no_organization',
            ),
            this.languageService.translate('buttons.create'),
            this.languageService.translate('buttons.join'),
            '',
            'choice',
        );
        this.confirm.eventOK = () => {
            this._showRequiredPoliciesDialog().then(() => {
                this.organizationService.newDialog().then(() => {
                    this.userService.reload().then(() => {
                        this._drawContent();
                    });
                });
            });
        };
        this.confirm.eventCancel = () => {
            this.tabPanel.setActive('public-organizations');
        };
        this.confirm.open();
    }
}

export const organizationsIndexController = app.controller(
    resources.organizationsIndexController,
    [
        resources.instances,
        resources.organizationService,
        resources.organizationUserService,
        resources.userService,
        resources.utilService,
        resources.assetService,
        resources.languageService,
    ],
    OrganizationsIndexController,
);
