import {
    CardCollection,
    DateIO,
    format,
    Instance,
    Knot,
    Objekt,
    Promize,
    Query,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { AssetService } from '../services/assetService';
import { BudgetService } from '../services/budgetService';
import { LanguageService } from '../services/languageService';
import { OrganizationService } from '../services/organizationService';
import { UtilService } from '../services/utilService';
import { DashboardController } from './dashboardController';

export class BudgetsIndexController extends DashboardController {
    private budgetCardCollection: CardCollection;

    constructor(
        instances: Instance,
        private budgetService: BudgetService,
        private organizationService: OrganizationService,
        private utilService: UtilService,
        private assetService: AssetService,
        private languageService: LanguageService,
    ) {
        super(instances);
    }

    protected override _initLayout(): void {
        this._initToolbarButtons();
        this._initFilterForm();

        this._drawContent();
    }

    protected override _drawContent(): Promize | boolean {
        this._initBudgetCardCollection();
        return true;
    }

    private _initToolbarButtons(): void {
        this.helper.iconButton('.btn-budgets-new', this.dom, () => {
            this.budgetService
                .newDialog('organizations', 'organization_id')
                .then(() => {
                    this._drawContent();
                });
        });

        this.helper.iconButton(
            '.btn-site-home',
            this.dom,
            () => {
                this.state.goBack('site.home');
            },
            '',
            true,
            [],
        );
    }

    private _initBudgetCardCollection(): void {
        if (!this.budgetCardCollection) {
            this.budgetCardCollection = new CardCollection(
                this.dom,
                '.budget-collection',
                {
                    formatStartAtAndEndAt:
                        this.formatStartAtAndEndAt.bind(this),
                    getCurrency: this.getCurrency.bind(this),
                    getAmountIcon: this.getAmountIcon.bind(this),
                },
                {
                    no_content: {
                        image_url: this.assetService.getPath(
                            'images/others/no-content.png',
                        ),
                        text: this.languageService.translate('text.no_content'),
                    },
                    row_count: 12,
                    sort: {
                        column: 'created_at',
                        order: 'desc',
                    },
                },
            );
            this.budgetCardCollection.eventCardKnot =
                this._budgetCardKnot.bind(this);
            this.budgetCardCollection.eventAction = (params) => {
                params.set('query', this.query);
                params.set(
                    'fields',
                    'id,name,start_amount,current_amount,currency,start_at,end_at,' +
                        this.organizationService.getAssociationTableFields(),
                );
                params.set('organization_ids', this.organizationIds);
                this.budgetService.getAll(params).then((response) => {
                    const count = response.get<number>('count');
                    this.budgetCardCollection.setCount(count);
                    const budgets = response.get<Objekt[]>('budgets');
                    this.budgetCardCollection.setData(budgets);
                });
            };
            this.budgetCardCollection.render();
        } else {
            this.budgetCardCollection.refresh(1);
        }
    }

    private _budgetCardKnot(cardKnot: Knot, budget: Objekt): void {
        this._initBudgetActivity(cardKnot, budget);
        this._initBudgetShowButton(cardKnot, budget);
        this.utilService.initOrganizationLink(cardKnot, budget);
    }

    private _initBudgetActivity(cardKnot: Knot, budget: Objekt): void {
        const cardItem = new Query('.card', cardKnot).getKnot();
        const endAt = DateIO.parse(budget.get('end_at'));
        const cssClass = DateIO.isBefore(new Date(), endAt)
            ? 'active'
            : 'inactive';
        cardItem.addClass(cssClass);
    }

    private _initBudgetShowButton(cardKnot: Knot, budget: Objekt): void {
        this.helper.link(
            'a.card',
            cardKnot,
            (href) => {
                this.state.go(href);
            },
            this.state.resolveUrl('budgets.show', {
                budgetId: budget.get('id'),
            }),
        );
    }

    getAmountIcon(budget: Objekt): string {
        const startAmount = budget.get('start_amount');
        const currentAmount = budget.get('current_amount');

        const iconKnot = new Knot('em');
        iconKnot.addClass('material-icons');

        if (currentAmount > startAmount) {
            iconKnot.addClass('success');
            iconKnot.setHtml('trending_up');
        } else if (currentAmount < startAmount) {
            iconKnot.addClass('error');
            iconKnot.setHtml('trending_down');
        } else {
            iconKnot.setHtml('trending_flat');
        }
        return iconKnot.getHtml();
    }

    getCurrency(
        amount: number,
        opt_currency: string | undefined = 'eur',
    ): string {
        return this.languageService.currency(amount, opt_currency);
    }

    formatStartAtAndEndAt(startAt: string, endAt: string): string {
        const startAtFormatted = this.languageService.format(
            DateIO.parse(startAt),
            'format.date',
        );
        const endAtFormatted = this.languageService.format(
            DateIO.parse(endAt),
            'format.date',
        );
        return format('{0} ‒ {1}', [startAtFormatted, endAtFormatted]);
    }
}

export const budgetsIndexController = app.controller(
    resources.budgetsIndexController,
    [
        resources.instances,
        resources.budgetService,
        resources.organizationService,
        resources.utilService,
        resources.assetService,
        resources.languageService,
    ],
    BudgetsIndexController,
);
