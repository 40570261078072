import {
    DateIO,
    DateTimeRangeField,
    Form,
    format,
    Instance,
    LocationField,
    Objekt,
    SelectField,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { AssetService } from './assetService';
import { EntityConfig } from './entityService';
import { LanguageService } from './languageService';
import { LocationService } from './locationService';
import { MapService } from './mapService';
import { ModelService } from './modelService';
import { PersonService } from './personService';

export class HuntService extends ModelService {
    constructor(
        entityConfig: EntityConfig,
        instances: Instance,
        $languageService: LanguageService,
        $assetService: AssetService,
        private locationService: LocationService,
        private personService: PersonService,
        private mapService: MapService,
    ) {
        super(entityConfig, instances, $languageService, $assetService);
    }

    getAllByOrganization(organizationId: string, opt_params?: object) {
        return this.getAllBy('organizations', organizationId, opt_params);
    }

    getAllByUser(userId: string, opt_params?: object) {
        return this.getAllBy('users', userId, opt_params);
    }

    createByOrganization(organizationId: string, hunt: Objekt) {
        return this.createBy('organizations', organizationId, hunt);
    }

    handleForm(form: Form, opt_entityName?: string): void {
        const entityName = opt_entityName || this.entityName;
        const organizationField = form.findByModel<SelectField>(
            format('{0}.organization_id', [entityName]),
        );
        const hunterField = form.findByModel<SelectField>(
            format('{0}.hunter_ids', [entityName]),
        );
        const participantField = form.findByModel<SelectField>(
            format('{0}.participant_ids', [entityName]),
        );
        const locationField = form.findByModel<LocationField>(
            format('{0}.location', [entityName]),
        );
        const startAtField = form.findByModel<DateTimeRangeField>(
            format('{0}.start_at', [entityName]),
        );
        const endAtField = form.findByModel<DateTimeRangeField>(
            format('{0}.end_at', [entityName]),
        );

        if (organizationField) {
            organizationField.eventChange = (organizationId) => {
                if (organizationId) {
                    this.handleLocationFieldByOrganization(
                        this.mapService,
                        this.locationService,
                        organizationId,
                        locationField,
                    );

                    hunterField.showLoader();
                    participantField.showLoader();
                    this.personService
                        .getAllByOrganization(organizationId, {
                            fields: 'id,name',
                        })
                        .then((response) => {
                            const people = response.get<Objekt[]>('people');
                            hunterField.setOptions(people, 'id', 'name');
                            participantField.setOptions(people, 'id', 'name');
                        });
                } else {
                    hunterField.setOptions([]);
                    participantField.setOptions([]);
                }
            };
        }

        const organizationId = organizationField.getValue();
        this.handleLocationFieldByOrganization(
            this.mapService,
            this.locationService,
            organizationId,
            locationField,
        );

        startAtField.eventChange = (startAt) => {
            const endAt = DateIO.format(
                DateIO.addHours(DateIO.parse(startAt), 4),
            );
            endAtField.setValue(endAt);
        };
    }
}

export const huntService = app.service(
    resources.huntService,
    [
        'hunt-hunts-title',
        resources.instances,
        resources.languageService,
        resources.assetService,
        resources.locationService,
        resources.personService,
        resources.mapService,
    ],
    HuntService,
);
