import { Deferred, format, Instance, Objekt } from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { AssetService } from './assetService';
import { GoogleAnalytics4Service } from './googleAnalytics4Service';
import { LanguageService } from './languageService';
import { ModelService } from './modelService';

export class OrderService extends ModelService {
    googleAnalytics4Service: GoogleAnalytics4Service;

    constructor(
        instances: Instance,
        $languageService: LanguageService,
        $assetService: AssetService,
        googleAnalytics4Service: GoogleAnalytics4Service,
    ) {
        super(
            'order-orders-order_id',
            instances,
            $languageService,
            $assetService,
        );

        this.googleAnalytics4Service = googleAnalytics4Service;
    }

    createByOrganization(organizationId: string, order: Objekt) {
        const deferred = new Deferred<Objekt, Objekt>();
        this.http
            .post(
                format('/api/v1/organizations/{0}/orders.json', [
                    organizationId,
                ]),
                order,
            )
            .then(
                (response) => {
                    this.googleAnalytics4Service.purchaseItem(
                        response.get('order.order_id'),
                        response.get('order.license_type') +
                            '_' +
                            response.get('order.year_id'),
                        response.get('order.license_type'),
                        response.get('organization.name'),
                        response.get('order.price'),
                        response.get('order.currency'),
                    );
                    deferred.resolve(response);
                },
                (response) => {
                    deferred.reject(response);
                },
            );
        return deferred.promise();
    }

    execute(paymentParams: Objekt) {
        return this.http.post(
            format('/api/v1/orders/execute.json'),
            paymentParams,
        );
    }

    reject(paymentParams: Objekt) {
        return this.http.post(
            format('/api/v1/orders/reject.json'),
            paymentParams,
        );
    }
}

export const orderService = app.service(
    resources.orderService,
    [
        resources.instances,
        resources.languageService,
        resources.assetService,
        resources.googleAnalytics4Service,
    ],
    OrderService,
);
