import { format, Instance, Objekt } from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { AssetService } from './assetService';
import { LanguageService } from './languageService';
import { ModelService } from './modelService';

export class PageService extends ModelService {
    constructor(
        instances: Instance,
        $languageService: LanguageService,
        $assetService: AssetService,
    ) {
        super(
            'page-pages-short_about',
            instances,
            $languageService,
            $assetService,
        );
    }

    getByOrganization(organizationId: string, opt_params?: object) {
        return this.http.get(
            format('/api/v1/organizations/{0}/pages.json', [organizationId]),
            opt_params,
        );
    }

    getPublicByOrganization(organizationId: string, opt_params?: object) {
        return this.http.get(
            format('/api/v1/organizations/{0}/pages/public.json', [
                organizationId,
            ]),
            opt_params,
        );
    }

    createByOrganization(organizationId: string, page: Objekt) {
        return this.createBy('organizations', organizationId, page);
    }

    createOrUpdateByOrganization(organizationId: string, page: Objekt) {
        const pageId = page.get('id');
        if (pageId) {
            return this.update(page);
        }
        return this.createByOrganization(organizationId, page);
    }
}

export const pageService = app.service(
    resources.pageService,
    [resources.instances, resources.languageService, resources.assetService],
    PageService,
);
