import {
    Dialog,
    Flash,
    Form,
    format,
    Instance,
    Objekt,
    Promize,
    Table,
} from '@siposdani87/sui-js';
import saveAs from 'file-saver';
import { app } from '../app';
import { resources } from '../resources';
import { AssetService } from '../services/assetService';
import { LanguageService } from '../services/languageService';
import { OrganizationService } from '../services/organizationService';
import { OrganizationUserService } from '../services/organizationUserService';
import { DashboardController } from './dashboardController';

export class OrganizationUsersIndexController extends DashboardController {
    organizationId: string;
    organizationUsersTable: Table;
    private dialog: Dialog;
    private flash: Flash;

    constructor(
        instances: Instance,
        private organizationUserService: OrganizationUserService,
        private organizationService: OrganizationService,
        private assetService: AssetService,
        private languageService: LanguageService,
    ) {
        super(instances);

        this.dialog = instances.dialog;
        this.flash = instances.flash;
    }

    protected override _initLayout(): void {
        this.organizationId = this.state.getParam('organizationId');

        this._initToolbarButtons();
        this._initFilterForm();

        this._drawContent();
    }

    protected override _drawContent(): Promize | boolean {
        this._initOrganizationUserTable();
        return true;
    }

    private _initOrganizationUserTable(): void {
        if (!this.organizationUsersTable) {
            this.organizationUsersTable = new Table(
                this.dom,
                '.organization-users-table',
                {
                    no_content: {
                        image_url: this.assetService.getPath(
                            'images/others/no-content.png',
                        ),
                        text: this.languageService.translate('text.no_content'),
                    },
                    sort: {
                        column: 'id',
                        order: 'asc',
                    },
                    row_count: 12,
                    column: 'name',
                    columns: [
                        'avatar',
                        'name',
                        'email',
                        'people',
                        'roles',
                        'active',
                        'actions',
                    ],
                    sorted: ['active'],
                    calculations:
                        this.organizationUserService.getTableCalculations(),
                },
            );

            this.organizationUsersTable.setActions([
                {
                    style: () => {
                        return [
                            'edit',
                            this.languageService.translate('buttons.edit'),
                        ];
                    },
                    click: (organizationUser) => {
                        this.organizationUserService
                            .editDialog(organizationUser)
                            .then(() => {
                                this._drawContent();
                            });
                    },
                },
            ]);

            this.organizationUsersTable.eventAction = (params) => {
                params.set(
                    'fields',
                    'id,roles,active,user.name,user.email,user.avatar,people.id,people.name,user_id,organization_id',
                );
                params.set('query', this.query);
                this.organizationUserService
                    .getAllByOrganization(this.organizationId, params)
                    .then((response) => {
                        const count = response.get<number>('count');
                        this.organizationUsersTable.setCount(count);
                        const organizationUsers =
                            response.get<Objekt[]>('organization_users');
                        this.organizationUsersTable.setData(organizationUsers);
                    });
            };

            this.organizationUsersTable.render();
        } else {
            this.organizationUsersTable.refresh();
        }
    }

    private _initToolbarButtons(): void {
        this.helper.iconButton(
            '.btn-organizations-show',
            this.dom,
            () => {
                this.state.goBack('organizations.show', {
                    organizationId: this.organizationId,
                });
            },
            '',
            true,
            [],
        );

        this.helper.iconButton('.btn-invite', this.dom, () => {
            this._showOrganizationUsersInviteDialog();
        });

        this.helper.iconButton('.btn-join-manual', this.dom, () => {
            this._downloadJoinManual();
        });
    }

    private _downloadJoinManual(): void {
        this.organizationService
            .downloadJoinManual(this.organizationId)
            .then((response, filename) => {
                saveAs(response.get('raw'), filename);
            });
    }

    private _showOrganizationUsersInviteDialog(): void {
        this.dialog
            .loadTemplate(
                format('/client/v1/organizations/{0}/invite.html', [
                    this.organizationId,
                ]),
            )
            .then((dialogKnot) => {
                const form = new Form(dialogKnot);
                form.eventSubmit = (formData) => {
                    form.lock();
                    const invitationData = formData.get<Objekt>('invitation');
                    this.organizationService
                        .invite(this.organizationId, invitationData)
                        .then(
                            (response) => {
                                this.flash.addMessage(response.get('message'));
                                this.dialog.close();
                            },
                            (response) => {
                                this.flash.addMessage(response.get('message'));
                                form.unlock();
                                form.setErrors(response.get('errors'));
                            },
                        );
                };
                form.eventReset = () => {
                    this.dialog.close();
                };
                this.dialog.open();
            });
    }
}

export const organizationUsersIndexController = app.controller(
    resources.organizationUsersIndexController,
    [
        resources.instances,
        resources.organizationUserService,
        resources.organizationService,
        resources.assetService,
        resources.languageService,
    ],
    OrganizationUsersIndexController,
);
