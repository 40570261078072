import { format, Instance, Objekt } from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { AssetService } from './assetService';
import { LanguageService } from './languageService';
import { PersonService } from './personService';

export class GuestService extends PersonService {
    constructor(
        instances: Instance,
        $languageService: LanguageService,
        $assetService: AssetService,
    ) {
        super('guest-guests-name', instances, $languageService, $assetService);
    }

    upgrade(guest: Objekt) {
        const guestId = guest.get('id');
        return this.http.post(
            format('/api/v1/guests/{0}/upgrade.json', [guestId]),
            guest,
        );
    }
}

export const guestService = app.service(
    resources.guestService,
    [resources.instances, resources.languageService, resources.assetService],
    GuestService,
);
