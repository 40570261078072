import { Deferred, Instance, Knot, Objekt, Promize } from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { AreaService } from '../services/areaService';
import { MapService } from '../services/mapService';
import { PersonService } from '../services/personService';
import { UtilService } from '../services/utilService';
import { DashboardController } from './dashboardController';

export class PeopleShowController extends DashboardController {
    personId: string;
    person: Objekt;
    btnPeopleEditKnot: Knot<HTMLElement>;

    constructor(
        instances: Instance,
        private mapService: MapService,
        private personService: PersonService,
        private areaService: AreaService,
        private utilService: UtilService,
    ) {
        super(instances);
    }

    protected override _initLayout(): void {
        this.personId = this.state.getParam('personId');
        this.person = new Objekt();

        this._initToolbarButtons();
        this._initTabPanel('details');
        this._changeToolbarButtons();

        this._loadPerson().then(() => {
            this._drawContent();
        });
    }

    private _initToolbarButtons(): void {
        this.helper.iconButton(
            '.btn-people-index',
            this.dom,
            () => {
                this.state.goBack('people.index');
            },
            '',
            true,
            [],
        );

        this.btnPeopleEditKnot = this.helper.iconButton(
            '.btn-people-edit',
            this.dom,
            () => {
                const classType = this.person.get('class_type');
                const stateId =
                    classType === 'member' ? 'members.index' : 'guests.index';
                this.personService.editDialog(this.person).then(
                    this.utilService.handleEditResponse(stateId, {
                        organizationId: this.person.get('organization_id'),
                    }),
                );
            },
        );
    }

    protected override _drawContent(): Promize | boolean {
        this._changeToolbarButtons();
        return true;
    }

    private _changeToolbarButtons(): void {
        this.utilService.showButton(
            this.btnPeopleEditKnot,
            this.activeTab === 'details',
        );
    }

    private _loadPerson(): Promize {
        const deferred = new Deferred();
        this.personService
            .get(this.personId, {
                fields: 'id,name,class_type',
            })
            .then(
                (response) => {
                    this.person = response.get('person');
                    deferred.resolve();
                },
                () => {
                    deferred.reject();
                },
            );
        return deferred.promise();
    }
}

export const peopleShowController = app.controller(
    resources.peopleShowController,
    [
        resources.instances,
        resources.mapService,
        resources.personService,
        resources.areaService,
        resources.utilService,
    ],
    PeopleShowController,
);
