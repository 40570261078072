import {
    CardCollection,
    DateIO,
    format,
    Instance,
    Knot,
    Objekt,
    Promize,
    Query,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { AssetService } from '../services/assetService';
import { LanguageService } from '../services/languageService';
import { NoteService } from '../services/noteService';
import { OrganizationService } from '../services/organizationService';
import { UtilService } from '../services/utilService';
import { DashboardController } from './dashboardController';

export class NotesIndexController extends DashboardController {
    noteCardCollection: CardCollection;

    constructor(
        instances: Instance,
        private noteService: NoteService,
        private organizationService: OrganizationService,
        private utilService: UtilService,
        private languageService: LanguageService,
        private assetService: AssetService,
    ) {
        super(instances);
    }

    protected override _initLayout(): void {
        this._initToolbarButtons();
        this._initFilterForm();

        this._drawContent();
    }

    protected override _drawContent(): Promize | boolean {
        this._initNoteCardCollection();
        return true;
    }

    private _initToolbarButtons(): void {
        this.helper.iconButton('.btn-notes-new', this.dom, () => {
            this.noteService
                .newDialog('organizations', 'organization_id')
                .then(() => {
                    this._drawContent();
                });
        });

        this.helper.iconButton(
            '.btn-site-home',
            this.dom,
            () => {
                this.state.goBack('site.home');
            },
            '',
            true,
            [],
        );
    }

    private _initNoteCardCollection(): void {
        if (!this.noteCardCollection) {
            this.noteCardCollection = new CardCollection(
                this.dom,
                '.note-collection',
                {
                    formatPublishedAt: this.formatPublishedAt.bind(this),
                },
                {
                    no_content: {
                        image_url: this.assetService.getPath(
                            'images/others/no-content.png',
                        ),
                        text: this.languageService.translate('text.no_content'),
                    },
                    row_count: 12,
                    sort: {
                        column: 'published_at',
                        order: 'desc',
                    },
                },
            );
            this.noteCardCollection.eventCardKnot =
                this._eventCardKnot.bind(this);
            this.noteCardCollection.eventAction = (params) => {
                params.set('query', this.query);
                params.set('organization_ids', this.organizationIds);
                params.set(
                    'fields',
                    'id,title,expired_at,published_at,short_description,color,' +
                        this.organizationService.getAssociationTableFields(),
                );
                this.noteService.getAll(params).then((response) => {
                    const count = response.get<number>('count');
                    this.noteCardCollection.setCount(count);
                    const notes = response.get<Objekt[]>('notes');
                    this.noteCardCollection.setData(notes);
                });
            };
            this.noteCardCollection.render();
        } else {
            this.noteCardCollection.refresh(1);
        }
    }

    private _eventCardKnot(cardKnot: Knot, note: Objekt): void {
        this._initNoteActivity(cardKnot, note);
        this._initNoteShowButton(cardKnot, note);
        this._initNoteColor(cardKnot, note);

        this.utilService.initOrganizationLink(cardKnot, note);
    }

    private _initNoteActivity(cardKnot: Knot, note: Objekt): void {
        const cardItem = new Query('.card', cardKnot).getKnot();
        const expiredAt = DateIO.parse(note.get('expired_at'));
        const cssClass = DateIO.isBefore(new Date(), expiredAt)
            ? 'active'
            : 'inactive';
        cardItem.addClass(cssClass);
    }

    private _initNoteColor(cardKnot: Knot, note: Objekt): void {
        const color = note.get('color');
        const card = new Query('.card', cardKnot).getKnot();
        const expiredAt = DateIO.parse(note.get('expired_at'));
        if (DateIO.isBefore(new Date(), expiredAt)) {
            card.setStyle({
                'border-left': format('3px solid {0}', [color]),
            });
        }
    }

    formatPublishedAt(publishedAt: string): string {
        return this.languageService.format(
            DateIO.parse(publishedAt),
            'format.datetime',
        );
    }

    private _initNoteShowButton(cardKnot: Knot, note: Objekt): void {
        this.helper.link(
            'a.card',
            cardKnot,
            (href) => {
                this.state.go(href);
            },
            this.state.resolveUrl('notes.show', {
                noteId: note.get('id'),
            }),
        );
    }
}

export const notesIndexController = app.controller(
    resources.notesIndexController,
    [
        resources.instances,
        resources.noteService,
        resources.organizationService,
        resources.utilService,
        resources.languageService,
        resources.assetService,
    ],
    NotesIndexController,
);
