import { Depot, Instance, Objekt, Service } from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';

export class AuthService extends Service {
    localDepot: Depot;
    userKey: string;

    constructor(instances: Instance) {
        super();

        this.localDepot = instances.localDepot;

        this.userKey = 'user';
    }

    setUser(user: Objekt): void {
        this.localDepot.set(this.userKey, user);
    }

    getUser<T = Objekt>(opt_attribute?: string, opt_defaultValue?: any): T {
        const userObj = this.localDepot.get(this.userKey);
        const user = new Objekt(userObj);
        if (opt_attribute) {
            return user.get<T>(opt_attribute, opt_defaultValue);
        }
        return user as unknown as T;
    }

    removeUser(): void {
        this.localDepot.remove(this.userKey);
    }
}

export const authService = app.service(
    resources.authService,
    [resources.instances],
    AuthService,
);
