import {
    eachArray,
    eq,
    EventBus,
    GoogleMap,
    gte,
    Http,
    Instance,
    isArray,
    lt,
    NavBar,
    Navigation,
    Objekt,
    Query,
    scrollToElement,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { LanguageService } from '../services/languageService';
import { MapService } from '../services/mapService';
import { PageService } from '../services/pageService';
import { UserService } from '../services/userService';
import { DashboardController } from './dashboardController';

export class PagesShowController extends DashboardController {
    eventBus: EventBus;
    http: Http;
    navBar: NavBar;
    organizationId: string;
    sections: Query<HTMLElement>;
    sectionOffsets: {};
    navBarNavigation: Navigation;
    organization: Objekt;
    map: GoogleMap;

    constructor(
        instances: Instance,
        private pageService: PageService,
        private mapService: MapService,
        private userService: UserService,
        private languageService: LanguageService,
    ) {
        super(instances);

        this.eventBus = instances.eventBus;
        this.http = instances.http;
        this.navBar = instances.navBar;
    }

    protected override _initLayout(): void {
        this.organizationId = this.state.getParam('organizationId');
        this.sections = new Query('section', this.dom);

        this._setSectionOffsets();

        this._initButtons();
        this._initScroll();
        this._initMap();
        this._loadPage();
    }

    private _initButtons(): void {
        this.helper.iconButton(
            '.btn-organizations-index',
            this.dom,
            () => {
                this.state.goBack('organizations.index');
            },
            '',
            true,
            [],
        );
    }

    private _setSectionOffsets(): void {
        this.sectionOffsets = {};
        let sumHeight = 0;
        this.sections.each((section) => {
            const style = section.getComputedStyle();
            const height = parseInt(style.height.replace('px', ''), 10);
            const cssClasses = section.getClasses();
            this.sectionOffsets[cssClasses[0]] = [
                sumHeight - 1,
                sumHeight + height,
            ];
            sumHeight += height;
        });
    }

    private _initScroll(): void {
        this.eventBus.set('window.scroll', (scrollTop) => {
            const scrollTopOffset = scrollTop + 100;
            this.navBarNavigation.each((menuItem) => {
                const id = menuItem.get('id');
                const sectionOffsets = this.sectionOffsets[id];
                if (isArray(sectionOffsets)) {
                    const [min, max] = sectionOffsets;
                    if (eq(scrollTop, 0)) {
                        this.navBarNavigation.setAllInactive();
                    } else if (
                        gte(scrollTopOffset, min) &&
                        lt(scrollTopOffset, max)
                    ) {
                        this.navBarNavigation.setActive(id);
                    }
                }
            });
        });
    }

    private _loadPage(): void {
        this.pageService
            .getPublicByOrganization(this.organizationId, {
                // fields: '',
            })
            .then((response) => {
                this.organization = response.get('organization');
                this._initAreaPolygons(response.get('areas'));
                this._initNavBarNavigation();
            });
    }

    private _initMap(): void {
        this.map = this.mapService.createGoogleMap(this.dom, '.map', {
            mapTypeId: google.maps.MapTypeId.SATELLITE,
            mapTypeControlOptions: {
                mapTypeIds: [google.maps.MapTypeId.SATELLITE],
            },
        });
        this.map.setPolygons(
            this.mapService.getAreaPolygonOptions({
                strokeColor: '#FFFFFF',
            }),
        );
    }

    private _initAreaPolygons(areas: Array<any>): void {
        eachArray(areas, (area, index) => {
            const areaId = area.get('id');
            const points = area.get('locations', []);
            this.map.createPolygon(areaId, '', points, area);
            if (index === 0) {
                this.map.fitPolygonToMap(areaId);
            }
        });
    }

    private _initNavBarNavigation(): void {
        this.navBarNavigation = new Navigation(this.http);

        this.navBarNavigation.addImage(
            'welcome',
            this.organization.get('image.thumb.url'),
            '',
            () => {
                scrollTo(0, 0);
                this.navBar.close();
            },
        );

        this.navBarNavigation.addText(
            'about',
            this.organization.get('name'),
            () => {
                scrollToElement('section.about');
                this.navBar.close();
            },
        );

        this.navBarNavigation.addText(
            'area',
            this.languageService.translate('text.pages.area'),
            () => {
                scrollToElement('section.area');
                this.navBar.close();
            },
        );

        this.navBarNavigation.addText(
            'contact',
            this.languageService.translate('text.pages.contact'),
            () => {
                scrollToElement('section.contact');
                this.navBar.close();
            },
        );

        if (this.userService.isLoggedIn()) {
            this.navBarNavigation.addText(
                'home',
                this.languageService.translate('captions.site.home'),
                (href) => {
                    this.state.go(href);
                    this.navBar.close();
                },
                this.state.resolveUrl('site.home'),
            );
        } else {
            this.navBarNavigation.addText(
                'login',
                this.languageService.translate('buttons.login'),
                (href) => {
                    this.state.go(href);
                    this.navBar.close();
                },
                this.state.resolveUrl('users.login'),
            );
        }

        this.navBarNavigation.bindToContainer(this.navBar.getContainer());
    }

    exit(): void {
        super.exit();

        this.mapService.removeGoogleMap(this.map);
    }
}

export const pagesShowController = app.controller(
    resources.pagesShowController,
    [
        resources.instances,
        resources.pageService,
        resources.mapService,
        resources.userService,
        resources.languageService,
    ],
    PagesShowController,
);
