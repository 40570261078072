import {
    DateIO,
    Form,
    Instance,
    LocationField,
    Objekt,
    TableCalculation,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { AssetService } from './assetService';
import { LanguageService } from './languageService';
import { MapService } from './mapService';
import { ModelService } from './modelService';

export class JourneyService extends ModelService {
    constructor(
        instances: Instance,
        $languageService: LanguageService,
        $assetService: AssetService,
        private mapService: MapService,
    ) {
        super(
            'journey-journeys-description',
            instances,
            $languageService,
            $assetService,
        );
    }

    getAllByVehicle(vehicleId: string, opt_params?: object) {
        return this.getAllBy('vehicles', vehicleId, opt_params);
    }

    createByVehicle(vehicleId: string, journey: Objekt) {
        return this.createBy('vehicles', vehicleId, journey);
    }

    newDialogByVehicle(vehicleId: string) {
        return this.newDialogBy('vehicles', vehicleId);
    }

    handleForm(form: Form, opt_entityName?: string): void {
        const locationField = form.findByModel<LocationField>(
            'journey.destination',
        );

        this.handleLocationField(this.mapService, locationField);
    }

    getTableOptions(): object {
        return {
            no_content: {
                image_url: this.assetService.getPath(
                    'images/others/no-content.png',
                ),
                text: this.languageService.translate('text.no_content'),
            },
            sort: {
                column: 'start_at',
                order: 'desc',
            },
            row_count: 12,
            column: 'description',
            columns: [
                'person_id',
                'description',
                'distance_with_unit',
                'start_at',
                'end_at',
                'actions',
            ],
            sorted: ['start_at:desc', 'end_at:desc'],
            calculations: this.getTableCalculations(),
        };
    }

    getTableFields(): string {
        return 'id,person.id,person.name,description,distance_with_unit,start_at,end_at';
    }

    getTableCalculations(): TableCalculation {
        return {
            person_id: (journey) => {
                return this.helper.createLink(
                    journey.get('person.name'),
                    (href) => {
                        this.state.go(href);
                    },
                    this.state.resolveUrl('people.show', {
                        personId: journey.get('person.id'),
                    }),
                );
            },
            start_at: (journey) => {
                const startAt = DateIO.parse(journey.get('start_at'));
                return this.languageService.format(startAt, 'format.datetime');
            },
            end_at: (journey) => {
                const endAt = DateIO.parse(journey.get('end_at'));
                return this.languageService.format(endAt, 'format.datetime');
            },
        };
    }
}

export const journeyService = app.service(
    resources.journeyService,
    [
        resources.instances,
        resources.languageService,
        resources.assetService,
        resources.mapService,
    ],
    JourneyService,
);
