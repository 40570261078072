import {
    Deferred,
    encodeBase64,
    format,
    Http,
    Instance,
    Objekt,
    Service,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';

export class AssetService extends Service {
    private http: Http;
    private assets: Objekt;

    constructor(instances: Instance) {
        super();

        this.http = instances.http;

        this.assets = new Objekt();
    }

    enter() {
        return this._load();
    }

    private _load() {
        const deferred = new Deferred();
        this.http.get('/api/v1/assets.json').then(
            (data) => {
                this.assets.merge(data.get('assets'));
                deferred.resolve();
            },
            () => {
                deferred.reject();
            },
        );
        return deferred.promise();
    }

    getPath(assetPath: string): string {
        const imageUrl = this.assets.get<string>(assetPath, undefined, true);
        if (!imageUrl) {
            return this.assets.get<string>(
                'images/defaults/asset.png',
                undefined,
                true,
            );
        }
        return imageUrl;
    }

    getManipulateColorPath(assetPath: string, color: string): string {
        const hash = encodeBase64([assetPath, color].join('*'));
        return format('/api/v1/images/manipulate/color/{0}', [hash]);
    }
}

export const assetService = app.service(
    resources.assetService,
    [resources.instances],
    AssetService,
);
