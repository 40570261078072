import { Knot } from '@siposdani87/sui-js';
import { Component } from 'solid-js';
import { render } from 'solid-js/web';

export type Dispose = () => void;

export const renderSolid = <P>(
    component: Component<P>,
    props: P,
    mountKnot: Knot,
): Dispose => {
    return render(() => component.call(this, props), mountKnot.getNode());
};

export const unmountSolid = (dispose: Dispose): void => {
    dispose();
};
