import { DateIO, Instance, Knot, TableCalculation } from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { AdminService } from './adminService';
import { AssetService } from './assetService';
import { LanguageService } from './languageService';

export class AdminOrderService extends AdminService {
    constructor(
        instances: Instance,
        $languageService: LanguageService,
        $assetService: AssetService,
    ) {
        super('order-orders-name', instances, $languageService, $assetService);
    }

    getTableOptions(): object {
        return {
            no_content: {
                image_url: this.assetService.getPath(
                    'images/others/no-content.png',
                ),
                text: this.languageService.translate('text.no_content'),
            },
            sort: {
                column: 'created_at',
                order: 'desc',
            },
            row_count: 12,
            columns: [
                'order_id',
                'invoice_id',
                'organization.name',
                'name',
                'price_with_currency',
                'license_type_text',
                'created_at',
                'year_id',
                'expired',
                'executed',
                'actions',
            ],
            sorted: [
                'invoice_id:asc',
                'year_id:desc',
                'created_at:desc',
                'expired:desc',
                'executed:asc',
            ],
            calculations: this.getTableCalculations(),
        };
    }

    getTableFields(): string {
        return 'id,order_id,invoice_id,organization.name,name,price_with_currency,license_type,license_type_text,year_id,created_at,expired,executed';
    }

    getTableCalculations(): TableCalculation {
        return {
            executed: (order) => {
                const executed = order.get('executed');
                const iconKnot = new Knot('em');
                iconKnot.addClass('material-icons');
                if (executed) {
                    iconKnot.addClass('text-success');
                    iconKnot.setHtml('check_circle');
                } else {
                    iconKnot.addClass('text-error');
                    iconKnot.setHtml('cancel');
                }
                return iconKnot;
            },
            year_id: (order) => {
                const yearId = order.get('year_id');
                return this.languageService.translate('datetime.count.years', {
                    count: yearId,
                });
            },
            expired: (order) => {
                const expired = DateIO.parse(order.get('expired'));
                return this.languageService.format(expired, 'format.datetime');
            },
            created_at: (order) => {
                const createdAt = DateIO.parse(order.get('created_at'));
                return this.languageService.format(
                    createdAt,
                    'format.datetime',
                );
            },
        };
    }
}

export const adminOrderService = app.service(
    resources.adminOrderService,
    [resources.instances, resources.languageService, resources.assetService],
    AdminOrderService,
);
