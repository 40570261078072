import {
    Deferred,
    eq,
    format,
    Instance,
    Objekt,
    Script,
    Service,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { ConfigService } from './configService';

export class FacebookService extends Service {
    script: Script;

    constructor(
        instances: Instance,
        private configService: ConfigService,
    ) {
        super();

        this.script = instances.script;
    }

    login() {
        const deferred = new Deferred<string, undefined>();
        if (window.FB) {
            FB.getLoginStatus((response) => {
                this._statusHandler(deferred, response);
            });
        } else {
            deferred.reject();
        }
        return deferred.promise();
    }

    private _statusHandler(
        deferred: Deferred<string, undefined>,
        fbResponse: fb.StatusResponse,
    ): void {
        const response = new Objekt(fbResponse);
        if (eq(response.get('status'), 'connected')) {
            const accessToken = response.get<string>(
                'authResponse.accessToken',
            );
            deferred.resolve(accessToken);
        } else {
            deferred.reject();
        }
    }

    private _asyncInit() {
        const deferred = new Deferred();
        if (window.FB) {
            FB.init({
                appId: this.configService.get('facebook.app_id'),
                version: 'v19.0',
            });
            FB['AppEvents']['logPageView']();
            deferred.resolve();
        } else {
            deferred.reject();
        }
        return deferred.promise();
    }

    loadSDK() {
        const deferred = new Deferred();
        const locale = this.configService.getLocale().replace('-', '_');
        this.script
            .load(
                'facebook-js-sdk',
                format('//connect.facebook.net/{0}/sdk.js', [locale]),
            )
            .then(
                () => {
                    this._asyncInit().defer(deferred);
                },
                () => {
                    deferred.reject();
                },
            );
        return deferred.promise();
    }

    reset(): void {
        this.script.remove('facebook-js-sdk');
    }

    getUser() {
        const deferred = new Deferred();
        if (window.FB) {
            FB.api('/me?fields=id,first_name,last_name,email', (response) => {
                if (!response || (response as any).error) {
                    deferred.resolve(response);
                } else {
                    deferred.reject(response);
                }
            });
        } else {
            deferred.reject();
        }
        return deferred.promise();
    }

    shareDialog(url: string) {
        const deferred = new Deferred();
        if (window.FB) {
            FB.ui(
                {
                    method: 'share',
                    href: url,
                    app_id: this.configService.get('facebook.app_id'),
                    display: 'popup',
                },
                (response) => {
                    deferred.resolve(response);
                },
            );
        } else {
            deferred.reject();
        }
        return deferred.promise();
    }

    loginDialog() {
        const deferred = new Deferred<string, undefined>();
        if (window.FB) {
            FB.login(
                (response) => {
                    this._statusHandler(deferred, response);
                },
                {
                    scope: this.configService.get('facebook.scope'),
                },
            );
        } else {
            deferred.reject();
        }
        return deferred.promise();
    }
}

export const facebookService = app.service(
    resources.facebookService,
    [resources.instances, resources.configService],
    FacebookService,
);
