import {
    Deferred,
    encodeBase64,
    format,
    guid,
    Instance,
    Script,
    Service,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { ConfigService } from './configService';

export class AppleAuthService extends Service {
    script: Script;

    constructor(
        instances: Instance,
        private configService: ConfigService,
    ) {
        super();

        this.script = instances.script;
    }

    private _asyncInit(): void {
        if (window['AppleID']) {
            const redirectUri =
                location.origin +
                this.configService.get<string>('app.oauth2_html');
            const state = guid();
            const timestamp = Math.round(Date.now() / 1000);
            const nonce = encodeBase64('consumerKey' + ':' + timestamp);
            window['AppleID']['auth']['init']({
                clientId: this.configService.get<string>('apple.client_id'),
                scope: this.configService.get<string>('apple.scope'),
                redirectURI: redirectUri,
                state: state,
                nonce: nonce,
                usePopup: true,
            });
        }
    }

    loadSDK(): void {
        const locale = this.configService.getLocale().replace('-', '_');
        this.script
            .load(
                'apple-id',
                format(
                    'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/{0}/appleid.auth.js',
                    [locale],
                ),
            )
            .then(() => {
                this._asyncInit();
            });
    }

    reset(): void {
        this.script.remove('apple-id');
    }

    loginDialog(opt_key: string | undefined = 'id_token') {
        const deferred = new Deferred<
            [string, string, string, string],
            undefined
        >();
        if (window['AppleID']) {
            window['AppleID']['auth']['signIn']().then(
                (data: any) => {
                    const idToken = data?.['authorization']?.[opt_key] || '';
                    const email = data?.['user']?.['email'] || '';
                    const firstName =
                        data?.['user']?.['name']?.['firstName'] || '***';
                    const lastName =
                        data?.['user']?.['name']?.['lastName'] || '***';
                    deferred.resolve([idToken, email, firstName, lastName]);
                },
                () => {
                    deferred.reject();
                },
            );
        } else {
            deferred.reject();
        }
        return deferred.promise();
    }

    logout(): void {
        if (window['AppleID']) {
            const appleAuth = window['AppleID']['auth2']['getAuthInstance']();
            appleAuth['disconnect']();
        }
    }
}

export const appleAuthService = app.service(
    resources.appleAuthService,
    [resources.instances, resources.configService],
    AppleAuthService,
);
