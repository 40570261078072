import {
    DateTimeRangeField,
    Depot,
    Dialog,
    Form,
    Helper,
    Http,
    Instance,
    Objekt,
    SelectField,
    Service,
    State,
    format,
    isUndefined,
    round,
    TableCalculation,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { AssetService } from './assetService';
import { BuildingService } from './buildingService';
import { DistrictService } from './districtService';
import { GroupService } from './groupService';
import { LanguageService } from './languageService';
import { PersonService } from './personService';
import { PlanPreyService } from './planPreyService';
import { PlanService } from './planService';
import { WildService } from './wildService';

export class ReportService extends Service {
    http: Http;
    helper: Helper;
    state: State;
    dialog: Dialog;
    localDepot: Depot;

    constructor(
        instances: Instance,
        private languageService: LanguageService,
        private assetService: AssetService,
        private personService: PersonService,
        private groupService: GroupService,
        private buildingService: BuildingService,
        private districtService: DistrictService,
        private planService: PlanService,
        private wildService: WildService,
        private planPreyService: PlanPreyService,
    ) {
        super();

        this.http = instances.http;
        this.helper = instances.helper;
        this.state = instances.state;
        this.dialog = instances.dialog;
        this.localDepot = instances.localDepot;
    }

    getAllByPeople(report: Objekt, params: Objekt) {
        return this.http.get(
            format('/api/v1/reports/people/{0}.json', [this._getType(report)]),
            params.merge(report),
        );
    }

    getAllByBuildings(report: Objekt, params: Objekt) {
        return this.http.get(
            format('/api/v1/reports/buildings/{0}.json', [
                this._getType(report),
            ]),
            params.merge(report),
        );
    }

    getAllByTrophies(report: Objekt, params: Objekt) {
        return this.http.get(
            format('/api/v1/reports/trophies/{0}.json', [
                this._getType(report),
            ]),
            params.merge(report),
        );
    }

    private _getType(report: Objekt): string {
        const type = report.get<string>('type');
        return type.replace(/_/, '-').toLowerCase();
    }

    handleForm(type: string, form: Form): void {
        switch (type) {
            case 'people':
                this._handlePeopleForm(form);
                break;
            case 'buildings':
                this._handleBuildingsForm(form);
                break;
            case 'trophies':
                this._handleTrophiesForm(form);
                break;
        }
    }

    private _handlePeopleForm(form: Form): void {
        const peopleField = form.findByModel<SelectField>('report.person_ids');
        const groupsField = form.findByModel<SelectField>('report.group_ids');
        const organizationField = form.findByModel<SelectField>(
            'report.organization_id',
        );

        organizationField.eventChange = (organizationId) => {
            if (organizationId) {
                peopleField.showLoader();
                this.personService
                    .getAllByOrganization(organizationId, {
                        fields: 'id,name',
                    })
                    .then((response) => {
                        const people = response.get<Objekt[]>('people');
                        peopleField.setOptions(people, 'id', 'name');
                    });
                groupsField.showLoader();
                this.groupService
                    .getAllByOrganization(organizationId, {
                        fields: 'id,name',
                    })
                    .then((response) => {
                        const groups = response.get<Objekt[]>('groups');
                        groupsField.setOptions(groups, 'id', 'name');
                    });
            } else {
                peopleField.setOptions([]);
                groupsField.setOptions([]);
            }
        };
    }

    private _handleBuildingsForm(form: Form): void {
        const districtsField = form.findByModel<SelectField>(
            'report.district_ids',
        );
        const buildingsField = form.findByModel<SelectField>(
            'report.building_ids',
        );
        const organizationField = form.findByModel<SelectField>(
            'report.organization_id',
        );

        organizationField.eventChange = (organizationId) => {
            if (organizationId) {
                districtsField.showLoader();
                this.districtService
                    .getAllByOrganization(organizationId, {
                        fields: 'id,title',
                    })
                    .then((response) => {
                        const districts = response.get<Objekt[]>('districts');
                        districtsField.setOptions(districts, 'id', 'title');
                    });
                buildingsField.showLoader();
                this.buildingService
                    .getAllByOrganization(organizationId, {
                        fields: 'id,name',
                    })
                    .then((response) => {
                        const buildings = response.get<Objekt[]>('buildings');
                        buildingsField.setOptions(buildings, 'id', 'name');
                    });
            } else {
                districtsField.setOptions([]);
                buildingsField.setOptions([]);
            }
        };
    }

    private _handleTrophiesForm(form: Form): void {
        const planField = form.findByModel<SelectField>('report.plan_id');
        const planPreysField = form.findByModel<SelectField>(
            'report.plan_prey_ids',
        );
        const wildsField = form.findByModel<SelectField>('report.wild_ids');
        const organizationField = form.findByModel<SelectField>(
            'report.organization_id',
        );
        const startAtField =
            form.findByModel<DateTimeRangeField>('report.start_at');
        const endAtField =
            form.findByModel<DateTimeRangeField>('report.end_at');

        organizationField.eventChange = (organizationId) => {
            if (organizationId) {
                planField.showLoader();
                this.planService
                    .getAllByOrganization(organizationId, {
                        fields: 'id,full_name,start_at,end_at',
                    })
                    .then((response) => {
                        const plans = response.get<Objekt[]>('plans');
                        planField.setOptions(plans, 'id', 'full_name');
                    });
                wildsField.showLoader();
                this.wildService
                    .getAllByOrganization(organizationId, {
                        fields: 'id,full_name',
                    })
                    .then((response) => {
                        const wilds = response.get<Objekt[]>('wilds');
                        wildsField.setOptions(wilds, 'id', 'full_name');
                    });
            } else {
                planField.setOptions([]);
                wildsField.setOptions([]);
            }
        };

        planField.eventChange = (planId) => {
            if (planId) {
                planPreysField.showLoader();
                this.planPreyService
                    .getAllByPlan(planId, {
                        fields: 'id,full_name',
                    })
                    .then((response) => {
                        const planPreys = response.get<Objekt[]>('plan_preys');
                        planPreysField.setOptions(planPreys, 'id', 'full_name');
                    });
                const startAt = planField.getOptionValue('start_at');
                startAtField.setValue(startAt);
                const endAt = planField.getOptionValue('end_at');
                endAtField.setValue(endAt);
            } else {
                planPreysField.setOptions([]);
            }
        };
    }

    protected _getTableCalculations(): TableCalculation {
        return {
            number: (_object, index) => {
                return format('{0}.', [index + 1]);
            },
            sum_duration: (object) => {
                const sumDuration = object.get('sum_duration', 0);
                const sumDurationHours = isUndefined(sumDuration)
                    ? '-'
                    : this.languageService.translate('datetime.count.hours', {
                          count: round(sumDuration / 60 / 60, -1),
                      });
                const sumDurationPrev = object.get('sum_duration_prev', 0);
                const sumDurationPrevHours = isUndefined(sumDurationPrev)
                    ? '-'
                    : round(sumDurationPrev / 60 / 60, -1);
                return format('{1} &rarr; <b>{0}</b>', [
                    sumDurationHours,
                    sumDurationPrevHours,
                ]);
            },
            hunts_count: (object) => {
                const huntsCount = object.get('hunts_count');
                const huntsCountValue = isUndefined(huntsCount)
                    ? '-'
                    : huntsCount;
                const huntsCountPrev = object.get('hunts_count_prev');
                const huntsCountPrevValue = isUndefined(huntsCountPrev)
                    ? '-'
                    : huntsCountPrev;
                return format('{1} &rarr; <b>{0}</b>', [
                    huntsCountValue,
                    huntsCountPrevValue,
                ]);
            },
            trophies_count: (object) => {
                const trophiesCount = object.get('trophies_count');
                const trophiesCountValue = isUndefined(trophiesCount)
                    ? '-'
                    : trophiesCount;
                const trophiesCountPrev = object.get('trophies_count_prev');
                const trophiesCountPrevValue = isUndefined(trophiesCountPrev)
                    ? '-'
                    : trophiesCountPrev;
                return format('{1} &rarr; <b>{0}</b>', [
                    trophiesCountValue,
                    trophiesCountPrevValue,
                ]);
            },
        };
    }

    detailsDialog(reportType: string): void {
        this.dialog
            .loadTemplate(format('/client/v1/reports/{0}.html', [reportType]))
            .then((dialogKnot) => {
                const form = new Form(dialogKnot);
                form.eventSubmit = (formData) => {
                    const reportData = formData.get<Objekt>('report');
                    this.localDepot.set(
                        this.getStorageKeyReport(reportType),
                        reportData,
                    );
                    this.dialog.close();
                    this.state.go(
                        `reports.${reportType}.${reportData.get('type')}`,
                        undefined,
                        false,
                        true,
                    );
                };
                form.eventReset = () => {
                    this.dialog.close();
                };

                form.eventButton = () => {
                    this.localDepot.remove(
                        this.getStorageKeyReport(reportType),
                    );
                    this.detailsDialog(reportType);
                };

                this.handleForm(reportType, form);

                const report = this.localDepot.get(
                    this.getStorageKeyReport(reportType),
                );
                if (report) {
                    form.setModel(
                        new Objekt({
                            report: report,
                        }),
                    );
                }

                this.dialog.open();
            });
    }

    getStorageKeyReport = (reportType) => {
        return `report.${reportType}`;
    };
}

export const reportService = app.service(
    resources.reportService,
    [
        resources.instances,
        resources.languageService,
        resources.assetService,
        resources.personService,
        resources.groupService,
        resources.buildingService,
        resources.districtService,
        resources.planService,
        resources.wildService,
        resources.planPreyService,
    ],
    ReportService,
);
