import {
    colorContrast,
    Deferred,
    eq,
    GoogleMap,
    Instance,
    Knot,
    LatLng,
    Objekt,
    Promize,
    Query,
    Viewer,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { AreaService } from '../services/areaService';
import { DistrictService } from '../services/districtService';
import { MapService } from '../services/mapService';
import { UtilService } from '../services/utilService';
import { DashboardController } from './dashboardController';

export class DistrictsShowController extends DashboardController {
    viewer: Viewer;
    districtId: string;
    district: Objekt;
    organizationId: string;
    btnDistrictsEditKnot: Knot<HTMLElement>;
    area: Objekt;
    map: GoogleMap;

    constructor(
        instances: Instance,
        private mapService: MapService,
        private districtService: DistrictService,
        private areaService: AreaService,
        private utilService: UtilService,
    ) {
        super(instances);

        this.viewer = instances.viewer;
    }

    protected override _initLayout(): void {
        this.districtId = this.state.getParam('districtId');
        this.district = new Objekt();

        this._initToolbarButtons();
        this._initTabPanel('details');
        this._changeToolbarButtons();

        this._initViewer();
        this._initLinks('#details a');
        this._correctDates();

        this._loadDistrict().then(() => {
            this._drawContent();
        });
    }

    private _initToolbarButtons(): void {
        this.helper.iconButton(
            '.btn-districts-index',
            this.dom,
            () => {
                this.state.goBack('districts.index', {
                    organizationId: this.organizationId,
                });
            },
            '',
            true,
            [],
        );

        this.btnDistrictsEditKnot = this.helper.iconButton(
            '.btn-districts-edit',
            this.dom,
            () => {
                this.districtService.editDialog(this.district).then(
                    this.utilService.handleEditResponse('districts.index', {
                        organizationId: this.organizationId,
                    }),
                );
            },
        );
    }

    protected override _drawContent(): Promize | boolean {
        this._changeToolbarButtons();

        this._initMap();
        return true;
    }

    private _changeToolbarButtons(): void {
        this.utilService.showButton(
            this.btnDistrictsEditKnot,
            this.activeTab === 'details',
        );
    }

    private _initViewer(): void {
        const imageBoxKnot = new Query('.image-box', this.dom).getKnot();
        if (imageBoxKnot.exists()) {
            imageBoxKnot.addEventListener('click', () => {
                const imageUrl = this.district.get<string>('photo.url');
                const title = this.district.get<string>('title');
                this.viewer.loadImage(imageUrl, title);
            });
        }
    }

    private _loadDistrict(): Promize {
        const deferred = new Deferred();
        this.districtService
            .get(this.districtId, {
                fields: 'id,title,photo,organization_id,locations,color',
            })
            .then(
                (response) => {
                    this.district = response.get('district');
                    this.organizationId = this.district.get('organization_id');
                    deferred.resolve();
                },
                () => {
                    deferred.reject();
                },
            );
        return deferred.promise();
    }

    private _loadArea(): Promize {
        const deferred = new Deferred();
        this.areaService
            .getAllByOrganization(this.district.get('organization_id'))
            .then(
                (areaResponse) => {
                    this.area =
                        areaResponse.get('areas', [])[0] || new Objekt();
                    deferred.resolve();
                },
                () => {
                    deferred.reject();
                },
            );
        return deferred.promise();
    }

    private _initMap(): void {
        if (eq(this.activeTab, 'details')) {
            if (!this.map) {
                this.map = this.mapService.createGoogleMap(this.dom);

                this.map.setPolygons(this.mapService.getAreaPolygonOptions());
            }

            this._loadArea().then(() => {
                this._setMap();
            });
        }
    }

    private _correctDates(): void {
        this.utilService.correctDate('.move-at', 'format.datetime', this.dom);
        this.utilService.correctComputeAreaInHa('.compute-area', this.dom);
    }

    private _setMap(): void {
        this.map.removeAllPolygon();
        this.map.removeAllMarker();

        const areaId = this.area.get<string>('id');
        const areaPoints = this.area.get<LatLng[]>('locations', []);
        this.map.createPolygon(areaId, '', areaPoints, this.area);
        this.map.fitPolygonToMap(areaId);

        const districtId = this.district.get<string>('id');
        const locations = this.district.get<LatLng[]>('locations', []);
        this.map.createPolygon(districtId, '', locations, this.district, {
            strokeColor: colorContrast(this.district.get('color')),
            fillColor: this.district.get('color'),
        });
    }

    exit(): void {
        super.exit();

        this.mapService.removeGoogleMap(this.map);
    }
}

export const districtsShowController = app.controller(
    resources.districtsShowController,
    [
        resources.instances,
        resources.mapService,
        resources.districtService,
        resources.areaService,
        resources.utilService,
    ],
    DistrictsShowController,
);
