import {
    Deferred,
    eq,
    Instance,
    Knot,
    Objekt,
    Promize,
    Table,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { AssetService } from '../services/assetService';
import { BudgetService } from '../services/budgetService';
import { CategoryService } from '../services/categoryService';
import { ItemService } from '../services/itemService';
import { LanguageService } from '../services/languageService';
import { UtilService } from '../services/utilService';
import { DashboardController } from './dashboardController';

export class BudgetsShowController extends DashboardController {
    private budgetId: string;
    private budget: Objekt;
    private btnBudgetsEditKnot: Knot<HTMLElement>;
    private btnItemsNewKnot: Knot<HTMLElement>;
    private btnCategoriesIndexKnot: Knot<HTMLElement>;
    private organizationId: string;
    private categoriesTable: Table;

    constructor(
        instances: Instance,
        private budgetService: BudgetService,
        private categoryService: CategoryService,
        private itemService: ItemService,
        private utilService: UtilService,
        private assetService: AssetService,
        private languageService: LanguageService,
    ) {
        super(instances);
    }

    protected override _initLayout(): void {
        this.budgetId = this.state.getParam('budgetId');
        this.budget = new Objekt();

        this._initToolbarButtons();
        this._initTabPanel('details');
        this._changeToolbarButtons();

        this._initLinks('#details a');
        this._correctDates();

        this._loadBudget().then(() => {
            this._drawContent();
        });
    }

    private _initToolbarButtons(): void {
        this.helper.iconButton(
            '.btn-budgets-index',
            this.dom,
            () => {
                this.state.goBack('budgets.index');
            },
            '',
            true,
            [],
        );

        this.btnBudgetsEditKnot = this.helper.iconButton(
            '.btn-budgets-edit',
            this.dom,
            () => {
                this.budgetService
                    .editDialog(this.budget)
                    .then(this.utilService.handleEditResponse('budgets.index'));
            },
        );

        this.btnItemsNewKnot = this.helper.iconButton(
            '.btn-items-new',
            this.dom,
            () => {
                this.itemService.newDialogByBudget(this.budgetId).then(() => {
                    this.state.refresh(true);
                });
            },
        );

        this.btnCategoriesIndexKnot = this.helper.iconButton(
            '.btn-categories-index',
            this.dom,
            () => {
                this.state.go('categories.index', {
                    organizationId: this.organizationId,
                });
            },
        );
    }

    protected override _drawContent(): Promize | boolean {
        this._changeToolbarButtons();

        this._initCategoryTable();
        return true;
    }

    private _changeToolbarButtons(): void {
        this.utilService.showButton(
            this.btnBudgetsEditKnot,
            this.activeTab === 'details',
        );

        this.utilService.showButton(
            this.btnItemsNewKnot,
            this.activeTab === 'category-table',
        );

        this.utilService.showButton(
            this.btnCategoriesIndexKnot,
            this.activeTab === 'category-table',
        );
    }

    private _loadBudget(): Promize {
        const deferred = new Deferred();
        this.budgetService
            .get(this.budgetId, {
                fields: 'id,name,organization_id',
            })
            .then(
                (response) => {
                    this.budget = response.get('budget');
                    this.organizationId = this.budget.get('organization_id');
                    deferred.resolve();
                },
                () => {
                    deferred.reject();
                },
            );
        return deferred.promise();
    }

    private _correctDates(): void {
        this.utilService.correctDate('.start-at', 'format.date', this.dom);
        this.utilService.correctDate('.end-at', 'format.date', this.dom);
    }

    private _initCategoryTable(): void {
        if (eq(this.activeTab, 'category-table')) {
            if (!this.categoriesTable) {
                this.categoriesTable = new Table(
                    this.dom,
                    '.categories-table',
                    {
                        no_content: {
                            image_url: this.assetService.getPath(
                                'images/others/no-content.png',
                            ),
                            text: this.languageService.translate(
                                'text.no_content',
                            ),
                        },
                        sort: {
                            column: 'name',
                            order: 'asc',
                        },
                        row_count: 12,
                        columns: [
                            'name',
                            'type_text',
                            'items_count',
                            'sum_amount',
                            'actions',
                        ],
                        sorted: [
                            'name:asc',
                            'items_count:desc',
                            'sum_amount:desc',
                        ],
                        calculations: {
                            name: (category) => {
                                return this.helper.createLink(
                                    category.get('name'),
                                    (href) => {
                                        this.state.go(href);
                                    },
                                    this.state.resolveUrl('items.index', {
                                        budgetId: this.budgetId,
                                        categoryId: category.get('id'),
                                    }),
                                );
                            },
                            sum_amount: (category) => {
                                const sumAmount = category.get('sum_amount');
                                const currency = category.get('currency');
                                return this.languageService.currency(
                                    sumAmount,
                                    currency,
                                );
                            },
                        },
                    },
                );

                this.categoriesTable.eventAction = (params) => {
                    params.set(
                        'fields',
                        'id,name,type_text,items_count,sum_amount,currency',
                    );
                    this.categoryService
                        .getAllByBudget(this.budgetId, params)
                        .then((response) => {
                            const count = response.get<number>('count');
                            this.categoriesTable.setCount(count);
                            const categories =
                                response.get<Objekt[]>('categories');
                            this.categoriesTable.setData(categories);
                        });
                };

                this.categoriesTable.render();
            } else {
                this.categoriesTable.refresh();
            }
        }
    }
}

export const budgetsShowController = app.controller(
    resources.budgetsShowController,
    [
        resources.instances,
        resources.budgetService,
        resources.categoryService,
        resources.itemService,
        resources.utilService,
        resources.assetService,
        resources.languageService,
    ],
    BudgetsShowController,
);
