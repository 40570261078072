import {
    CardCollection,
    Instance,
    Knot,
    Objekt,
    Promize,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { AssetService } from '../services/assetService';
import { LanguageService } from '../services/languageService';
import { ObservationService } from '../services/observationService';
import { OrganizationService } from '../services/organizationService';
import { UtilService } from '../services/utilService';
import { DashboardController } from './dashboardController';

export class ObservationsIndexByUserController extends DashboardController {
    userId: string;
    observationCardCollection: CardCollection;

    constructor(
        instances: Instance,
        private observationService: ObservationService,
        private organizationService: OrganizationService,
        private utilService: UtilService,
        private assetService: AssetService,
        private languageService: LanguageService,
    ) {
        super(instances);
    }

    protected override _initLayout(): void {
        this.userId = this.state.getParam('userId');

        this._initToolbarButtons();
        this._initFilterForm();

        this._drawContent();
    }

    protected override _drawContent(): Promize | boolean {
        this._initObservationCardCollection();
        return true;
    }

    private _initToolbarButtons(): void {
        this.helper.iconButton('.btn-observations-new', this.dom, () => {
            this.observationService.newDialog().then(() => {
                this._drawContent();
            });
        });

        this.helper.iconButton(
            '.btn-users-home',
            this.dom,
            () => {
                this.state.goBack('users.home');
            },
            '',
            true,
            [],
        );
    }

    private _initObservationCardCollection(): void {
        if (!this.observationCardCollection) {
            this.observationCardCollection = new CardCollection(
                this.dom,
                '.observation-collection',
                {},
                {
                    no_content: {
                        image_url: this.assetService.getPath(
                            'images/others/no-content.png',
                        ),
                        text: this.languageService.translate('text.no_content'),
                    },
                    row_count: 12,
                    sort: {
                        column: 'created_at',
                        order: 'desc',
                    },
                },
            );
            this.observationCardCollection.eventCardKnot =
                this._observationCardKnot.bind(this);
            this.observationCardCollection.eventAction = (params) => {
                params.set('query', this.query);
                params.set('organization_ids', this.organizationIds);
                params.set(
                    'fields',
                    'id,short_description,photo,location.address,' +
                        this.organizationService.getAssociationTableFields(),
                );
                this.observationService
                    .getAllByUser(this.userId, params)
                    .then((response) => {
                        const count = response.get<number>('count');
                        this.observationCardCollection.setCount(count);
                        const observations =
                            response.get<Objekt[]>('observations');
                        this.observationCardCollection.setData(observations);
                    });
            };
            this.observationCardCollection.render();
        } else {
            this.observationCardCollection.refresh(1);
        }
    }

    private _observationCardKnot(cardKnot: Knot, observation: Objekt): void {
        this._initObservationShowButton(cardKnot, observation);
        this._initCardButtons(cardKnot, observation);
        this.utilService.initOrganizationLink(cardKnot, observation);
    }

    private _initObservationShowButton(
        cardKnot: Knot,
        observation: Objekt,
    ): void {
        this.helper.link(
            'a.card',
            cardKnot,
            (href) => {
                this.state.go(href);
            },
            this.state.resolveUrl('observations.show', {
                observationId: observation.get('id'),
            }),
        );
    }

    private _initCardButtons(cardKnot: Knot, observation: Objekt): void {
        // let actionsKnot = new Query('.actions', cardKnot).getKnot();
    }
}

export const observationsIndexByUserController = app.controller(
    resources.observationsIndexByUserController,
    [
        resources.instances,
        resources.observationService,
        resources.organizationService,
        resources.utilService,
        resources.assetService,
        resources.languageService,
    ],
    ObservationsIndexByUserController,
);
