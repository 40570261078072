import {
    Deferred,
    Instance,
    Screen,
    Service,
    contain,
    format,
    guid,
    urlWithQueryString,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { ConfigService } from './configService';

export class Oauth2Service extends Service {
    screen: Screen;

    constructor(
        instances: Instance,
        private configService: ConfigService,
    ) {
        super();

        this.screen = instances.screen;
    }

    loginDialog(
        provider: string,
        opt_responseType: string | undefined = 'code',
    ) {
        const redirectUri =
            location.origin + this.configService.get('app.oauth2_html');
        const state = guid();
        const url = urlWithQueryString(
            this.configService.get(format('{0}.authorize_api', [provider])),
            {
                response_type: opt_responseType,
                redirect_uri: encodeURIComponent(redirectUri),
                client_id: this.configService.get(
                    format('{0}.client_id', [provider]),
                ),
                scope: this.configService.get(format('{0}.scope', [provider])),
                state,
            },
        );
        return this._openPopupWithCSRF(
            url,
            state,
            redirectUri,
            opt_responseType,
        );
    }

    private _openPopup(
        url: string,
        opt_width: number | undefined = 500,
        opt_height: number | undefined = 500,
    ) {
        const deferred = new Deferred();
        const top = (this.screen.getWidth() - opt_width) / 2;
        const left = (this.screen.getHeight() - opt_height) / 2;
        const options = format(
            'height={1},width={0},scrollbars=yes,location=yes',
            [opt_width, opt_height, top, left],
        );
        const oauth2Window = window.open(url, '_blank', options);
        window.addEventListener(
            'message',
            (event) => {
                // TODO: Check this condition if auth in usage (SonarLint requirement)
                if (event.origin !== url) {
                    return;
                }

                if (event.data.params === 'oauth2') {
                    oauth2Window.close();
                    deferred.resolve(event.data.params);
                }
            },
            false,
        );
        return deferred.promise();
    }

    private _openPopupWithCSRF(
        url: string,
        state: string,
        redirectUri: string,
        responseType: string,
        opt_width: number | undefined = 500,
        opt_height: number | undefined = 500,
    ) {
        const deferred = new Deferred<[string, string], undefined>();
        this._openPopup(url, opt_width, opt_height).then(
            (params) => {
                if (contain(params['state'], state)) {
                    deferred.resolve([params[responseType], redirectUri]);
                }
            },
            () => {
                deferred.reject();
            },
        );
        return deferred.promise();
    }
}

export const oauth2Service = app.service(
    resources.oauth2Service,
    [resources.instances, resources.configService],
    Oauth2Service,
);
