import { Instance, Objekt, Promize, Table } from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { GroupService } from '../services/groupService';
import { LanguageService } from '../services/languageService';
import { UserService } from '../services/userService';
import { DashboardController } from './dashboardController';

export class GroupsIndexController extends DashboardController {
    organizationId: string;
    groupsTable: Table;

    constructor(
        instances: Instance,
        private groupService: GroupService,
        private userService: UserService,
        private languageService: LanguageService,
    ) {
        super(instances);
    }

    protected override _initLayout(): void {
        this.organizationId = this.state.getParam('organizationId');

        this._initToolbarButtons();
        this._initFilterForm();

        this._drawContent();
    }

    protected override _drawContent(): Promize | boolean {
        this._initGroupTable();
        return true;
    }

    private _initGroupTable(): void {
        if (!this.groupsTable) {
            this.groupsTable = new Table(
                this.dom,
                '.groups-table',
                this.groupService.getTableOptions(),
            );

            this.groupsTable.setActions([
                {
                    style: () => {
                        return [
                            'edit',
                            this.languageService.translate('buttons.edit'),
                            false,
                            !this.userService.hasAccessByOrganization(
                                this.organizationId,
                                'groups_write',
                            ),
                        ];
                    },
                    click: (group) => {
                        this.groupService.editDialog(group).then(() => {
                            this._drawContent();
                        });
                    },
                },
            ]);

            this.groupsTable.eventAction = (params) => {
                params.set('query', this.query);
                params.set('fields', this.groupService.getTableFields());
                this.groupService
                    .getAllByOrganization(this.organizationId, params)
                    .then((response) => {
                        const count = response.get<number>('count');
                        this.groupsTable.setCount(count);
                        const groups = response.get<Objekt[]>('groups');
                        this.groupsTable.setData(groups);
                    });
            };
            this.groupsTable.render();
        } else {
            this.groupsTable.refresh();
        }
    }

    private _initToolbarButtons(): void {
        this.helper.iconButton('.btn-groups-new', this.dom, () => {
            this.groupService
                .newDialogByOrganization(this.organizationId)
                .then(() => {
                    this._drawContent();
                });
        });

        this.helper.iconButton(
            '.btn-organizations-show',
            this.dom,
            () => {
                this.state.goBack('organizations.show', {
                    organizationId: this.organizationId,
                });
            },
            '',
            true,
            [],
        );
    }
}

export const groupsIndexController = app.controller(
    resources.groupsIndexController,
    [
        resources.instances,
        resources.groupService,
        resources.userService,
        resources.languageService,
    ],
    GroupsIndexController,
);
