import { Controller, Helper, Instance, Knot, State } from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';

export class SiteChangesController extends Controller {
    dom: Knot;
    state: State;
    helper: Helper;

    constructor(instances: Instance) {
        super();

        this.dom = instances.dom;
        this.state = instances.state;
        this.helper = instances.helper;
    }

    enter(): void {
        this._initLayout();
    }

    protected _initLayout(): void {
        this._initToolbarButtons();
    }

    private _initToolbarButtons(): void {
        this.helper.iconButton(
            '.btn-landing-page',
            this.dom,
            () => {
                this.state.goBack('site.landing-page');
            },
            '',
            true,
            [],
        );
    }
}

export const siteChangesController = app.controller(
    resources.siteChangesController,
    [resources.instances],
    SiteChangesController,
);
