import { Instance, Objekt, Table } from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { AdminOpenSeasonService } from '../services/adminOpenSeasonService';
import { LanguageService } from '../services/languageService';
import { AdminController } from './adminController';

export class AdminOpenSeasonsIndexController extends AdminController {
    private table: Table;

    constructor(
        instances: Instance,
        private adminOpenSeasonService: AdminOpenSeasonService,
        private languageService: LanguageService,
    ) {
        super(instances);
    }

    protected override _initToolbarButtons(): void {
        this.helper.iconButton(
            '.btn-admin-index',
            this.dom,
            () => {
                this.state.goBack('admin.index');
            },
            '',
            true,
            [],
        );

        this.helper.iconButton('.btn-open-seasons-new', this.dom, () => {
            this.adminOpenSeasonService.newDialogByAdmin().then(() => {
                this._drawContent();
            });
        });
    }

    protected override _initTable(): void {
        if (!this.table) {
            this.table = new Table(
                this.dom,
                'table',
                this.adminOpenSeasonService.getTableOptions(),
            );

            this.table.setActions([
                {
                    style: () => {
                        return [
                            'edit',
                            this.languageService.translate('buttons.edit'),
                        ];
                    },
                    click: (openSeason) => {
                        this.adminOpenSeasonService
                            .editDialog(openSeason, '/admin/')
                            .then(() => {
                                this._drawContent();
                            });
                    },
                },
            ]);

            this.table.eventAction = (params) => {
                params.set('query', this.query);
                params.set(
                    'fields',
                    this.adminOpenSeasonService.getTableFields(),
                );
                this.adminOpenSeasonService.getAll(params).then((response) => {
                    const count = response.get<number>('count');
                    this.table.setCount(count);
                    const openSeasons = response.get<Objekt[]>('open_seasons');
                    this.table.setData(openSeasons);
                });
            };

            this.table.render();
        } else {
            this.table.refresh();
        }
    }
}

export const adminOpenSeasonsIndexController = app.controller(
    resources.adminOpenSeasonsIndexController,
    [
        resources.instances,
        resources.adminOpenSeasonService,
        resources.languageService,
    ],
    AdminOpenSeasonsIndexController,
);
