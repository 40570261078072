import { coreResources } from '@siposdani87/sui-js';

export const resources = {
    ...coreResources,
    utilService: 'utilService',
    militaryService: 'militaryService',
    footerService: 'footerService',
    headerService: 'headerService',
    layoutService: 'layoutService',
    leftMenuService: 'leftMenuService',
    menuService: 'menuService',
    topMenuService: 'topMenuService',
    navBarService: 'navBarService',
    tokenService: 'tokenService',
    pageHeaderService: 'pageHeaderService',
    authService: 'authService',
    appService: 'appService',
    mapService: 'mapService',
    configService: 'configService',
    bannerService: 'bannerService',
    facebookService: 'facebookService',
    facebookPixelService: 'facebookPixelService',
    googleIdentityService: 'googleIdentityService',
    googleAnalytics4Service: 'googleAnalytics4Service',
    googleChartService: 'googleChartService',
    googleCaptchaService: 'googleCaptchaService',
    googleTagManagerService: 'googleTagManagerService',
    appleAuthService: 'appleAuthService',
    oauth2Service: 'oauth2Service',
    actionCableService: 'actionCableService',

    notificationHistoryService: 'notificationHistoryService',
    preyService: 'preyService',
    reportPersonService: 'reportPersonService',
    reportBuildingService: 'reportBuildingService',
    reportTrophyService: 'reportTrophyService',
    notificationService: 'notificationService',
    locationService: 'locationService',
    pageService: 'pageService',
    siteService: 'siteService',
    assetService: 'assetService',
    deviceService: 'deviceService',
    buildingService: 'buildingService',
    areaService: 'areaService',
    districtService: 'districtService',
    personService: 'personService',
    memberService: 'memberService',
    guestService: 'guestService',
    huntingPackageService: 'huntingPackageService',
    groupService: 'groupService',
    eventService: 'eventService',
    noteService: 'noteService',
    vehicleService: 'vehicleService',
    journeyService: 'journeyService',
    budgetService: 'budgetService',
    categoryService: 'categoryService',
    languageService: 'languageService',
    organizationService: 'organizationService',
    organizationUserService: 'organizationUserService',
    itemService: 'itemService',
    userService: 'userService',
    planService: 'planService',
    openSeasonService: 'openSeasonService',
    planPreyService: 'planPreyService',
    huntingPackagePreyService: 'huntingPackagePreyService',
    wildService: 'wildService',
    trophyService: 'trophyService',
    smallGameTrophyService: 'smallGameTrophyService',
    bigGameTrophyService: 'bigGameTrophyService',
    huntService: 'huntService',
    socialHuntService: 'socialHuntService',
    individualHuntService: 'individualHuntService',
    orderService: 'orderService',
    couponService: 'couponService',
    licenseService: 'licenseService',
    subscriberService: 'subscriberService',
    discoveryService: 'discoveryService',
    membershipService: 'membershipService',
    reportService: 'reportService',
    hunterTicketService: 'hunterTicketService',
    earTagService: 'earTagService',
    chamberService: 'chamberService',
    observationService: 'observationService',
    countryService: 'countryService',
    securityService: 'securityService',
    feedbackService: 'feedbackService',

    adminOrganizationService: 'adminOrganizationService',
    adminOrderService: 'adminOrderService',
    adminUserService: 'adminUserService',
    adminWildService: 'adminWildService',
    adminOpenSeasonService: 'adminOpenSeasonService',
    adminGameService: 'adminGameService',
    adminCountryService: 'adminCountryService',
    adminCouponService: 'adminCouponService',
    adminSponsorService: 'adminSponsorService',
    adminSubscriberService: 'adminSubscriberService',
    adminChamberService: 'adminChamberService',

    organizationsIndexController: 'organizationsIndexController',
    organizationsShowController: 'organizationsShowController',
    organizationsConnectController: 'organizationsConnectController',
    organizationsRolesController: 'organizationsRolesController',

    organizationUsersIndexController: 'organizationUsersIndexController',

    chambersIndexController: 'chambersIndexController',
    chambersShowController: 'chambersShowController',

    pagesShowController: 'pagesShowController',

    licensesIndexByOrganizationController:
        'licensesIndexByOrganizationController',

    areasIndexController: 'areasIndexController',

    districtsIndexController: 'districtsIndexController',
    districtsShowController: 'districtsShowController',

    buildingsIndexController: 'buildingsIndexController',
    buildingsShowController: 'buildingsShowController',

    membersIndexController: 'membersIndexController',

    guestsIndexController: 'guestsIndexController',

    peopleIndexController: 'peopleIndexController',
    peopleShowController: 'peopleShowController',

    groupsIndexController: 'groupsIndexController',
    groupsShowController: 'groupsShowController',

    siteMaintenanceController: 'siteMaintenanceController',
    siteHomeController: 'siteHomeController',
    siteLandingPageController: 'siteLandingPageController',
    sitePoliciesController: 'sitePoliciesController',
    siteChangesController: 'siteChangesController',

    usersActivationController: 'usersActivationController',
    usersLoginController: 'usersLoginController',
    usersProfileController: 'usersProfileController',
    usersHomeController: 'usersHomeController',
    usersRegistrationController: 'usersRegistrationController',
    usersRecoveryPasswordController: 'usersRecoveryPasswordController',
    usersChangePasswordController: 'usersChangePasswordController',

    usersIndexController: 'usersIndexController',

    eventsIndexController: 'eventsIndexController',
    eventsShowController: 'eventsShowController',

    budgetsIndexController: 'budgetsIndexController',
    budgetsShowController: 'budgetsShowController',

    itemsIndexController: 'itemsIndexController',
    itemsIndexByUserController: 'itemsIndexByUserController',

    vehiclesIndexController: 'vehiclesIndexController',
    vehiclesShowController: 'vehiclesShowController',

    categoriesIndexController: 'categoriesIndexController',

    reportsIndexController: 'reportsIndexController',

    reportsPeopleWhatShootController: 'reportsPeopleWhatShootController',
    reportsPeopleWhereShootController: 'reportsPeopleWhereShootController',
    reportsPeopleWhenShootController: 'reportsPeopleWhenShootController',
    reportsPeopleWhereHuntController: 'reportsPeopleWhereHuntController',
    reportsPeopleHowMuchHuntController: 'reportsPeopleHowMuchHuntController',
    reportsPeopleWhenHuntController: 'reportsPeopleWhenHuntController',

    reportsBuildingsWhatTheyShootController:
        'reportsBuildingsWhatTheyShootController',
    reportsBuildingsWhenTheyShootController:
        'reportsBuildingsWhenTheyShootController',
    reportsBuildingsWhoTheyShootController:
        'reportsBuildingsWhoTheyShootController',
    reportsBuildingsWhoTheyHuntController:
        'reportsBuildingsWhoTheyHuntController',
    reportsBuildingsHowMuchTheyHuntController:
        'reportsBuildingsHowMuchTheyHuntController',
    reportsBuildingsWhenTheyHuntController:
        'reportsBuildingsWhenTheyHuntController',

    reportsTrophiesWhereTheyShootController:
        'reportsTrophiesWhereTheyShootController',
    reportsTrophiesWhenTheyShootController:
        'reportsTrophiesWhenTheyShootController',
    reportsTrophiesWhoTheyShootController:
        'reportsTrophiesWhoTheyShootController',

    plansIndexController: 'plansIndexController',
    plansShowController: 'plansShowController',

    trophiesIndexController: 'trophiesIndexController',
    trophiesIndexByUserController: 'trophiesIndexByUserController',
    trophiesShowController: 'trophiesShowController',

    discoveriesIndexController: 'discoveriesIndexController',
    discoveriesShowController: 'discoveriesShowController',

    planPreysShowController: 'planPreysShowController',

    huntingPackagePreysShowController: 'huntingPackagePreysShowController',

    huntsIndexController: 'huntsIndexController',
    huntsShowController: 'huntsShowController',
    huntsIndexByUserController: 'huntsIndexByUserController',

    huntingPackagesIndexController: 'huntingPackagesIndexController',
    huntingPackagesShowController: 'huntingPackagesShowController',

    notesIndexController: 'notesIndexController',
    notesShowController: 'notesShowController',

    ordersNewController: 'ordersNewController',
    ordersExecuteController: 'ordersExecuteController',
    ordersRejectController: 'ordersRejectController',

    subscribersConfirmController: 'subscribersConfirmController',

    membershipsIndexController: 'membershipsIndexController',

    observationsIndexByUserController: 'observationsIndexByUserController',
    observationsIndexByOrganizationController:
        'observationsIndexByOrganizationController',
    observationsShowController: 'observationsShowController',

    featuresIndexController: 'featuresIndexController',
    rewardsIndexController: 'rewardsIndexController',

    adminIndexController: 'adminIndexController',
    adminUsersIndexController: 'adminUsersIndexController',
    adminChambersIndexController: 'adminChambersIndexController',
    adminOrganizationsIndexController: 'adminOrganizationsIndexController',
    adminWildsIndexController: 'adminWildsIndexController',
    adminOpenSeasonsIndexController: 'adminOpenSeasonsIndexController',
    adminGamesIndexController: 'adminGamesIndexController',
    adminOrdersIndexController: 'adminOrdersIndexController',
    adminCountriesIndexController: 'adminCountriesIndexController',
    adminCouponsIndexController: 'adminCouponsIndexController',
    adminSponsorsIndexController: 'adminSponsorsIndexController',
    adminSubscribersIndexController: 'adminSubscribersIndexController',
};
