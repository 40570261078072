import { format, Instance, Knot, Objekt } from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { AssetService } from './assetService';
import { LanguageService } from './languageService';
import { ModelService } from './modelService';

export class OrganizationService extends ModelService {
    constructor(
        instances: Instance,
        $languageService: LanguageService,
        $assetService: AssetService,
    ) {
        super(
            'organization-organizations-name',
            instances,
            $languageService,
            $assetService,
        );
    }

    getPublic(organizationId: string, opt_params?: object) {
        return this.http.get(
            format('/api/v1/organizations/{0}/public.json', [organizationId]),
            opt_params,
        );
    }

    getAllByUser(userId: string, opt_params?: object) {
        return this.getAllBy('users', userId, opt_params);
    }

    getAllByChamber(chamberId: string, opt_params?: object) {
        return this.getAllBy('chambers', chamberId, opt_params);
    }

    invite(organizationId: string, invitation: Objekt) {
        return this.http.post(
            format('/api/v1/organizations/{0}/invite.json', [organizationId]),
            invitation,
        );
    }

    downloadJoinManual(organizationId: string, opt_params?: object) {
        return this.http.get(
            format('/api/v1/organizations/{0}/join-manual.pdf', [
                organizationId,
            ]),
            opt_params,
            {
                responseType: 'blob',
            },
        );
    }

    getAssociationTableFields(): string {
        return 'organization.id,organization.full_name,organization.image';
    }

    getTableOrganizationImageCalculation(entity: Objekt): Knot {
        const organizationImageUrl = entity.get<string>(
            'organization.image.thumb.url',
        );
        const imageWrapper = new Knot('div');
        imageWrapper.addClass('img-wrapper');
        if (!organizationImageUrl) {
            return imageWrapper;
        }
        const imageKnot = new Knot('img');
        imageKnot.addClass('img-responsive');
        imageKnot.setAttribute('src', organizationImageUrl);
        imageWrapper.appendChild(imageKnot);
        const organizationFullName = entity.get('organization.full_name');
        const linkKnot = this.helper.createLink(
            imageWrapper.getHtml() + organizationFullName,
            (href) => {
                this.state.go(href);
            },
            this.state.resolveUrl('organizations.show', {
                organizationId: entity.get('organization.id'),
            }),
        );
        linkKnot.addClass('image-with-title');
        return linkKnot;
    }
}

export const organizationService = app.service(
    resources.organizationService,
    [resources.instances, resources.languageService, resources.assetService],
    OrganizationService,
);
