import {
    DateIO,
    each,
    eq,
    format,
    inArray,
    Instance,
    Knot,
    Objekt,
    pluck,
    Promize,
    Table,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { AssetService } from '../services/assetService';
import { AuthService } from '../services/authService';
import { LanguageService } from '../services/languageService';
import { LicenseService } from '../services/licenseService';
import { MembershipService } from '../services/membershipService';
import { OrganizationService } from '../services/organizationService';
import { UtilService } from '../services/utilService';
import { DashboardController } from './dashboardController';

export class MembershipsIndexController extends DashboardController {
    userId: string;
    membershipsTable: Table;
    licensesTable: Table;
    btnOrdersNew: Knot;

    constructor(
        instances: Instance,
        private authService: AuthService,
        private membershipService: MembershipService,
        private licenseService: LicenseService,
        private organizationService: OrganizationService,
        private utilService: UtilService,
        private languageService: LanguageService,
        private assetService: AssetService,
    ) {
        super(instances);
    }

    protected override _initLayout(): void {
        this.userId = this.state.getParam('userId');

        this._initToolbarButtons();
        this._initTabPanel('memberships');
        this._changeToolbarButtons();

        this._drawContent();
    }

    protected override _drawContent(): Promize | boolean {
        this._changeToolbarButtons();

        this._initMembershipsTable();
        this._initLicensesTable();
        return true;
    }

    private _changeToolbarButtons(): void {
        this.utilService.showButton(
            this.btnOrdersNew,
            this.activeTab === 'licenses',
        );
    }

    private _initToolbarButtons(): void {
        this.helper.iconButton(
            '.btn-users-home',
            this.dom,
            () => {
                this.state.goBack('users.home');
            },
            '',
            true,
            [],
        );

        this.btnOrdersNew = this.helper.iconButton(
            '.btn-orders-new',
            this.dom,
            () => {
                this.state.go('orders.new');
            },
        );
    }

    private _initMembershipsTable(): void {
        if (eq(this.activeTab, 'memberships')) {
            if (!this.membershipsTable) {
                this.membershipsTable = new Table(
                    this.dom,
                    '.memberships-table',
                    {
                        no_content: {
                            image_url: this.assetService.getPath(
                                'images/others/no-content.png',
                            ),
                            text: this.languageService.translate(
                                'text.no_content',
                            ),
                        },
                        sort: {
                            column: 'created_at',
                            order: 'desc',
                        },
                        row_count: 12,
                        column: 'organization.full_name',
                        columns: [
                            'organizationImage',
                            'join_at',
                            'allowNotifications',
                            'allow_data_share',
                            'actions',
                        ],
                        sorted: [],
                        calculations: {
                            organizationImage: (membership) => {
                                return this.organizationService.getTableOrganizationImageCalculation(
                                    membership,
                                );
                            },
                            allowNotifications: (membership) => {
                                const notificationsKnot = new Knot('div');
                                const allowNotifications = membership.get(
                                    'allow_notifications',
                                    [],
                                );
                                if (allowNotifications.length === 0) {
                                    const tagKnot = new Knot('span');
                                    tagKnot.addClass('tag');
                                    tagKnot.setHtml(
                                        this.languageService.translate(
                                            'text.all_notifications',
                                        ),
                                    );
                                    return tagKnot;
                                }
                                each(allowNotifications, (notification) => {
                                    const notificationText =
                                        this.languageService.translate(
                                            format(
                                                'notification_history.type.{0}',
                                                [notification],
                                            ),
                                        );
                                    const tagKnot = new Knot('span');
                                    tagKnot.addClass('tag');
                                    tagKnot.setHtml(notificationText);
                                    notificationsKnot.appendChild(tagKnot);
                                });
                                return notificationsKnot;
                            },
                            allow_data_share: (membership) => {
                                const allowDataShare =
                                    membership.get('allow_data_share');
                                const iconKnot = new Knot('em');
                                iconKnot.addClass([
                                    'material-icons',
                                    'text-success',
                                ]);
                                iconKnot.setHtml('check_circle');
                                return allowDataShare ? iconKnot : '';
                            },
                        },
                    },
                );

                this.membershipsTable.setActions([
                    {
                        style: () => {
                            return [
                                'edit',
                                this.languageService.translate('buttons.edit'),
                            ];
                        },
                        click: (membership) => {
                            this.membershipService
                                .editDialog(membership)
                                .then(() => {
                                    this._drawContent();
                                });
                        },
                    },
                ]);

                this.membershipsTable.eventAction = (params) => {
                    params.set(
                        'fields',
                        'id,allow_notifications,join_at,allow_data_share,user_id,' +
                            this.organizationService.getAssociationTableFields(),
                    );
                    this.membershipService.getAll(params).then((response) => {
                        const count = response.get<number>('count');
                        this.membershipsTable.setCount(count);
                        const memberships =
                            response.get<Objekt[]>('memberships');
                        this.membershipsTable.setData(memberships);
                    });
                };

                this.membershipsTable.render();
            } else {
                this.membershipsTable.refresh();
            }
        }
    }

    private _initLicensesTable(): void {
        if (eq(this.activeTab, 'licenses')) {
            if (!this.licensesTable) {
                this.licensesTable = new Table(this.dom, '.licenses-table', {
                    no_content: {
                        image_url: this.assetService.getPath(
                            'images/others/no-content.png',
                        ),
                        text: this.languageService.translate('text.no_content'),
                    },
                    sort: {
                        column: 'expired',
                        order: 'desc',
                    },
                    row_count: 12,
                    column: 'order_id',
                    columns: [
                        'organizationImage',
                        'order_id',
                        'license_type',
                        'expired',
                    ],
                    sorted: ['expired:desc'],
                    calculations: {
                        organizationImage: (license) => {
                            return this.organizationService.getTableOrganizationImageCalculation(
                                license,
                            );
                        },
                        license_type: (license) => {
                            const licenseType = license.get('license_type');
                            const tagKnot = new Knot('span');
                            tagKnot.addClass('tag');
                            tagKnot.setHtml(
                                this.languageService.translate(
                                    format('order.license_type.{0}', [
                                        licenseType,
                                    ]),
                                ),
                            );
                            return tagKnot;
                        },
                        expired: (license) => {
                            const expired = DateIO.parse(
                                license.get('expired'),
                                'YYYY-MM-DD',
                            );
                            return this.languageService.format(
                                expired,
                                'format.datetime',
                            );
                        },
                    },
                    rowStyle: (license) => {
                        const styles = [];
                        const expired = DateIO.parse(
                            license.get('expired'),
                            'YYYY-MM-DD',
                        );
                        const licenses = this.authService.getUser<Objekt[]>(
                            'licenses',
                            [],
                        );
                        const isSelected = inArray(
                            pluck(licenses, 'id'),
                            license.get('id'),
                        );
                        if (isSelected) {
                            styles.push('selected');
                        }
                        if (DateIO.isAfter(new Date(), expired)) {
                            styles.push('inactive');
                        }
                        return styles;
                    },
                });

                this.licensesTable.eventAction = (params) => {
                    params.set(
                        'fields',
                        'id,order_id,license_type,expired,' +
                            this.organizationService.getAssociationTableFields(),
                    );
                    this.licenseService
                        .getAllByUser(this.userId, params)
                        .then((response) => {
                            const count = response.get<number>('count');
                            this.licensesTable.setCount(count);
                            const licenses = response.get<Objekt[]>('licenses');
                            this.licensesTable.setData(licenses);
                        });
                };

                this.licensesTable.render();
            } else {
                this.licensesTable.refresh();
            }
        }
    }
}

export const membershipsIndexController = app.controller(
    resources.membershipsIndexController,
    [
        resources.instances,
        resources.authService,
        resources.membershipService,
        resources.licenseService,
        resources.organizationService,
        resources.utilService,
        resources.languageService,
        resources.assetService,
    ],
    MembershipsIndexController,
);
