import {
    CardCollection,
    DateIO,
    eq,
    Instance,
    Knot,
    Objekt,
    Promize,
    Query,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { AssetService } from '../services/assetService';
import { DiscoveryService } from '../services/discoveryService';
import { LanguageService } from '../services/languageService';
import { ObservationService } from '../services/observationService';
import { OrganizationService } from '../services/organizationService';
import { UtilService } from '../services/utilService';
import { DashboardController } from './dashboardController';

export class DiscoveriesIndexController extends DashboardController {
    discoveryCardCollection: CardCollection;
    observationCardCollection: CardCollection;

    constructor(
        instances: Instance,
        private discoveryService: DiscoveryService,
        private observationService: ObservationService,
        private organizationService: OrganizationService,
        private utilService: UtilService,
        private assetService: AssetService,
        private languageService: LanguageService,
    ) {
        super(instances);
    }

    protected override _initLayout(): void {
        this._initToolbarButtons();
        this._initFilterForm();
        this._initTabPanel('discoveries');

        this._drawContent();
    }

    private _initToolbarButtons(): void {
        this.helper.iconButton(
            '.btn-site-home',
            this.dom,
            () => {
                this.state.goBack('site.home');
            },
            '',
            true,
            [],
        );
    }

    protected override _drawContent(): Promize | boolean {
        this._initDiscoveryCardCollection();
        this._initObservationCardCollection();
        return true;
    }

    private _initDiscoveryCardCollection(): void {
        if (eq(this.activeTab, 'discoveries')) {
            if (!this.discoveryCardCollection) {
                this.discoveryCardCollection = new CardCollection(
                    this.dom,
                    '.discovery-collection',
                    {
                        formatKillingAt: this.formatKillingAt.bind(this),
                    },
                    {
                        no_content: {
                            image_url: this.assetService.getPath(
                                'images/others/no-content.png',
                            ),
                            text: this.languageService.translate(
                                'text.no_content',
                            ),
                        },
                        row_count: 12,
                        sort: {
                            column: 'killing_at',
                            order: 'desc',
                        },
                    },
                );
                this.discoveryCardCollection.eventCardKnot =
                    this._discoveryCardKnot.bind(this);

                this.discoveryCardCollection.eventAction = (params) => {
                    params.set('query', this.query);
                    params.set('organization_ids', this.organizationIds);
                    params.set(
                        'fields',
                        'id,full_name,photo,killing_at,person.name,quantity,' +
                            this.organizationService.getAssociationTableFields(),
                    );
                    this.discoveryService.getAll(params).then((response) => {
                        const count = response.get<number>('count');
                        this.discoveryCardCollection.setCount(count);
                        const discoveries =
                            response.get<Objekt[]>('discoveries');
                        this.discoveryCardCollection.setData(discoveries);
                    });
                };
                this.discoveryCardCollection.render();
            } else {
                this.discoveryCardCollection.refresh(1);
            }
        }
    }

    private _initObservationCardCollection(): void {
        if (eq(this.activeTab, 'observations')) {
            if (!this.observationCardCollection) {
                this.observationCardCollection = new CardCollection(
                    this.dom,
                    '.observation-collection',
                    {},
                    {
                        no_content: {
                            image_url: this.assetService.getPath(
                                'images/others/no-content.png',
                            ),
                            text: this.languageService.translate(
                                'text.no_content',
                            ),
                        },
                        row_count: 12,
                        sort: {
                            column: 'name',
                            order: 'desc',
                        },
                    },
                );
                this.observationCardCollection.eventCardKnot =
                    this._observationCardKnot.bind(this);
                this.observationCardCollection.eventAction = (params) => {
                    params.set('query', this.query);
                    params.set('organization_ids', this.organizationIds);
                    params.set(
                        'fields',
                        'id,short_description,photo,location.address,' +
                            this.organizationService.getAssociationTableFields(),
                    );
                    this.observationService.getAll(params).then((response) => {
                        const count = response.get<number>('count');
                        this.observationCardCollection.setCount(count);
                        const observations =
                            response.get<Objekt[]>('observations');
                        this.observationCardCollection.setData(observations);
                    });
                };
                this.observationCardCollection.render();
            } else {
                this.observationCardCollection.refresh(1);
            }
        }
    }

    private _observationCardKnot(cardKnot: Knot, observation: Objekt): void {
        this._initObservationShowButton(cardKnot, observation);
        this.utilService.initOrganizationLink(cardKnot, observation);
    }

    private _initObservationShowButton(
        cardKnot: Knot,
        observation: Objekt,
    ): void {
        this.helper.link(
            'a.card',
            cardKnot,
            (href) => {
                this.state.go(href);
            },
            this.state.resolveUrl('observations.show', {
                observationId: observation.get('id'),
            }),
        );
    }

    formatKillingAt(killingAt: string): string {
        return this.languageService.format(
            DateIO.parse(killingAt),
            'format.datetime',
        );
    }

    private _discoveryCardKnot(cardKnot: Knot, discovery: Objekt): void {
        this._initDiscoveryContent(cardKnot, discovery);
        this._initDiscoveryShowButton(cardKnot, discovery);
        this.utilService.initOrganizationLink(cardKnot, discovery);
    }

    private _initDiscoveryContent(cardKnot: Knot, discovery: Objekt): void {
        const cardContent = new Query('.card .content', cardKnot).getKnot();
        const infoBoxKnot = new Knot('div');
        infoBoxKnot.addClass(['col-12', 'box', 'info']);
        const iconKnot = new Knot('em');
        iconKnot.addClass('material-icons');
        infoBoxKnot.appendChild(iconKnot);
        const textKnot = new Knot('span');
        infoBoxKnot.appendChild(textKnot);
        cardContent.appendChild(infoBoxKnot);

        const personName = discovery.get<string>('person.name', '');
        if (personName) {
            iconKnot.setHtml('person');
            textKnot.setHtml(personName);
        } else {
            iconKnot.setHtml('center_focus_strong');
            textKnot.setHtml(discovery.get('quantity'));
        }
    }

    private _initDiscoveryShowButton(cardKnot: Knot, discovery: Objekt): void {
        this.helper.link(
            'a.card',
            cardKnot,
            (href) => {
                this.state.go(href);
            },
            this.state.resolveUrl('discoveries.show', {
                discoveryId: discovery.get('id'),
            }),
        );
    }
}

export const discoveriesIndexController = app.controller(
    resources.discoveriesIndexController,
    [
        resources.instances,
        resources.discoveryService,
        resources.observationService,
        resources.organizationService,
        resources.utilService,
        resources.assetService,
        resources.languageService,
    ],
    DiscoveriesIndexController,
);
