import { Instance } from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { DashboardController } from './dashboardController';

export class RewardsIndexController extends DashboardController {
    organizationId: string;

    constructor(instances: Instance) {
        super(instances);
    }

    protected override _initLayout(): void {
        this.organizationId = this.state.getParam('organizationId');

        this._initToolbarButtons();
    }

    private _initToolbarButtons(): void {
        this.helper.iconButton(
            '.btn-organizations-show',
            this.dom,
            () => {
                this.state.goBack('organizations.show', {
                    organizationId: this.organizationId,
                });
            },
            '',
            true,
            [],
        );
    }
}

export const rewardsIndexController = app.controller(
    resources.rewardsIndexController,
    [resources.instances],
    RewardsIndexController,
);
