import {
    Form,
    format,
    Instance,
    Objekt,
    TableCalculation,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { AssetService } from './assetService';
import { LanguageService } from './languageService';
import { PreyService } from './preyService';

export class HuntingPackagePreyService extends PreyService {
    constructor(
        instances: Instance,
        $languageService: LanguageService,
        $assetService: AssetService,
    ) {
        super(
            'hunting_package_prey-hunting_package_preys-full_name',
            instances,
            $languageService,
            $assetService,
        );
    }

    getAllByHuntingPackage(huntingPackageId: string, opt_params?: object) {
        return this.getAllBy('hunting-packages', huntingPackageId, opt_params);
    }

    getAllByHuntingPackageAndClassification(
        huntingPackageId: string,
        classification: string,
        opt_params: object | undefined,
    ) {
        return this.http.get(
            format('/api/v1/hunting-packages/{0}/hunting-package-preys.json', [
                huntingPackageId,
            ]),
            new Objekt({
                classification: classification,
            }).merge(opt_params),
        );
    }

    getByHuntingPackage(
        huntingPackageId: string,
        huntingPackagePreyId: string,
        opt_params?: object,
    ) {
        return this.http.get(
            format(
                '/api/v1/hunting-packages/{0}/hunting-package-preys/{1}.json',
                [huntingPackageId, huntingPackagePreyId],
            ),
            opt_params,
        );
    }

    createByHuntingPackage(
        huntingPackageId: string,
        huntingPackagePrey: Objekt,
    ) {
        return this.createBy(
            'hunting-packages',
            huntingPackageId,
            huntingPackagePrey,
        );
    }

    newDialogByHuntingPackage(huntingPackageId: string) {
        return this.newDialogBy('hunting-packages', huntingPackageId);
    }

    handleForm(form: Form, opt_entityName?: string): void {
        const nameFieldSetRequired = function (wildIds) {
            if (wildIds.length > 1) {
                nameField.setRequired(true);
            } else {
                nameField.setRequired(false);
            }
        };

        const nameField = form.findByModel('hunting_package_prey.name');
        const wildField = form.findByModel('hunting_package_prey.wild_ids');

        if (wildField) {
            wildField.eventChange = (wildIds) => {
                nameFieldSetRequired(wildIds);
            };
            const wildIds = wildField.getValue();
            nameFieldSetRequired(wildIds);
        }
    }

    getTableFields(): string {
        return 'id,full_name,min_age,max_age,count,hunted_count,hunting_package_id,wilds.full_name,class_type';
    }

    getTableCalculations(): TableCalculation {
        return {
            full_name: (huntingPackagePrey) => {
                return this.helper.createLink(
                    huntingPackagePrey.get('full_name'),
                    (href) => {
                        this.state.go(href);
                    },
                    this.state.resolveUrl('hunting_package_preys.show', {
                        huntingPackageId:
                            huntingPackagePrey.get('hunting_package_id'),
                        huntingPackagePreyId: huntingPackagePrey.get('id'),
                    }),
                );
            },
            wilds: (huntingPackagePrey) => {
                const wilds = huntingPackagePrey.get('wilds', []);
                return wilds
                    .map((wild) => {
                        return wild.get('full_name');
                    })
                    .join(', ');
            },
        };
    }
}

export const huntingPackagePreyService = app.service(
    resources.huntingPackagePreyService,
    [resources.instances, resources.languageService, resources.assetService],
    HuntingPackagePreyService,
);
