import {
    Deferred,
    eq,
    Instance,
    Knot,
    Objekt,
    Promize,
    Table,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { HuntingPackagePreyService } from '../services/huntingPackagePreyService';
import { HuntingPackageService } from '../services/huntingPackageService';
import { ItemService } from '../services/itemService';
import { LanguageService } from '../services/languageService';
import { UserService } from '../services/userService';
import { UtilService } from '../services/utilService';
import { DashboardController } from './dashboardController';

export class HuntingPackagesShowController extends DashboardController {
    huntingPackageId: string;
    huntingPackage: Objekt;
    preysTable: Table;
    organizationId: string;
    itemsTable: Table;
    btnHuntingPackagesEditKnot: Knot;
    btnHuntingPackagePreysNewKnot: Knot;
    btnItemsNewKnot: Knot;

    constructor(
        instances: Instance,
        private huntingPackageService: HuntingPackageService,
        private huntingPackagePreyService: HuntingPackagePreyService,
        private itemService: ItemService,
        private userService: UserService,
        private utilService: UtilService,
        private languageService: LanguageService,
    ) {
        super(instances);
    }

    protected override _initLayout(): void {
        this.huntingPackageId = this.state.getParam('huntingPackageId');
        this.huntingPackage = new Objekt();

        this._initToolbarButtons();
        this._initTabPanel('details');
        this._changeToolbarButtons();

        this._initLinks('#details a');
        this._correctDates();

        this._loadHuntingPackage().then(() => {
            this._drawContent();
        });
    }

    protected override _drawContent(): Promize | boolean {
        this._changeToolbarButtons();

        this._initHuntingPackagePreysTable();
        this._initItemsTable();
        return true;
    }

    private _changeToolbarButtons(): void {
        this.utilService.showButton(
            this.btnHuntingPackagesEditKnot,
            this.activeTab === 'details',
        );

        this.utilService.showButton(
            this.btnHuntingPackagePreysNewKnot,
            this.activeTab === 'hunting-package-prey-table',
        );

        this.utilService.showButton(
            this.btnItemsNewKnot,
            this.activeTab === 'item-table',
        );
    }

    private _initToolbarButtons(): void {
        this.helper.iconButton(
            '.btn-hunting-packages-index',
            this.dom,
            () => {
                this.state.goBack('hunting_packages.index');
            },
            '',
            true,
            [],
        );

        this.btnHuntingPackagesEditKnot = this.helper.iconButton(
            '.btn-hunting-packages-edit',
            this.dom,
            () => {
                this.huntingPackageService
                    .editDialog(this.huntingPackage)
                    .then(
                        this.utilService.handleEditResponse(
                            'huntingPackages.index',
                        ),
                    );
            },
        );

        this.btnHuntingPackagePreysNewKnot = this.helper.iconButton(
            '.btn-hunting-package-preys-new',
            this.dom,
            () => {
                this.huntingPackagePreyService
                    .newDialogByHuntingPackage(this.huntingPackageId)
                    .then(() => {
                        this._drawContent();
                    });
            },
        );

        this.btnItemsNewKnot = this.helper.iconButton(
            '.btn-items-new',
            this.dom,
            () => {
                this.itemService
                    .newDialogByHuntingPackage(this.huntingPackageId)
                    .then(() => {
                        this.state.refresh(true);
                    });
            },
        );
    }

    private _initHuntingPackagePreysTable(): void {
        if (eq(this.activeTab, 'hunting-package-prey-table')) {
            if (!this.preysTable) {
                this.preysTable = new Table(
                    this.dom,
                    '.preys-table',
                    this.huntingPackagePreyService.getTableOptions(),
                );

                this.preysTable.setActions([
                    {
                        style: (huntingPackagePrey) => {
                            return [
                                'edit',
                                this.languageService.translate('buttons.edit'),
                                !huntingPackagePrey.get('id'),
                                !this.userService.hasAccessByOrganization(
                                    this.huntingPackage.get('organizationId'),
                                    'hunting_packages_write',
                                ),
                            ];
                        },
                        click: (huntingPackagePrey) => {
                            this.huntingPackagePreyService
                                .editDialog(huntingPackagePrey)
                                .then(() => {
                                    this._drawContent();
                                });
                        },
                    },
                ]);

                this.preysTable.eventAction = (params) => {
                    params.set(
                        'fields',
                        this.huntingPackagePreyService.getTableFields(),
                    );
                    this.huntingPackagePreyService
                        .getAllByHuntingPackage(this.huntingPackageId, params)
                        .then((response) => {
                            const count = response.get<number>('count');
                            this.preysTable.setCount(count);
                            const huntingPackagePreys = response.get<Objekt[]>(
                                'hunting_package_preys',
                            );
                            this.preysTable.setData(huntingPackagePreys);
                        });
                };
                this.preysTable.render();
            } else {
                this.preysTable.refresh();
            }
        }
    }

    private _loadHuntingPackage(): Promize {
        const deferred = new Deferred();
        this.huntingPackageService
            .get(this.huntingPackageId, {
                fields: 'id,title,organization_id,people.name',
            })
            .then(
                (response) => {
                    this.huntingPackage = response.get('hunting_package');
                    this.organizationId =
                        this.huntingPackage.get('organization_id');
                    deferred.resolve();
                },
                () => {
                    deferred.reject();
                },
            );
        return deferred.promise();
    }

    private _correctDates(): void {
        this.utilService.correctDate('.start-at', 'format.datetime', this.dom);
        this.utilService.correctDate('.end-at', 'format.datetime', this.dom);
    }

    private _initItemsTable(): void {
        if (eq(this.activeTab, 'item-table')) {
            if (!this.itemsTable) {
                this.itemsTable = new Table(
                    this.dom,
                    '.items-table',
                    this.itemService.getTableOptions(),
                );

                this.itemsTable.setActions([
                    {
                        style: () => {
                            return [
                                'edit',
                                this.languageService.translate('buttons.edit'),
                                false,
                                !this.userService.hasAccessByOrganization(
                                    this.organizationId,
                                    'items_write',
                                ),
                            ];
                        },
                        click: (item) => {
                            this.itemService.editDialog(item).then(() => {
                                this.state.refresh(true);
                            });
                        },
                    },
                ]);

                this.itemsTable.eventAction = (params) => {
                    params.set('fields', this.itemService.getTableFields());
                    this.itemService
                        .getAllByHuntingPackage(this.huntingPackageId, params)
                        .then((response) => {
                            const count = response.get<number>('count');
                            this.itemsTable.setCount(count);
                            const items = response.get<Objekt[]>('items');
                            this.itemsTable.setData(items);
                        });
                };
                this.itemsTable.render();
            } else {
                this.itemsTable.refresh();
            }
        }
    }
}

export const huntingPackagesShowController = app.controller(
    resources.huntingPackagesShowController,
    [
        resources.instances,
        resources.huntingPackageService,
        resources.huntingPackagePreyService,
        resources.itemService,
        resources.userService,
        resources.utilService,
        resources.languageService,
    ],
    HuntingPackagesShowController,
);
