import {
    CardCollection,
    DateIO,
    Instance,
    Knot,
    Objekt,
    Promize,
    Query,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { AssetService } from '../services/assetService';
import { LanguageService } from '../services/languageService';
import { OrganizationService } from '../services/organizationService';
import { TrophyService } from '../services/trophyService';
import { UtilService } from '../services/utilService';
import { DashboardController } from './dashboardController';

export class TrophiesIndexByUserController extends DashboardController {
    userId: string;
    trophyCardCollection: CardCollection;

    constructor(
        instances: Instance,
        private trophyService: TrophyService,
        private organizationService: OrganizationService,
        private utilService: UtilService,
        private languageService: LanguageService,
        private assetService: AssetService,
    ) {
        super(instances);
    }

    protected override _initLayout(): void {
        this.userId = this.state.getParam('userId');

        this._initToolbarButtons();
        this._initFilterForm();

        this._drawContent();
    }

    protected override _drawContent(): Promize | boolean {
        this._initTrophyCollection();
        return true;
    }

    private _initToolbarButtons(): void {
        this.helper.iconButton(
            '.btn-users-home',
            this.dom,
            () => {
                this.state.goBack('users.home');
            },
            '',
            true,
            [],
        );
    }

    private _initTrophyCollection(): void {
        if (!this.trophyCardCollection) {
            this.trophyCardCollection = new CardCollection(
                this.dom,
                '.trophy-collection',
                {
                    formatKillingAt: this.formatKillingAt.bind(this),
                },
                {
                    no_content: {
                        image_url: this.assetService.getPath(
                            'images/others/no-content.png',
                        ),
                        text: this.languageService.translate('text.no_content'),
                    },
                    row_count: 12,
                    sort: {
                        column: 'killing_at',
                        order: 'desc',
                    },
                },
            );
            this.trophyCardCollection.eventCardKnot =
                this._trophyCardKnot.bind(this);
            this.trophyCardCollection.eventAction = (params) => {
                params.set('query', this.query);
                params.set('organization_ids', this.organizationIds);
                params.set(
                    'fields',
                    'id,full_name,photo,killing_at,person.name,quantity,' +
                        this.organizationService.getAssociationTableFields(),
                );
                this.trophyService
                    .getAllByUser(this.userId, params)
                    .then((response) => {
                        const count = response.get<number>('count');
                        this.trophyCardCollection.setCount(count);
                        const trophies = response.get<Objekt[]>('trophies');
                        this.trophyCardCollection.setData(trophies);
                    });
            };
            this.trophyCardCollection.render();
        } else {
            this.trophyCardCollection.refresh(1);
        }
    }

    private _trophyCardKnot(cardKnot: Knot, trophy: Objekt): void {
        this._initTrophyContent(cardKnot, trophy);
        this._initTrophyShowButton(cardKnot, trophy);
        this._initCardButtons(cardKnot, trophy);
        this.utilService.initOrganizationLink(cardKnot, trophy);
    }

    private _initTrophyContent(cardKnot: Knot, trophy: Objekt): void {
        const cardContent = new Query('.card .content', cardKnot).getKnot();
        const infoBoxKnot = new Knot('div');
        infoBoxKnot.addClass(['col-12', 'box', 'info']);
        const iconKnot = new Knot('em');
        iconKnot.addClass('material-icons');
        infoBoxKnot.appendChild(iconKnot);
        const textKnot = new Knot('span');
        infoBoxKnot.appendChild(textKnot);
        cardContent.appendChild(infoBoxKnot);

        const personName = trophy.get<string>('person.name', '');
        if (personName) {
            iconKnot.setHtml('person');
            textKnot.setHtml(personName);
        } else {
            iconKnot.setHtml('center_focus_strong');
            textKnot.setHtml(trophy.get('quantity'));
        }
    }

    private _initTrophyShowButton(cardKnot: Knot, trophy: Objekt): void {
        this.helper.link(
            'a.card',
            cardKnot,
            (href) => {
                this.state.go(href);
            },
            this.state.resolveUrl('trophies.show', {
                trophyId: trophy.get('id'),
            }),
        );
    }

    private _initCardButtons(cardKnot: Knot, trophy: Objekt): void {
        // let actionsKnot = new Query('.actions', cardKnot).getKnot();
    }

    formatKillingAt(killingAt: string): string {
        return this.languageService.format(
            DateIO.parse(killingAt),
            'format.datetime',
        );
    }
}

export const trophiesIndexByUserController = app.controller(
    resources.trophiesIndexByUserController,
    [
        resources.instances,
        resources.trophyService,
        resources.organizationService,
        resources.utilService,
        resources.languageService,
        resources.assetService,
    ],
    TrophiesIndexByUserController,
);
