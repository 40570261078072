import { format, Instance, Objekt } from '@siposdani87/sui-js';
import { AssetService } from './assetService';
import { EntityConfig } from './entityService';
import { LanguageService } from './languageService';
import { ModelService } from './modelService';

export class AdminService extends ModelService {
    constructor(
        entityConfig: EntityConfig,
        instances: Instance,
        $languageService: LanguageService,
        $assetService: AssetService,
    ) {
        super(entityConfig, instances, $languageService, $assetService);
    }

    getAll(opt_params?: object) {
        return this.http.get(
            format('/api/v1/admin/{0}.json', [this._getPath()]),
            opt_params,
        );
    }

    getAllBy(foreignName: string, foreignId: string, opt_params?: object) {
        return this.http.get(
            format('/api/v1/admin/{0}/{1}/{2}.json', [
                foreignName,
                foreignId,
                this._getPath(),
            ]),
            opt_params,
        );
    }

    get(entityId: string, opt_params?: object) {
        return this.http.get(
            format('/api/v1/admin/{0}/{1}.json', [this._getPath(), entityId]),
            opt_params,
        );
    }

    create(entity: Objekt) {
        return this.http.post(
            format('/api/v1/admin/{0}.json', [this._getPath()]),
            entity,
        );
    }

    createBy(foreignName: string, foreignId: string, entity: Objekt) {
        return this.http.post(
            format('/api/v1/admin/{0}/{1}/{2}.json', [
                foreignName,
                foreignId,
                this._getPath(),
            ]),
            entity,
        );
    }

    update(entity: Objekt) {
        return this.http.put(
            format('/api/v1/admin/{0}/{1}.json', [
                this._getPath(),
                this._getId(entity),
            ]),
            entity,
        );
    }

    remove(entity: Objekt) {
        return this.http.delete(
            format('/api/v1/admin/{0}/{1}.json', [
                this._getPath(),
                this._getId(entity),
            ]),
        );
    }

    newDialogByAdmin(opt_data: (Objekt | null) | undefined = null) {
        return this.newDialogBy('', '', '/admin/');
    }
}
