import {
    DateIO,
    Instance,
    Objekt,
    TableCalculation,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { AssetService } from './assetService';
import { LanguageService } from './languageService';
import { ModelService } from './modelService';

export class EarTagService extends ModelService {
    constructor(
        instances: Instance,
        $languageService: LanguageService,
        $assetService: AssetService,
    ) {
        super(
            'ear_tag-ear_tags-identifier',
            instances,
            $languageService,
            $assetService,
        );
    }

    getAllByPlan(planId: string, opt_params?: object) {
        return this.getAllBy('plans', planId, opt_params);
    }

    createByPlan(planId: string, earTag: Objekt) {
        return this.createBy('plans', planId, earTag);
    }

    newDialogByPlan(planId: string) {
        return this.newDialogBy('plans', planId);
    }

    getTableOptions(): object {
        return {
            no_content: {
                image_url: this.assetService.getPath(
                    'images/others/no-content.png',
                ),
                text: this.languageService.translate('text.no_content'),
            },
            sort: {
                column: 'created_at',
                order: 'desc',
            },
            sorted: ['identifier:asc', 'created_at:desc'],
            row_count: 12,
            columns: [
                'identifier',
                'trophy_id',
                'person_id',
                'created_at',
                'actions',
            ],
            calculations: this.getTableCalculations(),
        };
    }

    getTableFields(): string {
        return 'id,identifier,trophy.id,trophy.full_name,person.id,person.full_name,created_at';
    }

    getTableCalculations(): TableCalculation {
        return {
            trophy_id: (earTag) => {
                const trophyFullName = earTag.get<string>('trophy.full_name');
                return trophyFullName
                    ? this.helper.createLink(
                          trophyFullName,
                          (href) => {
                              this.state.go(href);
                          },
                          this.state.resolveUrl('trophies.show', {
                              trophyId: earTag.get('trophy.id'),
                          }),
                      )
                    : '';
            },
            person_id: (earTag) => {
                const personFullName = earTag.get<string>('person.full_name');
                return personFullName
                    ? this.helper.createLink(
                          personFullName,
                          (href) => {
                              this.state.go(href);
                          },
                          this.state.resolveUrl('people.show', {
                              personId: earTag.get('person.id'),
                          }),
                      )
                    : '';
            },
            created_at: (earTag) => {
                const createdAt = DateIO.parse(earTag.get('created_at'));
                return this.languageService.format(
                    createdAt,
                    'format.datetime',
                );
            },
        };
    }
}

export const earTagService = app.service(
    resources.earTagService,
    [resources.instances, resources.languageService, resources.assetService],
    EarTagService,
);
