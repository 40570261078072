import { Instance, Objekt, Table } from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { AdminSubscriberService } from '../services/adminSubscriberService';
import { LanguageService } from '../services/languageService';
import { AdminController } from './adminController';

export class AdminSubscribersIndexController extends AdminController {
    private table: Table;

    constructor(
        instances: Instance,
        private adminSubscriberService: AdminSubscriberService,
        private languageService: LanguageService,
    ) {
        super(instances);
    }

    protected override _initToolbarButtons(): void {
        this.helper.iconButton(
            '.btn-admin-index',
            this.dom,
            () => {
                this.state.goBack('admin.index');
            },
            '',
            true,
            [],
        );

        this.helper.iconButton('.btn-subscribers-new', this.dom, () => {
            this.adminSubscriberService.newDialogByAdmin().then(() => {
                this._drawContent();
            });
        });
    }

    protected override _initTable(): void {
        if (!this.table) {
            this.table = new Table(
                this.dom,
                'table',
                this.adminSubscriberService.getTableOptions(),
            );

            this.table.setActions([
                {
                    style: () => {
                        return [
                            'edit',
                            this.languageService.translate('buttons.edit'),
                        ];
                    },
                    click: (subscriber) => {
                        this.adminSubscriberService
                            .editDialog(subscriber, '/admin/')
                            .then(() => {
                                this._drawContent();
                            });
                    },
                },
            ]);

            this.table.eventAction = (params) => {
                params.set('query', this.query);
                params.set(
                    'fields',
                    this.adminSubscriberService.getTableFields(),
                );
                this.adminSubscriberService.getAll(params).then((response) => {
                    const count = response.get<number>('count');
                    this.table.setCount(count);
                    const subscribers = response.get<Objekt[]>('subscribers');
                    this.table.setData(subscribers);
                });
            };

            this.table.render();
        } else {
            this.table.refresh();
        }
    }
}

export const adminSubscribersIndexController = app.controller(
    resources.adminSubscribersIndexController,
    [
        resources.instances,
        resources.adminSubscriberService,
        resources.languageService,
    ],
    AdminSubscribersIndexController,
);
