import { Deferred, Instance, Script, Service } from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { ConfigService } from './configService';

export class GoogleChartService extends Service {
    script: Script;

    constructor(
        instances: Instance,
        private configService: ConfigService,
    ) {
        super();

        this.script = instances.script;
    }

    loadSDK(opt_packages: Array<any> | undefined = ['corechart']) {
        const deferred = new Deferred();
        this.script
            .load(
                'google-chart-sdk',
                'https://www.gstatic.com/charts/loader.js',
            )
            .then(() => {
                this._asyncInit(opt_packages).defer(deferred);
            });
        return deferred.promise();
    }

    private _asyncInit(opt_packages: Array<any> | undefined = ['corechart']) {
        const deferred = new Deferred();
        const languageCode = this.configService.getLocale().split('-', 2)[0];
        window['google']['charts']['load']('current', {
            packages: opt_packages,
            language: languageCode,
        });
        window['google']['charts']['setOnLoadCallback'](() => {
            deferred.resolve();
        });
        return deferred.promise();
    }

    reset(): void {
        delete window['google']['charts'];
        this.script.remove('google-chart-sdk');
    }
}

export const googleChartService = app.service(
    resources.googleChartService,
    [resources.instances, resources.configService],
    GoogleChartService,
);
