import {
    Deferred,
    Flash,
    Instance,
    Script,
    Service,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { ConfigService } from './configService';

export class GoogleIdentityService extends Service {
    script: Script;
    flash: Flash;

    constructor(
        instances: Instance,
        private configService: ConfigService,
    ) {
        super();

        this.script = instances.script;
        this.flash = instances.flash;
    }

    private _asyncInit(): void {
        // No init method
    }

    loadSDK(): void {
        this.script
            .load('google-3P', 'https://accounts.google.com/gsi/client')
            .then(() => {
                this._asyncInit();
            });
    }

    reset(): void {
        this.script.remove('google-3P');
    }

    loginDialog() {
        const deferred = new Deferred<string, undefined>();

        window['google']['accounts']['id']['initialize']({
            client_id: this.configService.get('google.client_id'),
            callback: (response: any) => {
                const idToken = response['credential'];
                deferred.resolve(idToken);
            },
        });
        window['google']['accounts']['id']['prompt']((notification: any) => {
            if (notification['isNotDisplayed']()) {
                // try next provider if OneTap is not displayed
                const message = notification['getNotDisplayedReason']();
                this.flash.addInfo(message);
                deferred.reject();
            } else if (notification['isSkippedMoment']()) {
                // Skipped
                deferred.reject();
            }
        });

        return deferred.promise();
    }
}

export const googleIdentityService = app.service(
    resources.googleIdentityService,
    [resources.instances, resources.configService],
    GoogleIdentityService,
);
