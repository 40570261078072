import {
    Deferred,
    Form,
    format,
    Instance,
    Objekt,
    Query,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { AssetService } from './assetService';
import { GoogleCaptchaService } from './googleCaptchaService';
import { LanguageService } from './languageService';
import { ModelService } from './modelService';

export class SubscriberService extends ModelService {
    constructor(
        instances: Instance,
        $languageService: LanguageService,
        $assetService: AssetService,
        private googleCaptchaService: GoogleCaptchaService,
    ) {
        super(
            'subscriber-subscribers-name',
            instances,
            $languageService,
            $assetService,
        );
    }

    confirmation(code: string) {
        return this.http.post(
            '/api/v1/subscribers/confirm.json',
            {},
            {},
            {
                Authorization: format('Bearer {0}', [code]),
            },
        );
    }

    newDialog(
        opt_foreignName?: string,
        opt_foreignId?: string,
        opt_data: (Objekt | null) | undefined = null,
    ) {
        const deferred = new Deferred<Objekt, undefined>();
        this.dialog
            .loadTemplate('/client/v1/subscribers/new.html')
            .then((dialogKnot) => {
                this.googleCaptchaService.render(
                    new Query('#captcha', dialogKnot).getKnot(),
                    '#subscriber_captcha',
                );
                const form = new Form(dialogKnot);
                form.eventSubmit = (formData) => {
                    form.lock();
                    const subscriberData = formData.get<Objekt>('subscriber');
                    this.create(subscriberData).then(
                        (response) => {
                            this.flash.addMessage(response.get('message'));
                            this.dialog.close();
                            deferred.resolve();
                        },
                        (response) => {
                            this.flash.addMessage(response.get('message'));
                            form.unlock();
                            form.setErrors(response.get('errors'));
                        },
                    );
                    this.googleCaptchaService.reset();
                };
                form.eventReset = () => {
                    this.dialog.close();
                    deferred.reject();
                };
                this.dialog.open();
            });
        return deferred.promise();
    }
}

export const subscriberService = app.service(
    resources.subscriberService,
    [
        resources.instances,
        resources.languageService,
        resources.assetService,
        resources.googleCaptchaService,
    ],
    SubscriberService,
);
