import { Instance, Knot, TableCalculation } from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { AdminService } from './adminService';
import { AssetService } from './assetService';
import { LanguageService } from './languageService';

export class AdminCountryService extends AdminService {
    constructor(
        instances: Instance,
        $languageService: LanguageService,
        $assetService: AssetService,
    ) {
        super(
            'country-countries-name',
            instances,
            $languageService,
            $assetService,
        );
    }

    getTableOptions(): object {
        return {
            no_content: {
                image_url: this.assetService.getPath(
                    'images/others/no-content.png',
                ),
                text: this.languageService.translate('text.no_content'),
            },
            sort: {
                column: 'name',
                order: 'asc',
            },
            row_count: 12,
            column: 'name',
            columns: ['iso_code', 'name', 'locale', 'active', 'actions'],
            calculations: this.getTableCalculations(),
        };
    }

    getTableFields(): string {
        return ['id', 'iso_code', 'name', 'locale', 'active'].join(',');
    }

    getTableCalculations(): TableCalculation {
        return {
            active: (country) => {
                const active = country.get('active');
                const iconKnot = new Knot('em');
                iconKnot.addClass(['material-icons', 'text-success']);
                iconKnot.setHtml('check_circle');
                return active ? iconKnot : '';
            },
        };
    }
}

export const adminCountryService = app.service(
    resources.adminCountryService,
    [resources.instances, resources.languageService, resources.assetService],
    AdminCountryService,
);
