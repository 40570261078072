import {
    Controller,
    Depot,
    Flash,
    Helper,
    Instance,
    Knot,
    ProgressStatus,
    State,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { LanguageService } from '../services/languageService';
import { OrderService } from '../services/orderService';

export class OrdersExecuteController extends Controller {
    localDepot: Depot;
    orderKey: string;
    private helper: Helper;
    private dom: Knot;
    private state: State;
    private flash: Flash;

    constructor(
        instances: Instance,
        private orderService: OrderService,
        private languageService: LanguageService,
    ) {
        super();

        this.helper = instances.helper;
        this.dom = instances.dom;
        this.state = instances.state;
        this.localDepot = instances.localDepot;
        this.flash = instances.flash;
    }

    enter(): void {
        this._initLayout();
    }

    protected _initLayout(): void {
        this.orderKey = 'orders.data';

        this._initToolbarButtons();
        this._initPayment();
    }

    private _initToolbarButtons(): void {
        this.helper.iconButton(
            '.btn-site-landing-page',
            this.dom,
            () => {
                this.state.go('site.landing-page');
            },
            '',
            true,
            [],
        );
    }

    private _initPayment(): void {
        const paymentParams = this.state.getParams();
        const progressStatus = new ProgressStatus(this.dom);
        this.orderService.execute(paymentParams).then(
            (response) => {
                this.localDepot.remove(this.orderKey);
                this.flash.addMessage(response.get('message'));
                progressStatus.setSuccess(
                    this.languageService.translate(
                        'orders_controller.execute.success',
                    ),
                    'done',
                );
                setTimeout(() => {
                    this.state.go('site.home');
                }, 2000);
            },
            (response) => {
                this.flash.addMessage(response.get('message'));
                progressStatus.setError(
                    this.languageService.translate(
                        'orders_controller.execute.fail',
                    ),
                    'close',
                );
            },
        );
    }
}

export const ordersExecuteController = app.controller(
    resources.ordersExecuteController,
    [resources.instances, resources.orderService, resources.languageService],
    OrdersExecuteController,
);
