import {
    Application,
    Canvas,
    EventBus,
    Instance,
    Knot,
    md5,
    noop,
    round,
    Screen,
    Service,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';

export class MilitaryService extends Service {
    screen: Screen;
    eventBus: EventBus;
    canvas: Canvas;
    backgroundElement: Knot;
    canvasElement: Knot<HTMLCanvasElement>;
    dom: Knot;
    resize: Function = noop();
    app: Application;
    hash = '';

    constructor(instances: Instance) {
        super();

        this.screen = instances.screen;
        this.eventBus = instances.eventBus;
        this.app = instances.app;

        this.hash = md5(this.app.options.get('secret'));

        this.canvas = null;
        this.backgroundElement = null;
        this.canvasElement = null;
        this.dom = null;
    }

    reset(): void {
        this.eventBus.remove('window.resize', this.resize);
        this.canvas = null;
        this.dom = null;
        this.backgroundElement = null;
        this.canvasElement = null;
    }

    draw(
        $dom: Knot,
        colors: Array<string>,
        opt_opacity: number | undefined = 1,
    ) {
        this.dom = $dom;
        const backgroundImage = this.dom.getStyle().backgroundImage;
        const filter = this.dom.getStyle().filter;
        const backgroundColor = this.dom.getComputedStyle().backgroundColor;
        this.dom.setStyle({
            background: 'none',
        });

        this.resize = this.eventBus.set('window.resize', () => {
            this._render(
                backgroundImage,
                backgroundColor,
                filter,
                colors,
                opt_opacity,
            );
        });

        this.canvasElement =
            this.dom.createElement<HTMLCanvasElement>('canvas');
        this.canvasElement.addClass('military-pattern');
        this.dom.getParentKnot().beforeChild(this.canvasElement);

        this.backgroundElement = this.dom.createElement('div');
        this.backgroundElement.addClass('fullscreen-background');
        this.dom.getParentKnot().beforeChild(this.backgroundElement);

        this.canvas = new Canvas(this.canvasElement);

        this._render(
            backgroundImage,
            backgroundColor,
            filter,
            colors,
            opt_opacity,
        );
    }

    private _render(
        backgroundImage: string,
        backgroundColor: string,
        filter: string,
        colors: Array<string>,
        opt_opacity: number | undefined = 1,
    ): void {
        if (
            !this.canvas &&
            !this.dom &&
            !this.backgroundElement &&
            !this.canvasElement
        ) {
            return;
        }

        this.backgroundElement.setStyle({
            'background-image': backgroundImage,
            'background-color': backgroundColor,
            filter: filter,
        });

        this.canvasElement.setStyle({
            opacity: opt_opacity,
        });

        const screenWidth = this.screen.getWidth();
        const screenHeight = this.screen.getHeight();
        this.canvas.setSize(screenWidth, screenHeight);

        this._renderPolygons(colors, screenWidth, screenHeight);
    }

    private _random(index: number, min: number, max: number): number {
        const i = index % this.hash.length;
        const value = this.hash.charCodeAt(i) / 100;

        return round(value * (max - min) + min, 0);
    }

    private _renderPolygons(
        colors: Array<string>,
        width: number,
        height: number,
    ): void {
        const shadows = [0, 0, 3, 0, 2, 1, 0, 1, 2, 0, 0, 1, 0];
        const radius = 20;
        let x = 0;
        let y = 0;
        let i = 0;
        while (y < height + radius) {
            while (x < width + radius) {
                const fillColor =
                    colors[this._random(i * x, 0, colors.length - 1)];
                const options = {
                    shadowBlur:
                        shadows[this._random(i * x, 0, shadows.length - 1)],
                    shadowColor: 'rgba(100, 100, 100, .3)',
                    shadowOffsetX: 0,
                    shadowOffsetY: 0,
                    fillStyle: fillColor,
                    strokeStyle: fillColor,
                };

                this.canvas.drawPolygon(x, y, radius, 6, 0, options);
                x += radius * 3;
            }
            x = i % 2 === 0 ? 30 : 0;
            y += 16;
            i++;
        }
    }
}

export const militaryService = app.service(
    resources.militaryService,
    [resources.instances, resources.assetService],
    MilitaryService,
);
