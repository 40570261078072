import {
    CardCollection,
    DateIO,
    Instance,
    Knot,
    Objekt,
    pluck,
    Promize,
    Query,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { AssetService } from '../services/assetService';
import { HuntingPackageService } from '../services/huntingPackageService';
import { LanguageService } from '../services/languageService';
import { OrganizationService } from '../services/organizationService';
import { UtilService } from '../services/utilService';
import { DashboardController } from './dashboardController';

export class HuntingPackagesIndexController extends DashboardController {
    huntingPackageCardCollection: CardCollection;

    constructor(
        instances: Instance,
        private huntingPackageService: HuntingPackageService,
        private organizationService: OrganizationService,
        private utilService: UtilService,
        private assetService: AssetService,
        private languageService: LanguageService,
    ) {
        super(instances);
    }

    protected override _initLayout(): void {
        this._initToolbarButtons();
        this._initFilterForm();

        this._drawContent();
    }

    protected override _drawContent(): Promize | boolean {
        this._initHuntingPackageCardCollection();
        return true;
    }

    private _initToolbarButtons(): void {
        this.helper.iconButton('.btn-hunting-packages-new', this.dom, () => {
            this.huntingPackageService
                .newDialog('organizations', 'organization_id')
                .then(() => {
                    this._drawContent();
                });
        });

        this.helper.iconButton(
            '.btn-site-home',
            this.dom,
            () => {
                this.state.goBack('site.home');
            },
            '',
            true,
            [],
        );
    }

    private _initHuntingPackageCardCollection(): void {
        if (!this.huntingPackageCardCollection) {
            this.huntingPackageCardCollection = new CardCollection(
                this.dom,
                '.hunting-package-collection',
                {
                    getPersonNames: this.getPersonNames.bind(this),
                },
                {
                    no_content: {
                        image_url: this.assetService.getPath(
                            'images/others/no-content.png',
                        ),
                        text: this.languageService.translate('text.no_content'),
                    },
                    row_count: 12,
                    sort: {
                        column: 'start_at',
                        order: 'desc',
                    },
                },
            );
            this.huntingPackageCardCollection.eventCardKnot =
                this._huntingPackageCardKnot.bind(this);
            this.huntingPackageCardCollection.eventAction = (params) => {
                params.set('query', this.query);
                params.set('organization_ids', this.organizationIds);
                params.set(
                    'fields',
                    'id,title,end_at,plan.full_name,people.name,' +
                        this.organizationService.getAssociationTableFields(),
                );
                this.huntingPackageService.getAll(params).then((response) => {
                    const count = response.get<number>('count');
                    this.huntingPackageCardCollection.setCount(count);
                    const huntingPackages =
                        response.get<Objekt[]>('hunting_packages');
                    this.huntingPackageCardCollection.setData(huntingPackages);
                });
            };
            this.huntingPackageCardCollection.render();
        } else {
            this.huntingPackageCardCollection.refresh(1);
        }
    }

    private _huntingPackageCardKnot(
        cardKnot: Knot,
        huntingPackage: Objekt,
    ): void {
        this._initHuntingPackageActivity(cardKnot, huntingPackage);
        this._initHuntingPackageShowButton(cardKnot, huntingPackage);
        this._initCardButtons(cardKnot, huntingPackage);
        this.utilService.initOrganizationLink(cardKnot, huntingPackage);
    }

    private _initHuntingPackageActivity(
        cardKnot: Knot,
        huntingPackage: Objekt,
    ): void {
        const cardItem = new Query('.card', cardKnot).getKnot();
        const endAt = DateIO.parse(huntingPackage.get('end_at'));
        const cssClass = DateIO.isBefore(new Date(), endAt)
            ? 'active'
            : 'inactive';
        cardItem.addClass(cssClass);
    }

    private _initHuntingPackageShowButton(
        cardKnot: Knot,
        huntingPackage: Objekt,
    ): void {
        this.helper.link(
            'a.card',
            cardKnot,
            (href) => {
                this.state.go(href);
            },
            this.state.resolveUrl('hunting_packages.show', {
                huntingPackageId: huntingPackage.get('id'),
            }),
        );
    }

    private _initCardButtons(cardKnot: Knot, huntingPackage: Objekt): void {
        // let actionsKnot = new Query('.actions', cardKnot).getKnot();
    }

    getPersonNames(people: Array<any>): string {
        return pluck(people, 'name').join(', ');
    }
}

export const huntingPackagesIndexController = app.controller(
    resources.huntingPackagesIndexController,
    [
        resources.instances,
        resources.huntingPackageService,
        resources.organizationService,
        resources.utilService,
        resources.assetService,
        resources.languageService,
    ],
    HuntingPackagesIndexController,
);
