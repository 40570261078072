import { Instance, Objekt, Promize, Table } from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { CategoryService } from '../services/categoryService';
import { LanguageService } from '../services/languageService';
import { DashboardController } from './dashboardController';

export class CategoriesIndexController extends DashboardController {
    organizationId: string;
    categoriesTable: Table;

    constructor(
        instances: Instance,
        private categoryService: CategoryService,
        private languageService: LanguageService,
    ) {
        super(instances);
    }

    protected override _initLayout(): void {
        this.organizationId = this.state.getParam('organizationId');

        this._initToolbarButtons();
        this._initFilterForm();

        this._drawContent();
    }

    protected override _drawContent(): Promize | boolean {
        this._initCategoryTable();
        return true;
    }

    private _initToolbarButtons(): void {
        this.helper.iconButton('.btn-categories-new', this.dom, () => {
            this.categoryService
                .newDialogByOrganization(this.organizationId)
                .then(() => {
                    this._drawContent();
                });
        });

        this.helper.iconButton(
            '.btn-budgets-show',
            this.dom,
            () => {
                this.state.goBack('budgets.index');
            },
            '',
            true,
            [],
        );
    }

    private _initCategoryTable(): void {
        if (!this.categoriesTable) {
            this.categoriesTable = new Table(
                this.dom,
                '.categories-table',
                this.categoryService.getTableOptions(),
            );

            this.categoriesTable.setActions([
                {
                    style: () => {
                        return [
                            'edit',
                            this.languageService.translate('buttons.edit'),
                        ];
                    },
                    click: (category) => {
                        this.categoryService.editDialog(category).then(() => {
                            this._drawContent();
                        });
                    },
                },
            ]);

            this.categoriesTable.eventAction = (params) => {
                params.set('fields', this.categoryService.getTableFields());
                params.set('query', this.query);
                this.categoryService
                    .getAllByOrganization(this.organizationId, params)
                    .then((response) => {
                        const count = response.get<number>('count');
                        this.categoriesTable.setCount(count);
                        const categories = response.get<Objekt[]>('categories');
                        this.categoriesTable.setData(categories);
                    });
            };

            this.categoriesTable.render();
        } else {
            this.categoriesTable.refresh();
        }
    }
}

export const categoriesIndexController = app.controller(
    resources.categoriesIndexController,
    [resources.instances, resources.categoryService, resources.languageService],
    CategoriesIndexController,
);
