import {
    colorContrastYIQ,
    Form,
    format,
    Instance,
    Knot,
    LocationField,
    Objekt,
    pluck,
    TableCalculation,
    Viewer,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { AssetService } from './assetService';
import { LanguageService } from './languageService';
import { MapService } from './mapService';
import { ModelService } from './modelService';

export class BuildingService extends ModelService {
    viewer: Viewer;

    constructor(
        instances: Instance,
        $languageService: LanguageService,
        $assetService: AssetService,
        private mapService: MapService,
    ) {
        super(
            'building-buildings-name',
            instances,
            $languageService,
            $assetService,
        );

        this.viewer = instances.viewer;
    }

    getAllByOrganization(organizationId: string, opt_params?: object) {
        return this.getAllBy('organizations', organizationId, opt_params);
    }

    createByOrganization(organizationId: string, building: Objekt) {
        return this.createBy('organizations', organizationId, building);
    }

    importList(organizationId: string, formData: Objekt) {
        return this.http.post(
            format('/api/v1/organizations/{0}/buildings/import-list.json', [
                organizationId,
            ]),
            formData,
        );
    }

    exportList(organizationId: string, opt_params?: object) {
        return this.http.get(
            format('/api/v1/organizations/{0}/buildings/export-list.xlsx', [
                organizationId,
            ]),
            opt_params,
            {
                responseType: 'blob',
            },
        );
    }

    handleForm(form: Form, opt_entityName?: string): void {
        const locationField =
            form.findByModel<LocationField>('building.location');

        this.handleLocationField(this.mapService, locationField);
    }

    getTableOptions(): object {
        return {
            no_content: {
                image_url: this.assetService.getPath(
                    'images/others/no-content.png',
                ),
                text: this.languageService.translate('text.no_content'),
            },
            sort: {
                column: 'name',
                order: 'asc',
            },
            row_count: 12,
            column: 'name',
            columns: [
                'photo',
                'name',
                'type_text',
                'district_ids',
                'status',
                'personName',
                'visible',
                'actions',
            ],
            sorted: ['name:asc', 'status:asc', 'visible:desc'],
            calculations: this.getTableCalculations(),
        };
    }

    getTableFields(): string {
        return 'id,name,photo,type_text,status_percentage,status_color,visible,person.id,person.name,districts.title';
    }

    getTableCalculations(): TableCalculation {
        return {
            name: (building) => {
                return this.helper.createLink(
                    building.get('name'),
                    (href) => {
                        this.state.go(href);
                    },
                    this.state.resolveUrl('buildings.show', {
                        buildingId: building.get('id'),
                    }),
                );
            },
            district_ids: (building) => {
                return pluck(building.get('districts', []), 'title').join(', ');
            },
            photo: (building) => {
                const imageWrapper = new Knot('div');
                imageWrapper.addClass(['img-wrapper', 'clickable']);
                const imageKnot = new Knot('img');
                imageKnot.addClass('img-responsive');
                imageKnot.setAttribute('src', building.get('photo.thumb.url'));
                imageWrapper.appendChild(imageKnot);
                imageWrapper.addEventListener('click', () => {
                    const photoUrl = building.get<string>('photo.url');
                    const name = building.get<string>('name');
                    this.viewer.loadImage(photoUrl, name);
                });
                return imageWrapper;
            },
            status: (building) => {
                const statusPercentage =
                    building.get<string>('status_percentage');
                const statusColor = building.get<string>('status_color');
                const node = new Knot('span');
                node.addClass('tag');
                node.setStyle({
                    'border-color': statusColor,
                    'background-color': statusColor,
                    color: colorContrastYIQ(statusColor),
                });
                node.setHtml(statusPercentage);
                return node;
            },
            visible: (building) => {
                const visible = building.get('visible');
                const iconKnot = new Knot('em');
                iconKnot.addClass(['material-icons', 'text-success']);
                iconKnot.setHtml('check_circle');
                return visible ? iconKnot : '';
            },
            personName: (building) => {
                const personName = building.get<string>('person.name');
                return personName
                    ? this.helper.createLink(
                          personName,
                          (href) => {
                              this.state.go(href);
                          },
                          this.state.resolveUrl('people.show', {
                              personId: building.get('person.id'),
                          }),
                      )
                    : '';
            },
        };
    }
}

export const buildingService = app.service(
    resources.buildingService,
    [
        resources.instances,
        resources.languageService,
        resources.assetService,
        resources.mapService,
    ],
    BuildingService,
);
