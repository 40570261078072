import {
    Deferred,
    eq,
    GoogleMap,
    Instance,
    Objekt,
    Promize,
    Query,
    Viewer,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { DiscoveryService } from '../services/discoveryService';
import { MapService } from '../services/mapService';
import { TrophyService } from '../services/trophyService';
import { UtilService } from '../services/utilService';
import { DashboardController } from './dashboardController';

export class DiscoveriesShowController extends DashboardController {
    viewer: Viewer;
    discoveryId: string;
    discovery: Objekt;
    map: GoogleMap;

    constructor(
        instances: Instance,
        private mapService: MapService,
        private discoveryService: DiscoveryService,
        private trophyService: TrophyService,
        private utilService: UtilService,
    ) {
        super(instances);

        this.viewer = instances.viewer;
    }

    protected override _initLayout(): void {
        this.discoveryId = this.state.getParam('discoveryId');
        this.discovery = new Objekt();

        this._initToolbarButtons();
        this._initTabPanel('details');
        this._changeToolbarButtons();

        this._initViewer();
        this._initLinks('#details a');
        this._correctDates();

        this._loadDiscovery().then(() => {
            this._drawContent();
        });
    }

    private _initToolbarButtons(): void {
        this.helper.iconButton(
            '.btn-discoveries-index',
            this.dom,
            () => {
                this.state.goBack('discoveries.index');
            },
            '',
            true,
            [],
        );

        /* this.btnDiscoveriesEditKnot = this.helper.iconButton('.btn-discoveries-edit', this.dom, () => {
      this.discoveryService.editDialog(this.discovery).then(this.utilService.handleEditResponse('discoveries.index'));
    }); */
    }

    private _initViewer(): void {
        const imageBoxKnot = new Query('.image-box', this.dom).getKnot();
        imageBoxKnot.addEventListener('click', () => {
            const imageUrl = this.discovery.get<string>('photo.url');
            const fullName = this.discovery.get<string>('full_name');
            this.viewer.loadImage(imageUrl, fullName);
        });
    }

    private _correctDates(): void {
        this.utilService.correctDate(
            '.killing-at',
            'format.datetime',
            this.dom,
        );
    }

    private _loadDiscovery(): Promize {
        const deferred = new Deferred();
        this.discoveryService
            .get(this.discoveryId, {
                fields: 'id,full_name,location,photo',
            })
            .then(
                (response) => {
                    this.discovery = response.get('discovery');
                    deferred.resolve();
                },
                () => {
                    deferred.reject();
                },
            );
        return deferred.promise();
    }

    protected override _drawContent(): Promize | boolean {
        this._changeToolbarButtons();

        this._initMap();
        return true;
    }

    private _changeToolbarButtons(): void {
        // this.utilService.showButton(this.btnDiscoveriesEditKnot, this.activeTab === 'details');
    }

    private _initMap(): void {
        if (eq(this.activeTab, 'details')) {
            if (!this.map) {
                this.map = this.mapService.createGoogleMap(this.dom);

                this.map.setMarkerIcon(
                    'location',
                    this.mapService.getIcon('location', '#795548'),
                );

                this.map.eventMarkerClick = this._eventMarkerClick.bind(this);
            }

            const discoveryId = this.discovery.get<string>('id');
            const location = this.discovery.get<Objekt>(
                'location',
                new Objekt(),
            );
            this.map.createMarker(
                discoveryId,
                '',
                'location',
                location.get('latitude'),
                location.get('longitude'),
                this.discovery,
            );
        }
    }

    private _eventMarkerClick(discovery: Objekt): void {
        const discoveryId = discovery.get<string>('id');
        const address = this.discovery.get<string>('location.address');
        this.map.openInfoWindow(discoveryId, address);
    }

    exit(): void {
        super.exit();

        this.mapService.removeGoogleMap(this.map);
    }
}

export const discoveriesShowController = app.controller(
    resources.discoveriesShowController,
    [
        resources.instances,
        resources.mapService,
        resources.discoveryService,
        resources.trophyService,
        resources.utilService,
    ],
    DiscoveriesShowController,
);
