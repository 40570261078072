import { Depot, Instance, Objekt, Promize, Table } from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { ReportService } from '../services/reportService';
import { TrophyService } from '../services/trophyService';
import { DashboardController } from './dashboardController';

export class ReportsPeopleWhatShootController extends DashboardController {
    localDepot: Depot;
    trophiesTable: Table;

    constructor(
        instances: Instance,
        private reportService: ReportService,
        private trophyService: TrophyService,
    ) {
        super(instances);

        this.localDepot = instances.localDepot;
    }

    protected override _initLayout(): void {
        this._initToolbarButtons();

        this._drawContent();
    }

    protected override _drawContent(): Promize | boolean {
        this._initTrophyTable();
        return true;
    }

    private _initToolbarButtons(): void {
        this.helper.iconButton(
            '.btn-reports-index',
            this.dom,
            () => {
                this.state.goBack('reports.index');
            },
            '',
            true,
            [],
        );

        this.helper.iconButton('.btn-reports-filter', this.dom, () => {
            this.reportService.detailsDialog('people');
        });
    }

    private _initTrophyTable(): void {
        if (!this.trophiesTable) {
            this.trophiesTable = new Table(
                this.dom,
                '.trophies-table',
                this.trophyService.getTableOptions(),
            );

            this.trophiesTable.eventAction = (params) => {
                params.set('fields', this.trophyService.getTableFields());
                const peopleReport = new Objekt(
                    this.localDepot.get(
                        this.reportService.getStorageKeyReport('people'),
                    ),
                );
                this.reportService
                    .getAllByPeople(peopleReport, params)
                    .then((response) => {
                        const count = response.get<number>('count');
                        this.trophiesTable.setCount(count);
                        const trophies = response.get<Objekt[]>('trophies');
                        this.trophiesTable.setData(trophies);
                    });
            };

            this.trophiesTable.render();
        } else {
            this.trophiesTable.refresh();
        }
    }
}

export const reportsPeopleWhatShootController = app.controller(
    resources.reportsPeopleWhatShootController,
    [resources.instances, resources.reportService, resources.trophyService],
    ReportsPeopleWhatShootController,
);
