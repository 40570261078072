import {
    Deferred,
    eq,
    GoogleMap,
    Instance,
    Knot,
    LatLng,
    noop,
    Objekt,
    Promize,
    Query,
    Table,
    Viewer,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { AreaService } from '../services/areaService';
import { BigGameTrophyService } from '../services/bigGameTrophyService';
import { HuntService } from '../services/huntService';
import { ItemService } from '../services/itemService';
import { LanguageService } from '../services/languageService';
import { MapService } from '../services/mapService';
import { SmallGameTrophyService } from '../services/smallGameTrophyService';
import { TrophyService } from '../services/trophyService';
import { UserService } from '../services/userService';
import { UtilService } from '../services/utilService';
import { DashboardController } from './dashboardController';

export class HuntsShowController extends DashboardController {
    viewer: Viewer;
    huntId: string;
    hunt: Objekt;
    btnHuntsEditKnot: Knot<HTMLElement>;
    btnTrophiesNewKnot: Knot<HTMLElement>;
    btnItemsNewKnot: Knot<HTMLElement>;
    itemsTable: Table;
    trophiesTable: Table;
    area: Objekt;
    map: GoogleMap;
    organizationId: string;

    constructor(
        instances: Instance,
        private mapService: MapService,
        private huntService: HuntService,
        private areaService: AreaService,
        private trophyService: TrophyService,
        private smallGameTrophyService: SmallGameTrophyService,
        private bigGameTrophyService: BigGameTrophyService,
        private itemService: ItemService,
        private utilService: UtilService,
        private userService: UserService,
        private languageService: LanguageService,
    ) {
        super(instances);

        this.viewer = instances.viewer;
    }

    protected override _initLayout(): void {
        this.huntId = this.state.getParam('huntId');
        this.hunt = new Objekt();

        this._initToolbarButtons();
        this._initTabPanel('details');
        this._changeToolbarButtons();

        this._initViewer();
        this._initLinks('#details a');
        this._correctDates();

        this._loadHunt().then(() => {
            this._drawContent();
        });
    }

    private _initToolbarButtons(): void {
        this.helper.iconButton(
            '.btn-hunts-index',
            this.dom,
            () => {
                this.state.goBack('hunts.index');
            },
            '',
            true,
            [],
        );

        this.btnHuntsEditKnot = this.helper.iconButton(
            '.btn-hunts-edit',
            this.dom,
            () => {
                this.huntService
                    .editDialog(this.hunt)
                    .then(this.utilService.handleEditResponse('hunts.index'));
            },
        );

        this.btnTrophiesNewKnot = this.helper.iconButton(
            '.btn-trophies-new',
            this.dom,
            () => {
                this.trophyService
                    .chooseTrophyDialog()
                    .then((classification) => {
                        if (classification === 'small') {
                            this.smallGameTrophyService
                                .newDialogByHunt(this.huntId)
                                .then(() => {
                                    this.state.refresh(true);
                                });
                        } else if (classification === 'big') {
                            this.bigGameTrophyService
                                .newDialogByHunt(this.huntId)
                                .then(() => {
                                    this.state.refresh(true);
                                });
                        }
                    });
            },
        );

        this.btnItemsNewKnot = this.helper.iconButton(
            '.btn-items-new',
            this.dom,
            () => {
                this.itemService.newDialogByHunt(this.huntId).then(() => {
                    this.state.refresh(true);
                });
            },
        );
    }

    protected override _drawContent(): Promize | boolean {
        this._changeToolbarButtons();

        this._initMap();
        this._initTrophiesTable();
        this._initItemsTable();
        return true;
    }

    private _changeToolbarButtons(): void {
        this.utilService.showButton(
            this.btnHuntsEditKnot,
            this.activeTab === 'details',
        );

        this.utilService.showButton(
            this.btnTrophiesNewKnot,
            this.activeTab === 'trophy-table',
        );

        this.utilService.showButton(
            this.btnItemsNewKnot,
            this.activeTab === 'item-table',
        );
    }

    private _initViewer(): void {
        const imageBoxKnot = new Query('.image-box', this.dom).getKnot();
        if (imageBoxKnot.exists()) {
            imageBoxKnot.addEventListener('click', () => {
                const imageUrl = this.hunt.get<string>('photo.url');
                const title = this.hunt.get<string>('title');
                this.viewer.loadImage(imageUrl, title);
            });
        }
    }

    private _initItemsTable(): void {
        if (eq(this.activeTab, 'item-table')) {
            if (!this.itemsTable) {
                this.itemsTable = new Table(
                    this.dom,
                    '.items-table',
                    this.itemService.getTableOptions(),
                );

                this.itemsTable.setActions([
                    {
                        style: () => {
                            return [
                                'edit',
                                this.languageService.translate('buttons.edit'),
                                false,
                                !this.userService.hasAccessByOrganization(
                                    this.organizationId,
                                    'items_write',
                                ),
                            ];
                        },
                        click: (item) => {
                            this.itemService.editDialog(item).then(() => {
                                this.state.refresh(true);
                            });
                        },
                    },
                ]);

                this.itemsTable.eventAction = (params) => {
                    params.set('fields', this.itemService.getTableFields());
                    this.itemService
                        .getAllByHunt(this.huntId, params)
                        .then((response) => {
                            const count = response.get<number>('count');
                            this.itemsTable.setCount(count);
                            const items = response.get<Objekt[]>('items');
                            this.itemsTable.setData(items);
                        });
                };
                this.itemsTable.render();
            } else {
                this.itemsTable.refresh();
            }
        }
    }

    private _initTrophiesTable(): void {
        if (eq(this.activeTab, 'trophy-table')) {
            if (!this.trophiesTable) {
                this.trophiesTable = new Table(
                    this.dom,
                    '.trophies-table',
                    this.trophyService.getTableOptions(),
                );

                this.trophiesTable.setActions([
                    {
                        style: (trophy) => {
                            return [
                                'info',
                                trophy.get('description'),
                                false,
                                !trophy.get('description'),
                            ];
                        },
                        click: noop(),
                    },
                    {
                        style: () => {
                            return [
                                'edit',
                                this.languageService.translate('buttons.edit'),
                            ];
                        },
                        click: (trophy) => {
                            this.trophyService.editDialog(trophy).then(() => {
                                this.state.refresh(true);
                            });
                        },
                    },
                ]);

                this.trophiesTable.eventAction = (params) => {
                    params.set('fields', this.trophyService.getTableFields());
                    this.trophyService
                        .getAllByHunt(this.huntId, params)
                        .then((response) => {
                            const count = response.get<number>('count');
                            this.trophiesTable.setCount(count);
                            const trophies = response.get<Objekt[]>('trophies');
                            this.trophiesTable.setData(trophies);
                        });
                };

                this.trophiesTable.render();
            } else {
                this.trophiesTable.refresh();
            }
        }
    }

    private _loadHunt(): Promize {
        const deferred = new Deferred();
        this.huntService
            .get(this.huntId, {
                fields: 'id,title,photo,organization_id,location,class_type',
            })
            .then(
                (response) => {
                    this.hunt = response.get('hunt');
                    this.organizationId =
                        this.hunt.get<string>('organization_id');
                    deferred.resolve();
                },
                () => {
                    deferred.reject();
                },
            );
        return deferred.promise();
    }

    private _loadArea(): Promize {
        const deferred = new Deferred();
        this.areaService
            .getAllByOrganization(this.hunt.get('organization_id'))
            .then(
                (areaResponse) => {
                    this.area =
                        areaResponse.get('areas', [])[0] || new Objekt();
                    deferred.resolve();
                },
                () => {
                    deferred.reject();
                },
            );
        return deferred.promise();
    }

    private _initMap(): void {
        if (eq(this.activeTab, 'details')) {
            if (!this.map) {
                this.map = this.mapService.createGoogleMap(this.dom);

                this.map.setMarkerIcon(
                    'location',
                    this.mapService.getIcon('location', '#795548'),
                );
                this.map.eventMarkerClick = this._eventMarkerClick.bind(this);

                this.map.setPolygons(this.mapService.getAreaPolygonOptions());
            }

            this._loadArea().then(() => {
                this._setMap();
            });
        }
    }

    private _correctDates(): void {
        this.utilService.correctDate('.start-at', 'format.datetime', this.dom);
        this.utilService.correctDate('.end-at', 'format.datetime', this.dom);
    }

    private _setMap(): void {
        this.map.removeAllPolygon();
        this.map.removeAllMarker();

        const areaId = this.area.get<string>('id');
        const areaPoints = this.area.get<LatLng[]>('locations', []);
        this.map.createPolygon(areaId, '', areaPoints, this.area);
        this.map.fitPolygonToMap(areaId);

        const huntId = this.hunt.get<string>('id');
        const location = this.hunt.get<Objekt>('location', new Objekt());
        this.map.createMarker(
            huntId,
            location.get('address'),
            'location',
            location.get('latitude'),
            location.get('longitude'),
            this.hunt,
        );
    }

    private _eventMarkerClick(hunt: Objekt): void {
        const huntId = hunt.get<string>('id');
        const address = hunt.get<string>('location.address');
        this.map.openInfoWindow(huntId, address);
    }

    exit(): void {
        super.exit();

        this.mapService.removeGoogleMap(this.map);
    }
}

export const huntsShowController = app.controller(
    resources.huntsShowController,
    [
        resources.instances,
        resources.mapService,
        resources.huntService,
        resources.areaService,
        resources.trophyService,
        resources.smallGameTrophyService,
        resources.bigGameTrophyService,
        resources.itemService,
        resources.utilService,
        resources.userService,
        resources.languageService,
    ],
    HuntsShowController,
);
