import { Form, format, Instance, Knot, Objekt } from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { createHunterTicketIdentifierCell } from '../utils/tableHelper';
import { AssetService } from './assetService';
import { EntityConfig } from './entityService';
import { LanguageService } from './languageService';
import { ModelService } from './modelService';

export class PersonService extends ModelService {
    constructor(
        entityConfig: EntityConfig,
        instances: Instance,
        $languageService: LanguageService,
        $assetService: AssetService,
    ) {
        super(entityConfig, instances, $languageService, $assetService);
    }

    getAllByOrganization(organizationId: string, opt_params?: object) {
        return this.getAllBy('organizations', organizationId, opt_params);
    }

    createByOrganization(organizationId: string, person: Objekt) {
        return this.createBy('organizations', organizationId, person);
    }

    handleForm(form: Form, opt_entityName?: string): void {
        const entityName = opt_entityName || this.entityName;

        const confirmDataShareField = form.findByModel(
            format('{0}.confirm_data_share', [entityName]),
        );
        const userIdField = form.findByModel(
            format('{0}.user_id', [entityName]),
        );

        const firstNameField = form.findByModel(
            format('{0}.first_name', [entityName]),
        );
        const lastNameField = form.findByModel(
            format('{0}.last_name', [entityName]),
        );
        const emailField = form.findByModel(format('{0}.email', [entityName]));
        const genderField = form.findByModel(
            format('{0}.gender', [entityName]),
        );
        const identifierField = form.findByModel(
            format('{0}.identifier', [entityName]),
        );
        const addressField = form.findByModel(
            format('{0}.address', [entityName]),
        );
        const phoneField = form.findByModel(format('{0}.phone', [entityName]));
        const allowNotificationsField = form.findByModel(
            format('{0}.allow_notifications', [entityName]),
        );
        const joinAtField = form.findByModel(
            format('{0}.join_at', [entityName]),
        );

        if (userIdField) {
            userIdField.eventChange = (userId) => {
                if (userId) {
                    confirmDataShareField.setDisabled(false);
                } else {
                    confirmDataShareField.setDisabled(true);
                }
            };
        }

        if (confirmDataShareField) {
            confirmDataShareField.eventChange = (confirmDataShare) => {
                if (confirmDataShare) {
                    firstNameField.setDisabled(true);
                    lastNameField.setDisabled(true);
                    emailField.setDisabled(true);
                    genderField.setDisabled(true);
                    identifierField.setDisabled(true);
                    addressField.setDisabled(true);
                    phoneField.setDisabled(true);
                    userIdField.setDisabled(true);
                    allowNotificationsField.setDisabled(true);
                    joinAtField.setDisabled(true);
                } else {
                    firstNameField.setDisabled(false);
                    lastNameField.setDisabled(false);
                    emailField.setDisabled(false);
                    genderField.setDisabled(false);
                    identifierField.setDisabled(false);
                    addressField.setDisabled(false);
                    phoneField.setDisabled(false);
                    userIdField.setDisabled(false);
                    allowNotificationsField.setDisabled(false);
                    joinAtField.setDisabled(false);
                }
            };
        }
    }

    getTableOptionsByOrganization(organizationId: string): object {
        return {
            no_content: {
                image_url: this.assetService.getPath(
                    'images/others/no-content.png',
                ),
                text: this.languageService.translate('text.no_content'),
            },
            sort: {
                column: 'last_name',
                order: 'asc',
            },
            row_count: 12,
            column: 'last_name',
            columns: [
                'user_id',
                'last_name',
                'identifier',
                'groups',
                'join_at',
                'contact',
                'allow_data_share',
                'actions',
            ],
            sorted: ['last_name', 'identifier', 'join_at'],
            calculations:
                this.getTableCalculationsByOrganization(organizationId),
        };
    }

    getTableFields(): string {
        return 'id,name,identifier,identifier_validity,groups.id,groups.name,join_at,address,email,phone,user.avatar,confirm_data_share,organization_user.allow_data_share,class_type';
    }

    getTableCalculationsByOrganization(organizationId: string): object {
        return {
            user_id: (person) => {
                const avatarUrl = person.get('user.avatar.url');
                const imageWrapper = new Knot('div');
                imageWrapper.addClass('img-wrapper');
                if (avatarUrl) {
                    const imageKnot = new Knot('img');
                    imageKnot.addClass(['img-responsive', 'img-circle']);
                    imageKnot.setAttribute('src', avatarUrl);
                    imageWrapper.appendChild(imageKnot);
                }
                imageWrapper.addEventListener('click', () => {
                    this.state.go('organization-users.index', {
                        organizationId: organizationId,
                    });
                });
                return imageWrapper;
            },
            last_name: (person) => {
                const guestName = person.get('name');
                return this.helper.createLink(
                    guestName,
                    (href) => {
                        this.state.go(href);
                    },
                    this.state.resolveUrl('people.show', {
                        personId: person.get('id'),
                    }),
                );
            },
            identifier: (person) => {
                const identifier = person.get('identifier');
                const validity = person.get('identifier_validity', null);

                return createHunterTicketIdentifierCell(identifier, validity);
            },
            groups: (person) => {
                const groups = person.get('groups');
                const links = [];
                groups.map((group) => {
                    const linkKnot = this.helper.createLink(
                        group.get('name'),
                        (href) => {
                            this.state.go(href);
                        },
                        this.state.resolveUrl('groups.show', {
                            groupId: group.get('id'),
                        }),
                    );
                    links.push(linkKnot);
                });
                return links;
            },
            contact: (person) => {
                const address = person.get('address');
                const email = person.get('email');
                const phone = person.get('phone');
                const links = [];
                if (address) {
                    const addressKnot = new Knot('em');
                    addressKnot.addClass('material-icons');
                    addressKnot.setHtml('mail');
                    const addressLinkKnot = this.helper.createLink(
                        addressKnot.toString(),
                        undefined,
                        'javascript:void(0)',
                        address,
                    );
                    links.push(addressLinkKnot);
                }
                if (email) {
                    const emailKnot = new Knot('em');
                    emailKnot.addClass('material-icons');
                    emailKnot.setHtml('alternate_email');
                    const emailLinkKnot = this.helper.createLink(
                        emailKnot.toString(),
                        undefined,
                        format('mailto:{0}', [email]),
                        email,
                    );
                    links.push(emailLinkKnot);
                }
                if (phone) {
                    const phoneKnot = new Knot('em');
                    phoneKnot.addClass('material-icons');
                    phoneKnot.setHtml('phone');
                    const phoneLinkKnot = this.helper.createLink(
                        phoneKnot.toString(),
                        undefined,
                        format('tel:{0}', [phone]),
                        phone,
                    );
                    links.push(phoneLinkKnot);
                }
                return links;
            },
            allow_data_share: (person) => {
                const allowDataShare = person.get(
                    'organization_user.allow_data_share',
                    false,
                );
                const confirmDataShare = person.get(
                    'confirm_data_share',
                    false,
                );
                const iconKnot = new Knot('em');
                iconKnot.addClass('material-icons');
                if (confirmDataShare) {
                    iconKnot.addClass('text-success');
                    iconKnot.setHtml('check_circle');
                    iconKnot.setAttribute(
                        'title',
                        this.languageService.translate(
                            'text.people.confirm_data_share',
                        ),
                    );
                } else if (allowDataShare) {
                    iconKnot.addClass('text-warning');
                    iconKnot.setHtml('warning');
                    iconKnot.setAttribute(
                        'title',
                        this.languageService.translate(
                            'text.people.allow_data_share',
                        ),
                    );
                }
                return allowDataShare ? iconKnot : '';
            },
        };
    }
}

export const personService = app.service(
    resources.personService,
    [
        'person-people-name',
        resources.instances,
        resources.languageService,
        resources.assetService,
    ],
    PersonService,
);
