import {
    DateIO,
    Deferred,
    Http,
    Instance,
    Objekt,
    Service,
    format,
    isNull,
    isObject,
    isUndefined,
    readableCurrency,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';

export class LanguageService extends Service {
    http: Http;
    _languages: Objekt;

    constructor(instances: Instance) {
        super();

        this.http = instances.http;

        this._languages = new Objekt();
    }

    enter() {
        return this._load();
    }

    private _load() {
        const deferred = new Deferred();
        this.http.get('/api/v1/languages.json').then(
            (response) => {
                this._languages.merge(response.get('languages'));
                deferred.resolve();
            },
            () => {
                deferred.resolve();
            },
        );
        return deferred.promise();
    }

    translate(
        attributes: string,
        opt_params: (object | Array<any> | null) | undefined = null,
        opt_defaultValue: (string | null) | undefined = '',
        opt_prefix: string | undefined = '',
        opt_postfix: string | undefined = '',
    ): string {
        opt_defaultValue = isNull(opt_defaultValue)
            ? ''
            : opt_defaultValue || ['!', attributes].join('');
        const translatedValue = this._languages.get<Objekt | string>(
            attributes,
            opt_defaultValue,
        );
        let translatedText = translatedValue as string;
        if (opt_params) {
            if (
                isObject(translatedValue) &&
                !isUndefined(opt_params['count'])
            ) {
                const translatedObj = translatedValue as Objekt;
                const count = opt_params['count'];
                if (count === 0) {
                    translatedText =
                        translatedObj.get<string>('zero') ||
                        translatedObj.get<string>('one');
                } else if (count === 1) {
                    translatedText = translatedObj.get<string>('one');
                } else {
                    translatedText = translatedObj.get<string>('other');
                }
            }
            opt_prefix = '%{';
            opt_postfix = '}';
        }
        return format(translatedText, opt_params, opt_prefix, opt_postfix);
    }

    format(
        date: Date,
        attributes: string,
        opt_params?: object | Array<any>,
        opt_defaultValue?: string,
    ): string {
        const format = this.translate(attributes, opt_params, opt_defaultValue);
        return DateIO.format(date, format);
    }

    currency(price: number, opt_currency: string | undefined = 'eur'): string {
        const currencyFormat = format('currency.{0}', [opt_currency]);
        const delimiter = this.translate(
            format('{0}.delimiter', [currencyFormat]),
        );
        const separator = this.translate(
            format('{0}.separator', [currencyFormat]),
        );
        const precision = parseInt(
            this.translate(format('{0}.precision', [currencyFormat])),
            10,
        );
        const unit = this.translate(format('{0}.unit', [currencyFormat]));
        const currency = readableCurrency(
            price,
            delimiter,
            separator,
            precision,
        );
        return this.translate(format('{0}.format', [currencyFormat]), {
            n: currency,
            u: unit,
        });
    }
}

export const languageService = app.service(
    resources.languageService,
    [resources.instances],
    LanguageService,
);
