import {
    Application,
    Depot,
    Dialog,
    EventBus,
    Flash,
    Footer,
    Form,
    Helper,
    Http,
    Instance,
    Knot,
    Navigation,
    Objekt,
    Script,
    Service,
    State,
    format,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { AppService } from './appService';
import { AssetService } from './assetService';
import { ConfigService } from './configService';
import { LanguageService } from './languageService';
import { UserService } from './userService';

export class BannerService extends Service {
    app: Application;
    helper: Helper;
    eventBus: EventBus;
    dialog: Dialog;
    localDepot: Depot;
    footer: Footer;
    flash: Flash;
    state: State;
    script: Script;
    http: Http;

    constructor(
        instances: Instance,
        private appService: AppService,
        private languageService: LanguageService,
        private userService: UserService,
        private assetService: AssetService,
        private configService: ConfigService,
    ) {
        super();

        this.app = instances.app;
        this.helper = instances.helper;
        this.eventBus = instances.eventBus;
        this.dialog = instances.dialog;
        this.localDepot = instances.localDepot;
        this.footer = instances.footer;
        this.flash = instances.flash;
        this.state = instances.state;
        this.script = instances.script;
        this.http = instances.http;
    }

    enter(): void {
        let i = 0;
        this.eventBus.set('state.change', (state) => {
            this._checkEsrbRating();
            this._checkCookiePolicy();
            if (i >= 10 && !state.get('public', false)) {
                this._showAdBanner();
                i = 0;
            }
            i++;
        });
        this._setFooterContent();
    }

    private _checkEsrbRating(): void {
        if (!this.localDepot.get('esrb.rating')) {
            this.dialog
                .loadTemplate('/client/v1/site/esrb-rating.html')
                .then(() => {
                    this.dialog.open(false);
                });
            this.dialog.eventOK = () => {
                this.localDepot.set('esrb.rating', true);
            };
            this.dialog.eventCancel = () => {
                const publicUrl =
                    this.configService.get<string>('other.public_url');
                this.state.redirect(publicUrl);
            };
        }
    }

    private _checkCookiePolicy(): void {
        if (!this.localDepot.get('cookie.policy')) {
            const message = this.languageService.translate('text.technologies');

            const realUrl = this.state.routes.getById<string>(
                'site.policies',
                'realUrl',
            );

            const link = new Knot('a');
            link.setAttribute('href', realUrl.replace(':tab', 'technologies'));
            link.setAttribute('target', '_self');
            link.setHtml(this.languageService.translate('buttons.learn_more'));

            this.flash.addWarning(
                format('{0} {1}.', [message, link.getHtml()]),
                0,
                () => {
                    this.localDepot.set('cookie.policy', true);
                },
                'COOKIE_POLICY',
            );
        }
    }

    private _showAdBanner(): void {
        if (!this.userService.hasLicense()) {
            const adClient = this.configService.get('google.ad_client');
            const adSlot = this.configService.get('google.ad_slot');
            this.script
                .load(
                    'google-adsense-sdk',
                    '//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js',
                )
                .then(() => {
                    const adsText = format(
                        '<ins class="adsbygoogle" style="display:block" data-ad-client="{0}" data-ad-slot="{1}" data-ad-format="auto"></ins>',
                        [adClient, adSlot],
                    );

                    this.flash.addDefault(
                        adsText,
                        0,
                        () => {
                            // empty method
                        },
                        'GOOGLE_ADSENSE',
                    );

                    /* (window['adsbygoogle'] = window['adsbygoogle'] || []).push({
'google_ad_client': adClient,
'enable_page_level_ads': true,
}); */
                    if (!window['adsbygoogle']) {
                        this.flash.removeById('GOOGLE_ADSENSE');
                    }
                });
        }
    }

    private _setFooterContent(): void {
        const appTitle = this.languageService.translate('application.name');
        const version = this.appService.getVersion();
        const codename = this.appService.getCodename();
        const hostname = this.appService.getHostname();

        const contentKnot = new Knot('div');
        contentKnot.setHtml(format('&copy; {0} ‒ v{1}-', [appTitle, version]));
        this.footer.setContent(contentKnot);

        const linkKnot = this.helper.createLink(
            codename,
            (href) => {
                this.state.go(href);
            },
            this.state.resolveUrl('site.changes'),
            '',
            true,
            [],
        );
        contentKnot.appendChild(linkKnot);

        const hostKnot = new Knot('span');
        hostKnot.setHtml(format('/{0}', [hostname]));
        contentKnot.appendChild(hostKnot);

        this._setFooterLocales();
    }

    private _setFooterLocales(): void {
        const footerLocalesNavigation = new Navigation(this.http);
        const locale = this.app.getLocale();
        const language = this.app.getLanguage();
        footerLocalesNavigation.addImage(
            locale,
            this.assetService.getPath(format('images/flags/{0}.png', [locale])),
            language,
            () => {
                this._changeLanguageDialog(locale);
            },
        );
        footerLocalesNavigation.setActive(locale);
        footerLocalesNavigation.bindToContainer(
            this.footer.getLocalesContainer(),
        );
    }

    private _changeLanguageDialog(locale: string): void {
        this.dialog
            .loadTemplate('/client/v1/site/languages.html')
            .then((dialogKnot) => {
                const form = new Form(dialogKnot);
                form.setModel(
                    new Objekt({
                        locale,
                    }),
                );
                form.eventSubmit = (formData) => {
                    this.dialog.close();
                    this.app.setLocaleWithReload(formData.get('locale'));
                };
                form.eventReset = () => {
                    this.dialog.close();
                };
                this.dialog.open();
            });
    }
}

export const bannerService = app.service(
    resources.bannerService,
    [
        resources.instances,
        resources.appService,
        resources.languageService,
        resources.userService,
        resources.assetService,
        resources.configService,
    ],
    BannerService,
);
