import { Depot, Instance, Objekt, Promize, Table } from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { AssetService } from '../services/assetService';
import { LanguageService } from '../services/languageService';
import { ReportBuildingService } from '../services/reportBuildingService';
import { ReportService } from '../services/reportService';
import { DashboardController } from './dashboardController';

export class ReportsBuildingsHowMuchTheyHuntController extends DashboardController {
    localDepot: Depot;
    table: Table;

    constructor(
        instances: Instance,
        private reportService: ReportService,
        private reportBuildingService: ReportBuildingService,
        private assetService: AssetService,
        private languageService: LanguageService,
    ) {
        super(instances);

        this.localDepot = instances.localDepot;
    }

    protected override _initLayout(): void {
        this._initToolbarButtons();

        this._drawContent();
    }

    protected override _drawContent(): Promize | boolean {
        this._initTable();
        return true;
    }

    private _initToolbarButtons(): void {
        this.helper.iconButton(
            '.btn-reports-index',
            this.dom,
            () => {
                this.state.goBack('reports.index');
            },
            '',
            true,
            [],
        );

        this.helper.iconButton('.btn-reports-filter', this.dom, () => {
            this.reportService.detailsDialog('buildings');
        });
    }

    private _initTable(): void {
        if (!this.table) {
            this.table = new Table(this.dom, 'table', {
                no_content: {
                    image_url: this.assetService.getPath(
                        'images/others/no-content.png',
                    ),
                    text: this.languageService.translate('text.no_content'),
                },
                sort: {
                    column: 'sum_duration',
                    order: 'desc',
                },
                row_count: 1000,
                column: 'name',
                columns: [
                    'number',
                    'name',
                    'sum_duration',
                    'hunts_count',
                    'trophies_count',
                ],
                sorted: [
                    'name:asc',
                    'sum_duration:desc',
                    'hunts_count:desc',
                    'trophies_count:desc',
                ],
                calculations: this.reportBuildingService.getTableCalculations(),
            });

            this.table.eventAction = (params) => {
                params.set(
                    'fields',
                    'id,name,sum_duration,hunts_count,trophies_count,sum_duration_prev,hunts_count_prev,trophies_count_prev',
                );
                const buildingsReport = new Objekt(
                    this.localDepot.get(
                        this.reportService.getStorageKeyReport('buildings'),
                    ),
                );
                this.reportService
                    .getAllByBuildings(buildingsReport, params)
                    .then((response) => {
                        const count = response.get<number>('count');
                        this.table.setCount(count);
                        const objects = response.get<Objekt[]>('objects');
                        this.table.setData(objects);
                    });
            };

            this.table.render();
        } else {
            this.table.refresh();
        }
    }
}

export const reportsBuildingsHowMuchTheyHuntController = app.controller(
    resources.reportsBuildingsHowMuchTheyHuntController,
    [
        resources.instances,
        resources.reportService,
        resources.reportBuildingService,
        resources.assetService,
        resources.languageService,
    ],
    ReportsBuildingsHowMuchTheyHuntController,
);
