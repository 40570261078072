import {
    CardCollection,
    DateIO,
    format,
    Instance,
    Knot,
    Objekt,
    Promize,
    Query,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { AssetService } from '../services/assetService';
import { LanguageService } from '../services/languageService';
import { OrganizationService } from '../services/organizationService';
import { PlanService } from '../services/planService';
import { UtilService } from '../services/utilService';
import { DashboardController } from './dashboardController';

export class PlansIndexController extends DashboardController {
    planCardCollection: CardCollection;

    constructor(
        instances: Instance,
        private planService: PlanService,
        private organizationService: OrganizationService,
        private utilService: UtilService,
        private assetService: AssetService,
        private languageService: LanguageService,
    ) {
        super(instances);
    }

    protected override _initLayout(): void {
        this._initToolbarButtons();
        this._initFilterForm();

        this._drawContent();
    }

    protected override _drawContent(): Promize | boolean {
        this._initPlanCardCollection();
        return true;
    }

    private _initToolbarButtons(): void {
        this.helper.iconButton('.btn-plans-new', this.dom, () => {
            this.planService
                .newDialog('organizations', 'organization_id')
                .then(() => {
                    this._drawContent();
                });
        });

        this.helper.iconButton(
            '.btn-site-home',
            this.dom,
            () => {
                this.state.goBack('site.home');
            },
            '',
            true,
            [],
        );
    }

    private _initPlanCardCollection(): void {
        if (!this.planCardCollection) {
            this.planCardCollection = new CardCollection(
                this.dom,
                '.plan-collection',
                {
                    formatStartAtAndEndAt:
                        this.formatStartAtAndEndAt.bind(this),
                },
                {
                    no_content: {
                        image_url: this.assetService.getPath(
                            'images/others/no-content.png',
                        ),
                        text: this.languageService.translate('text.no_content'),
                    },
                    row_count: 12,
                    sort: {
                        column: 'start_at',
                        order: 'desc',
                    },
                },
            );
            this.planCardCollection.eventCardKnot =
                this._planCardKnot.bind(this);
            this.planCardCollection.eventAction = (params) => {
                params.set('query', this.query);
                params.set('organization_ids', this.organizationIds);
                params.set(
                    'fields',
                    'id,full_name,start_at,end_at,open_season.name,open_season.country.name,' +
                        this.organizationService.getAssociationTableFields(),
                );
                this.planService.getAll(params).then((response) => {
                    const count = response.get<number>('count');
                    this.planCardCollection.setCount(count);
                    const plans = response.get<Objekt[]>('plans');
                    this.planCardCollection.setData(plans);
                });
            };
            this.planCardCollection.render();
        } else {
            this.planCardCollection.refresh(1);
        }
    }

    private _planCardKnot(cardKnot: Knot, plan: Objekt): void {
        this._initPlanActivity(cardKnot, plan);
        this._initPlanShowButton(cardKnot, plan);
        this._initCardButtons(cardKnot, plan);
        this.utilService.initOrganizationLink(cardKnot, plan);
    }

    private _initPlanActivity(cardKnot: Knot, plan: Objekt): void {
        const cardItem = new Query('.card', cardKnot).getKnot();
        const endAt = DateIO.parse(plan.get('end_at'));
        const cssClass = DateIO.isBefore(new Date(), endAt)
            ? 'active'
            : 'inactive';
        cardItem.addClass(cssClass);
    }

    private _initPlanShowButton(cardKnot: Knot, plan: Objekt): void {
        this.helper.link(
            'a.card',
            cardKnot,
            (href) => {
                this.state.go(href);
            },
            this.state.resolveUrl('plans.show', {
                planId: plan.get('id'),
            }),
        );
    }

    private _initCardButtons(cardKnot: Knot, plan: Objekt): void {
        // let actionsKnot = new Query('.actions', cardKnot).getKnot();
    }

    formatStartAtAndEndAt(startAt: string, endAt: string): string {
        const startAtFormatted = this.languageService.format(
            DateIO.parse(startAt),
            'format.date',
        );
        const endAtFormatted = this.languageService.format(
            DateIO.parse(endAt),
            'format.date',
        );
        return format('{0} ‒ {1}', [startAtFormatted, endAtFormatted]);
    }
}

export const plansIndexController = app.controller(
    resources.plansIndexController,
    [
        resources.instances,
        resources.planService,
        resources.organizationService,
        resources.utilService,
        resources.assetService,
        resources.languageService,
    ],
    PlansIndexController,
);
