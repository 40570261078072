import {
    EventBus,
    Instance,
    Navigation,
    Service,
    State,
    TopMenu,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { LanguageService } from './languageService';
import { UserService } from './userService';

export class TopMenuService extends Service {
    topMenu: TopMenu;
    state: State;
    eventBus: EventBus;
    _topMenuNavigation: Navigation;

    constructor(
        instances: Instance,
        private languageService: LanguageService,
        private userService: UserService,
    ) {
        super();

        this.topMenu = instances.topMenu;
        this.state = instances.state;
        this.eventBus = instances.eventBus;

        this._topMenuNavigation = new Navigation();
    }

    enter(): void {
        this.eventBus.set('state.change', (state) => {
            this._topMenuNavigation.setActive(state.get('id'));

            if (this.userService.isLoggedIn()) {
                this._topMenuNavigation.hide('users.login');
                this._topMenuNavigation.show('site.home');
                this._topMenuNavigation.show('users.logout');
                this._topMenuNavigation.show('users.home');
                if (this.userService.hasAccessByRole('app.administrator')) {
                    this._topMenuNavigation.show('admin.index');
                } else {
                    this._topMenuNavigation.hide('admin.index');
                }
                if (this.userService.hasAccessByRole('chambers_read')) {
                    this._topMenuNavigation.show('chambers.index');
                } else {
                    this._topMenuNavigation.hide('chambers.index');
                }
            } else {
                this._topMenuNavigation.show('users.login');
                this._topMenuNavigation.hide('site.home');
                this._topMenuNavigation.hide('users.logout');
                this._topMenuNavigation.hide('users.home');
                this._topMenuNavigation.hide('admin.index');
                this._topMenuNavigation.hide('chambers.index');
            }
        });

        this._setTopMenuNavigation();
    }

    private _setTopMenuNavigation(): void {
        this._topMenuNavigation.addIcon(
            'site.home',
            'apps',
            this.languageService.translate('captions.site.home'),
            (href) => {
                this.state.go(href);
                this.topMenu.close();
            },
            this.state.resolveUrl('site.home'),
        );

        this._topMenuNavigation.addIcon(
            'users.home',
            'account_circle',
            this.languageService.translate('captions.users.home'),
            (href) => {
                this.state.go(href);
                this.topMenu.close();
            },
            this.state.resolveUrl('users.home'),
        );

        this._topMenuNavigation.addIcon(
            'chambers.index',
            'account_balance',
            this.languageService.translate('captions.chambers.index'),
            (href) => {
                this.state.go(href);
                this.topMenu.close();
            },
            this.state.resolveUrl('chambers.index'),
        );

        this._topMenuNavigation.addIcon(
            'admin.index',
            'build',
            this.languageService.translate('captions.admin.index'),
            (href) => {
                this.state.go(href);
                this.topMenu.close();
            },
            this.state.resolveUrl('admin.index'),
        );

        this._topMenuNavigation.addIcon(
            'users.login',
            'login',
            this.languageService.translate('buttons.login'),
            (href) => {
                this.state.go(href);
                this.topMenu.close();
            },
            this.state.resolveUrl('users.login'),
        );

        this._topMenuNavigation.addIcon(
            'users.logout',
            'logout',
            this.languageService.translate('buttons.logout'),
            () => {
                this.userService.logout();
                this.topMenu.close();
            },
        );

        this._topMenuNavigation.bindToContainer(this.topMenu.getContainer());
    }
}

export const topMenuService = app.service(
    resources.topMenuService,
    [resources.instances, resources.languageService, resources.userService],
    TopMenuService,
);
