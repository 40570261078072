import {
    Confirm,
    DateIO,
    Deferred,
    Dialog,
    each,
    eachArray,
    eq,
    Flash,
    Form,
    format,
    GoogleMap,
    Helper,
    Knot,
    LatLng,
    Objekt,
    State,
    TabPanel,
} from '@siposdani87/sui-js';
import {
    batch,
    Component,
    createEffect,
    createSignal,
    on,
    onCleanup,
    onMount,
} from 'solid-js';
import { AreaService } from '../services/areaService';
import { BudgetService } from '../services/budgetService';
import { BuildingService } from '../services/buildingService';
import { DistrictService } from '../services/districtService';
import { LanguageService } from '../services/languageService';
import { MapService } from '../services/mapService';
import { OrganizationService } from '../services/organizationService';
import { PageService } from '../services/pageService';
import { SecurityService } from '../services/securityService';
import { UserService } from '../services/userService';
import { UtilService } from '../services/utilService';

export const OrganizationsShowComponent: Component<{
    organizationId: string;
    $dom: Knot;
    $helper: Helper;
    $state: State;
    $dialog: Dialog;
    $confirm: Confirm;
    $flash: Flash;
    $organizationService: OrganizationService;
    $securityService: SecurityService;
    $areaService: AreaService;
    $buildingService: BuildingService;
    $districtService: DistrictService;
    $mapService: MapService;
    $pageService: PageService;
    $languageService: LanguageService;
    $userService: UserService;
    $budgetService: BudgetService;
    $utilService: UtilService;
}> = (props) => {
    const [map, setMap] = createSignal<GoogleMap | null>(null);
    const [activeTab, setActiveTab] = createSignal<string>('');
    const [organization, setOrganization] = createSignal<Objekt>(new Objekt());
    const [page, setPage] = createSignal<Objekt>(new Objekt());
    const [areas, setAreas] = createSignal<Objekt[]>([]);
    const [buildings, setBuildings] = createSignal<Objekt[]>([]);
    const [districts, setDistricts] = createSignal<Objekt[]>([]);
    const [budget, setBudget] = createSignal<Objekt>(new Objekt());
    const [hasAccessOrganizationsEdit, setHasAccessOrganizationsEdit] =
        createSignal<boolean>(false);
    const [hasAccessPagesEdit, setHasAccessPagesEdit] =
        createSignal<boolean>(false);

    const _initTabPanel = (defaultTab: string): void => {
        const tabParam = props.$state.getParam('tab', defaultTab);
        const tabPanel = new TabPanel(props.$dom, '.tab-panel', tabParam);
        tabPanel.eventChange = (currentTab) => {
            props.$state.setParam('tab', currentTab);
            setActiveTab(currentTab);
        };
        const tab = tabPanel.getActive();
        setActiveTab(tab);
    };

    const _initAccess = (): void => {
        const hasAccessOrganizationsWrite =
            props.$userService.hasAccessByOrganization(
                props.organizationId,
                'organizations_write',
            );
        const hasAccessPagesWrite = props.$userService.hasAccessByOrganization(
            props.organizationId,
            'pages_write',
        );
        batch(() => {
            setHasAccessOrganizationsEdit(hasAccessOrganizationsWrite);
            setHasAccessPagesEdit(hasAccessPagesWrite);
        });
    };

    const _loadOrganization = (): void => {
        props.$organizationService
            .get(props.organizationId, {
                fields: 'id,name,image,short_name,founded,security.private,security.pin_code,buildings_count,districts_count,groups_count,members_count,guests_count,organization_users_count,categories_count,observations_count,license.license_type_text,license.expired,license.upgrade,country.location,sum_compute_area',
            })
            .then((response) => {
                setOrganization(response.get('organization'));
            });
    };

    const _loadAreas = (): void => {
        props.$areaService
            .getAllByOrganization(props.organizationId, {
                fields: 'id,locations',
            })
            .then((response) => {
                setAreas(response.get('areas', []));
            });
    };

    const setCenterOnMap = (): void => {
        const countryLocation = organization().get<Objekt>('country.location');
        map().setCenter(
            countryLocation.get<number>('latitude'),
            countryLocation.get<number>('longitude'),
        );
    };

    const _handleAreaChanges = (area: Objekt): void => {
        const areaId = area.get<string>('id');
        const points = area.get<LatLng[]>('locations', []);
        map().createPolygon(areaId, '', points, area);
        map().fitPolygonToMap(areaId);
    };

    const _loadPage = (): void => {
        props.$pageService
            .getByOrganization(props.organizationId, {
                fields: 'id,about,description,contact,published,url',
            })
            .then((response) => {
                setPage(response.get('page', new Objekt()));
            });
    };

    const _loadBudget = (): void => {
        props.$budgetService
            .getAllByOrganization(props.organizationId, {
                fields: 'id,current_amount,current_amount_with_currency',
                limit: 1,
            })
            .then((response) => {
                setBudget(response.get('budgets')[0] || new Objekt());
            });
    };

    const _loadBuildings = (): void => {
        props.$buildingService
            .getAllByOrganization(props.organizationId, {
                fields: 'id,name,type,location,available,photo',
                visible: true,
            })
            .then((response) => {
                setBuildings(response.get('buildings', []));
            });
    };

    const _handleBuildingChanges = (building: Objekt): void => {
        const buildingId = building.get<string>('id');
        const available = building.get('available');
        const postfix = available ? '_available' : '_busy';
        map().createOrUpdateMarker(
            buildingId,
            building.get('name'),
            building.get('type') + postfix,
            building.get('location.latitude'),
            building.get('location.longitude'),
            building,
            {
                draggable: false,
            },
        );
    };

    const _loadDistricts = (): void => {
        props.$districtService
            .getAllByOrganization(props.organizationId, {
                fields: 'id,title,locations,available_color',
            })
            .then((response) => {
                setDistricts(response.get('districts', []));
            });
    };

    const _handleDistrictChanges = (district: Objekt): void => {
        const districtId = district.get<string>('id');
        const districtTitle = district.get<string>('title');
        const districtPoints = district.get('locations', []);
        const districtAvailableColor = district.get('available_color');
        map().createOrUpdatePolygon(
            districtId,
            districtTitle,
            districtPoints,
            district,
            {
                strokeColor: districtAvailableColor,
                strokeOpacity: 0.3,
                fillColor: districtAvailableColor,
                fillOpacity: 0.1,
            },
        );
    };

    const _initMap = (): void => {
        if (!map()) {
            const newMap = props.$mapService.createGoogleMap(
                props.$dom,
                '.map',
                {
                    mapTypeId: 'custom',
                    mapTypeControlOptions: {
                        mapTypeIds: ['custom', google.maps.MapTypeId.SATELLITE],
                    },
                    mapTypeControl: true,
                    scrollwheel: true,
                },
                (currentMap, colorScheme) => {
                    if (
                        colorScheme === 'dark' ||
                        currentMap.getMapType() ===
                            google.maps.MapTypeId.SATELLITE
                    ) {
                        props.$mapService.setBuildingIcons(
                            currentMap,
                            '#FAFAFA',
                            '_available',
                        );
                    } else {
                        props.$mapService.setBuildingIcons(
                            currentMap,
                            '#795548',
                            '_available',
                        );
                    }
                    props.$mapService.setBuildingIcons(
                        currentMap,
                        '#f44336',
                        '_busy',
                    );

                    each(buildings, _handleBuildingChanges);
                },
            );

            newMap.eventMarkerClick = (building) => {
                _showBuildingInfoWindow(newMap, building);
            };

            newMap.setPolygons(props.$mapService.getAreaPolygonOptions());

            setMap(newMap);
        }
        map()?.removeAllMarker();
        map()?.removeAllPolygon();
    };

    const _showBuildingInfoWindow = (
        googleMap: GoogleMap,
        building: Objekt,
    ): void => {
        const buildingId = building.get<string>('id');

        const divKnot = new Knot('div');
        const imageKnot = new Knot('img');
        imageKnot.addClass('img-fluid');
        imageKnot.setStyle({
            width: '150px',
            height: '150px',
            'object-fit': 'cover',
        });
        imageKnot.setAttribute('src', building.get('photo.thumb.url'));
        divKnot.appendChild(imageKnot);

        const nameKnot = new Knot('h5');
        nameKnot.addClass('text-center');
        nameKnot.setHtml(building.get('name'));
        divKnot.appendChild(nameKnot);

        googleMap.openInfoWindow(buildingId, divKnot.getHtml());
    };

    const _initLinks = (): void => {
        props.$helper.link('.page-header a', props.$dom, (href) => {
            props.$state.go(href);
        });
    };

    const _initButtons = (): void => {
        props.$helper.iconButton(
            '.btn-organizations-index',
            props.$dom,
            () => {
                props.$state.goBack('organizations.index');
            },
            '',
            true,
            [],
        );

        props.$helper.iconButton('.btn-organizations-edit', props.$dom, () => {
            if (hasAccessOrganizationsEdit()) {
                _editOrganizationDialog();
            }
        });

        props.$helper.iconButton(
            '.btn-upgrade-license',
            props.$dom,
            () => {
                _upgradeLicense();
            },
            '',
            true,
            ['mdl-button--primary'],
        );

        props.$helper.iconButton(
            '.btn-new-order',
            props.$dom,
            () => {
                _upgradeLicense();
            },
            '',
            true,
            ['mdl-button--primary'],
        );

        props.$helper.iconButton(
            '.btn-open-page',
            props.$dom,
            () => {
                _goPage();
            },
            '',
            true,
            ['mdl-button--primary'],
        );

        props.$helper.iconButton(
            '.btn-show-qr-code',
            props.$dom,
            () => {
                _showOrganizationQRCode();
            },
            '',
            true,
            ['mdl-button--primary'],
        );

        props.$helper.iconButton(
            '.btn-set-visibility-public',
            props.$dom,
            () => {
                if (hasAccessOrganizationsEdit()) {
                    _editOrganizationSecurityDialog();
                }
            },
            '',
            true,
            [],
        );

        props.$helper.iconButton(
            '.btn-set-visibility-private',
            props.$dom,
            () => {
                if (hasAccessOrganizationsEdit()) {
                    _editOrganizationSecurityDialog();
                }
            },
            '',
            true,
            [],
        );

        props.$helper.iconButton(
            '.btn-organizations-roles',
            props.$dom,
            () => {
                _goRoles();
            },
            '',
            true,
            ['mdl-button--primary'],
        );
    };

    const getSumComputeAreaHa = (): string => {
        return props.$utilService.getComputeAreaInHa(getSumComputeArea());
    };

    const getSumComputeArea = (): number => {
        return organization().get('sum_compute_area', 0);
    };

    const _editOrganizationSecurityDialog = (): void => {
        props.$dialog
            .loadTemplate(
                format('/client/v1/organizations/{0}/securities/edit.html', [
                    props.organizationId,
                ]),
            )
            .then((dialogNode) => {
                const form = new Form(dialogNode);
                form.eventSubmit = (formData) => {
                    form.lock();
                    const securityData = formData.get<Objekt>(
                        'organization.security_attributes',
                    );
                    props.$securityService
                        .createByOrganization(
                            props.organizationId,
                            securityData,
                        )
                        .then(
                            (response) => {
                                props.$flash.addMessage(
                                    response.get('message'),
                                );
                                _loadOrganization();
                                props.$dialog.close();
                            },
                            (response) => {
                                props.$flash.addMessage(
                                    response.get('message'),
                                );
                                form.unlock();
                                form.setErrors(response.get('errors'));
                            },
                        );
                };
                form.eventReset = () => {
                    props.$dialog.close();
                };
                props.$securityService.handleForm(form);
                props.$dialog.open();
            });
    };

    const _editOrganizationDialog = (): void => {
        props.$dialog
            .loadTemplate(
                format('/client/v1/organizations/{0}/edit.html', [
                    props.organizationId,
                ]),
            )
            .then((dialogNode) => {
                const form = new Form(dialogNode);
                form.eventSubmit = (formData) => {
                    form.lock();
                    const organizationData =
                        formData.get<Objekt>('organization');
                    props.$organizationService.update(organizationData).then(
                        (response) => {
                            props.$flash.addMessage(response.get('message'));
                            _loadOrganization();
                            props.$dialog.close();
                        },
                        (response) => {
                            props.$flash.addMessage(response.get('message'));
                            form.unlock();
                            form.setErrors(response.get('errors'));
                        },
                    );
                };
                form.eventReset = () => {
                    props.$dialog.close();
                };
                props.$dialog.open();
            });
    };

    const _editPageDialog = (): void => {
        const pageId = page().get('id');
        const templateUrl = pageId
            ? format('/client/v1/pages/{0}/edit.html', [pageId])
            : format('/client/v1/organizations/{0}/pages/new.html', [
                  props.organizationId,
              ]);
        props.$dialog.loadTemplate(templateUrl).then((dialogNode) => {
            const form = new Form(dialogNode);
            form.eventSubmit = (formData) => {
                form.lock();
                const pageData = formData.get<Objekt>('page');
                props.$pageService
                    .createOrUpdateByOrganization(
                        props.organizationId,
                        pageData,
                    )
                    .then(
                        (response) => {
                            props.$flash.addMessage(response.get('message'));
                            _loadPage();
                            props.$dialog.close();
                        },
                        (response) => {
                            props.$flash.addMessage(response.get('message'));
                            form.unlock();
                            form.setErrors(response.get('errors'));
                        },
                    );
            };
            form.eventButton = () => {
                form.lock();
                _removePageConfirm().then(
                    () => {
                        form.unlock();
                        _loadPage();
                        props.$dialog.close();
                    },
                    () => {
                        form.unlock();
                    },
                );
            };
            form.eventReset = () => {
                props.$dialog.close();
            };
            props.$dialog.open();
        });
    };

    const _removePageConfirm = () => {
        const deferred = new Deferred();
        props.$confirm.load(
            props.$languageService.translate(
                'pages_controller.destroy.confirm',
                {
                    page_title: page().get('title'),
                },
            ),
            props.$languageService.translate('buttons.delete'),
            props.$languageService.translate('buttons.cancel'),
            '',
            'warning',
        );
        props.$confirm.eventOK = () => {
            props.$pageService.remove(page()).then(
                (response) => {
                    props.$flash.addMessage(response.get('message'));
                    deferred.resolve();
                },
                (response) => {
                    props.$flash.addMessage(response.get('message'));
                    deferred.reject();
                },
            );
        };
        props.$confirm.eventCancel = () => {
            deferred.reject();
        };
        props.$confirm.open();
        return deferred.promise();
    };

    const _upgradeLicense = (): void => {
        props.$state.go('orders.new', {
            licenseType: organization().get('license.upgrade'),
            organizationId: props.organizationId,
        });
    };

    const _goRoles = (): void => {
        props.$state.go('organizations.roles', {
            organizationId: props.organizationId,
        });
    };

    const _goPage = (): void => {
        const url = page().get<string>('url');
        if (url) {
            props.$state.redirect(url, true);
        }
    };

    const _showOrganizationQRCode = (): void => {
        props.$dialog
            .loadTemplate(
                format('/client/v1/organizations/{0}/qr-code.html', [
                    props.organizationId,
                ]),
            )
            .then(() => {
                props.$dialog.open();
            });
    };

    onMount(() => {
        _initAccess();
        _loadOrganization();

        _initTabPanel('organization-map');
    });

    onCleanup(() => {
        props.$mapService.removeGoogleMap(map());
    });

    createEffect(() => {
        if (map() && !organization().isEmpty() && areas().length === 0) {
            setCenterOnMap();
        }
    });

    createEffect(() => {
        if (map()) {
            eachArray(areas(), _handleAreaChanges);
        }
    });

    createEffect(() => {
        if (map()) {
            eachArray(buildings(), _handleBuildingChanges);
        }
    });

    createEffect(() => {
        if (map()) {
            eachArray(districts(), _handleDistrictChanges);
        }
    });

    createEffect(() => {
        if (eq(activeTab(), 'organization-map')) {
            _initMap();
            _loadAreas();
            _loadBuildings();
            _loadDistricts();
        } else {
            _loadPage();
            _loadBudget();
        }
    });

    createEffect(
        on([organization, hasAccessOrganizationsEdit, activeTab], () => {
            _initLinks();
            _initButtons();
        }),
    );

    return (
        <>
            <div class="page-header">
                <button
                    class="btn-organizations-index"
                    title={props.$languageService.translate('buttons.back')}
                >
                    <em class="material-icons">arrow_back</em>
                </button>
                <h1>
                    <a
                        href={props.$state.resolveUrl('organizations.index')}
                        onClick={() => props.$state.go('organizations.index')}
                    >
                        {props.$languageService.translate(
                            'captions.organizations.index',
                        )}
                    </a>
                    {organization().get('name')}
                </h1>
                <div class="actions">
                    {hasAccessOrganizationsEdit() &&
                        activeTab() === 'organization-dashboard' && (
                            <button
                                class="btn-organizations-edit"
                                title={props.$languageService.translate(
                                    'captions.organizations.edit',
                                )}
                            >
                                <em class="material-icons">edit</em>
                            </button>
                        )}
                </div>
            </div>
            <div class="tab-panel">
                <div class="tabs">
                    <a href="#organization-map">
                        {props.$languageService.translate(
                            'captions.organizations.map',
                        )}
                    </a>
                    <a href="#organization-dashboard">
                        {props.$languageService.translate(
                            'captions.organizations.dashboard',
                        )}
                    </a>
                </div>
                <div id="organization-map" class="panel map-container">
                    <div class="map"></div>
                </div>
                <div id="organization-dashboard" class="panel">
                    <div class="row section card-collection">
                        <div class="cards">
                            <div class="col-md-4 flex-box">
                                <div
                                    class="card dashboard-box"
                                    onClick={() => _editOrganizationDialog()}
                                >
                                    <div class="title-with-actions">
                                        <div class="title">
                                            <div class="truncate-container">
                                                <h5 class="text-truncate">
                                                    {props.$languageService.translate(
                                                        'text.organizations.data',
                                                    )}
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="content">
                                        <div class="row section">
                                            <div class="col box">
                                                <div class="key">
                                                    {props.$languageService.translate(
                                                        'mongoid.attributes.organization.image',
                                                    )}
                                                </div>
                                            </div>
                                            <div class="col box">
                                                <div
                                                    class={`value ${
                                                        organization().get(
                                                            'image.uploaded',
                                                        )
                                                            ? 'text-primary'
                                                            : 'text-error'
                                                    }`}
                                                >
                                                    {organization().get(
                                                        'image.uploaded',
                                                    ) && (
                                                        <img
                                                            class="img-circle"
                                                            src={organization().get(
                                                                'image.thumb.url',
                                                            )}
                                                            style={{
                                                                width: '28px',
                                                            }}
                                                            alt=""
                                                        />
                                                    )}
                                                    {!organization().get(
                                                        'image.uploaded',
                                                    ) && (
                                                        <em class="material-icons">
                                                            close
                                                        </em>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row section">
                                            <div class="col box">
                                                <div class="key">
                                                    {props.$languageService.translate(
                                                        'mongoid.attributes.organization.short_name',
                                                    )}
                                                </div>
                                            </div>
                                            <div class="col box">
                                                <div
                                                    class={`value ${
                                                        organization().get(
                                                            'short_name',
                                                        )
                                                            ? 'text-primary'
                                                            : 'text-error'
                                                    }`}
                                                >
                                                    {organization().get(
                                                        'short_name',
                                                    ) && (
                                                        <span>
                                                            {organization().get(
                                                                'short_name',
                                                            )}
                                                        </span>
                                                    )}
                                                    {!organization().get(
                                                        'short_name',
                                                    ) && (
                                                        <em class="material-icons">
                                                            close
                                                        </em>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row section">
                                            <div class="col box">
                                                <div class="key">
                                                    {props.$languageService.translate(
                                                        'mongoid.attributes.organization.founded',
                                                    )}
                                                </div>
                                            </div>
                                            <div class="col box">
                                                <div
                                                    class={`value ${
                                                        organization().get(
                                                            'founded',
                                                        )
                                                            ? 'text-primary'
                                                            : 'text-error'
                                                    }`}
                                                >
                                                    {organization().get(
                                                        'founded',
                                                    ) && (
                                                        <span>
                                                            {organization().get(
                                                                'founded',
                                                            )}
                                                        </span>
                                                    )}
                                                    {!organization().get(
                                                        'founded',
                                                    ) && (
                                                        <em class="material-icons">
                                                            close
                                                        </em>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 flex-box">
                                <div
                                    class="card dashboard-box"
                                    onClick={() =>
                                        props.$state.go(
                                            'licenses.index-by-organization',
                                            {
                                                organizationId:
                                                    props.organizationId,
                                            },
                                        )
                                    }
                                >
                                    <div class="title-with-actions">
                                        <div class="title">
                                            <div class="truncate-container">
                                                <h5 class="text-truncate">
                                                    {props.$languageService.translate(
                                                        'captions.licenses.index',
                                                    )}
                                                </h5>
                                            </div>
                                        </div>
                                        <div class="actions">
                                            {organization().get(
                                                'license.upgrade',
                                            ) && (
                                                <button
                                                    class="btn-upgrade-license"
                                                    title={props.$languageService.translate(
                                                        'captions.licenses.upgrade',
                                                    )}
                                                >
                                                    <em class="material-icons">
                                                        present_to_all
                                                    </em>
                                                </button>
                                            )}
                                            {!organization().get(
                                                'license.upgrade',
                                            ) && (
                                                <button
                                                    class="btn-new-order"
                                                    title={props.$languageService.translate(
                                                        'captions.orders.new',
                                                    )}
                                                >
                                                    <em class="material-icons">
                                                        shopping_cart
                                                    </em>
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                    <div class="content">
                                        <div class="row section">
                                            <div class="col-12 box">
                                                <div class="data">
                                                    {organization().get(
                                                        'license',
                                                    ) && (
                                                        <div>
                                                            {organization().get(
                                                                'license.license_type_text',
                                                            )}
                                                            <br />
                                                            <span class="small">
                                                                {props.$languageService.format(
                                                                    DateIO.parse(
                                                                        organization().get(
                                                                            'license.expired',
                                                                        ),
                                                                    ),
                                                                    'format.datetime',
                                                                )}
                                                            </span>
                                                        </div>
                                                    )}
                                                    {!organization().get(
                                                        'license',
                                                    ) && (
                                                        <div>
                                                            <a
                                                                href="#"
                                                                onClick={(
                                                                    e,
                                                                ) => {
                                                                    e.preventDefault();
                                                                    e.stopPropagation();
                                                                    _upgradeLicense();
                                                                }}
                                                            >
                                                                {props.$languageService.translate(
                                                                    'buttons.purchase',
                                                                )}
                                                            </a>
                                                            <br />
                                                            <span class="small text-error">
                                                                {props.$languageService.translate(
                                                                    'text.organizations.no_license',
                                                                )}
                                                            </span>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-4 flex-box">
                                <div
                                    class="card dashboard-box"
                                    onClick={() =>
                                        hasAccessPagesEdit()
                                            ? _editPageDialog()
                                            : null
                                    }
                                >
                                    <div class="title-with-actions">
                                        <div class="title">
                                            <div class="truncate-container">
                                                <h5 class="text-truncate">
                                                    {props.$languageService.translate(
                                                        'captions.pages.show',
                                                    )}
                                                </h5>
                                            </div>
                                        </div>
                                        <div class="actions">
                                            <button
                                                class="btn-open-page"
                                                title={props.$languageService.translate(
                                                    'captions.pages.show',
                                                )}
                                            >
                                                {!page().get('published') && (
                                                    <em class="material-icons text-error">
                                                        open_in_new
                                                    </em>
                                                )}
                                                {page().get('published') && (
                                                    <em class="material-icons text-primary">
                                                        open_in_new
                                                    </em>
                                                )}
                                            </button>
                                        </div>
                                    </div>
                                    <div class="content">
                                        <div class="row section">
                                            <div class="col box">
                                                <div class="key">
                                                    {props.$languageService.translate(
                                                        'mongoid.attributes.page.about',
                                                    )}
                                                </div>
                                            </div>
                                            <div class="col box">
                                                <div
                                                    class={`value ${
                                                        page().get('about')
                                                            ? 'text-primary'
                                                            : 'text-error'
                                                    }`}
                                                >
                                                    <em class="material-icons">
                                                        {page().get('about')
                                                            ? 'done'
                                                            : 'close'}
                                                    </em>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row section">
                                            <div class="col box">
                                                <div class="key">
                                                    {props.$languageService.translate(
                                                        'mongoid.attributes.page.description',
                                                    )}
                                                </div>
                                            </div>
                                            <div class="col box">
                                                <div
                                                    class={`value ${
                                                        page().get(
                                                            'description',
                                                        )
                                                            ? 'text-primary'
                                                            : 'text-error'
                                                    }`}
                                                >
                                                    <em class="material-icons">
                                                        {page().get(
                                                            'description',
                                                        )
                                                            ? 'done'
                                                            : 'close'}
                                                    </em>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row section">
                                            <div class="col box">
                                                <div class="key">
                                                    {props.$languageService.translate(
                                                        'mongoid.attributes.page.contact',
                                                    )}
                                                </div>
                                            </div>
                                            <div class="col box">
                                                <div
                                                    class={`value ${
                                                        page().get('contact')
                                                            ? 'text-primary'
                                                            : 'text-error'
                                                    }`}
                                                >
                                                    <em class="material-icons">
                                                        {page().get('contact')
                                                            ? 'done'
                                                            : 'close'}
                                                    </em>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-4 flex-box">
                                <div
                                    class="card dashboard-box"
                                    onClick={() =>
                                        _editOrganizationSecurityDialog()
                                    }
                                >
                                    <div class="title-with-actions">
                                        <div class="title">
                                            <div class="truncate-container">
                                                <h5 class="text-truncate">
                                                    {props.$languageService.translate(
                                                        'captions.securities.show',
                                                    )}
                                                </h5>
                                            </div>
                                        </div>
                                        <div class="actions">
                                            {organization().get(
                                                'security.private',
                                            ) && (
                                                <button
                                                    class="btn-set-visibility-private"
                                                    title={props.$languageService.translate(
                                                        'text.organizations.private',
                                                    )}
                                                >
                                                    <em class="material-icons text-primary">
                                                        lock
                                                    </em>
                                                </button>
                                            )}
                                            {!organization().get(
                                                'security.private',
                                            ) && (
                                                <button
                                                    class="btn-set-visibility-public"
                                                    title={props.$languageService.translate(
                                                        'text.organizations.public',
                                                    )}
                                                >
                                                    <em class="material-icons text-error">
                                                        lock_open
                                                    </em>
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                    <div class="content">
                                        <div class="row section">
                                            <div class="col box">
                                                <div class="key">
                                                    {props.$languageService.translate(
                                                        'mongoid.attributes.security.pin_code',
                                                    )}
                                                </div>
                                            </div>
                                            <div class="col box">
                                                <div
                                                    class={`value ${
                                                        organization().get(
                                                            'security.pin_code',
                                                        )
                                                            ? 'text-primary'
                                                            : 'text-error'
                                                    }`}
                                                >
                                                    {organization().get(
                                                        'security.pin_code',
                                                    ) && (
                                                        <span>
                                                            {organization().get(
                                                                'security.pin_code',
                                                            )}
                                                        </span>
                                                    )}
                                                    {!organization().get(
                                                        'security.pin_code',
                                                    ) && (
                                                        <em class="material-icons">
                                                            close
                                                        </em>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-4 flex-box">
                                <div
                                    class="card dashboard-box"
                                    onClick={() =>
                                        props.$state.go('budgets.index', {
                                            organizationId:
                                                props.organizationId,
                                        })
                                    }
                                >
                                    <div class="title truncate-container">
                                        <h5 class="text-truncate">
                                            {props.$languageService.translate(
                                                'captions.budgets.index',
                                            )}
                                        </h5>
                                    </div>
                                    <div class="content">
                                        <div class="row section">
                                            <div class="col-12 box">
                                                <div
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        if (
                                                            budget().get('id')
                                                        ) {
                                                            props.$state.go(
                                                                'budgets.show',
                                                                {
                                                                    budgetId:
                                                                        budget().get(
                                                                            'id',
                                                                        ),
                                                                },
                                                            );
                                                        }
                                                    }}
                                                    class={`data ${
                                                        budget().get(
                                                            'current_amount',
                                                            0,
                                                        ) > 0
                                                            ? 'text-default'
                                                            : 'text-error'
                                                    }`}
                                                >
                                                    {budget().get(
                                                        'current_amount_with_currency',
                                                        'N/A',
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-4 flex-box">
                                <div
                                    class="card dashboard-box"
                                    onClick={() =>
                                        props.$state.go('categories.index', {
                                            organizationId:
                                                props.organizationId,
                                        })
                                    }
                                >
                                    <div class="title truncate-container">
                                        <h5 class="text-truncate">
                                            {props.$languageService.translate(
                                                'captions.categories.index',
                                            )}
                                        </h5>
                                    </div>
                                    <div class="content">
                                        <div class="row section">
                                            <div class="col-12 box">
                                                <div
                                                    class={`data ${
                                                        organization().get(
                                                            'categories_count',
                                                            0,
                                                        ) > 0
                                                            ? 'text-default'
                                                            : 'text-error'
                                                    }`}
                                                >
                                                    {organization().get(
                                                        'categories_count',
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-4 flex-box">
                                <div
                                    class="card dashboard-box"
                                    onClick={() =>
                                        props.$state.go('areas.index', {
                                            organizationId:
                                                props.organizationId,
                                        })
                                    }
                                >
                                    <div class="title truncate-container">
                                        <h5 class="text-truncate">
                                            {props.$languageService.translate(
                                                'captions.areas.show',
                                            )}
                                        </h5>
                                    </div>
                                    <div class="content">
                                        <div class="row section">
                                            <div class="col-12 box">
                                                <div
                                                    class={`data ${
                                                        getSumComputeArea()
                                                            ? 'text-default'
                                                            : 'text-error'
                                                    }`}
                                                    innerHTML={getSumComputeAreaHa()}
                                                ></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-4 flex-box">
                                <div
                                    class="card dashboard-box"
                                    onClick={() =>
                                        props.$state.go('districts.index', {
                                            organizationId:
                                                props.organizationId,
                                        })
                                    }
                                >
                                    <div class="title truncate-container">
                                        <h5 class="text-truncate">
                                            {props.$languageService.translate(
                                                'captions.districts.index',
                                            )}
                                        </h5>
                                    </div>
                                    <div class="content">
                                        <div class="row section">
                                            <div class="col-12 box">
                                                <div
                                                    class={`data ${
                                                        organization().get(
                                                            'districts_count',
                                                            0,
                                                        ) > 0
                                                            ? 'text-default'
                                                            : 'text-error'
                                                    }`}
                                                >
                                                    {organization().get(
                                                        'districts_count',
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-4 flex-box">
                                <div
                                    class="card dashboard-box"
                                    onClick={() =>
                                        props.$state.go('buildings.index', {
                                            organizationId:
                                                props.organizationId,
                                        })
                                    }
                                >
                                    <div class="title truncate-container">
                                        <h5 class="text-truncate">
                                            {props.$languageService.translate(
                                                'captions.buildings.index',
                                            )}
                                        </h5>
                                    </div>
                                    <div class="content">
                                        <div class="row section">
                                            <div class="col-12 box">
                                                <div
                                                    class={`data ${
                                                        organization().get(
                                                            'buildings_count',
                                                            0,
                                                        ) > 0
                                                            ? 'text-default'
                                                            : 'text-error'
                                                    }`}
                                                >
                                                    {organization().get(
                                                        'buildings_count',
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-4 flex-box">
                                <div
                                    class="card dashboard-box"
                                    onClick={() =>
                                        props.$state.go('people.index', {
                                            organizationId:
                                                props.organizationId,
                                        })
                                    }
                                >
                                    <div class="title truncate-container">
                                        <h5 class="text-truncate">
                                            {props.$languageService.translate(
                                                'captions.people.index',
                                            )}
                                        </h5>
                                    </div>
                                    <div class="content">
                                        <div
                                            class="row section"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                props.$state.go(
                                                    'members.index',
                                                    {
                                                        organizationId:
                                                            props.organizationId,
                                                    },
                                                );
                                            }}
                                        >
                                            <div class="col box">
                                                <div class="key">
                                                    {props.$languageService.translate(
                                                        'captions.members.index',
                                                    )}
                                                </div>
                                            </div>
                                            <div class="col box">
                                                <div
                                                    class={`value ${
                                                        organization().get<number>(
                                                            'members_count',
                                                        ) > 0
                                                            ? 'text-primary'
                                                            : 'text-error'
                                                    }`}
                                                >
                                                    {organization().get(
                                                        'members_count',
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            class="row section"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                props.$state.go(
                                                    'guests.index',
                                                    {
                                                        organizationId:
                                                            props.organizationId,
                                                    },
                                                );
                                            }}
                                        >
                                            <div class="col box">
                                                <div class="key">
                                                    {props.$languageService.translate(
                                                        'captions.guests.index',
                                                    )}
                                                </div>
                                            </div>
                                            <div class="col box">
                                                <div
                                                    class={`value ${
                                                        organization().get<number>(
                                                            'guests_count',
                                                        ) > 0
                                                            ? 'text-primary'
                                                            : 'text-default'
                                                    }`}
                                                >
                                                    {organization().get(
                                                        'guests_count',
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-4 flex-box">
                                <div
                                    class="card dashboard-box"
                                    onClick={() =>
                                        props.$state.go('groups.index', {
                                            organizationId:
                                                props.organizationId,
                                        })
                                    }
                                >
                                    <div class="title-with-actions">
                                        <div class="title">
                                            <div class="truncate-container">
                                                <h5 class="text-truncate">
                                                    {props.$languageService.translate(
                                                        'captions.groups.index',
                                                    )}
                                                </h5>
                                            </div>
                                        </div>
                                        <div class="actions">
                                            <button
                                                class="btn-organizations-roles"
                                                title={props.$languageService.translate(
                                                    'captions.organizations.roles',
                                                )}
                                            >
                                                <em class="material-icons text-primary">
                                                    security
                                                </em>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="content">
                                        <div class="row section">
                                            <div class="col-12 box">
                                                <div
                                                    class={`data ${
                                                        organization().get(
                                                            'groups_count',
                                                            0,
                                                        ) > 0
                                                            ? 'text-default'
                                                            : 'text-error'
                                                    }`}
                                                >
                                                    {organization().get(
                                                        'groups_count',
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-4 flex-box">
                                <div
                                    class="card dashboard-box"
                                    onClick={() =>
                                        props.$state.go(
                                            'organization-users.index',
                                            {
                                                organizationId:
                                                    props.organizationId,
                                            },
                                        )
                                    }
                                >
                                    <div class="title-with-actions">
                                        <div class="title">
                                            <div class="truncate-container">
                                                <h5 class="text-truncate">
                                                    {props.$languageService.translate(
                                                        'captions.organization_users.index',
                                                    )}
                                                </h5>
                                            </div>
                                        </div>
                                        <div class="actions">
                                            {hasAccessOrganizationsEdit() && (
                                                <button
                                                    class="btn-show-qr-code"
                                                    title={props.$languageService.translate(
                                                        'captions.organizations.connect_via_qr_code',
                                                    )}
                                                >
                                                    <em class="material-icons">
                                                        qr_code
                                                    </em>
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                    <div class="content">
                                        <div class="row section">
                                            <div class="col-12 box">
                                                <div
                                                    class={`data ${
                                                        organization().get(
                                                            'organization_users_count',
                                                            0,
                                                        ) > 0
                                                            ? 'text-default'
                                                            : 'text-error'
                                                    }`}
                                                >
                                                    {organization().get(
                                                        'organization_users_count',
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-4 flex-box">
                                <div
                                    class="card dashboard-box"
                                    onClick={() =>
                                        props.$state.go(
                                            'observations.index-by-organization',
                                            {
                                                organizationId:
                                                    props.organizationId,
                                            },
                                        )
                                    }
                                >
                                    <div class="title">
                                        <div class="truncate-container">
                                            <h5 class="text-truncate">
                                                {props.$languageService.translate(
                                                    'captions.observations.index',
                                                )}
                                            </h5>
                                        </div>
                                    </div>
                                    <div class="content">
                                        <div class="row section">
                                            <div class="col-12 box">
                                                <div
                                                    class={`data ${
                                                        organization().get(
                                                            'observations_count',
                                                            0,
                                                        ) > 0
                                                            ? 'text-info'
                                                            : 'text-default'
                                                    }`}
                                                >
                                                    {organization().get(
                                                        'observations_count',
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-4 flex-box">
                                <div
                                    class="card dashboard-box"
                                    onClick={() =>
                                        props.$state.go('features.index', {
                                            organizationId:
                                                props.organizationId,
                                        })
                                    }
                                >
                                    <div class="title">
                                        <div class="truncate-container">
                                            <h5 class="text-truncate">
                                                {props.$languageService.translate(
                                                    'captions.features.index',
                                                )}
                                            </h5>
                                        </div>
                                    </div>
                                    <div class="content">
                                        <div class="row section">
                                            <div class="col-12 box">
                                                <div
                                                    class={`data text-default`}
                                                >
                                                    <em
                                                        class={`material-icons`}
                                                    >
                                                        construction
                                                    </em>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 flex-box">
                                <div
                                    class="card dashboard-box"
                                    onClick={() =>
                                        props.$state.go('rewards.index', {
                                            organizationId:
                                                props.organizationId,
                                        })
                                    }
                                >
                                    <div class="title">
                                        <div class="truncate-container">
                                            <h5 class="text-truncate">
                                                {props.$languageService.translate(
                                                    'captions.rewards.index',
                                                )}
                                            </h5>
                                        </div>
                                    </div>
                                    <div class="content">
                                        <div class="row section">
                                            <div class="col-12 box">
                                                <div
                                                    class={`data text-default`}
                                                >
                                                    <em
                                                        class={`material-icons`}
                                                    >
                                                        construction
                                                    </em>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
