import {
    Deferred,
    eq,
    GoogleMap,
    Instance,
    Knot,
    Objekt,
    Promize,
    Query,
    Viewer,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { AreaService } from '../services/areaService';
import { MapService } from '../services/mapService';
import { ObservationService } from '../services/observationService';
import { UtilService } from '../services/utilService';
import { DashboardController } from './dashboardController';

export class ObservationsShowController extends DashboardController {
    viewer: Viewer;
    observationId: string;
    observation: Objekt;
    userId: string;
    btnObservationsEditKnot: Knot<HTMLElement>;
    organizationId: string;
    area: Objekt;
    map: GoogleMap;

    constructor(
        instances: Instance,
        private mapService: MapService,
        private observationService: ObservationService,
        private areaService: AreaService,
        private utilService: UtilService,
    ) {
        super(instances);

        this.viewer = instances.viewer;
    }

    protected override _initLayout(): void {
        this.observationId = this.state.getParam('observationId');
        this.observation = new Objekt();

        this._initToolbarButtons();
        this._initTabPanel('details');
        this._changeToolbarButtons();
        this._initViewer();
        this._initLinks('#details a');
        this._correctDates();

        this._loadObservation().then(() => {
            this._drawContent();
        });
    }

    private _initToolbarButtons(): void {
        this.helper.iconButton(
            '.btn-observations-index',
            this.dom,
            () => {
                this.state.goBack('observations.index-by-user', {
                    userId: this.userId,
                });
            },
            '',
            true,
            [],
        );

        this.btnObservationsEditKnot = this.helper.iconButton(
            '.btn-observations-edit',
            this.dom,
            () => {
                this.observationService
                    .editDialog(this.observation)
                    .then(
                        this.utilService.handleEditResponse(
                            'observations.index-by-user',
                            { userId: this.userId },
                        ),
                    );
            },
        );
    }

    protected override _drawContent(): Promize | boolean {
        this._changeToolbarButtons();

        this._initMap();
        return true;
    }

    private _initViewer(): void {
        const imageBoxKnot = new Query('.image-box', this.dom).getKnot();
        if (imageBoxKnot.exists()) {
            imageBoxKnot.addEventListener('click', () => {
                const imageUrl = this.observation.get<string>('photo.url');
                const shortDescription =
                    this.observation.get<string>('short_description');
                this.viewer.loadImage(imageUrl, shortDescription);
            });
        }
    }

    private _loadObservation(): Promize {
        const deferred = new Deferred();
        this.observationService
            .get(this.observationId, {
                fields: 'id,short_description,photo,organization_id,location,user_id',
            })
            .then(
                (response) => {
                    this.observation = response.get('observation');
                    this.userId = this.observation.get('user_id');
                    this.organizationId =
                        this.observation.get('organization_id');
                    deferred.resolve();
                },
                () => {
                    deferred.reject();
                },
            );
        return deferred.promise();
    }

    private _loadArea(): Promize {
        this.area = new Objekt();
        const deferred = new Deferred();
        if (this.organizationId) {
            this.areaService.getAllByOrganization(this.organizationId).then(
                (response) => {
                    this.area = response.get('areas', [])[0] || new Objekt();
                    deferred.resolve();
                },
                () => {
                    deferred.reject();
                },
            );
        } else {
            deferred.resolve();
        }
        return deferred.promise();
    }

    private _changeToolbarButtons(): void {
        this.utilService.showButton(
            this.btnObservationsEditKnot,
            this.activeTab === 'details',
        );
    }

    private _initMap(): void {
        if (eq(this.activeTab, 'details')) {
            if (!this.map) {
                this.map = this.mapService.createGoogleMap(this.dom);

                this.map.setMarkerIcon(
                    'location',
                    this.mapService.getIcon('location', '#795548'),
                );
                this.map.eventMarkerClick = this._eventMarkerClick.bind(this);

                this.map.setPolygons(this.mapService.getAreaPolygonOptions());
            }

            this._loadArea().then(() => {
                this._setMap();
            });
        }
    }

    private _correctDates(): void {
        this.utilService.correctDate(
            '.created-at',
            'format.datetime',
            this.dom,
        );
    }

    private _setMap(): void {
        this.map.removeAllPolygon();
        this.map.removeAllMarker();

        /* const areaId = /!** @type {string} *!/ (this.area.get('id'));
    const areaPoints = /!** @type {!Array} *!/ (this.area.get('locations', []));
    this.map.createPolygon(areaId, '', areaPoints, this.area);
    this.map.fitPolygonToMap(areaId);*/
        const observationId = this.observation.get<string>('id');
        const location = this.observation.get<Objekt>('location', new Objekt());
        this.map.createMarker(
            observationId,
            location.get<string>('address'),
            'location',
            location.get<number>('latitude'),
            location.get<number>('longitude'),
            this.observation,
        );
        this.map.fitMarkerToMap(observationId);
    }

    private _eventMarkerClick(observation: Objekt): void {
        const observationId = observation.get<string>('id');
        const address = observation.get<string>('location.address');
        this.map.openInfoWindow(observationId, address);
    }

    exit(): void {
        super.exit();

        this.mapService.removeGoogleMap(this.map);
    }
}

export const observationsShowController = app.controller(
    resources.observationsShowController,
    [
        resources.instances,
        resources.mapService,
        resources.observationService,
        resources.areaService,
        resources.utilService,
    ],
    ObservationsShowController,
);
