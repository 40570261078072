import {
    EventBus,
    Http,
    Instance,
    LeftMenu,
    Navigation,
    Objekt,
    Service,
    State,
    each,
    noop,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { AssetService } from './assetService';
import { LanguageService } from './languageService';
import { UserService } from './userService';

export class MenuService extends Service {
    eventBus: EventBus;
    state: State;
    leftMenu: LeftMenu;
    http: Http;
    _menuItems: Objekt[];
    _navigations: Navigation[];

    constructor(
        instances: Instance,
        private languageService: LanguageService,
        private assetService: AssetService,
        private userService: UserService,
    ) {
        super();

        this.eventBus = instances.eventBus;
        this.state = instances.state;
        this.leftMenu = instances.leftMenu;
        this.http = instances.http;

        this._menuItems = [];
        this._navigations = [];
    }

    enter(): void {
        this._setMenuItem(
            'budgets.index',
            'images/icons/backpack.svg',
            'captions.budgets.index',
            'budgets.index',
            'budgets_read',
        );
        this._setMenuItem(
            'events.index',
            'images/icons/compass.svg',
            'captions.events.index',
            'events.index',
            'events_read',
        );
        this._setMenuItem(
            'discoveries.index',
            'images/icons/dog.svg',
            'captions.discoveries.index',
            'discoveries.index',
            'discoveries_read',
        );
        this._setMenuItem(
            'hunts.index',
            'images/icons/duck.svg',
            'captions.hunts.index',
            'hunts.index',
            'hunts_read',
        );
        this._setMenuItem(
            'notes.index',
            'images/icons/horn.svg',
            'captions.notes.index',
            'notes.index',
            'notes_read',
        );
        this._setMenuItem(
            'reports.index',
            'images/icons/mountain.svg',
            'captions.reports.index',
            'reports.index',
            'reports_read',
        );
        this._setMenuItem(
            'organizations.index',
            'images/icons/organization.svg',
            'captions.organizations.index',
            'organizations.index',
            'organizations_read',
        );
        this._setMenuItem(
            'plans.index',
            'images/icons/target.svg',
            'captions.plans.index',
            'plans.index',
            'plans_read',
        );
        this._setMenuItem(
            'trophies.index',
            'images/icons/trophy.svg',
            'captions.trophies.index',
            'trophies.index',
            'trophies_read',
        );
        this._setMenuItem(
            'vehicles.index',
            'images/icons/jeep.svg',
            'captions.vehicles.index',
            'vehicles.index',
            'vehicles_read',
        );
        this._setMenuItem(
            'users.index',
            'images/icons/hat.svg',
            'captions.users.index',
            'users.index',
            'users_read',
        );
        this._setMenuItem(
            'hunting_packages.index',
            'images/icons/weapon.svg',
            'captions.hunting_packages.index',
            'hunting_packages.index',
            'hunting_packages_read',
        );

        this.eventBus.set('state.change', () => {
            each(this._menuItems, (menuItem) => {
                const roleId = menuItem.get('role_id');
                if (this.userService.hasAccessByRole(roleId)) {
                    this.setEnabled(menuItem.get('id'));
                } else {
                    this.setDisabled(menuItem.get('id'));
                }
            });
        });
    }

    private _setMenuItem(
        id: string,
        icon: string,
        title: string,
        stateId: string,
        roleId: string,
    ): void {
        const menuItem = new Objekt({
            id: id,
            icon: icon,
            title: this.languageService.translate(title),
            state_id: stateId,
            role_id: roleId,
            action: noop(),
            disabled: true,
        });
        this._menuItems.push(menuItem);
    }

    getMenuItems(isSidebar: boolean): Navigation {
        let callback = noop();
        if (isSidebar) {
            callback = () => {
                this.leftMenu.close();
            };
        }

        const navigation = new Navigation(this.http);
        this._navigations.push(navigation);

        if (isSidebar) {
            navigation.addIcon(
                'site.home',
                'apps',
                this.languageService.translate('captions.site.home'),
                (href) => {
                    this.state.go(href);
                    callback();
                },
                this.state.resolveUrl('site.home'),
            );
        }

        each(this._menuItems, (menuItem) => {
            const stateId = menuItem.get('state_id');
            const roleId = menuItem.get('role_id');
            const icon = menuItem.get('icon');
            /* if (!isSidebar) {
        icon = icon.replace('.svg', '_highres.svg');
      }*/
            menuItem.set('image', this.assetService.getPath(icon));
            menuItem.set('disabled', !this.userService.hasAccessByRole(roleId));
            menuItem.set('href', this.state.resolveUrl(stateId));
            menuItem.set('action', (href) => {
                this.state.go(href);
                callback();
            });
            navigation.add(menuItem);
        });

        if (isSidebar) {
            navigation.addIcon(
                'settings',
                'settings',
                this.languageService.translate('captions.settings.index'),
                () => {
                    this.leftMenu.openSubMenu(
                        this.languageService.translate(
                            'captions.settings.index',
                        ),
                    );
                },
            );
        }

        return navigation;
    }

    setEnabled(id: string): void {
        each(this._navigations, (navigation) => {
            navigation.setEnabled(id);
        });
    }

    setDisabled(id: string): void {
        each(this._navigations, (navigation) => {
            navigation.setDisabled(id);
        });
    }
}

export const menuService = app.service(
    resources.menuService,
    [
        resources.instances,
        resources.languageService,
        resources.assetService,
        resources.userService,
    ],
    MenuService,
);
