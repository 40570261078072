import { format, Http, Instance, Service } from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';

export class NotificationService extends Service {
    http: Http;

    constructor(instances: Instance) {
        super();

        this.http = instances.http;
    }

    getAllByNote(noteId: string, opt_params?: object) {
        return this.http.get(
            format('/api/v1/notes/{0}/notifications.json', [noteId]),
            opt_params,
        );
    }

    getAllByEvent(eventId: string, opt_params?: object) {
        return this.http.get(
            format('/api/v1/events/{0}/notifications.json', [eventId]),
            opt_params,
        );
    }
}

export const notificationService = app.service(
    resources.notificationService,
    [resources.instances],
    NotificationService,
);
