import {
    Dialog,
    Flash,
    Form,
    Instance,
    Objekt,
    Table,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { AdminUserService } from '../services/adminUserService';
import { LanguageService } from '../services/languageService';
import { UserService } from '../services/userService';
import { AdminController } from './adminController';

export class AdminUsersIndexController extends AdminController {
    private table: Table;
    private dialog: Dialog;
    private flash: Flash;

    constructor(
        instances: Instance,
        private adminUserService: AdminUserService,
        private languageService: LanguageService,
        private userService: UserService,
    ) {
        super(instances);

        this.dialog = instances.dialog;
        this.flash = instances.flash;
    }

    protected override _initToolbarButtons(): void {
        this.helper.iconButton('.btn-users-invite', this.dom, () => {
            this._inviteUserDialog();
        });

        this.helper.iconButton(
            '.btn-admin-index',
            this.dom,
            () => {
                this.state.goBack('admin.index');
            },
            '',
            true,
            [],
        );

        this.helper.iconButton('.btn-users-new', this.dom, () => {
            this.adminUserService.newDialogByAdmin().then(() => {
                this._drawContent();
            });
        });
    }

    protected override _initTable(): void {
        if (!this.table) {
            this.table = new Table(
                this.dom,
                'table',
                this.adminUserService.getTableOptions(),
            );

            this.table.setActions([
                {
                    style: () => {
                        return ['share'];
                    },
                    click: this._connectionUser.bind(this),
                },
                {
                    style: () => {
                        return [
                            'edit',
                            this.languageService.translate('buttons.edit'),
                        ];
                    },
                    click: (user) => {
                        this.adminUserService
                            .editDialog(user, '/admin/')
                            .then(() => {
                                this._drawContent();
                            });
                    },
                },
            ]);

            this.table.eventAction = (params) => {
                params.set('query', this.query);
                params.set('fields', this.adminUserService.getTableFields());
                this.adminUserService.getAll(params).then((response) => {
                    const count = response.get<number>('count');
                    this.table.setCount(count);
                    const users = response.get<Objekt[]>('users');
                    this.table.setData(users);
                });
            };

            this.table.render();
        } else {
            this.table.refresh();
        }
    }

    private _inviteUserDialog(): void {
        this.dialog
            .loadTemplate('/client/v1/users/invite.html')
            .then((dialogKnot) => {
                const form = new Form(dialogKnot);
                form.eventSubmit = (formData) => {
                    form.lock();
                    const userData = formData.get<Objekt>('user');
                    this.userService.invite(userData).then(
                        (response) => {
                            this.flash.addMessage(response.get('message'));
                            this.dialog.close();
                        },
                        (response) => {
                            this.flash.addMessage(response.get('message'));
                            form.unlock();
                            form.setErrors(response.get('errors'));
                        },
                    );
                };

                form.eventReset = () => {
                    this.dialog.close();
                };

                this.dialog.open();
            });
    }

    private _connectionUser(user: Objekt): void {
        // manage organization_users table
    }
}

export const adminUsersIndexController = app.controller(
    resources.adminUsersIndexController,
    [
        resources.instances,
        resources.adminUserService,
        resources.languageService,
        resources.userService,
    ],
    AdminUsersIndexController,
);
