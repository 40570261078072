import {
    DateIO,
    DateTimeField,
    Deferred,
    Form,
    format,
    Instance,
    Knot,
    LocationField,
    Objekt,
    SelectField,
    TableCalculation,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { AssetService } from './assetService';
import { EntityConfig } from './entityService';
import { HuntService } from './huntService';
import { LanguageService } from './languageService';
import { LocationService } from './locationService';
import { MapService } from './mapService';
import { ModelService } from './modelService';
import { PersonService } from './personService';
import { WildService } from './wildService';

export class TrophyService extends ModelService {
    constructor(
        entityConfig: EntityConfig,
        instances: Instance,
        $languageService: LanguageService,
        $assetService: AssetService,
        private personService: PersonService,
        private huntService: HuntService,
        private wildService: WildService,
        private locationService: LocationService,
        private mapService: MapService,
    ) {
        super(entityConfig, instances, $languageService, $assetService);
    }

    getAllByOrganization(organizationId: string, opt_params?: object) {
        return this.getAllBy('organizations', organizationId, opt_params);
    }

    getAllByHunt(huntId: string, opt_params?: object) {
        return this.getAllBy('hunts', huntId, opt_params);
    }

    getAllByPlanAndPlanPrey(
        planId: string,
        planPreyId: string,
        opt_params?: object,
    ) {
        return this.http.get(
            format('/api/v1/plans/{0}/plan-preys/{1}/trophies.json', [
                planId,
                planPreyId,
            ]),
            opt_params,
        );
    }

    getAllByHuntingPackageAndHuntingPackagePrey(
        huntingPackageId: string,
        huntingPackagePreyId: string,
        opt_params: object | undefined,
    ) {
        return this.http.get(
            format(
                '/api/v1/hunting-packages/{0}/hunting-package-preys/{1}/trophies.json',
                [huntingPackageId, huntingPackagePreyId],
            ),
            opt_params,
        );
    }

    getAllByPerson(personId: string, opt_params?: object) {
        return this.getAllBy('people', personId, opt_params);
    }

    getAllByUser(userId: string, opt_params?: object) {
        return this.getAllBy('users', userId, opt_params);
    }

    createByOrganization(organizationId: string, trophy: Objekt) {
        return this.createBy('organizations', organizationId, trophy);
    }

    newDialogByHunt(huntId: string) {
        // TODO: refactor to create endpoint like this POST /hunts/:hunt_id/{small-game-trophies|big-game-trophies}.json
        // return this.newDialogBy('hunts', huntId);
        return this.newDialogBy(
            'organizations',
            'organization_id',
            format('/hunts/{0}/', [huntId]),
        );
    }

    newDialogByPlanPrey(planPreyId: string) {
        return this.newDialogBy(
            'organizations',
            'organization_id',
            format('/plan-preys/{0}/', [planPreyId]),
        );
    }

    newDialogByHuntingPackagePrey(huntingPackagePreyId: string) {
        return this.newDialogBy(
            'organizations',
            'organization_id',
            format('/hunting-package-preys/{0}/', [huntingPackagePreyId]),
        );
    }

    handleForm(form: Form, opt_entityName?: string): void {
        const entityName = opt_entityName || this.entityName;
        const organizationField = form.findByModel<SelectField>(
            format('{0}.organization_id', [entityName]),
        );
        const huntField = form.findByModel<SelectField>(
            format('{0}.hunt_id', [entityName]),
        );
        const peopleField = form.findByModel<SelectField>(
            format('{0}.person_id', [entityName]),
        );
        const wildField = form.findByModel<SelectField>(
            format('{0}.wild_id', [entityName]),
        );
        const locationField = form.findByModel<LocationField>(
            format('{0}.location', [entityName]),
        );
        const killingAtField = form.findByModel<DateTimeField>(
            format('{0}.killing_at', [entityName]),
        );

        if (killingAtField) {
            killingAtField.eventChange = (killingAt) => {
                this._handleHuntField(
                    organizationField.getValue(),
                    huntField,
                    killingAt,
                );
            };
        }

        if (organizationField) {
            organizationField.eventChange = (organizationId) => {
                if (organizationId) {
                    this.handleLocationFieldByOrganization(
                        this.mapService,
                        this.locationService,
                        organizationId,
                        locationField,
                    );
                    this._handleHuntField(
                        organizationId,
                        huntField,
                        killingAtField.getValue(),
                    );

                    peopleField.showLoader();
                    this.personService
                        .getAllByOrganization(organizationId, {
                            fields: 'id,name',
                        })
                        .then((response) => {
                            const people = response.get<Objekt[]>('people');
                            peopleField.setOptions(people, 'id', 'name');
                        });

                    wildField.showLoader();
                    this.wildService
                        .getAllByOrganization(organizationId, {
                            fields: 'id,full_name',
                        })
                        .then((response) => {
                            const wilds = response.get<Objekt[]>('wilds');
                            wildField.setOptions(wilds, 'id', 'full_name');
                        });
                } else {
                    peopleField.setOptions([]);
                    huntField.setOptions([]);
                    wildField.setOptions([]);
                }
            };
        }

        const organizationId = organizationField.getValue();
        this.handleLocationFieldByOrganization(
            this.mapService,
            this.locationService,
            organizationId,
            locationField,
        );
    }

    private _handleHuntField(
        organizationId: string,
        huntField: SelectField,
        killingAt: string,
    ): void {
        huntField.showLoader();
        this.huntService
            .getAllByOrganization(organizationId, {
                fields: 'id,title',
                start_at: DateIO.format(
                    DateIO.subDays(DateIO.parse(killingAt), 2),
                ),
                end_at: DateIO.format(
                    DateIO.addDays(DateIO.parse(killingAt), 2),
                ),
            })
            .then((response) => {
                const hunts = response.get<Objekt[]>('hunts');
                huntField.setOptions(hunts, 'id', 'title');
            });
    }

    getTableOptions(): object {
        return {
            no_content: {
                image_url: this.assetService.getPath(
                    'images/others/no-content.png',
                ),
                text: this.languageService.translate('text.no_content'),
            },
            sort: {
                column: 'killing_at',
                order: 'desc',
            },
            row_count: 12,
            column: 'full_name',
            columns: [
                'full_name',
                'quantity',
                'ear_tag.identifier',
                'person.full_name',
                'location.address',
                'killing_at',
                'judged',
                'actions',
            ],
            sorted: ['killing_at:desc'],
            calculations: this.getTableCalculations(),
        };
    }

    getTableFields(): string {
        return 'id,person.id,person.full_name,ear_tag.identifier,quantity,full_name,location,killing_at,judged,description,class_type';
    }

    getTableCalculations(): TableCalculation {
        return {
            full_name: (trophy) => {
                return this.helper.createLink(
                    trophy.get('full_name'),
                    (href) => {
                        this.state.go(href);
                    },
                    this.state.resolveUrl('trophies.show', {
                        trophyId: trophy.get('id'),
                    }),
                );
            },
            'person.full_name': (trophy) => {
                const personFullName = trophy.get<string>('person.full_name');
                return personFullName
                    ? this.helper.createLink(
                          personFullName,
                          (href) => {
                              this.state.go(href);
                          },
                          this.state.resolveUrl('people.show', {
                              personId: trophy.get('person.id'),
                          }),
                      )
                    : '';
            },
            'ear_tag.identifier': (trophy) => {
                return trophy.get('ear_tag.identifier', '');
            },
            killing_at: (trophy) => {
                const killingAt = DateIO.parse(trophy.get('killing_at'));
                return this.languageService.format(
                    killingAt,
                    'format.datetime',
                );
            },
            judged: (trophy) => {
                const judged = trophy.get('judged');
                const iconKnot = new Knot('em');
                iconKnot.addClass('material-icons');
                switch (judged) {
                    case true:
                        iconKnot.addClass('text-success');
                        iconKnot.setHtml('done');
                        break;
                    case false:
                        iconKnot.addClass('text-error');
                        iconKnot.setHtml('close');
                        break;
                    default:
                        iconKnot.addClass('text-success');
                        iconKnot.setHtml('remove');
                        break;
                }
                return iconKnot;
            },
        };
    }

    chooseTrophyDialog() {
        const deferred = new Deferred();
        this.dialog
            .loadTemplate(format('/client/v1/trophies/choose.html', []))
            .then((dialogKnot) => {
                const form = new Form(dialogKnot);
                form.eventSubmit = (formData) => {
                    this.dialog.close();
                    const type = formData.get('type');
                    deferred.resolve(type);
                };
                form.eventReset = () => {
                    this.dialog.close();
                    deferred.reject();
                };
                this.dialog.open();
            });
        this.dialog.eventCancel = () => {
            deferred.reject();
        };
        return deferred.promise();
    }
}

export const trophyService = app.service(
    resources.trophyService,
    [
        'trophy-trophies-full_name',
        resources.instances,
        resources.languageService,
        resources.assetService,
        resources.personService,
        resources.huntService,
        resources.wildService,
        resources.locationService,
        resources.mapService,
    ],
    TrophyService,
);
