import {
    Async,
    Deferred,
    eachArray,
    GoogleMap,
    Instance,
    Objekt,
    Promize,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { ChamberService } from '../services/chamberService';
import { MapService } from '../services/mapService';
import { OrganizationService } from '../services/organizationService';
import { DashboardController } from './dashboardController';

export class ChambersShowController extends DashboardController {
    chamberId: string;
    chamber: Objekt;
    organizations: Objekt[];
    map: GoogleMap;

    constructor(
        instances: Instance,
        private chamberService: ChamberService,
        private organizationService: OrganizationService,
        private mapService: MapService,
    ) {
        super(instances);
    }

    protected override _initLayout(): void {
        this.chamberId = this.state.getParam('chamberId');
        this.chamber = new Objekt();

        this._initToolbarButtons();

        this._drawContent();
    }

    private _initToolbarButtons(): void {
        this.helper.iconButton(
            '.btn-chambers-index',
            this.dom,
            () => {
                this.state.goBack('chambers.index');
            },
            '',
            true,
            [],
        );
    }

    protected override _drawContent(): Promize | boolean {
        this._initMap();
        return true;
    }

    private _loadChamber(): Promize {
        const deferred = new Deferred();
        this.chamberService
            .get(this.chamberId, {
                fields: 'id,name,country.location',
            })
            .then(
                (response) => {
                    this.chamber = response.get('chamber');
                    deferred.resolve();
                },
                () => {
                    deferred.reject();
                },
            );
        return deferred.promise();
    }

    private _loadOrganizations(): Promize {
        const deferred = new Deferred();
        this.organizationService
            .getAllByChamber(this.chamberId, {
                fields: 'id,name,areas.locations',
            })
            .then(
                (response) => {
                    this.organizations = response.get('organizations');
                    deferred.resolve();
                },
                () => {
                    deferred.reject();
                },
            );
        return deferred.promise();
    }

    private _initMap(): void {
        if (!this.map) {
            this.map = this.mapService.createGoogleMap(this.dom, '.map', {
                mapTypeId: 'custom',
                mapTypeControlOptions: {
                    mapTypeIds: ['custom', google.maps.MapTypeId.SATELLITE],
                },
                mapTypeControl: true,
                scrollwheel: true,
            });

            this.map.setPolygons(this.mapService.getAreaPolygonOptions());
        }

        const async = new Async();
        async
            .parallel([
                this._loadChamber.bind(this),
                this._loadOrganizations.bind(this),
            ])
            .then(() => {
                this._setMap();
            });
    }

    private _setMap(): void {
        eachArray(this.organizations, (organization) => {
            const areas = organization.get('areas', []);
            eachArray(areas, (area) => {
                const points = area.get('locations', []);
                this.map.createPolygon(
                    organization.get('id'),
                    organization.get('name'),
                    points,
                );
            });
        });

        this.map.setCenter(
            this.chamber.get('country.location.latitude'),
            this.chamber.get('country.location.longitude'),
        );
    }

    exit(): void {
        super.exit();

        this.mapService.removeGoogleMap(this.map);
    }
}

export const chambersShowController = app.controller(
    resources.chambersShowController,
    [
        resources.instances,
        resources.chamberService,
        resources.organizationService,
        resources.mapService,
    ],
    ChambersShowController,
);
