import { Confirm, Flash, Instance, Objekt, Table } from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { AdminOrganizationService } from '../services/adminOrganizationService';
import { LanguageService } from '../services/languageService';
import { AdminController } from './adminController';

export class AdminOrganizationsIndexController extends AdminController {
    private table: Table;
    private flash: Flash;
    private confirm: Confirm;

    constructor(
        instances: Instance,
        private adminOrganizationService: AdminOrganizationService,
        private languageService: LanguageService,
    ) {
        super(instances);

        this.flash = instances.flash;
        this.confirm = instances.confirm;
    }

    protected override _initToolbarButtons(): void {
        this.helper.iconButton(
            '.btn-admin-index',
            this.dom,
            () => {
                this.state.goBack('admin.index');
            },
            '',
            true,
            [],
        );

        this.helper.iconButton('.btn-organizations-new', this.dom, () => {
            this.adminOrganizationService.newDialogByAdmin().then(() => {
                this._drawContent();
            });
        });
    }

    protected override _initTable(): void {
        if (!this.table) {
            this.table = new Table(
                this.dom,
                'table',
                this.adminOrganizationService.getTableOptions(),
            );

            this.table.setActions([
                {
                    style: (item) => {
                        return [
                            'done',
                            this.languageService.translate('buttons.accept'),
                            false,
                            item.get('active'),
                        ];
                    },
                    click: this._acceptOrganization.bind(this),
                },
                {
                    style: (item) => {
                        return [
                            'close',
                            this.languageService.translate('buttons.decline'),
                            false,
                            item.get('active'),
                        ];
                    },
                    click: this._declineOrganization.bind(this),
                },
                /*    {
style: () => {
return ['person', this.languageService.translate('organization.owners')];
},
click: this._ownersOrganization.bind(this),
},*/
                {
                    style: () => {
                        return [
                            'edit',
                            this.languageService.translate('buttons.edit'),
                        ];
                    },
                    click: (organization) => {
                        this.adminOrganizationService
                            .editDialog(organization, '/admin/')
                            .then(() => {
                                this._drawContent();
                            });
                    },
                },
            ]);

            this.table.eventAction = (params) => {
                params.set('query', this.query);
                params.set(
                    'fields',
                    this.adminOrganizationService.getTableFields(),
                );
                this.adminOrganizationService
                    .getAll(params)
                    .then((response) => {
                        const count = response.get<number>('count');
                        this.table.setCount(count);
                        const organizations =
                            response.get<Objekt[]>('organizations');
                        this.table.setData(organizations);
                    });
            };

            this.table.render();
        } else {
            this.table.refresh();
        }
    }

    private _ownersOrganization(organization: Objekt): void {
        // empty method
    }

    private _acceptOrganization(organization: Objekt): void {
        this.confirm.load(
            this.languageService.translate(
                'organizations_controller.accept.confirm',
                {
                    organization_name: organization.get('name'),
                },
            ),
            this.languageService.translate('buttons.accept'),
            this.languageService.translate('buttons.cancel'),
            '',
            'warning',
        );
        this.confirm.eventOK = () => {
            this.adminOrganizationService.accept(organization).then(
                (response) => {
                    this.flash.addMessage(response.get('message'));
                    this.table.refresh();
                },
                (response) => {
                    this.flash.addMessage(response.get('message'));
                },
            );
        };
        this.confirm.open();
    }

    private _declineOrganization(organization: Objekt): void {
        this.confirm.load(
            this.languageService.translate(
                'organizations_controller.decline.confirm',
                {
                    organization_name: organization.get('name'),
                },
            ),
            this.languageService.translate('buttons.decline'),
            this.languageService.translate('buttons.cancel'),
            '',
            'warning',
        );
        this.confirm.eventOK = () => {
            this.adminOrganizationService.decline(organization).then(
                (response) => {
                    this.flash.addMessage(response.get('message'));
                    this.table.refresh();
                },
                (response) => {
                    this.flash.addMessage(response.get('message'));
                },
            );
        };
        this.confirm.open();
    }
}

export const adminOrganizationsIndexController = app.controller(
    resources.adminOrganizationsIndexController,
    [
        resources.instances,
        resources.adminOrganizationService,
        resources.languageService,
    ],
    AdminOrganizationsIndexController,
);
