import { Knot } from '@siposdani87/sui-js';

export const createHunterTicketIdentifierCell = (
    identifier: string,
    validity: boolean,
) => {
    const iconName = validity
        ? 'verified'
        : validity === null
          ? 'update'
          : 'block';
    const cssClass = validity
        ? 'text-success'
        : validity === null
          ? 'text-info'
          : 'text-error';

    if (identifier) {
        const knot = new Knot('span');
        knot.setHtml(identifier);

        const iconKnot = new Knot('em');
        iconKnot.addClass(['material-icons', cssClass]);
        iconKnot.setHtml(iconName);
        knot.appendChild(iconKnot);

        return knot;
    }

    return null;
};
