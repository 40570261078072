import {
    DateIO,
    format,
    Instance,
    Knot,
    TableCalculation,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { AssetService } from './assetService';
import { LanguageService } from './languageService';
import { ModelService } from './modelService';

export class ItemService extends ModelService {
    constructor(
        instances: Instance,
        $languageService: LanguageService,
        $assetService: AssetService,
    ) {
        super(
            'item-items-description',
            instances,
            $languageService,
            $assetService,
        );
    }

    getAllByBudgetAndCategory(
        budgetId: string,
        categoryId: string,
        opt_params?: object,
    ) {
        return this.http.get(
            format('/api/v1/budgets/{0}/categories/{1}/items.json', [
                budgetId,
                categoryId,
            ]),
            opt_params,
        );
    }

    getAllByPlan(planId: string, opt_params?: object) {
        return this.getAllBy('plans', planId, opt_params);
    }

    getAllByVehicle(vehicleId: string, opt_params?: object) {
        return this.getAllBy('vehicles', vehicleId, opt_params);
    }

    getAllByTrophy(trophyId: string, opt_params?: object) {
        return this.getAllBy('trophies', trophyId, opt_params);
    }

    getAllByHunt(huntId: string, opt_params?: object) {
        return this.getAllBy('hunts', huntId, opt_params);
    }

    getAllByHuntingPackage(huntingPackageId: string, opt_params?: object) {
        return this.getAllBy('hunting-packages', huntingPackageId, opt_params);
    }

    getAllByEvent(eventId: string, opt_params?: object) {
        return this.getAllBy('events', eventId, opt_params);
    }

    getAllByUser(userId: string, opt_params?: object) {
        return this.getAllBy('users', userId, opt_params);
    }

    getTableOptions(): object {
        return {
            no_content: {
                image_url: this.assetService.getPath(
                    'images/others/no-content.png',
                ),
                text: this.languageService.translate('text.no_content'),
            },
            sort: {
                column: 'completion_at',
                order: 'desc',
            },
            row_count: 12,
            columns: [
                'description',
                'amount_with_currency',
                'person_id',
                'checked',
                'completed',
                'completion_at',
                'actions',
            ],
            calculations: this.getTableCalculations(),
        };
    }

    getTableFields(): string {
        return 'id,description,amount_with_currency,person.id,person.full_name,checked,completed,completion_at';
    }

    getTableCalculations(): TableCalculation {
        return {
            person_id: (item) => {
                const personFullName = item.get<string>('person.full_name');
                return personFullName
                    ? this.helper.createLink(
                          personFullName,
                          (href) => {
                              this.state.go(href);
                          },
                          this.state.resolveUrl('people.show', {
                              personId: item.get('person.id'),
                          }),
                      )
                    : '';
            },
            checked: (item) => {
                const checked = item.get('checked');
                const iconKnot = new Knot('em');
                iconKnot.addClass(['material-icons', 'text-success']);
                iconKnot.setHtml('check_circle');
                return checked ? iconKnot : '';
            },
            completed: (item) => {
                const completed = item.get('completed');
                const iconKnot = new Knot('em');
                iconKnot.addClass(['material-icons', 'text-success']);
                iconKnot.setHtml('check_circle');
                return completed ? iconKnot : '';
            },
            completion_at: (item) => {
                const completionAt = DateIO.parse(item.get('completion_at'));
                return this.languageService.format(
                    completionAt,
                    'format.datetime',
                );
            },
        };
    }

    newDialogByBudget(budgetId: string) {
        return this.newDialogBy('budgets', budgetId);
    }

    newDialogByPlan(planId: string) {
        return this.newDialogBy(
            'budgets',
            'budget_id',
            format('/plans/{0}/', [planId]),
        );
    }

    newDialogByEvent(eventId: string) {
        return this.newDialogBy(
            'budgets',
            'budget_id',
            format('/events/{0}/', [eventId]),
        );
    }

    newDialogByHunt(huntId: string) {
        return this.newDialogBy(
            'budgets',
            'budget_id',
            format('/hunts/{0}/', [huntId]),
        );
    }

    newDialogByTrophy(trophyId: string) {
        return this.newDialogBy(
            'budgets',
            'budget_id',
            format('/trophies/{0}/', [trophyId]),
        );
    }

    newDialogByHuntingPackage(huntingPackageId: string) {
        return this.newDialogBy(
            'budgets',
            'budget_id',
            format('/hunting-packages/{0}/', [huntingPackageId]),
        );
    }

    newDialogByVehicle(vehicleId: string) {
        return this.newDialogBy(
            'budgets',
            'budget_id',
            format('/vehicles/{0}/', [vehicleId]),
        );
    }
}

export const itemService = app.service(
    resources.itemService,
    [resources.instances, resources.languageService, resources.assetService],
    ItemService,
);
