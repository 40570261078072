import { Deferred, Http, Instance, Objekt, Service } from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';

export class ConfigService extends Service {
    http: Http;
    config: Objekt;

    constructor(instances: Instance) {
        super();

        this.http = instances.http;
        this.config = instances.config;
    }

    enter() {
        return this._loadConfiguration();
    }

    private _loadConfiguration() {
        const deferred = new Deferred();
        this.http.get('/api/configurations.json').then(
            (data) => {
                this.config.merge(data);
                deferred.resolve();
            },
            () => {
                deferred.reject();
            },
        );
        return deferred.promise();
    }

    get<T>(
        opt_attribute: string | undefined,
        opt_defaultValue?: any,
        opt_isSafe: boolean | undefined = false,
    ): T {
        return this.config.get<T>(opt_attribute, opt_defaultValue, opt_isSafe);
    }

    set(attribute: string, value: any): void {
        return this.config.set(attribute, value);
    }

    getLocale(): string {
        return this.config.locale;
    }

    isProduction(): boolean {
        return this.config.production;
    }
}

export const configService = app.service(
    resources.configService,
    [resources.instances],
    ConfigService,
);
