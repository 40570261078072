import {
    Form,
    format,
    Instance,
    Objekt,
    TableCalculation,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { AssetService } from './assetService';
import { LanguageService } from './languageService';
import { PreyService } from './preyService';

export class PlanPreyService extends PreyService {
    constructor(
        instances: Instance,
        $languageService: LanguageService,
        $assetService: AssetService,
    ) {
        super(
            'plan_prey-plan_preys-full_name',
            instances,
            $languageService,
            $assetService,
        );
    }

    getAllByPlan(planId: string, opt_params?: object) {
        return this.getAllBy('plans', planId, opt_params);
    }

    getAllByPlanAndClassification(
        planId: string,
        classification: string,
        opt_params?: object,
    ) {
        return this.http.get(
            format('/api/v1/plans/{0}/plan-preys.json', [planId]),
            new Objekt({
                classification: classification,
            }).merge(opt_params),
        );
    }

    getByPlan(planId: string, planPreyId: string, opt_params?: object) {
        return this.http.get(
            format('/api/v1/plans/{0}/plan-preys/{1}.json', [
                planId,
                planPreyId,
            ]),
            opt_params,
        );
    }

    createByPlan(planId: string, planPrey: Objekt) {
        return this.createBy('plans', planId, planPrey);
    }

    newDialogByPlan(planId: string) {
        return this.newDialogBy('plans', planId);
    }

    handleForm(form: Form, opt_entityName?: string): void {
        const nameFieldSetRequired = function (wildIds) {
            if (wildIds.length > 1) {
                nameField.setRequired(true);
            } else {
                nameField.setRequired(false);
            }
        };

        const nameField = form.findByModel('plan_prey.name');
        const wildField = form.findByModel('plan_prey.wild_ids');

        if (wildField) {
            wildField.eventChange = (wildIds) => {
                nameFieldSetRequired(wildIds);
            };
            const wildIds = wildField.getValue();
            nameFieldSetRequired(wildIds);
        }
    }

    getTableFields(): string {
        return 'id,full_name,min_age,max_age,count,hunted_count,plan_id,wilds.full_name,class_type';
    }

    getTableCalculations(): TableCalculation {
        return {
            full_name: (planPrey) => {
                return this.helper.createLink(
                    planPrey.get('full_name'),
                    (href) => {
                        this.state.go(href);
                    },
                    this.state.resolveUrl('plan_preys.show', {
                        planId: planPrey.get('plan_id'),
                        planPreyId: planPrey.get('id'),
                    }),
                );
            },
            wilds: (planPrey) => {
                const wilds = planPrey.get('wilds', []);
                return wilds
                    .map((wild) => {
                        return wild.get('full_name');
                    })
                    .join(', ');
            },
        };
    }
}

export const planPreyService = app.service(
    resources.planPreyService,
    [resources.instances, resources.languageService, resources.assetService],
    PlanPreyService,
);
