import { Route } from '@siposdani87/sui-js';
import { adminChambersIndexController } from './controllers/adminChambersIndexController';
import { adminCountriesIndexController } from './controllers/adminCountriesIndexController';
import { adminCouponsIndexController } from './controllers/adminCouponsIndexController';
import { adminGamesIndexController } from './controllers/adminGamesIndexController';
import { adminIndexController } from './controllers/adminIndexController';
import { adminOpenSeasonsIndexController } from './controllers/adminOpenSeasonsIndexController';
import { adminOrdersIndexController } from './controllers/adminOrdersIndexController';
import { adminOrganizationsIndexController } from './controllers/adminOrganizationsIndexController';
import { adminSponsorsIndexController } from './controllers/adminSponsorsIndexController';
import { adminSubscribersIndexController } from './controllers/adminSubscribersIndexController';
import { adminUsersIndexController } from './controllers/adminUsersIndexController';
import { adminWildsIndexController } from './controllers/adminWildsIndexController';
import { areasIndexController } from './controllers/areasIndexController';
import { budgetsIndexController } from './controllers/budgetsIndexController';
import { budgetsShowController } from './controllers/budgetsShowController';
import { buildingsIndexController } from './controllers/buildingsIndexController';
import { buildingsShowController } from './controllers/buildingsShowController';
import { categoriesIndexController } from './controllers/categoriesIndexController';
import { chambersIndexController } from './controllers/chambersIndexController';
import { chambersShowController } from './controllers/chambersShowController';
import { discoveriesIndexController } from './controllers/discoveriesIndexController';
import { discoveriesShowController } from './controllers/discoveriesShowController';
import { districtsIndexController } from './controllers/districtsIndexController';
import { districtsShowController } from './controllers/districtsShowController';
import { eventsIndexController } from './controllers/eventsIndexController';
import { eventsShowController } from './controllers/eventsShowController';
import { featuresIndexController } from './controllers/featuresIndexController';
import { groupsIndexController } from './controllers/groupsIndexController';
import { groupsShowController } from './controllers/groupsShowController';
import { guestsIndexController } from './controllers/guestsIndexController';
import { huntingPackagePreysShowController } from './controllers/huntingPackagePreysShowController';
import { huntingPackagesIndexController } from './controllers/huntingPackagesIndexController';
import { huntingPackagesShowController } from './controllers/huntingPackagesShowController';
import { huntsIndexByUserController } from './controllers/huntsIndexByUserController';
import { huntsIndexController } from './controllers/huntsIndexController';
import { huntsShowController } from './controllers/huntsShowController';
import { itemsIndexByUserController } from './controllers/itemsIndexByUserController';
import { itemsIndexController } from './controllers/itemsIndexController';
import { licensesIndexByOrganizationController } from './controllers/licensesIndexByOrganizationController';
import { membersIndexController } from './controllers/membersIndexController';
import { membershipsIndexController } from './controllers/membershipsIndexController';
import { notesIndexController } from './controllers/notesIndexController';
import { notesShowController } from './controllers/notesShowController';
import { observationsIndexByOrganizationController } from './controllers/observationsIndexByOrganizationController';
import { observationsIndexByUserController } from './controllers/observationsIndexByUserController';
import { observationsShowController } from './controllers/observationsShowController';
import { ordersExecuteController } from './controllers/ordersExecuteController';
import { ordersNewController } from './controllers/ordersNewController';
import { ordersRejectController } from './controllers/ordersRejectController';
import { organizationUsersIndexController } from './controllers/organizationUsersIndexController';
import { organizationsConnectController } from './controllers/organizationsConnectController';
import { organizationsIndexController } from './controllers/organizationsIndexController';
import { organizationsRolesController } from './controllers/organizationsRolesController';
import { organizationsShowController } from './controllers/organizationsShowController';
import { pagesShowController } from './controllers/pagesShowController';
import { peopleIndexController } from './controllers/peopleIndexController';
import { peopleShowController } from './controllers/peopleShowController';
import { planPreysShowController } from './controllers/planPreysShowController';
import { plansIndexController } from './controllers/plansIndexController';
import { plansShowController } from './controllers/plansShowController';
import { reportsBuildingsHowMuchTheyHuntController } from './controllers/reportsBuildingsHowMuchTheyHuntController';
import { reportsBuildingsWhatTheyShootController } from './controllers/reportsBuildingsWhatTheyShootController';
import { reportsBuildingsWhenTheyHuntController } from './controllers/reportsBuildingsWhenTheyHuntController';
import { reportsBuildingsWhenTheyShootController } from './controllers/reportsBuildingsWhenTheyShootController';
import { reportsBuildingsWhoTheyHuntController } from './controllers/reportsBuildingsWhoTheyHuntController';
import { reportsBuildingsWhoTheyShootController } from './controllers/reportsBuildingsWhoTheyShootController';
import { reportsIndexController } from './controllers/reportsIndexController';
import { reportsPeopleHowMuchHuntController } from './controllers/reportsPeopleHowMuchHuntController';
import { reportsPeopleWhatShootController } from './controllers/reportsPeopleWhatShootController';
import { reportsPeopleWhenHuntController } from './controllers/reportsPeopleWhenHuntController';
import { reportsPeopleWhenShootController } from './controllers/reportsPeopleWhenShootController';
import { reportsPeopleWhereHuntController } from './controllers/reportsPeopleWhereHuntController';
import { reportsPeopleWhereShootController } from './controllers/reportsPeopleWhereShootController';
import { reportsTrophiesWhenTheyShootController } from './controllers/reportsTrophiesWhenTheyShootController';
import { reportsTrophiesWhereTheyShootController } from './controllers/reportsTrophiesWhereTheyShootController';
import { reportsTrophiesWhoTheyShootController } from './controllers/reportsTrophiesWhoTheyShootController';
import { rewardsIndexController } from './controllers/rewardsIndexController';
import { siteChangesController } from './controllers/siteChangesController';
import { siteHomeController } from './controllers/siteHomeController';
import { siteLandingPageController } from './controllers/siteLandingPageController';
import { siteMaintenanceController } from './controllers/siteMaintenanceController';
import { sitePoliciesController } from './controllers/sitePoliciesController';
import { subscribersConfirmController } from './controllers/subscribersConfirmController';
import { trophiesIndexByUserController } from './controllers/trophiesIndexByUserController';
import { trophiesIndexController } from './controllers/trophiesIndexController';
import { trophiesShowController } from './controllers/trophiesShowController';
import { usersActivationController } from './controllers/usersActivationController';
import { usersChangePasswordController } from './controllers/usersChangePasswordController';
import { usersHomeController } from './controllers/usersHomeController';
import { usersIndexController } from './controllers/usersIndexController';
import { usersLoginController } from './controllers/usersLoginController';
import { usersProfileController } from './controllers/usersProfileController';
import { usersRecoveryPasswordController } from './controllers/usersRecoveryPasswordController';
import { usersRegistrationController } from './controllers/usersRegistrationController';
import { vehiclesIndexController } from './controllers/vehiclesIndexController';
import { vehiclesShowController } from './controllers/vehiclesShowController';

export const routes: Route[] = [
    new Route(
        'site.maintenance',
        'application.name',
        '/maintenance',
        siteMaintenanceController,
        '/client/v1/site/maintenance.html',
        { public: true },
    ),
    new Route(
        'site.landing-page',
        'captions.site.landing_page',
        '/site/landing-page',
        siteLandingPageController,
        '/client/v1/site/landing-page.html',
        { public: true },
    ),
    new Route(
        'site.policies',
        'captions.site.policies',
        '/site/policies/:tab',
        sitePoliciesController,
        '/client/v1/site/policies.html',
        { public: true },
    ),
    new Route(
        'site.policies',
        'captions.site.policies',
        '/site/policies',
        sitePoliciesController,
        '/client/v1/site/policies.html',
        { public: true },
    ),
    new Route(
        'site.changes',
        'captions.site.changes',
        '/site/changes',
        siteChangesController,
        '/client/v1/site/changes.html',
        { public: true },
    ),
    new Route(
        'site.home',
        'captions.site.home',
        '/site/home',
        siteHomeController,
        '/client/v1/site/home.html',
    ),
    new Route(
        'admin.index',
        'captions.admin.index',
        '/admin/index',
        adminIndexController,
        '/client/v1/admin/index.html',
    ),
    new Route(
        'admin.users.index',
        'captions.users.index',
        '/admin/users',
        adminUsersIndexController,
        '/client/v1/admin/users.html',
    ),
    new Route(
        'admin.organizations.index',
        'captions.organizations.index',
        '/admin/organizations',
        adminOrganizationsIndexController,
        '/client/v1/admin/organizations.html',
    ),
    new Route(
        'admin.chambers.index',
        'captions.chambers.index',
        '/admin/chambers',
        adminChambersIndexController,
        '/client/v1/admin/chambers.html',
    ),
    new Route(
        'admin.wilds.index',
        'captions.wilds.index',
        '/admin/wilds',
        adminWildsIndexController,
        '/client/v1/admin/wilds.html',
    ),
    new Route(
        'admin.open_seasons.index',
        'captions.open_seasons.index',
        '/admin/opens-seasons',
        adminOpenSeasonsIndexController,
        '/client/v1/admin/open-seasons.html',
    ),
    new Route(
        'admin.games.index',
        'captions.games.index',
        '/admin/opens-seasons/:openSeasonId/games',
        adminGamesIndexController,
        '/client/v1/admin/open-seasons/:openSeasonId/games.html',
    ),
    new Route(
        'admin.countries.index',
        'captions.countries.index',
        '/admin/countries',
        adminCountriesIndexController,
        '/client/v1/admin/countries.html',
    ),
    new Route(
        'admin.orders.index',
        'captions.orders.index',
        '/admin/orders',
        adminOrdersIndexController,
        '/client/v1/admin/orders.html',
    ),
    new Route(
        'admin.coupons.index',
        'captions.coupons.index',
        '/admin/coupons',
        adminCouponsIndexController,
        '/client/v1/admin/coupons.html',
    ),
    new Route(
        'admin.sponsors.index',
        'captions.sponsors.index',
        '/admin/sponsors',
        adminSponsorsIndexController,
        '/client/v1/admin/sponsors.html',
    ),
    new Route(
        'admin.subscribers.index',
        'captions.subscribers.index',
        '/admin/subscribers',
        adminSubscribersIndexController,
        '/client/v1/admin/subscribers.html',
    ),
    new Route(
        'users.login',
        'captions.users.login',
        '/users/login',
        usersLoginController,
        '/client/v1/users/login.html',
        { public: true },
    ),
    new Route(
        'users.registration',
        'captions.users.registration',
        '/users/registration',
        usersRegistrationController,
        '/client/v1/users/registration.html',
        { public: true },
    ),
    new Route(
        'users.recovery-password',
        'captions.users.recovery_password',
        '/users/recovery-password',
        usersRecoveryPasswordController,
        '/client/v1/users/recovery-password.html',
        { public: true },
    ),
    new Route(
        'users.change-password',
        'captions.users.change_password',
        '/users/change-password',
        usersChangePasswordController,
        '/client/v1/users/change-password.html',
        { public: true },
    ),
    new Route(
        'users.activation',
        'captions.users.activation',
        '/users/activation',
        usersActivationController,
        '',
        { public: true },
    ),
    new Route(
        'users.home',
        'captions.users.home',
        '/users/home',
        usersHomeController,
        '/client/v1/users/home.html',
    ),
    new Route(
        'users.profile',
        'captions.users.profile',
        '/users/profile',
        usersProfileController,
        '/client/v1/users/profile.html',
    ),
    new Route(
        'observations.index-by-user',
        'captions.observations.my_index',
        '/users/:userId/observations',
        observationsIndexByUserController,
        '/client/v1/users/:userId/observations.html',
    ),
    new Route(
        'observations.index-by-organization',
        'captions.observations.index',
        '/organizations/:organizationId/observations',
        observationsIndexByOrganizationController,
        '/client/v1/organizations/:organizationId/observations.html',
    ),
    new Route(
        'observations.show',
        'captions.observations.show',
        '/observations/:observationId',
        observationsShowController,
        '/client/v1/observations/:observationId.html',
    ),
    new Route(
        'memberships.index',
        'captions.memberships.my_index',
        '/users/:userId/memberships',
        membershipsIndexController,
        '/client/v1/users/:userId/memberships.html',
    ),
    new Route(
        'users.index',
        'captions.users.index',
        '/users',
        usersIndexController,
        '/client/v1/users.html',
    ),
    new Route(
        'organization-users.index',
        'captions.organization_users.index',
        '/organizations/:organizationId/users',
        organizationUsersIndexController,
        '/client/v1/organizations/:organizationId/users.html',
    ),
    new Route(
        'organizations.index',
        'captions.organizations.index',
        '/organizations',
        organizationsIndexController,
        '/client/v1/organizations.html',
    ),
    new Route(
        'organizations.show',
        'captions.organizations.show',
        '/organizations/:organizationId',
        organizationsShowController,
        '/client/v1/organizations/:organizationId.html',
    ),
    new Route(
        'organizations.connect',
        'captions.organizations.connect',
        '/organizations/:organizationId/connect',
        organizationsConnectController,
        '/client/v1/organizations/:organizationId/connect-via-qr-code.html',
    ),
    new Route(
        'organizations.roles',
        'captions.organizations.roles',
        '/organizations/:organizationId/roles',
        organizationsRolesController,
        '/client/v1/organizations/:organizationId/roles.html',
    ),
    new Route(
        'chambers.index',
        'captions.chambers.index',
        '/chambers',
        chambersIndexController,
        '/client/v1/chambers.html',
    ),
    new Route(
        'chambers.show',
        'captions.chambers.show',
        '/chambers/:chamberId',
        chambersShowController,
        '/client/v1/chambers/:chamberId.html',
    ),
    new Route(
        'areas.index',
        'captions.areas.show',
        '/organizations/:organizationId/areas',
        areasIndexController,
        '/client/v1/organizations/:organizationId/areas.html',
    ),
    new Route(
        'buildings.index',
        'captions.buildings.index',
        '/organizations/:organizationId/buildings',
        buildingsIndexController,
        '/client/v1/organizations/:organizationId/buildings.html',
    ),
    new Route(
        'buildings.show',
        'captions.buildings.show',
        '/buildings/:buildingId',
        buildingsShowController,
        '/client/v1/buildings/:buildingId.html',
    ),
    new Route(
        'districts.index',
        'captions.districts.index',
        '/organizations/:organizationId/districts',
        districtsIndexController,
        '/client/v1/organizations/:organizationId/districts.html',
    ),
    new Route(
        'districts.show',
        'captions.districts.show',
        '/districts/:districtId',
        districtsShowController,
        '/client/v1/districts/:districtId.html',
    ),
    new Route(
        'members.index',
        'captions.members.index',
        '/organizations/:organizationId/members',
        membersIndexController,
        '/client/v1/organizations/:organizationId/members.html',
    ),
    new Route(
        'guests.index',
        'captions.guests.index',
        '/organizations/:organizationId/guests',
        guestsIndexController,
        '/client/v1/organizations/:organizationId/guests.html',
    ),
    new Route(
        'people.index',
        'captions.people.index',
        '/organizations/:organizationId/people',
        peopleIndexController,
        '/client/v1/organizations/:organizationId/people.html',
    ),
    new Route(
        'people.show',
        'captions.people.show',
        '/people/:personId',
        peopleShowController,
        '/client/v1/people/:personId.html',
    ),
    new Route(
        'groups.index',
        'captions.groups.index',
        '/organizations/:organizationId/groups',
        groupsIndexController,
        '/client/v1/organizations/:organizationId/groups.html',
    ),
    new Route(
        'groups.show',
        'captions.groups.show',
        '/groups/:groupId',
        groupsShowController,
        '/client/v1/groups/:groupId.html',
    ),
    new Route(
        'features.index',
        'captions.features.index',
        '/organizations/:organizationId/features',
        featuresIndexController,
        '/client/v1/organizations/:organizationId/features.html',
    ),
    new Route(
        'rewards.index',
        'captions.rewards.index',
        '/organizations/:organizationId/rewards',
        rewardsIndexController,
        '/client/v1/organizations/:organizationId/rewards.html',
    ),
    new Route(
        'hunting_packages.index',
        'captions.hunting_packages.index',
        '/hunting-packages',
        huntingPackagesIndexController,
        '/client/v1/hunting-packages.html',
    ),
    new Route(
        'hunting_packages.show',
        'captions.hunting_packages.show',
        '/hunting-packages/:huntingPackageId',
        huntingPackagesShowController,
        '/client/v1/hunting-packages/:huntingPackageId.html',
    ),
    new Route(
        'events.index',
        'captions.events.index',
        '/events',
        eventsIndexController,
        '/client/v1/events.html',
    ),
    new Route(
        'events.show',
        'captions.events.show',
        '/events/:eventId',
        eventsShowController,
        '/client/v1/events/:eventId.html',
    ),
    new Route(
        'budgets.index',
        'captions.budgets.index',
        '/budgets',
        budgetsIndexController,
        '/client/v1/budgets.html',
    ),
    new Route(
        'budgets.show',
        'captions.budgets.show',
        '/budgets/:budgetId',
        budgetsShowController,
        '/client/v1/budgets/:budgetId.html',
    ),
    new Route(
        'items.index',
        'captions.items.index',
        '/budgets/:budgetId/categories/:categoryId/items',
        itemsIndexController,
        '/client/v1/budgets/:budgetId/categories/:categoryId/items.html',
    ),
    new Route(
        'items.index-by-user',
        'captions.items.my_index',
        '/users/:userId/items',
        itemsIndexByUserController,
        '/client/v1/users/:userId/items.html',
    ),
    new Route(
        'categories.index',
        'captions.categories.index',
        '/organizations/:organizationId/categories',
        categoriesIndexController,
        '/client/v1/organizations/:organizationId/categories.html',
    ),
    new Route(
        'vehicles.index',
        'captions.vehicles.index',
        '/vehicles',
        vehiclesIndexController,
        '/client/v1/vehicles.html',
    ),
    new Route(
        'vehicles.show',
        'captions.vehicles.show',
        '/vehicles/:vehicleId',
        vehiclesShowController,
        '/client/v1/vehicles/:vehicleId.html',
    ),
    new Route(
        'reports.index',
        'captions.reports.index',
        '/reports',
        reportsIndexController,
        '/client/v1/reports.html',
    ),
    new Route(
        'reports.people.WHAT_SHOOT',
        'captions.reports.show',
        '/reports/people/what-shoot',
        reportsPeopleWhatShootController,
        '/client/v1/reports/people/what-shoot.html',
    ),
    new Route(
        'reports.people.WHERE_SHOOT',
        'captions.reports.show',
        '/reports/people/where-shoot',
        reportsPeopleWhereShootController,
        '/client/v1/reports/people/where-shoot.html',
    ),
    new Route(
        'reports.people.WHEN_SHOOT',
        'captions.reports.show',
        '/reports/people/when-shoot',
        reportsPeopleWhenShootController,
        '/client/v1/reports/people/when-shoot.html',
    ),
    new Route(
        'reports.people.WHERE_HUNT',
        'captions.reports.show',
        '/reports/people/where-hunt',
        reportsPeopleWhereHuntController,
        '/client/v1/reports/people/where-hunt.html',
    ),
    new Route(
        'reports.people.HOW_MUCH_HUNT',
        'captions.reports.show',
        '/reports/people/how-much-hunt',
        reportsPeopleHowMuchHuntController,
        '/client/v1/reports/people/how-much-hunt.html',
    ),
    new Route(
        'reports.people.WHEN_HUNT',
        'captions.reports.show',
        '/reports/people/when-hunt',
        reportsPeopleWhenHuntController,
        '/client/v1/reports/people/when-hunt.html',
    ),
    new Route(
        'reports.buildings.WHAT_THEY_SHOOT',
        'captions.reports.show',
        '/reports/buildings/what-they-shoot',
        reportsBuildingsWhatTheyShootController,
        '/client/v1/reports/buildings/what-they-shoot.html',
    ),
    new Route(
        'reports.buildings.WHEN_THEY_SHOOT',
        'captions.reports.show',
        '/reports/buildings/when-they-shoot',
        reportsBuildingsWhenTheyShootController,
        '/client/v1/reports/buildings/when-they-shoot.html',
    ),
    new Route(
        'reports.buildings.WHO_THEY_SHOOT',
        'captions.reports.show',
        '/reports/buildings/who-they-shoot',
        reportsBuildingsWhoTheyShootController,
        '/client/v1/reports/buildings/who-they-shoot.html',
    ),
    new Route(
        'reports.buildings.WHO_THEY_HUNT',
        'captions.reports.show',
        '/reports/buildings/who-they-hunt',
        reportsBuildingsWhoTheyHuntController,
        '/client/v1/reports/buildings/who-they-hunt.html',
    ),
    new Route(
        'reports.buildings.HOW_MUCH_THEY_HUNT',
        'captions.reports.show',
        '/reports/buildings/how-much-they-hunt',
        reportsBuildingsHowMuchTheyHuntController,
        '/client/v1/reports/buildings/how-much-they-hunt.html',
    ),
    new Route(
        'reports.buildings.WHEN_THEY_HUNT',
        'captions.reports.show',
        '/reports/buildings/when-they-hunt',
        reportsBuildingsWhenTheyHuntController,
        '/client/v1/reports/buildings/when-they-hunt.html',
    ),
    new Route(
        'reports.trophies.WHERE_THEY_SHOOT',
        'captions.reports.show',
        '/reports/trophies/where-they-shoot',
        reportsTrophiesWhereTheyShootController,
        '/client/v1/reports/trophies/where-they-shoot.html',
    ),
    new Route(
        'reports.trophies.WHEN_THEY_SHOOT',
        'captions.reports.show',
        '/reports/trophies/when-they-shoot',
        reportsTrophiesWhenTheyShootController,
        '/client/v1/reports/trophies/when-they-shoot.html',
    ),
    new Route(
        'reports.trophies.WHO_THEY_SHOOT',
        'captions.reports.show',
        '/reports/trophies/who-they-shoot',
        reportsTrophiesWhoTheyShootController,
        '/client/v1/reports/trophies/who-they-shoot.html',
    ),
    new Route(
        'plans.index',
        'captions.plans.index',
        '/plans',
        plansIndexController,
        '/client/v1/plans.html',
    ),
    new Route(
        'plans.show',
        'captions.plans.show',
        '/plans/:planId',
        plansShowController,
        '/client/v1/plans/:planId.html',
    ),
    new Route(
        'plan_preys.show',
        'captions.plan_preys.show',
        '/plans/:planId/plan-preys/:planPreyId',
        planPreysShowController,
        '/client/v1/plans/:planId/plan-preys/:planPreyId.html',
    ),
    new Route(
        'hunting_package_preys.show',
        'captions.hunting_package_preys.show',
        '/hunting-packages/:huntingPackageId/hunting-package-preys/:huntingPackagePreyId',
        huntingPackagePreysShowController,
        '/client/v1/hunting-packages/:huntingPackageId/hunting-package-preys/:huntingPackagePreyId.html',
    ),
    new Route(
        'trophies.index',
        'captions.trophies.index',
        '/trophies',
        trophiesIndexController,
        '/client/v1/trophies.html',
    ),
    new Route(
        'trophies.show',
        'captions.trophies.show',
        '/trophies/:trophyId',
        trophiesShowController,
        '/client/v1/trophies/:trophyId.html',
    ),
    new Route(
        'trophies.index-by-user',
        'captions.trophies.my_index',
        '/users/:userId/trophies',
        trophiesIndexByUserController,
        '/client/v1/users/:userId/trophies.html',
    ),
    new Route(
        'discoveries.index',
        'captions.discoveries.index',
        '/discoveries',
        discoveriesIndexController,
        '/client/v1/discoveries.html',
    ),
    new Route(
        'discoveries.show',
        'captions.discoveries.show',
        '/discoveries/:discoveryId',
        discoveriesShowController,
        '/client/v1/discoveries/:discoveryId.html',
    ),
    new Route(
        'hunts.index',
        'captions.hunts.index',
        '/hunts',
        huntsIndexController,
        '/client/v1/hunts.html',
    ),
    new Route(
        'hunts.show',
        'captions.hunts.show',
        '/hunts/:huntId',
        huntsShowController,
        '/client/v1/hunts/:huntId.html',
    ),
    new Route(
        'hunts.index-by-user',
        'captions.hunts.my_index',
        '/users/:userId/hunts',
        huntsIndexByUserController,
        '/client/v1/users/:userId/hunts.html',
    ),
    new Route(
        'orders.new',
        'captions.orders.new',
        '/orders/new',
        ordersNewController,
        '/client/v1/orders/new.html',
    ),
    new Route(
        'orders.execute',
        'captions.orders.execute',
        '/orders/execute',
        ordersExecuteController,
        '/client/v1/orders/execute.html',
    ),
    new Route(
        'orders.reject',
        'captions.orders.reject',
        '/orders/reject',
        ordersRejectController,
        '/client/v1/orders/reject.html',
    ),
    new Route(
        'licenses.index-by-organization',
        'captions.licenses.index',
        '/organizations/:organizationId/licenses',
        licensesIndexByOrganizationController,
        '/client/v1/organizations/:organizationId/licenses.html',
    ),
    new Route(
        'pages.show',
        'captions.pages.show',
        '/pages/:organizationId',
        pagesShowController,
        '/client/v1/organizations/:organizationId/pages.html',
        { public: true },
    ),
    new Route(
        'notes.index',
        'captions.notes.index',
        '/notes',
        notesIndexController,
        '/client/v1/notes.html',
    ),
    new Route(
        'notes.show',
        'captions.notes.show',
        '/notes/:noteId',
        notesShowController,
        '/client/v1/notes/:noteId.html',
    ),
    new Route(
        'subscribers.confirm',
        'captions.subscribers.confirm',
        '/subscribers/confirm',
        subscribersConfirmController,
        '',
        { public: true },
    ),
];
