import { app } from '../app';
import { resources } from '../resources';

import { Controller, Flash, Instance, State } from '@siposdani87/sui-js';
import { SubscriberService } from '../services/subscriberService';

export class SubscribersConfirmController extends Controller {
    private flash: Flash;
    private state: State;

    constructor(
        instances: Instance,
        private subscriberService: SubscriberService,
    ) {
        super();

        this.flash = instances.flash;
        this.state = instances.state;
    }

    enter(): void {
        this._initLayout();
    }

    protected _initLayout(): void {
        const code = this.state.getParam('code');
        this.subscriberService.confirmation(code).then(
            (response) => {
                this.flash.addMessage(response.get('message'));
            },
            (response) => {
                this.flash.addMessage(response.get('message'));
            },
        );
        this.state.go('site.home');
    }
}

export const subscribersConfirmController = app.controller(
    resources.subscribersConfirmController,
    [resources.instances, resources.subscriberService],
    SubscribersConfirmController,
);
