import {
    BottomMenu,
    EventBus,
    Instance,
    Navigation,
    Page,
    Service,
    State,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { LanguageService } from './languageService';

export class FooterService extends Service {
    eventBus: EventBus;
    bottomMenu: BottomMenu;
    state: State;
    page: Page;
    footerNavigation: Navigation;

    constructor(
        instances: Instance,
        private languageService: LanguageService,
    ) {
        super();

        this.eventBus = instances.eventBus;
        this.bottomMenu = instances.bottomMenu;
        this.state = instances.state;
        this.page = instances.page;

        this.footerNavigation = new Navigation();
    }

    enter() {
        this.eventBus.set('state.change', (state) => {
            this.footerNavigation.setActive(state.get('id'));
        });

        this._setFooterNavigation();
    }

    private _setFooterNavigation() {
        this.footerNavigation.addText(
            'site.landing-page',
            this.languageService.translate('captions.site.landing_page'),
            (href) => {
                this.state.go(href);
            },
            this.state.resolveUrl('site.landing-page'),
        );

        this.footerNavigation.addText(
            'site.policies',
            this.languageService.translate('captions.site.policies'),
            (href) => {
                this.state.go(href);
            },
            this.state.resolveUrl('site.policies', {
                tab: 'terms-of-service',
            }),
        );

        this.footerNavigation.addText(
            'feedback',
            this.languageService.translate('captions.site.feedback'),
            () => {
                const email =
                    this.languageService.translate('application.email');
                const subject = this.languageService.translate(
                    'captions.site.feedback',
                );
                this.page.mailTo(email, subject);
            },
        );

        this.footerNavigation.bindToContainer(this.bottomMenu.getContainer());
    }
}

export const footerService = app.service(
    resources.footerService,
    [resources.instances, resources.languageService],
    FooterService,
);
