import {
    DateIO,
    Instance,
    Objekt,
    TableCalculation,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { AdminService } from './adminService';
import { AssetService } from './assetService';
import { LanguageService } from './languageService';

export class AdminGameService extends AdminService {
    constructor(
        instances: Instance,
        $languageService: LanguageService,
        $assetService: AssetService,
    ) {
        super(
            'game-games-full_name',
            instances,
            $languageService,
            $assetService,
        );
    }

    getAllByOpenSeason(openSeasonId: string, opt_params?: object) {
        return this.getAllBy('open-seasons', openSeasonId, opt_params);
    }

    createByOpenSeason(openSeasonId: string, game: Objekt) {
        return this.createBy('open-seasons', openSeasonId, game);
    }

    getTableOptions(): object {
        return {
            no_content: {
                image_url: this.assetService.getPath(
                    'images/others/no-content.png',
                ),
                text: this.languageService.translate('text.no_content'),
            },
            sort: {
                column: 'start_at',
                order: 'asc',
            },
            row_count: 12,
            columns: ['full_name', 'start_at', 'end_at', 'actions'],
            calculations: this.getTableCalculations(),
        };
    }

    getTableFields(): string {
        return ['id', 'full_name', 'start_at', 'end_at'].join(',');
    }

    getTableCalculations(): TableCalculation {
        return {
            start_at: (game) => {
                const startAt = DateIO.parse(game.get('start_at'));
                return this.languageService.format(startAt, 'format.date');
            },
            end_at: (game) => {
                const endAt = DateIO.parse(game.get('end_at'));
                return this.languageService.format(endAt, 'format.date');
            },
        };
    }

    newDialogByOpenSeason(
        openSeasonId: string,
        opt_data: (Objekt | null) | undefined = null,
    ) {
        return this.newDialogBy(
            'open-seasons',
            openSeasonId,
            `/admin/open-seasons/${openSeasonId}/`,
            opt_data,
        );
    }
}

export const adminGameService = app.service(
    resources.adminGameService,
    [resources.instances, resources.languageService, resources.assetService],
    AdminGameService,
);
