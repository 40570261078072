import { Controller, Form, Instance, Knot, State } from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';

import { ConfigService } from '../services/configService';
import { MilitaryService } from '../services/militaryService';
import { SubscriberService } from '../services/subscriberService';

export class SiteMaintenanceController extends Controller {
    private dom: Knot;
    private state: State;

    constructor(
        instances: Instance,
        private configService: ConfigService,
        private subscriberService: SubscriberService,
        private militaryService: MilitaryService,
    ) {
        super();

        this.dom = instances.dom;
        this.state = instances.state;
    }

    enter(): void {
        this._initLayout();

        this.militaryService.draw(
            this.dom,
            ['#f1f2ea', '#f9fafe', '#fbfeff', '#c6c6c6', '#dad8d8', '#e2e2e2'],
            0.1,
        );
    }

    protected _initLayout(): void {
        if (
            this.configService.get('api.maintenance') ||
            !this.configService.isProduction()
        ) {
            this._initSubscriberForm();
        } else {
            this.state.goRoot();
        }
    }

    private _initSubscriberForm(): void {
        const form = new Form(this.dom);
        form.eventButton = () => {
            this.subscriberService.newDialog();
        };
    }

    exit(): void {
        super.exit();

        this.militaryService.reset();
    }
}

export const siteMaintenanceController = app.controller(
    resources.siteMaintenanceController,
    [
        resources.instances,
        resources.configService,
        resources.subscriberService,
        resources.militaryService,
    ],
    SiteMaintenanceController,
);
