import { DateIO, Instance, TableCalculation } from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { AdminService } from './adminService';
import { AssetService } from './assetService';
import { LanguageService } from './languageService';

export class AdminCouponService extends AdminService {
    constructor(
        instances: Instance,
        $languageService: LanguageService,
        $assetService: AssetService,
    ) {
        super(
            'coupon-coupons-name',
            instances,
            $languageService,
            $assetService,
        );
    }

    getTableOptions(): object {
        return {
            no_content: {
                image_url: this.assetService.getPath(
                    'images/others/no-content.png',
                ),
                text: this.languageService.translate('text.no_content'),
            },
            sort: {
                column: 'name',
                order: 'asc',
            },
            row_count: 12,
            columns: [
                'name',
                'code',
                'discount',
                'active',
                'used',
                'expired',
                'organization.name',
                'actions',
            ],
            calculations: this.getTableCalculations(),
        };
    }

    getTableFields(): string {
        return [
            'id',
            'name',
            'code',
            'discount',
            'active',
            'used',
            'expired',
            'organization.name',
        ].join(',');
    }

    getTableCalculations(): TableCalculation {
        return {
            expired: (coupon) => {
                const expired = DateIO.parse(coupon.get('expired'));
                return this.languageService.format(expired, 'format.datetime');
            },
        };
    }
}

export const adminCouponService = app.service(
    resources.adminCouponService,
    [resources.instances, resources.languageService, resources.assetService],
    AdminCouponService,
);
