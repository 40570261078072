import { format, Instance } from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { AssetService } from './assetService';
import { LanguageService } from './languageService';
import { ModelService } from './modelService';

export class CouponService extends ModelService {
    constructor(
        instances: Instance,
        $languageService: LanguageService,
        $assetService: AssetService,
    ) {
        super(
            'coupon-coupons-name',
            instances,
            $languageService,
            $assetService,
        );
    }

    checkCodeByOrganization(organizationId: string, code: string) {
        return this.http.get(
            format('/api/v1/organizations/{0}/coupons/check/{1}.json', [
                organizationId,
                code,
            ]),
        );
    }
}

export const couponService = app.service(
    resources.couponService,
    [resources.instances, resources.languageService, resources.assetService],
    CouponService,
);
