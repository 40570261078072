import {
    Controller,
    EventBus,
    Form,
    Helper,
    Http,
    Instance,
    Knot,
    NavBar,
    Navigation,
    Page,
    Query,
    Screen,
    State,
    eq,
    format,
    gte,
    isArray,
    lt,
    readableNumber,
    scrollToElement,
    Objekt,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';

import LazyLoad, { ILazyLoadInstance } from 'vanilla-lazyload';
import { AssetService } from '../services/assetService';
import { ConfigService } from '../services/configService';
import { LanguageService } from '../services/languageService';
import { MilitaryService } from '../services/militaryService';
import { SiteService } from '../services/siteService';
import { SubscriberService } from '../services/subscriberService';
import { UserService } from '../services/userService';

export class SiteLandingPageController extends Controller {
    eventBus: EventBus;
    screen: Screen;
    page: Page;
    navBar: NavBar;
    http: Http;
    sectionOffsets: {};
    navBarNavigation: Navigation;
    lazyLoad: ILazyLoadInstance;
    dom: Knot;
    state: State;
    helper: Helper;

    constructor(
        instances: Instance,
        private militaryService: MilitaryService,
        private configService: ConfigService,
        private subscriberService: SubscriberService,
        private siteService: SiteService,
        private assetService: AssetService,
        private languageService: LanguageService,
        private userService: UserService,
    ) {
        super();

        this.eventBus = instances.eventBus;
        this.screen = instances.screen;
        this.page = instances.page;
        this.navBar = instances.navBar;
        this.http = instances.http;
        this.dom = instances.dom;
        this.state = instances.state;
        this.helper = instances.helper;
    }

    enter(): void {
        this._initLayout();
    }

    protected _initLayout(): void {
        this.sectionOffsets = {};

        this._initLazyLoad();
        this._initEvents();
        this._initButtons();
        this._initNavBarNavigation();
        this._initSponsors();
        this._initPricing();
        this._initSubscriber();
        this._initRates();
        this._initFuture();
        this._initScrollPosition();
    }

    private _initLazyLoad(): void {
        this.lazyLoad = new LazyLoad({
            elements_selector: '.lazy',
        });
    }

    private _setSectionOffsets(): void {
        let sumHeight = 0;
        const sections = new Query('section', this.dom);
        sections.each((section) => {
            const style = section.getComputedStyle();
            const height = parseInt(style.height.replace('px', ''), 10);
            const cssClasses = section.getClasses();
            this.sectionOffsets[cssClasses[0]] = [
                sumHeight - 1,
                sumHeight + height,
            ];
            sumHeight += height;
        });
    }

    private _setLayout(winHeight: number): void {
        const containerResizable = new Query('.resizable', this.dom);
        containerResizable.each((node) => {
            node.removeStyle(['height']);
            const height = parseInt(
                node.getComputedStyle().height.slice(0, -2),
            );
            if (height < winHeight) {
                node.setStyle({
                    height: winHeight + 'px',
                });
            }
        });
        this._setSectionOffsets();
    }

    private _initScrollPosition(): void {
        const section = this.state.getParam('section');
        if (section) {
            scrollToElement(format('section.{0}', [section]));
        }
    }

    private _initEvents(): void {
        const parallaxKnot = new Query(
            '.welcome .parallax',
            this.dom,
        ).getKnot();
        this.militaryService.draw(
            parallaxKnot,
            ['#f1f2ea', '#f9fafe', '#fbfeff', '#c6c6c6', '#dad8d8', '#e2e2e2'],
            0.2,
        );

        this.eventBus.set('window.resize', (width, height) => {
            this._setLayout(height);
        });

        this.eventBus.set('window.scroll', (scrollTop) => {
            this._setNavigationStyle(scrollTop);
        });

        this._setLayout(this.screen.getHeight());
    }

    private _setNavigationStyle(scrollTop: number): void {
        const scrollTopOffset = scrollTop + 100;
        this.navBarNavigation.each((menuItem) => {
            const id = menuItem.get('id');
            const sectionOffsets = this.sectionOffsets[id];
            if (isArray(sectionOffsets)) {
                const [min, max] = sectionOffsets;
                if (eq(scrollTop, 0)) {
                    this.navBarNavigation.setAllInactive();
                } else if (
                    gte(scrollTopOffset, min) &&
                    lt(scrollTopOffset, max)
                ) {
                    this.navBarNavigation.setActive(id);
                }
            }
        });
    }

    private _initButtons(): void {
        /* this.helper.button('.btn-scroll-down', this.dom, () => {
  scrollToElement('section.toolkit');
});*/
        this.helper.button('.btn-get-started', this.dom, () => {
            scrollToElement('section.toolkit');
        });
    }

    private _initSubscriber(): void {
        const form = new Form(this.dom);
        form.eventButton = () => {
            this.subscriberService.newDialog();
        };
    }

    private _initSponsors(): void {
        const sponsorLinks = new Query('section.sponsors a');
        sponsorLinks.each((sponsorLink) => {
            this.helper.linkElement(sponsorLink, (href) => {
                this.state.redirect(href, true);
            });
        });

        this.helper.button(
            'section.sponsors .btn-join-as-sponsor',
            this.dom,
            () => {
                const email =
                    this.languageService.translate('application.email');
                const subject = this.languageService.translate(
                    'text.site.landing_page.sponsors.button',
                );
                this.page.mailTo(email, subject);
            },
        );
    }

    private _initFuture(): void {
        this.helper.button('section.future button.donate', this.dom, () => {
            const price = this.configService.get<number>('other.donate_price');
            this.siteService.getExchangeRate(price).then((response) => {
                const exchangeRate = response.get<Objekt>('exchange_rate');
                const url = this.configService.get<string>('other.donate_url');
                const urlWithPrice = format(url, [
                    exchangeRate.get('price'),
                    exchangeRate.get<string>('currency').toUpperCase(),
                ]);
                this.state.redirect(urlWithPrice, true);
            });
        });

        this.helper.iconButton(
            'section.future .btn-download.android',
            this.dom,
            () => {
                const url = this.configService.get<string>('google.app_url');
                this.state.redirect(url, true);
            },
            '',
            true,
            ['mdl-button--fab', 'mdl-button--raised'],
        );

        this.helper.iconButton(
            'section.future .btn-download.ios',
            this.dom,
            () => {
                const url = this.configService.get<string>('apple.app_url');
                this.state.redirect(url, true);
            },
            '',
            true,
            ['mdl-button--fab', 'mdl-button--raised'],
        );

        this.helper.iconButton(
            'section.future .btn-download.huawei',
            this.dom,
            () => {
                const url = this.configService.get<string>('huawei.app_url');
                this.state.redirect(url, true);
            },
            '',
            true,
            ['mdl-button--fab', 'mdl-button--raised'],
        );
    }

    private _initPricing(): void {
        this.helper.button('section.pricing button.starter', this.dom, () => {
            this.state.go('site.home');
        });

        this.helper.button('section.pricing button.standard', this.dom, () => {
            this.state.go('orders.new', {
                licenseType: 'standard',
            });
        });

        this.helper.button('section.pricing button.premium', this.dom, () => {
            this.state.go('orders.new', {
                licenseType: 'premium',
            });
        });
    }

    private _initRates(): void {
        const rateNodes = new Query('.rate h4');
        rateNodes.each((rateKnot) => {
            const rate = parseInt(rateKnot.getHtml(true).trim(), 10);
            const readableRate = readableNumber(rate, true);
            rateKnot.setHtml(readableRate);
        });
    }

    private _initNavBarNavigation(): void {
        this.navBarNavigation = new Navigation(this.http);

        this.navBarNavigation.addImage(
            'welcome',
            this.assetService.getPath('images/favicons/trophymap.svg'),
            '',
            () => {
                scrollToElement('section.welcome');
                this.navBar.close();
            },
        );

        this.navBarNavigation.addText(
            'toolkit',
            this.languageService.translate(
                'text.site.landing_page.menu.toolkit',
            ),
            () => {
                scrollToElement('section.toolkit');
                this.navBar.close();
            },
        );

        this.navBarNavigation.addText(
            'about',
            this.languageService.translate('text.site.landing_page.menu.about'),
            () => {
                scrollToElement('section.about');
                this.navBar.close();
            },
        );

        this.navBarNavigation.addText(
            'pricing',
            this.languageService.translate(
                'text.site.landing_page.menu.pricing',
            ),
            () => {
                scrollToElement('section.pricing');
                this.navBar.close();
            },
        );

        this.navBarNavigation.addText(
            'feature',
            this.languageService.translate(
                'text.site.landing_page.menu.advantages',
            ),
            () => {
                scrollToElement('section.feature');
                this.navBar.close();
            },
        );

        this.navBarNavigation.addText(
            'future',
            this.languageService.translate(
                'text.site.landing_page.menu.future',
            ),
            () => {
                scrollToElement('section.future');
                this.navBar.close();
            },
        );

        this.navBarNavigation.addText(
            'contact',
            this.languageService.translate(
                'text.site.landing_page.menu.contact',
            ),
            () => {
                scrollToElement('section.contact');
                this.navBar.close();
            },
        );

        if (this.userService.isLoggedIn()) {
            this.navBarNavigation.addText(
                'home',
                this.languageService.translate('captions.site.home'),
                (href) => {
                    this.state.go(href);
                    this.navBar.close();
                },
                this.state.resolveUrl('site.home'),
            );
        } else {
            this.navBarNavigation.addText(
                'login',
                this.languageService.translate('buttons.login'),
                (href) => {
                    this.state.go(href);
                    this.navBar.close();
                },
                this.state.resolveUrl('users.login'),
            );
        }

        this.navBarNavigation.bindToContainer(this.navBar.getContainer());
    }

    exit(): void {
        super.exit();

        this.eventBus.pop('window.resize');
        this.eventBus.pop('window.scroll');
        this.militaryService.reset();
    }
}

export const siteLandingPageController = app.controller(
    resources.siteLandingPageController,
    [
        resources.instances,
        resources.militaryService,
        resources.configService,
        resources.subscriberService,
        resources.siteService,
        resources.assetService,
        resources.languageService,
        resources.userService,
    ],
    SiteLandingPageController,
);
