import {
    Deferred,
    Instance,
    Objekt,
    Promize,
    Table,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { BudgetService } from '../services/budgetService';
import { ItemService } from '../services/itemService';
import { LanguageService } from '../services/languageService';
import { UserService } from '../services/userService';
import { DashboardController } from './dashboardController';

export class ItemsIndexController extends DashboardController {
    budgetId: string;
    categoryId: string;
    budget: Objekt;
    organizationId: string;
    itemsTable: Table;

    constructor(
        instances: Instance,
        private itemService: ItemService,
        private budgetService: BudgetService,
        private userService: UserService,
        private languageService: LanguageService,
    ) {
        super(instances);
    }

    protected override _initLayout(): void {
        this.budgetId = this.state.getParam('budgetId');
        this.categoryId = this.state.getParam('categoryId');

        this._initToolbarButtons();

        this._loadBudget().then(() => {
            this._drawContent();
        });
    }

    private _loadBudget(): Promize {
        const deferred = new Deferred();
        this.budgetService
            .get(this.budgetId, {
                fields: 'id,name,organization_id',
            })
            .then(
                (response) => {
                    this.budget = response.get('budget');
                    this.organizationId = this.budget.get('organization_id');
                    deferred.resolve();
                },
                () => {
                    deferred.reject();
                },
            );
        return deferred.promise();
    }

    protected override _drawContent(): Promize | boolean {
        this._initItemTable();
        return true;
    }

    private _initToolbarButtons(): void {
        this.helper.iconButton(
            '.btn-budgets-show',
            this.dom,
            () => {
                this.state.goBack('budgets.show', {
                    budgetId: this.budgetId,
                });
            },
            '',
            true,
            [],
        );

        this.helper.iconButton('.btn-items-new', this.dom, () => {
            this.itemService.newDialogByBudget(this.budgetId).then(() => {
                this.state.refresh(true);
            });
        });
    }

    private _initItemTable(): void {
        if (!this.itemsTable) {
            this.itemsTable = new Table(
                this.dom,
                '.items-table',
                this.itemService.getTableOptions(),
            );

            this.itemsTable.setActions([
                {
                    style: (item) => {
                        return [
                            'edit',
                            this.languageService.translate('buttons.edit'),
                            false,
                            !this.userService.hasAccessByOrganization(
                                this.organizationId,
                                'items_write',
                            ),
                        ];
                    },
                    click: (item) => {
                        this.itemService.editDialog(item).then(() => {
                            this.state.refresh(true);
                        });
                    },
                },
            ]);

            this.itemsTable.eventAction = (params) => {
                params.set('fields', this.itemService.getTableFields());
                this.itemService
                    .getAllByBudgetAndCategory(
                        this.budgetId,
                        this.categoryId,
                        params,
                    )
                    .then((response) => {
                        const count = response.get<number>('count');
                        this.itemsTable.setCount(count);
                        const items = response.get<Objekt[]>('items');
                        this.itemsTable.setData(items);
                    });
            };
            this.itemsTable.render();
        } else {
            this.itemsTable.refresh();
        }
    }
}

export const itemsIndexController = app.controller(
    resources.itemsIndexController,
    [
        resources.instances,
        resources.itemService,
        resources.budgetService,
        resources.userService,
        resources.languageService,
    ],
    ItemsIndexController,
);
