import {
    DateIO,
    Deferred,
    format,
    Instance,
    Knot,
    Objekt,
    Promize,
    Table,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { AssetService } from '../services/assetService';
import { LanguageService } from '../services/languageService';
import { LicenseService } from '../services/licenseService';
import { OrganizationService } from '../services/organizationService';
import { DashboardController } from './dashboardController';

export class LicensesIndexByOrganizationController extends DashboardController {
    organizationId: string;
    organization: Objekt;
    licensesTable: Table;

    constructor(
        instances: Instance,
        private licenseService: LicenseService,
        private organizationService: OrganizationService,
        private assetService: AssetService,
        private languageService: LanguageService,
    ) {
        super(instances);
    }

    protected override _initLayout(): void {
        this.organizationId = this.state.getParam('organizationId');
        this.organization = new Objekt();

        this._initToolbarButtons();

        this._loadOrganization().then(() => {
            this._drawContent();
        });
    }

    protected override _drawContent(): Promize | boolean {
        this._initLicenseTable();
        return true;
    }

    private _loadOrganization(): Promize {
        const deferred = new Deferred();
        this.organizationService
            .get(this.organizationId, {
                fields: 'id',
            })
            .then(
                (response) => {
                    this.organization = response.get('organization');
                    deferred.resolve();
                },
                () => {
                    deferred.reject();
                },
            );
        return deferred.promise();
    }

    private _initLicenseTable(): void {
        if (!this.licensesTable) {
            this.licensesTable = new Table(this.dom, 'table', {
                no_content: {
                    image_url: this.assetService.getPath(
                        'images/others/no-content.png',
                    ),
                    text: this.languageService.translate('text.no_content'),
                },
                sort: {
                    column: 'expired',
                    order: 'desc',
                },
                sorted: ['expired'],
                row_count: 12,
                columns: ['order_id', 'license_type', 'expired'],
                calculations: {
                    license_type: (license) => {
                        const licenseType = license.get('license_type');
                        const tagKnot = new Knot('span');
                        tagKnot.addClass('tag');
                        tagKnot.setHtml(
                            this.languageService.translate(
                                format('order.license_type.{0}', [licenseType]),
                            ),
                        );
                        return tagKnot;
                    },
                    expired: (license) => {
                        const expired = DateIO.parse(
                            license.get('expired'),
                            'YYYY-MM-DD',
                        );
                        return this.languageService.format(
                            expired,
                            'format.datetime',
                        );
                    },
                },
                rowStyle: (license) => {
                    const expired = DateIO.parse(
                        license.get('expired'),
                        'YYYY-MM-DD',
                    );
                    return DateIO.isBefore(new Date(), expired)
                        ? null
                        : 'inactive';
                },
            });

            this.licensesTable.eventAction = (params) => {
                params.set('fields', 'order_id,license_type,expired');
                this.licenseService
                    .getAllByOrganization(this.organizationId, params)
                    .then((response) => {
                        const count = response.get<number>('count');
                        this.licensesTable.setCount(count);
                        const licenses = response.get<Objekt[]>('licenses');
                        this.licensesTable.setData(licenses);
                    });
            };

            this.licensesTable.render();
        } else {
            this.licensesTable.refresh();
        }
    }

    private _initToolbarButtons(): void {
        this.helper.iconButton('.btn-orders-new', this.dom, () => {
            this.state.go('orders.new', {
                organizationId: this.organizationId,
            });
        });

        this.helper.iconButton(
            '.btn-organizations-show',
            this.dom,
            () => {
                this.state.goBack('organizations.show', {
                    organizationId: this.organizationId,
                });
            },
            '',
            true,
            [],
        );
    }
}

export const licensesIndexByOrganizationController = app.controller(
    resources.licensesIndexByOrganizationController,
    [
        resources.instances,
        resources.licenseService,
        resources.organizationService,
        resources.assetService,
        resources.languageService,
    ],
    LicensesIndexByOrganizationController,
);
