import {
    Controller,
    Deferred,
    Depot,
    Dialog,
    Flash,
    Form,
    Helper,
    inArray,
    Instance,
    isNull,
    Knot,
    noop,
    Objekt,
    State,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';

import { AppleAuthService } from '../services/appleAuthService';
import { FacebookService } from '../services/facebookService';
import { GoogleIdentityService } from '../services/googleIdentityService';
import { LanguageService } from '../services/languageService';
import { MilitaryService } from '../services/militaryService';
import { Oauth2Service } from '../services/oauth2Service';
import { UserService } from '../services/userService';

export class UsersLoginController extends Controller {
    private sessionDepot: Depot;
    private loginForm: Form;
    private readonly dom: Knot;
    private state: State;
    private helper: Helper;
    private flash: Flash;
    private dialog: Dialog;

    constructor(
        instances: Instance,
        private militaryService: MilitaryService,
        private facebookService: FacebookService,
        private googleIdentityService: GoogleIdentityService,
        private appleAuthService: AppleAuthService,
        private oauth2Service: Oauth2Service,
        private userService: UserService,
        private languageService: LanguageService,
    ) {
        super();

        this.sessionDepot = instances.sessionDepot;
        this.dom = instances.dom;
        this.state = instances.state;
        this.helper = instances.helper;
        this.flash = instances.flash;
        this.dialog = instances.dialog;

        this._handleRefreshToken();
    }

    enter(): void {
        this._initLayout();

        const month = new Date().getMonth() + 1;
        let opacity = 0.3;
        let colors = [
            '#f1f2ea',
            '#f9fafe',
            '#fbfeff',
            '#c6c6c6',
            '#dad8d8',
            '#e2e2e2',
        ]; // 3
        if (inArray([3, 4, 5], month)) {
            opacity = 0.8;
            colors = [
                '#181b12',
                '#1d2015',
                '#1a2213',
                '#1c2415',
                '#1f2b17',
                '#223019',
            ]; // 0
        } else if (inArray([6, 7, 8], month)) {
            opacity = 0.7;
            colors = [
                '#1c1b13',
                '#201f15',
                '#222113',
                '#242315',
                '#2b2917',
                '#302e19',
            ]; // 2
        } else if (inArray([9, 10, 11], month)) {
            opacity = 0.6;
            colors = [
                '#1c1b13',
                '#201f15',
                '#222113',
                '#242315',
                '#2b2917',
                '#302e19',
            ]; // 2
        }
        // ['#DED29E', '#B3A580', '#936A4A', '#BAA378', '#C0A172', '#AE956D'] // 1
        this.militaryService.draw(this.dom, colors, opacity);

        this.facebookService.loadSDK();
        this.googleIdentityService.loadSDK();
        this.appleAuthService.loadSDK();
    }

    protected _initLayout(): void {
        this._initButtons();
        this._initLoginForm();
        this._initSocialButtons();
    }

    private _handleRefreshToken(): void {
        const refreshToken = this.sessionDepot.get('user.refresh_token');
        if (refreshToken) {
            this.sessionDepot.remove('user.refresh_token');
            this.userService.loginWithToken(refreshToken).then(
                (response) => {
                    this.flash.addMessage(response.get('message'));
                    this._nextState();
                },
                (response) => {
                    this.flash.addMessage(response.get('message'));
                },
            );
        }
    }

    private _initLoginForm(): void {
        this.loginForm = new Form(this.dom);
        this.loginForm.eventSubmit = (formData) => {
            this.loginForm.lock();
            const userData = formData.get<Objekt>('user');
            this.userService.loginWithEmail(userData).then(
                (response) => {
                    this.flash.addMessage(response.get('message'));
                    this._nextState();
                },
                (response) => {
                    this.flash.addMessage(response.get('message'));
                    this.loginForm.unlock();
                    this.loginForm.setErrors(response.get('errors'));
                },
            );
        };
        this.loginForm.eventButton = noop();
    }

    private _initButtons(): void {
        this.helper.link(
            '.btn-registration',
            this.dom,
            (href) => {
                this.state.go(href);
            },
            this.state.resolveUrl('users.registration'),
        );

        this.helper.link(
            '.btn-recovery-password',
            this.dom,
            (href) => {
                this.state.go(href);
            },
            this.state.resolveUrl('users.recovery-password'),
        );

        this.helper.iconButton(
            '.close',
            this.dom,
            () => {
                this.state.go('site.landing-page');
            },
            '',
            true,
            [],
        );
    }

    private _handleAuthSuccess() {
        return (response: Objekt): void => {
            this.flash.addMessage(response.get('message'));
            this._nextState();
        };
    }

    private _handleAuthFailed() {
        return (response: Objekt) => {
            this.flash.addMessage(response.get('message'));
            this._stopState();
        };
    }

    private _initSocialButtons(): void {
        this.helper.button(
            '.btn-facebook',
            this.dom,
            () => {
                this._handleSocialPolicies().then(() => {
                    this.facebookService.loginDialog().then(
                        (accessToken) => {
                            this.userService
                                .loginWithFacebook(accessToken)
                                .then(
                                    this._handleAuthSuccess(),
                                    this._handleAuthFailed(),
                                );
                        },
                        () => {
                            this._stopState();
                        },
                    );
                });
            },
            this.languageService.translate('buttons.sign_in_with_facebook'),
        );

        this.helper.button(
            '.btn-google',
            this.dom,
            () => {
                this._handleSocialPolicies().then(() => {
                    this.googleIdentityService.loginDialog().then(
                        (idToken) => {
                            this.userService
                                .loginWithGoogle(idToken, 'id_token')
                                .then(
                                    this._handleAuthSuccess(),
                                    this._handleAuthFailed(),
                                );
                        },
                        () => {
                            this._stopState();
                        },
                    );
                });
            },
            this.languageService.translate('buttons.sign_in_with_google'),
        );

        this.helper.button(
            '.btn-apple',
            this.dom,
            () => {
                this._handleSocialPolicies().then(() => {
                    this.appleAuthService.loginDialog('id_token').then(
                        (idToken, email, firstName, lastName) => {
                            this.userService
                                .loginWithApple(
                                    idToken,
                                    email,
                                    firstName,
                                    lastName,
                                    'id_token',
                                )
                                .then(
                                    this._handleAuthSuccess(),
                                    this._handleAuthFailed(),
                                );
                        },
                        () => {
                            this._stopState();
                        },
                    );
                });
            },
            this.languageService.translate('buttons.sign_in_with_apple'),
        );

        this.helper.button(
            '.btn-microsoft',
            this.dom,
            () => {
                this._handleSocialPolicies().then(() => {
                    this.oauth2Service.loginDialog('microsoft').then(
                        (code, redirectUri) => {
                            this.userService
                                .loginWithMicrosoft(code, redirectUri)
                                .then(
                                    this._handleAuthSuccess(),
                                    this._handleAuthFailed(),
                                );
                        },
                        () => {
                            this._stopState();
                        },
                    );
                });
            },
            this.languageService.translate('buttons.sign_in_with_microsoft'),
        );
    }

    private _handleSocialPolicies() {
        const deferred = new Deferred();
        this.loginForm.lock();
        this.dialog
            .loadTemplate('/client/v1/site/gdpr-policies.html')
            .then((dialogKnot) => {
                const form = new Form(dialogKnot);
                form.eventSubmit = () => {
                    this.dialog.close();
                    deferred.resolve();
                };
                form.eventReset = () => {
                    this.dialog.close();
                    this.loginForm.unlock();
                    deferred.reject();
                };
                this.dialog.open();
            });
        this.dialog.eventCancel = () => {
            this.loginForm.unlock();
            deferred.reject();
        };
        return deferred.promise();
    }

    private _nextState(): void {
        const refererState = this.sessionDepot.get('user.referer');
        if (!isNull(refererState)) {
            this.state.goState(new Objekt(refererState));
            this.sessionDepot.remove('user.referer');
        } else {
            this.state.go('site.home');
        }
    }

    private _stopState(): void {
        this.loginForm.unlock();
    }

    exit(): void {
        super.exit();

        this.militaryService.reset();

        this.facebookService.reset();
        this.googleIdentityService.reset();
        this.appleAuthService.reset();
    }
}

export const usersLoginController = app.controller(
    resources.usersLoginController,
    [
        resources.instances,
        resources.militaryService,
        resources.facebookService,
        resources.googleIdentityService,
        resources.appleAuthService,
        resources.oauth2Service,
        resources.userService,
        resources.languageService,
    ],
    UsersLoginController,
);
