import {
    Instance,
    Knot,
    Objekt,
    pluck,
    TableCalculation,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { AssetService } from './assetService';
import { LanguageService } from './languageService';
import { ModelService } from './modelService';

export class DistrictService extends ModelService {
    constructor(
        instances: Instance,
        $languageService: LanguageService,
        $assetService: AssetService,
    ) {
        super(
            'district-districts-title',
            instances,
            $languageService,
            $assetService,
        );
    }

    getAllByOrganization(organizationId: string, opt_params?: object) {
        return this.getAllBy('organizations', organizationId, opt_params);
    }

    createByOrganization(organizationId: string, district: Objekt) {
        return this.createBy('organizations', organizationId, district);
    }

    getTableOptions(): object {
        return {
            no_content: {
                image_url: this.assetService.getPath(
                    'images/others/no-content.png',
                ),
                text: this.languageService.translate('text.no_content'),
            },
            sort: {
                column: 'title',
                order: 'asc',
            },
            row_count: 12,
            column: 'title',
            columns: [
                'color',
                'title',
                'compute_area',
                'buildings_count',
                'building_ids',
                'comment',
                'actions',
            ],
            sorted: ['title:asc', 'compute_area:desc', 'buildings_count:desc'],
            calculations: this.getTableCalculations(),
        };
    }

    getTableFields(): string {
        return 'id,title,color,compute_area_ha,comment,buildings_count,buildings.name';
    }

    getTableCalculations(): TableCalculation {
        return {
            title: (district) => {
                return this.helper.createLink(
                    district.get('title'),
                    (href) => {
                        this.state.go(href);
                    },
                    this.state.resolveUrl('districts.show', {
                        districtId: district.get('id'),
                    }),
                );
            },
            building_ids: (district) => {
                return pluck(district.get('buildings', []), 'name').join(', ');
            },
            color: (district) => {
                const color = district.get('color');
                const imageWrapper = new Knot('div');
                imageWrapper.addClass('img-wrapper');
                imageWrapper.setStyle({
                    'background-color': color,
                });
                return imageWrapper;
            },
            compute_area: (district) => {
                return district.get('compute_area_ha');
            },
        };
    }
}

export const districtService = app.service(
    resources.districtService,
    [resources.instances, resources.languageService, resources.assetService],
    DistrictService,
);
