import { Controller, Helper, Instance, Knot, State } from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';

export class OrganizationsRolesController extends Controller {
    private helper: Helper;
    private state: State;
    private dom: Knot;

    constructor(instances: Instance) {
        super();

        this.helper = instances.helper;
        this.state = instances.state;
        this.dom = instances.dom;
    }

    enter(): void {
        this._initLayout();
    }

    protected _initLayout(): void {
        this._initButtons();
    }

    private _initButtons(): void {
        this.helper.iconButton(
            '.btn-organizations-show',
            this.dom,
            () => {
                this.state.goBack('organizations.show', {
                    organizationId: this.state.getParam('organizationId'),
                });
            },
            '',
            true,
            [],
        );
    }
}

export const organizationsRolesController = app.controller(
    resources.organizationsRolesController,
    [resources.instances],
    OrganizationsRolesController,
);
