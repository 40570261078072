import { Deferred, Form, Instance, Objekt, Query } from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { AssetService } from './assetService';
import { GoogleCaptchaService } from './googleCaptchaService';
import { LanguageService } from './languageService';
import { ModelService } from './modelService';

export class FeedbackService extends ModelService {
    constructor(
        instances: Instance,
        $languageService: LanguageService,
        $assetService: AssetService,
        private googleCaptchaService: GoogleCaptchaService,
    ) {
        super(
            'feedback-feedbacks-title',
            instances,
            $languageService,
            $assetService,
        );
    }

    newDialog(
        opt_foreignName: string | undefined,
        opt_foreignId: string | undefined,
        opt_data: (Objekt | null) | undefined = null,
    ) {
        const deferred = new Deferred<Objekt, undefined>();
        this.dialog
            .loadTemplate('/client/v1/feedbacks/new.html')
            .then((dialogKnot) => {
                this.googleCaptchaService.render(
                    new Query('#captcha', dialogKnot).getKnot(),
                    '#feedback_captcha',
                );
                const form = new Form(dialogKnot);
                form.eventSubmit = (formData) => {
                    form.lock();
                    const feedbackData = formData.get<Objekt>('feedback');
                    this.create(feedbackData).then(
                        (response) => {
                            this.flash.addMessage(response.get('message'));
                            this.dialog.close();
                            deferred.resolve();
                        },
                        (response) => {
                            this.flash.addMessage(response.get('message'));
                            form.unlock();
                            form.setErrors(response.get('errors'));
                        },
                    );
                    this.googleCaptchaService.reset();
                };
                form.eventReset = () => {
                    this.dialog.close();
                    deferred.reject();
                };
                this.dialog.open();
            });
        return deferred.promise();
    }
}

export const feedbackService = app.service(
    resources.feedbackService,
    [
        resources.instances,
        resources.languageService,
        resources.assetService,
        resources.googleCaptchaService,
    ],
    FeedbackService,
);
