import {
    Confirm,
    Deferred,
    Dialog,
    eq,
    Flash,
    Form,
    format,
    Instance,
    Knot,
    Objekt,
    Promize,
    Query,
    Table,
} from '@siposdani87/sui-js';
import { saveAs } from 'file-saver';
import { app } from '../app';
import { resources } from '../resources';
import { LanguageService } from '../services/languageService';
import { NoteService } from '../services/noteService';
import { NotificationHistoryService } from '../services/notificationHistoryService';
import { NotificationService } from '../services/notificationService';
import { UtilService } from '../services/utilService';
import { DashboardController } from './dashboardController';

export class NotesShowController extends DashboardController {
    noteId: string;
    note: Objekt;
    btnNotesEditKnot: Knot<HTMLElement>;
    btnNotesPublishKnot: Knot<HTMLElement>;
    btnNotificationsExportKnot: Knot<HTMLElement>;
    notificationsTable: Table;
    private flash: Flash;
    private dialog: Dialog;
    private confirm: Confirm;

    constructor(
        instances: Instance,
        private noteService: NoteService,
        private notificationService: NotificationService,
        private notificationHistoryService: NotificationHistoryService,
        private utilService: UtilService,
        private languageService: LanguageService,
    ) {
        super(instances);

        this.flash = instances.flash;
        this.dialog = instances.dialog;
        this.confirm = instances.confirm;
    }

    protected override _initLayout(): void {
        this.noteId = this.state.getParam('noteId');
        this.note = new Objekt();

        this._initToolbarButtons();
        this._initTabPanel('details');
        this._changeToolbarButtons();

        this._correctDates();

        this._loadNote().then(() => {
            this._drawContent();
        });
    }

    private _initToolbarButtons(): void {
        this.helper.iconButton(
            '.btn-notes-index',
            this.dom,
            () => {
                this.state.goBack('notes.index');
            },
            '',
            true,
            [],
        );

        this.btnNotesEditKnot = this.helper.iconButton(
            '.btn-notes-edit',
            this.dom,
            () => {
                this.noteService
                    .editDialog(this.note)
                    .then(this.utilService.handleEditResponse('notes.index'));
            },
        );

        this.btnNotesPublishKnot = this.helper.iconButton(
            '.btn-notes-publish',
            this.dom,
            () => {
                this._publishNote(this.note);
            },
        );

        this.btnNotificationsExportKnot = this.helper.iconButton(
            '.btn-notifications-export',
            this.dom,
            () => {
                this._exportNotificationsDialog();
            },
        );
    }

    private _correctDates(): void {
        this.utilService.correctDate(
            '.published-at',
            'format.datetime',
            this.dom,
        );
        this.utilService.correctDate(
            '.expired-at',
            'format.datetime',
            this.dom,
        );
    }

    protected override _drawContent(): Promize | boolean {
        this._changeToolbarButtons();

        this._initNotificationsTable();
        return true;
    }

    private _changeToolbarButtons(): void {
        this.utilService.showButton(
            this.btnNotesEditKnot,
            this.activeTab === 'details',
        );

        this.utilService.showButton(
            this.btnNotesPublishKnot,
            this.activeTab === 'notifications-table',
        );

        this.utilService.showButton(
            this.btnNotificationsExportKnot,
            this.activeTab === 'notifications-table',
        );
    }

    private _initInfoBox(): void {
        const color = this.note.get('color');
        new Query('.colorized', this.dom).each((infoBox) => {
            infoBox.setStyle({
                'border-left': format('3px solid {0}', [color]),
            });
        });
    }

    private _loadNote(): Promize {
        const deferred = new Deferred();
        this.noteService
            .get(this.noteId, {
                fields: 'id,title,color,document',
            })
            .then(
                (response) => {
                    this.note = response.get('note');
                    this._initInfoBox();
                    deferred.resolve();
                },
                () => {
                    deferred.reject();
                },
            );
        return deferred.promise();
    }

    private _initNotificationsTable(): void {
        if (eq(this.activeTab, 'notifications-table')) {
            if (!this.notificationsTable) {
                this.notificationsTable = new Table(
                    this.dom,
                    '.notifications-table',
                    this.notificationHistoryService.getTableOptions(),
                );

                this.notificationsTable.setActions([
                    {
                        style: () => {
                            return [
                                'file_download',
                                this.languageService.translate(
                                    'buttons.download',
                                ),
                            ];
                        },
                        click: (notification) => {
                            const person = notification.get<Objekt>('person');
                            this._exportNotificationsDialog(person).then(() => {
                                this.notificationsTable.refresh();
                            });
                        },
                    },
                    {
                        style: () => {
                            return [
                                'send',
                                this.languageService.translate(
                                    'buttons.resend',
                                ),
                            ];
                        },
                        click: (notification) => {
                            this._publishNoteByPersonConfirm(notification).then(
                                () => {
                                    this.notificationsTable.refresh();
                                },
                            );
                        },
                    },
                ]);

                this.notificationsTable.eventAction = (params) => {
                    params.set(
                        'fields',
                        this.notificationHistoryService.getTableFields(),
                    );
                    this.notificationService
                        .getAllByNote(this.noteId, params)
                        .then((response) => {
                            const count = response.get<number>('count');
                            this.notificationsTable.setCount(count);
                            const notifications =
                                response.get<Objekt[]>('notifications');
                            this.notificationsTable.setData(notifications);
                        });
                };

                this.notificationsTable.render();
            } else {
                this.notificationsTable.refresh();
            }
        }
    }

    private _publishNoteByPersonConfirm(notification: Objekt): Promize {
        const deferred = new Deferred();
        this.notificationHistoryService
            .openChooseTypeDialog(
                this.notificationHistoryService.getTypes(notification),
            )
            .then(
                (types) => {
                    this.confirm.load(
                        this.languageService.translate(
                            'notes_controller.publish.confirm',
                            {
                                note_title: this.note.get('title'),
                                person_name: notification.get('person.name'),
                            },
                        ),
                        this.languageService.translate('buttons.resend'),
                        this.languageService.translate('buttons.cancel'),
                        '',
                        'warning',
                    );
                    this.confirm.eventOK = () => {
                        const person = notification.get<Objekt>('person');
                        this.noteService
                            .publishByPerson(this.note, person, types)
                            .then(
                                (response) => {
                                    this.flash.addMessage(
                                        response.get('message'),
                                    );
                                    deferred.resolve();
                                },
                                (response) => {
                                    this.flash.addMessage(
                                        response.get('message'),
                                    );
                                    deferred.reject();
                                },
                            );
                    };
                    this.confirm.eventCancel = () => {
                        deferred.reject();
                    };
                    this.confirm.open();
                },
                () => {
                    deferred.reject();
                },
            );
        return deferred.promise();
    }

    private _publishNote(note: Objekt): void {
        this.notificationHistoryService.openChooseTypeDialog().then((types) => {
            this.noteService.publish(note, types).then(
                (response) => {
                    this.flash.addMessage(response.get('message'));
                    this._drawContent();
                },
                (response) => {
                    this.flash.addMessage(response.get('message'));
                },
            );
        });
    }

    private _exportNotificationsDialog(opt_person?: Objekt): Promize {
        const deferred = new Deferred();
        this.dialog
            .loadTemplate(
                format('/client/v1/notes/{0}/export.html', [this.noteId]),
            )
            .then((dialogKnot) => {
                const form = new Form(dialogKnot);
                form.eventSubmit = (formData) => {
                    const noteData = formData.get<Objekt>('note');
                    const type = noteData.get<string>('export');
                    if (opt_person) {
                        this._exportNotificationsByPerson(
                            this.note,
                            opt_person,
                            type,
                        ).defer(deferred);
                    } else {
                        this._exportNotifications(this.note, type).defer(
                            deferred,
                        );
                    }
                };
                form.eventReset = () => {
                    this.dialog.close();
                };
                this.dialog.open();
            });
        return deferred.promise();
    }

    private _exportNotifications(note: Objekt, type: string): Promize {
        const deferred = new Deferred();
        this.noteService.exportList(note, type).then(
            (response, filename) => {
                saveAs(response.get('raw'), filename);
                this.dialog.close();
                deferred.resolve();
            },
            (response) => {
                this.flash.addMessage(response.get('message'));
                deferred.reject();
            },
        );
        return deferred.promise();
    }

    private _exportNotificationsByPerson(
        note: Objekt,
        person: Objekt,
        type: string,
    ): Promize {
        const deferred = new Deferred();
        this.noteService.exportListByPerson(note, person, type).then(
            (response, filename) => {
                saveAs(response.get('raw'), filename);
                this.dialog.close();
                deferred.resolve();
            },
            (response) => {
                this.flash.addMessage(response.get('message'));
                deferred.reject();
            },
        );
        return deferred.promise();
    }
}

export const notesShowController = app.controller(
    resources.notesShowController,
    [
        resources.instances,
        resources.noteService,
        resources.notificationService,
        resources.notificationHistoryService,
        resources.utilService,
        resources.languageService,
    ],
    NotesShowController,
);
