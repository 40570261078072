import {
    Deferred,
    eq,
    GoogleMap,
    Instance,
    Knot,
    Objekt,
    Promize,
    Query,
    Table,
    Viewer,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { ItemService } from '../services/itemService';
import { JourneyService } from '../services/journeyService';
import { LanguageService } from '../services/languageService';
import { MapService } from '../services/mapService';
import { UserService } from '../services/userService';
import { UtilService } from '../services/utilService';
import { VehicleService } from '../services/vehicleService';
import { DashboardController } from './dashboardController';

export class VehiclesShowController extends DashboardController {
    viewer: Viewer;
    vehicleId: string;
    vehicle: Objekt;
    btnVehiclesEditKnot: Knot<HTMLElement>;
    btnJourneysNewKnot: Knot<HTMLElement>;
    btnItemsNewKnot: Knot<HTMLElement>;
    hasAccessJourneyEdit: boolean;
    hasAccessItemEdit: boolean;
    map: GoogleMap;
    journeysTable: Table;
    itemsTable: Table;

    constructor(
        instances: Instance,
        private mapService: MapService,
        private vehicleService: VehicleService,
        private journeyService: JourneyService,
        private itemService: ItemService,
        private utilService: UtilService,
        private userService: UserService,
        private languageService: LanguageService,
    ) {
        super(instances);

        this.viewer = instances.viewer;
    }

    protected override _initLayout(): void {
        this.vehicleId = this.state.getParam('vehicleId');
        this.vehicle = new Objekt();

        this._initToolbarButtons();
        this._initTabPanel('details');
        this._changeToolbarButtons();

        this._initViewer();

        this._loadVehicle().then(() => {
            this._initAccess();
            this._drawContent();
        });
    }

    private _initToolbarButtons(): void {
        this.helper.iconButton(
            '.btn-vehicles-index',
            this.dom,
            () => {
                this.state.goBack('vehicles.index');
            },
            '',
            true,
            [],
        );

        this.btnVehiclesEditKnot = this.helper.iconButton(
            '.btn-vehicles-edit',
            this.dom,
            () => {
                this.vehicleService
                    .editDialog(this.vehicle)
                    .then(
                        this.utilService.handleEditResponse('vehicles.index'),
                    );
            },
        );

        this.btnJourneysNewKnot = this.helper.iconButton(
            '.btn-journeys-new',
            this.dom,
            () => {
                this.journeyService
                    .newDialogByVehicle(this.vehicleId)
                    .then(() => {
                        this._drawContent();
                    });
            },
        );

        this.btnItemsNewKnot = this.helper.iconButton(
            '.btn-items-new',
            this.dom,
            () => {
                this.itemService.newDialogByVehicle(this.vehicleId).then(() => {
                    this.state.refresh(true);
                });
            },
        );
    }

    private _initAccess(): void {
        const organizationId = this.vehicle.get<string>('organization_id');

        this.hasAccessJourneyEdit = this.userService.hasAccessByOrganization(
            organizationId,
            'journeys_write',
        );
        this.hasAccessItemEdit = this.userService.hasAccessByOrganization(
            organizationId,
            'items_write',
        );
    }

    private _initViewer(): void {
        const imageBoxKnot = new Query('.image-box', this.dom).getKnot();
        imageBoxKnot.addEventListener('click', () => {
            const imageUrl = this.vehicle.get<string>('photo.url');
            const name = this.vehicle.get<string>('name');
            this.viewer.loadImage(imageUrl, name);
        });
    }

    private _loadVehicle(): Promize {
        const deferred = new Deferred();
        this.vehicleService
            .get(this.vehicleId, {
                fields: 'id,name,location,photo.url,organization_id',
            })
            .then(
                (response) => {
                    this.vehicle = response.get('vehicle');
                    deferred.resolve();
                },
                () => {
                    deferred.reject();
                },
            );
        return deferred.promise();
    }

    protected override _drawContent(): Promize | boolean {
        this._changeToolbarButtons();

        this._initMap();
        this._initJourneysTable();
        this._initItemsTable();
        return true;
    }

    private _changeToolbarButtons(): void {
        this.utilService.showButton(
            this.btnVehiclesEditKnot,
            this.activeTab === 'details',
        );

        this.utilService.showButton(
            this.btnJourneysNewKnot,
            this.activeTab === 'journey-table',
        );

        this.utilService.showButton(
            this.btnItemsNewKnot,
            this.activeTab === 'item-table',
        );
    }

    private _initMap(): void {
        if (eq(this.activeTab, 'details')) {
            if (!this.map) {
                this.map = this.mapService.createGoogleMap(this.dom);
                this.map.setMarkerIcon(
                    'location',
                    this.mapService.getIcon('location', '#795548'),
                );
                this.map.eventMarkerClick = this._eventMarkerClick.bind(this);
            }
            const vehicleId = this.vehicle.get<string>('id');
            const location = this.vehicle.get<Objekt>('location', new Objekt());
            this.map.createMarker(
                vehicleId,
                '',
                'location',
                location.get('latitude'),
                location.get('longitude'),
                this.vehicle,
            );
        }
    }

    private _eventMarkerClick(vehicle: Objekt): void {
        const vehicleId = vehicle.get<string>('id');
        const address = this.vehicle.get<string>('location.address');
        this.map.openInfoWindow(vehicleId, address);
    }

    private _initJourneysTable(): void {
        if (eq(this.activeTab, 'journey-table')) {
            if (!this.journeysTable) {
                this.journeysTable = new Table(
                    this.dom,
                    '.journeys-table',
                    this.journeyService.getTableOptions(),
                );

                this.journeysTable.setActions([
                    {
                        style: () => {
                            return [
                                'edit',
                                this.languageService.translate('buttons.edit'),
                                false,
                                !this.hasAccessJourneyEdit,
                            ];
                        },
                        click: (journey) => {
                            this.journeyService.editDialog(journey).then(() => {
                                this._drawContent();
                            });
                        },
                    },
                ]);

                this.journeysTable.eventAction = (params) => {
                    params.set('fields', this.journeyService.getTableFields());
                    this.journeyService
                        .getAllByVehicle(this.vehicleId, params)
                        .then((response) => {
                            const count = response.get<number>('count');
                            this.journeysTable.setCount(count);
                            const journeys = response.get<Objekt[]>('journeys');
                            this.journeysTable.setData(journeys);
                        });
                };

                this.journeysTable.render();
            } else {
                this.journeysTable.refresh();
            }
        }
    }

    private _initItemsTable(): void {
        if (eq(this.activeTab, 'item-table')) {
            if (!this.itemsTable) {
                this.itemsTable = new Table(
                    this.dom,
                    '.items-table',
                    this.itemService.getTableOptions(),
                );

                this.itemsTable.setActions([
                    {
                        style: () => {
                            return [
                                'edit',
                                this.languageService.translate('buttons.edit'),
                                false,
                                !this.hasAccessItemEdit,
                            ];
                        },
                        click: (item) => {
                            this.itemService.editDialog(item).then(() => {
                                this.state.refresh(true);
                            });
                        },
                    },
                ]);

                this.itemsTable.eventAction = (params) => {
                    params.set('fields', this.itemService.getTableFields());
                    this.itemService
                        .getAllByVehicle(this.vehicleId, params)
                        .then((response) => {
                            const count = response.get<number>('count');
                            this.itemsTable.setCount(count);
                            const items = response.get<Objekt[]>('items');
                            this.itemsTable.setData(items);
                        });
                };
                this.itemsTable.render();
            } else {
                this.itemsTable.refresh();
            }
        }
    }

    exit(): void {
        super.exit();

        this.mapService.removeGoogleMap(this.map);
    }
}

export const vehiclesShowController = app.controller(
    resources.vehiclesShowController,
    [
        resources.instances,
        resources.mapService,
        resources.vehicleService,
        resources.journeyService,
        resources.itemService,
        resources.utilService,
        resources.userService,
        resources.languageService,
    ],
    VehiclesShowController,
);
