import { Instance, TableCalculation } from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { AssetService } from './assetService';
import { EntityConfig } from './entityService';
import { LanguageService } from './languageService';
import { ModelService } from './modelService';

export class PreyService extends ModelService {
    constructor(
        entityConfig: EntityConfig,
        instances: Instance,
        $languageService: LanguageService,
        $assetService: AssetService,
    ) {
        super(entityConfig, instances, $languageService, $assetService);
    }

    getTableOptions(): object {
        return {
            no_content: {
                image_url: this.assetService.getPath(
                    'images/others/no-content.png',
                ),
                text: this.languageService.translate('text.no_content'),
            },
            sort: {
                column: 'id',
                order: 'desc',
            },
            row_count: 1000,
            columns: [
                'full_name',
                'wilds',
                'min_age',
                'max_age',
                'hunted_count',
                'count',
                'actions',
            ],
            sorted: ['id:desc'],
            calculations: this.getTableCalculations(),
        };
    }

    getTableFields(): string {
        return 'id,full_name,min_age,max_age,count,hunted_count,wilds.full_name,class_type';
    }

    getTableCalculations(): TableCalculation {
        return {
            wilds: (prey) => {
                const wilds = prey.get('wilds', []);
                return wilds
                    .map((wild) => {
                        return wild.get('full_name');
                    })
                    .join(', ');
            },
        };
    }
}

export const preyService = app.service(
    resources.preyService,
    [
        'prey-preys-full_name',
        resources.instances,
        resources.languageService,
        resources.assetService,
    ],
    PreyService,
);
