import { Deferred, Form, format, Instance, Objekt } from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { AssetService } from './assetService';
import { LanguageService } from './languageService';
import { ModelService } from './modelService';

export class MembershipService extends ModelService {
    constructor(
        instances: Instance,
        $languageService: LanguageService,
        $assetService: AssetService,
    ) {
        super(
            'membership-memberships-title',
            instances,
            $languageService,
            $assetService,
        );
    }

    update(organizationUser: Objekt) {
        const organizationId = organizationUser.get('organization_id');
        const userId = organizationUser.get('user_id');
        return this.http.put(
            format('/api/v1/organizations/{0}/users/{1}/memberships.json', [
                organizationId,
                userId,
            ]),
            organizationUser,
        );
    }

    editDialog(
        membership: Objekt,
        opt_path: string | undefined = '',
        opt_entityName: string | undefined = '',
    ) {
        const deferred = new Deferred<[Objekt, boolean], undefined>();
        const organizationId = membership.get('organization.id');
        const userId = membership.get('user_id');
        this.dialog
            .loadTemplate(
                format(
                    '/client/v1/organizations/{0}/users/{1}/memberships/edit.html',
                    [organizationId, userId],
                ),
            )
            .then((dialogKnot) => {
                const form = new Form(dialogKnot);
                form.eventSubmit = (formData) => {
                    form.lock();
                    const organizationUserData =
                        formData.get<Objekt>('organization_user');
                    this.update(organizationUserData).then(
                        (response) => {
                            this.flash.addMessage(response.get('message'));
                            this.dialog.close();
                            deferred.resolve([
                                response.get('organization_user'),
                                false,
                            ]);
                        },
                        (response) => {
                            this.flash.addMessage(response.get('message'));
                            form.unlock();
                            form.setErrors(response.get('errors'));
                        },
                    );
                };

                form.eventReset = () => {
                    this.dialog.close();
                    deferred.reject();
                };

                this.handleForm(form);
                this.dialog.open();
            });
        return deferred.promise();
    }
}

export const membershipService = app.service(
    resources.membershipService,
    [resources.instances, resources.languageService, resources.assetService],
    MembershipService,
);
