import { Instance, Objekt, TableCalculation } from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { AssetService } from './assetService';
import { LanguageService } from './languageService';
import { ModelService } from './modelService';

export class GroupService extends ModelService {
    constructor(
        instances: Instance,
        $languageService: LanguageService,
        $assetService: AssetService,
    ) {
        super('group-groups-name', instances, $languageService, $assetService);
    }

    getAllByOrganization(organizationId: string, opt_params?: object) {
        return this.getAllBy('organizations', organizationId, opt_params);
    }

    createByOrganization(organizationId: string, group: Objekt) {
        return this.createBy('organizations', organizationId, group);
    }

    getTableOptions(): object {
        return {
            no_content: {
                image_url: this.assetService.getPath(
                    'images/others/no-content.png',
                ),
                text: this.languageService.translate('text.no_content'),
            },
            sort: {
                column: 'name',
                order: 'asc',
            },
            row_count: 15,
            columns: [
                'name',
                'people_count',
                'members_count',
                'guests_count',
                'actions',
            ],
            sorted: ['name'],
            calculations: this.getTableCalculations(),
        };
    }

    getTableFields(): string {
        return 'id,name,people_count,members_count,guests_count,organization_id';
    }

    getTableCalculations(): TableCalculation {
        return {
            name: (group) => {
                return this.helper.createLink(
                    group.get('name'),
                    (href) => {
                        this.state.go(href);
                    },
                    this.state.resolveUrl('groups.show', {
                        groupId: [group.get('id')],
                    }),
                );
            },
            members_count: (group) => {
                return this.helper.createLink(
                    group.get('members_count'),
                    (href) => {
                        this.state.go(href);
                    },
                    this.state.resolveUrl('members.index', {
                        organizationId: group.get('organization_id'),
                        groupIds: [group.get('id')],
                    }),
                );
            },
            guests_count: (group) => {
                return this.helper.createLink(
                    group.get('guests_count'),
                    (href) => {
                        this.state.go(href);
                    },
                    this.state.resolveUrl('guests.index', {
                        organizationId: group.get('organization_id'),
                        groupIds: [group.get('id')],
                    }),
                );
            },
        };
    }
}

export const groupService = app.service(
    resources.groupService,
    [resources.instances, resources.languageService, resources.assetService],
    GroupService,
);
