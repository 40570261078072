import {
    EventBus,
    inArray,
    Instance,
    NavBar,
    Service,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';

export class NavBarService extends Service {
    navBar: NavBar;
    eventBus: EventBus;

    constructor(instances: Instance) {
        super();

        this.navBar = instances.navBar;
        this.eventBus = instances.eventBus;
    }

    enter(): void {
        this.eventBus.set('state.change', (state) => {
            if (inArray(['site.landing-page', 'pages.show'], state.get('id'))) {
                this.navBar.show();
            } else {
                this.navBar.hide();
            }
        });
    }
}

export const navBarService = app.service(
    resources.navBarService,
    [resources.instances],
    NavBarService,
);
