import {
    Confirm,
    Flash,
    Instance,
    Objekt,
    Promize,
    Table,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { GuestService } from '../services/guestService';
import { LanguageService } from '../services/languageService';
import { UserService } from '../services/userService';
import { DashboardController } from './dashboardController';

export class GuestsIndexController extends DashboardController {
    organizationId: string;
    hasAccessGuestEdit: boolean;
    guestsTable: Table;
    private flash: Flash;
    private confirm: Confirm;

    constructor(
        instances: Instance,
        private guestService: GuestService,
        private userService: UserService,
        private languageService: LanguageService,
    ) {
        super(instances);

        this.flash = instances.flash;
        this.confirm = instances.confirm;
    }

    protected override _initLayout(): void {
        this.organizationId = this.state.getParam('organizationId');

        this._initToolbarButtons();
        this._initFilterForm();
        this._initAccess();

        this._drawContent();
    }

    protected override _drawContent(): Promize | boolean {
        this._initGuestTable();
        return true;
    }

    private _initAccess(): void {
        this.hasAccessGuestEdit = this.userService.hasAccessByOrganization(
            this.organizationId,
            'guests_write',
        );
    }

    private _initGuestTable(): void {
        if (!this.guestsTable) {
            this.guestsTable = new Table(
                this.dom,
                '.guests-table',
                this.guestService.getTableOptionsByOrganization(
                    this.organizationId,
                ),
            );

            this.guestsTable.setActions([
                {
                    style: () => {
                        return [
                            'unarchive',
                            this.languageService.translate(
                                'buttons.move_to_members',
                            ),
                            false,
                            !this.hasAccessGuestEdit,
                        ];
                    },
                    click: this._upgradeGuestConfirm.bind(this),
                },
                {
                    style: () => {
                        return [
                            'edit',
                            this.languageService.translate('buttons.edit'),
                            false,
                            !this.hasAccessGuestEdit,
                        ];
                    },
                    click: (guest) => {
                        this.guestService.editDialog(guest).then(() => {
                            this._drawContent();
                        });
                    },
                },
            ]);

            this.guestsTable.eventAction = (params) => {
                params.set('fields', this.guestService.getTableFields());
                params.set('query', this.query);
                params.set('group_ids', this.groupIds);
                this.guestService
                    .getAllByOrganization(this.organizationId, params)
                    .then((response) => {
                        const count = response.get<number>('count');
                        this.guestsTable.setCount(count);
                        const guests = response.get<Objekt[]>('guests');
                        this.guestsTable.setData(guests);
                    });
            };

            this.guestsTable.render();
        } else {
            this.guestsTable.refresh();
        }
    }

    private _initToolbarButtons(): void {
        this.helper.iconButton('.btn-guests-new', this.dom, () => {
            this.guestService
                .newDialogByOrganization(this.organizationId)
                .then(() => {
                    this._drawContent();
                });
        });

        this.helper.iconButton(
            '.btn-organizations-show',
            this.dom,
            () => {
                this.state.goBack('organizations.show', {
                    organizationId: this.organizationId,
                });
            },
            '',
            true,
            [],
        );
    }

    private _upgradeGuestConfirm(guest: Objekt): void {
        this.confirm.load(
            this.languageService.translate(
                'guests_controller.upgrade.confirm',
                {
                    guest_name: guest.get('name'),
                },
            ),
            this.languageService.translate('buttons.move'),
            this.languageService.translate('buttons.cancel'),
            '',
            'warning',
        );
        this.confirm.eventOK = () => {
            this.guestService.upgrade(guest).then(
                (response) => {
                    this.flash.addMessage(response.get('message'));
                    this.guestsTable.refresh();
                },
                (response) => {
                    this.flash.addMessage(response.get('message'));
                },
            );
        };
        this.confirm.open();
    }
}

export const guestsIndexController = app.controller(
    resources.guestsIndexController,
    [
        resources.instances,
        resources.guestService,
        resources.userService,
        resources.languageService,
    ],
    GuestsIndexController,
);
