import {
    DateIO,
    EventBus,
    format,
    Helper,
    Instance,
    Knot,
    Objekt,
    Query,
    round,
    Service,
    State,
    Style,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { LanguageService } from './languageService';
import { UserService } from './userService';

export class UtilService extends Service {
    eventBus: EventBus;
    style: Style;
    helper: Helper;
    state: State;

    constructor(
        instances: Instance,
        private languageService: LanguageService,
        private userService: UserService,
    ) {
        super();

        this.eventBus = instances.eventBus;
        this.style = instances.style;
        this.helper = instances.helper;
        this.state = instances.state;
    }

    enter(): void {
        this.style.load(
            'google-font-app',
            'https://fonts.googleapis.com/css2',
            {
                family: 'Allerta+Stencil:wght@400',
                display: 'swap',
            },
        );

        this.style.load(
            'google-font-heading',
            'https://fonts.googleapis.com/css2',
            {
                family: 'Ubuntu:wght@300;400;500;700',
                subset: 'latin-ext',
                display: 'swap',
            },
        );

        this.style.load(
            'google-font-body',
            'https://fonts.googleapis.com/css2',
            {
                family: 'Inter:wght@300;400;500;700',
                subset: 'latin-ext',
                display: 'swap',
            },
        );

        /*this.style.load(
            'google-material-icons',
            'https://fonts.googleapis.com/icon',
            {
                family: 'Material+Icons+Round',
                // family: 'Material+Icons',
                display: 'swap',
            },
        );*/

        this.style.load(
            'google-material-symbols',
            'https://fonts.googleapis.com/css2',
            {
                family: 'Material+Symbols+Rounded:opsz,wght,FILL,GRAD@48,400,1,0',
                display: 'swap',
            },
        );
    }

    getComputeAreaInHa(computeArea: number): string {
        const computeAreaHa = computeArea * 0.0001;
        return format('{0}&nbsp;ha', [round(computeAreaHa, 0)]);
    }

    correctDate(selector: string, formatter: string, dom: Knot): void {
        const dateKnot = new Query(selector, dom).getKnot();
        const date = dateKnot.getHtml(true);
        const dateFormatted = this.languageService.format(
            DateIO.parse(date),
            formatter,
        );
        dateKnot.setHtml(dateFormatted);
    }

    correctComputeAreaInHa(selector: string, dom: Knot): void {
        const dateKnot = new Query(selector, dom).getKnot();
        const computeArea = parseFloat(dateKnot.getHtml(true));
        const computeAreaInHa = this.getComputeAreaInHa(computeArea);
        dateKnot.setHtml(computeAreaInHa);
    }

    initOrganizationLink(cardKnot: Knot, item: Objekt): void {
        const organizationId = item.get<string>('organization.id');
        this.helper.link(
            'a.organization-link',
            cardKnot,
            (href) => {
                if (this.userService.hasAccessByOrganization(organizationId)) {
                    this.state.go(href);
                }
            },
            this.state.resolveUrl('organizations.show', {
                organizationId: organizationId,
            }),
            '',
            !!organizationId,
        );
    }

    handleEditResponse(
        stateId: string,
        opt_params?: object,
    ): (data: Objekt, isDeleted: boolean) => void {
        return (data, isDeleted) => {
            if (isDeleted) {
                this.state.goBack(stateId, opt_params);
            } else {
                this.state.refresh(true);
            }
        };
    }

    showButton(btnKnot: Knot, condition: boolean): void {
        if (!btnKnot.isEmpty()) {
            if (condition) {
                btnKnot.removeClass('hidden');
            } else {
                btnKnot.addClass('hidden');
            }
        }
    }

    enableButton(btnKnot: Knot, condition: boolean): void {
        if (!btnKnot.isEmpty()) {
            if (condition) {
                btnKnot.removeAttribute('disabled');
            } else {
                btnKnot.setAttribute('disabled');
            }
        }
    }
}

export const utilService = app.service(
    resources.utilService,
    [resources.instances, resources.languageService, resources.userService],
    UtilService,
);
