import { Instance, Objekt, Table } from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { AdminWildService } from '../services/adminWildService';
import { LanguageService } from '../services/languageService';
import { AdminController } from './adminController';

export class AdminWildsIndexController extends AdminController {
    private table: Table;

    constructor(
        instances: Instance,
        private adminWildService: AdminWildService,
        private languageService: LanguageService,
    ) {
        super(instances);
    }

    protected override _initToolbarButtons(): void {
        this.helper.iconButton(
            '.btn-admin-index',
            this.dom,
            () => {
                this.state.goBack('admin.index');
            },
            '',
            true,
            [],
        );

        this.helper.iconButton('.btn-wilds-new', this.dom, () => {
            this.adminWildService.newDialogByAdmin().then(() => {
                this._drawContent();
            });
        });
    }

    protected override _initTable(): void {
        if (!this.table) {
            this.table = new Table(
                this.dom,
                'table',
                this.adminWildService.getTableOptions(),
            );

            this.table.setActions([
                {
                    style: () => {
                        return [
                            'edit',
                            this.languageService.translate('buttons.edit'),
                        ];
                    },
                    click: (wild) => {
                        this.adminWildService
                            .editDialog(wild, '/admin/')
                            .then(() => {
                                this._drawContent();
                            });
                    },
                },
            ]);

            this.table.eventAction = (params) => {
                params.set('query', this.query);
                params.set('country_id', this.countryId);
                params.set('fields', this.adminWildService.getTableFields());
                this.adminWildService.getAll(params).then((response) => {
                    const count = response.get<number>('count');
                    this.table.setCount(count);
                    const wilds = response.get<Objekt[]>('wilds');
                    this.table.setData(wilds);
                });
            };

            this.table.render();
        } else {
            this.table.refresh();
        }
    }
}

export const adminWildsIndexController = app.controller(
    resources.adminWildsIndexController,
    [
        resources.instances,
        resources.adminWildService,
        resources.languageService,
    ],
    AdminWildsIndexController,
);
