import { Instance, TableCalculation } from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { AssetService } from './assetService';
import { BuildingService } from './buildingService';
import { DistrictService } from './districtService';
import { GroupService } from './groupService';
import { LanguageService } from './languageService';
import { PersonService } from './personService';
import { PlanPreyService } from './planPreyService';
import { PlanService } from './planService';
import { ReportService } from './reportService';
import { WildService } from './wildService';

export class ReportTrophyService extends ReportService {
    constructor(
        instances: Instance,
        $languageService: LanguageService,
        $assetService: AssetService,
        $personService: PersonService,
        $groupService: GroupService,
        $buildingService: BuildingService,
        $districtService: DistrictService,
        $planService: PlanService,
        $wildService: WildService,
        $planPreyService: PlanPreyService,
    ) {
        super(
            instances,
            $languageService,
            $assetService,
            $personService,
            $groupService,
            $buildingService,
            $districtService,
            $planService,
            $wildService,
            $planPreyService,
        );
    }

    getTableCalculations(): TableCalculation {
        return {
            ...this._getTableCalculations(),
        };
    }
}

export const reportTrophyService = app.service(
    resources.reportTrophyService,
    [
        resources.instances,
        resources.languageService,
        resources.assetService,
        resources.personService,
        resources.groupService,
        resources.buildingService,
        resources.districtService,
        resources.planService,
        resources.wildService,
        resources.planPreyService,
    ],
    ReportTrophyService,
);
