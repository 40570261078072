import { Instance, Knot, pluck, TableCalculation } from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { AdminService } from './adminService';
import { AssetService } from './assetService';
import { LanguageService } from './languageService';

export class AdminChamberService extends AdminService {
    constructor(
        instances: Instance,
        $languageService: LanguageService,
        $assetService: AssetService,
    ) {
        super(
            'chamber-chambers-name',
            instances,
            $languageService,
            $assetService,
        );
    }

    getTableOptions(): object {
        return {
            no_content: {
                image_url: this.assetService.getPath(
                    'images/others/no-content.png',
                ),
                text: this.languageService.translate('text.no_content'),
            },
            sort: {
                column: 'created_at',
                order: 'desc',
            },
            row_count: 12,
            column: 'name',
            columns: [
                'logo',
                'name',
                'country.name',
                'parent.name',
                'children',
                'organizations',
                'actions',
            ],
            sorted: ['name:asc', 'created_at', 'active:asc'],
            calculations: this.getTableCalculations(),
        };
    }

    getTableFields(): string {
        return 'id,logo,name,country.name,organizations.name,parent.name,children.name';
    }

    getTableCalculations(): TableCalculation {
        return {
            logo: (chamber) => {
                const imageWrapper = new Knot('div');
                imageWrapper.addClass('img-wrapper');
                const imageKnot = new Knot('img');
                imageKnot.addClass('img-responsive');
                const defaultSrc = chamber.get('logo.thumb.url');
                imageKnot.setAttribute('src', defaultSrc);
                imageWrapper.appendChild(imageKnot);
                return imageWrapper;
            },
            children: (chamber) => {
                return pluck(chamber.get('children', []), 'name').join(', ');
            },
            organizations: (chamber) => {
                return pluck(chamber.get('organizations', []), 'name').join(
                    ', ',
                );
            },
        };
    }
}

export const adminChamberService = app.service(
    resources.adminChamberService,
    [resources.instances, resources.languageService, resources.assetService],
    AdminChamberService,
);
