import {
    CardCollection,
    DateIO,
    Instance,
    Knot,
    Objekt,
    Promize,
    Query,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { AssetService } from '../services/assetService';
import { EventService } from '../services/eventService';
import { LanguageService } from '../services/languageService';
import { OrganizationService } from '../services/organizationService';
import { UtilService } from '../services/utilService';
import { DashboardController } from './dashboardController';

export class EventsIndexController extends DashboardController {
    eventCardCollection: CardCollection;

    constructor(
        instances: Instance,
        private eventService: EventService,
        private organizationService: OrganizationService,
        private utilService: UtilService,
        private assetService: AssetService,
        private languageService: LanguageService,
    ) {
        super(instances);
    }

    protected override _initLayout(): void {
        this._initToolbarButtons();
        this._initFilterForm();

        this._drawContent();
    }

    protected override _drawContent(): Promize | boolean {
        this._initEventCardCollection();
        return true;
    }

    private _initToolbarButtons(): void {
        this.helper.iconButton('.btn-events-new', this.dom, () => {
            this.eventService
                .newDialog('organizations', 'organization_id')
                .then(() => {
                    this._drawContent();
                });
        });

        this.helper.iconButton(
            '.btn-site-home',
            this.dom,
            () => {
                this.state.goBack('site.home');
            },
            '',
            true,
            [],
        );
    }

    private _initEventCardCollection(): void {
        if (!this.eventCardCollection) {
            this.eventCardCollection = new CardCollection(
                this.dom,
                '.event-collection',
                {
                    formatStartAt: this.formatStartAt.bind(this),
                },
                {
                    no_content: {
                        image_url: this.assetService.getPath(
                            'images/others/no-content.png',
                        ),
                        text: this.languageService.translate('text.no_content'),
                    },
                    row_count: 12,
                    sort: {
                        column: 'start_at',
                        order: 'desc',
                    },
                },
            );
            this.eventCardCollection.eventCardKnot =
                this._eventCardKnot.bind(this);
            this.eventCardCollection.eventAction = (params) => {
                params.set('query', this.query);
                params.set('organization_ids', this.organizationIds);
                params.set(
                    'fields',
                    'id,title,start_at,location,' +
                        this.organizationService.getAssociationTableFields(),
                );
                this.eventService.getAll(params).then((response) => {
                    const count = response.get<number>('count');
                    this.eventCardCollection.setCount(count);
                    const events = response.get<Objekt[]>('events');
                    this.eventCardCollection.setData(events);
                });
            };
            this.eventCardCollection.render();
        } else {
            this.eventCardCollection.refresh(1);
        }
    }

    private _eventCardKnot(cardKnot: Knot, event: Objekt): void {
        this._initEventActivity(cardKnot, event);
        this._initEventShowButton(cardKnot, event);
        this._initCardButtons(cardKnot, event);
        this.utilService.initOrganizationLink(cardKnot, event);
    }

    private _initEventActivity(cardKnot: Knot, event: Objekt): void {
        const cardItem = new Query('.card', cardKnot).getKnot();
        const startAt = DateIO.parse(event.get('start_at'));
        const cssClass = DateIO.isBefore(new Date(), startAt)
            ? 'active'
            : 'inactive';
        cardItem.addClass(cssClass);
    }

    private _initEventShowButton(cardKnot: Knot, event: Objekt): void {
        this.helper.link(
            'a.card',
            cardKnot,
            (href) => {
                this.state.go(href);
            },
            this.state.resolveUrl('events.show', {
                eventId: event.get('id'),
            }),
        );
    }

    private _initCardButtons(cardKnot: Knot, event: Objekt): void {
        // let actionsKnot = new Query('.actions', cardKnot).getKnot();
    }

    formatStartAt(startAt: string): string {
        return this.languageService.format(
            DateIO.parse(startAt),
            'format.datetime',
        );
    }
}

export const eventsIndexController = app.controller(
    resources.eventsIndexController,
    [
        resources.instances,
        resources.eventService,
        resources.organizationService,
        resources.utilService,
        resources.assetService,
        resources.languageService,
    ],
    EventsIndexController,
);
