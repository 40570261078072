import {
    DateIO,
    format,
    Instance,
    Knot,
    Objekt,
    pluck,
    TableCalculation,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { AdminService } from './adminService';
import { AssetService } from './assetService';
import { LanguageService } from './languageService';
import { UtilService } from './utilService';

export class AdminOrganizationService extends AdminService {
    constructor(
        instances: Instance,
        $languageService: LanguageService,
        $assetService: AssetService,
        private utilService: UtilService,
    ) {
        super(
            'organization-organizations-name',
            instances,
            $languageService,
            $assetService,
        );
    }

    accept(organization: Objekt) {
        const organizationId = organization.get('id');
        return this.http.patch(
            format('/api/v1/admin/organizations/{0}/accept.json', [
                organizationId,
            ]),
        );
    }

    decline(organization: Objekt) {
        const organizationId = organization.get('id');
        return this.http.patch(
            format('/api/v1/admin/organizations/{0}/decline.json', [
                organizationId,
            ]),
        );
    }

    getTableOptions(): object {
        return {
            no_content: {
                image_url: this.assetService.getPath(
                    'images/others/no-content.png',
                ),
                text: this.languageService.translate('text.no_content'),
            },
            sort: {
                column: 'created_at',
                order: 'desc',
            },
            row_count: 12,
            column: 'name',
            columns: [
                'image',
                'name',
                'sum_compute_area',
                'owners',
                'created_at',
                'active',
                'actions',
            ],
            sorted: ['name:asc', 'created_at', 'active:asc'],
            calculations: this.getTableCalculations(),
        };
    }

    getTableFields(): string {
        return [
            'id',
            'image',
            'name',
            'sum_compute_area',
            'owners.name',
            'created_at',
            'active',
        ].join(',');
    }

    getTableCalculations(): TableCalculation {
        return {
            image: (organization) => {
                // let color = organization.get('color');
                const imageWrapper = new Knot('div');
                imageWrapper.addClass('img-wrapper');
                /* imageWrapper.setStyle({
'background-color': color
});*/
                const imageKnot = new Knot('img');
                imageKnot.addClass('img-responsive');
                const defaultSrc = organization.get('image.thumb.url');
                imageKnot.setAttribute('src', defaultSrc);
                imageWrapper.appendChild(imageKnot);
                return imageWrapper;
            },
            sum_compute_area: (organization) => {
                const computeArea = organization.get('sum_compute_area', 0);
                return this.utilService.getComputeAreaInHa(computeArea);
            },
            owners: (organization) => {
                const owners = organization.get('owners', []);
                return pluck(owners, 'name').join(', ');
            },
            created_at: (organization) => {
                const createdAt = DateIO.parse(organization.get('created_at'));
                return this.languageService.format(
                    createdAt,
                    'format.datetime',
                );
            },
            active: (organization) => {
                const active = organization.get('active');
                const iconKnot = new Knot('em');
                iconKnot.addClass(['material-icons', 'text-success']);
                iconKnot.setHtml('check_circle');
                return active ? iconKnot : '';
            },
        };
    }
}

export const adminOrganizationService = app.service(
    resources.adminOrganizationService,
    [
        resources.instances,
        resources.languageService,
        resources.assetService,
        resources.utilService,
    ],
    AdminOrganizationService,
);
