import {
    DateIO,
    eachArray,
    Instance,
    Knot,
    TableCalculation,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { AssetService } from './assetService';
import { HuntService } from './huntService';
import { LanguageService } from './languageService';
import { LocationService } from './locationService';
import { MapService } from './mapService';
import { OrganizationService } from './organizationService';
import { PersonService } from './personService';

export class IndividualHuntService extends HuntService {
    constructor(
        instances: Instance,
        $languageService: LanguageService,
        $assetService: AssetService,
        $locationService: LocationService,
        $personService: PersonService,
        private organizationService: OrganizationService,
        $mapService: MapService,
    ) {
        super(
            'individual_hunt-individual_hunts-title',
            instances,
            $languageService,
            $assetService,
            $locationService,
            $personService,
            $mapService,
        );
    }

    getTableOptions(): object {
        return {
            no_content: {
                image_url: this.assetService.getPath(
                    'images/others/no-content.png',
                ),
                text: this.languageService.translate('text.no_content'),
            },
            sort: {
                column: 'start_at',
                order: 'desc',
            },
            row_count: 12,
            column: 'title',
            columns: [
                'organizationImage',
                'title',
                'location.address',
                'start_at',
                'end_at',
                'hunter_ids',
                'participant_ids',
                'trophy_ids',
                'actions',
            ],
            sorted: ['start_at', 'end_at'],
            calculations: this.getTableCalculations(),
        };
    }

    getTableFields(): string {
        return (
            'id,title,start_at,end_at,location,photo,description,hunters.id,hunters.name,participants.id,participants.name,trophies.id,trophies.full_name_with_quantity,class_type,' +
            this.organizationService.getAssociationTableFields()
        );
    }

    getTableCalculations(): TableCalculation {
        return {
            organizationImage: (hunt) => {
                return this.organizationService.getTableOrganizationImageCalculation(
                    hunt,
                );
            },
            title: (hunt) => {
                return this.helper.createLink(
                    hunt.get('title'),
                    (href) => {
                        this.state.go(href);
                    },
                    this.state.resolveUrl('hunts.show', {
                        huntId: hunt.get('id'),
                    }),
                );
            },
            start_at: (hunt) => {
                const startAt = DateIO.parse(hunt.get('start_at'));
                return this.languageService.format(startAt, 'format.datetime');
            },
            end_at: (hunt) => {
                const endAt = DateIO.parse(hunt.get('end_at'));
                return this.languageService.format(endAt, 'format.datetime');
            },
            hunter_ids: (hunt) => {
                const links: Knot[] = [];
                eachArray(hunt.get('hunters', []), (hunter) => {
                    const linkKnot = this.helper.createLink(
                        hunter.get('name'),
                        (href) => {
                            this.state.go(href);
                        },
                        this.state.resolveUrl('people.show', {
                            personId: hunter.get('id'),
                        }),
                    );
                    links.push(linkKnot);
                });
                return links;
            },
            participant_ids: (hunt) => {
                const links: Knot[] = [];
                eachArray(hunt.get('participants', []), (participant) => {
                    const linkKnot = this.helper.createLink(
                        participant.get('name'),
                        (href) => {
                            this.state.go(href);
                        },
                        this.state.resolveUrl('people.show', {
                            personId: participant.get('id'),
                        }),
                    );
                    links.push(linkKnot);
                });
                return links;
            },
            trophy_ids: (hunt) => {
                const links: Knot[] = [];
                eachArray(hunt.get('trophies', []), (trophy) => {
                    const linkKnot = this.helper.createLink(
                        trophy.get('full_name_with_quantity'),
                        (href) => {
                            this.state.go(href);
                        },
                        this.state.resolveUrl('trophies.show', {
                            trophyId: trophy.get('id'),
                        }),
                    );
                    links.push(linkKnot);
                });
                return links;
            },
        };
    }
}

export const individualHuntService = app.service(
    resources.individualHuntService,
    [
        resources.instances,
        resources.languageService,
        resources.assetService,
        resources.locationService,
        resources.personService,
        resources.organizationService,
        resources.mapService,
    ],
    IndividualHuntService,
);
