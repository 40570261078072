import { Instance, Knot, Objekt, TableCalculation } from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { AssetService } from './assetService';
import { LanguageService } from './languageService';
import { ModelService } from './modelService';

export class DeviceService extends ModelService {
    constructor(
        instances: Instance,
        $languageService: LanguageService,
        $assetService: AssetService,
    ) {
        super(
            'device-devices-name',
            instances,
            $languageService,
            $assetService,
        );
    }

    getAllByUser(userId: string, opt_params?: object) {
        return this.getAllBy('users', userId, opt_params);
    }

    createByUser(userId: string, device: Objekt) {
        return this.createBy('users', userId, device);
    }

    getTableOptions(): object {
        return {
            no_content: {
                image_url: this.assetService.getPath(
                    'images/others/no-content.png',
                ),
                text: this.languageService.translate('text.no_content'),
            },
            sort: {
                column: 'name',
                order: 'desc',
            },
            sorted: ['name'],
            row_count: 12,
            columns: ['name', 'identifier', 'token', 'actions'],
            calculations: this.getTableCalculations(),
        };
    }

    getTableFields(): string {
        return 'id,identifier,name,token';
    }

    getTableCalculations(): TableCalculation {
        return {
            token: (device) => {
                const hasToken = !!device.get('token');
                const iconKnot = new Knot('em');
                iconKnot.addClass(['material-icons']);
                if (hasToken) {
                    iconKnot.addClass('text-default');
                    iconKnot.setHtml('notifications');
                } else {
                    iconKnot.addClass('text-error');
                    iconKnot.setHtml('notifications_off');
                }
                return iconKnot;
            },
        };
    }
}

export const deviceService = app.service(
    resources.deviceService,
    [resources.instances, resources.languageService, resources.assetService],
    DeviceService,
);
