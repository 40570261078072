import {
    Deferred,
    eachArray,
    eq,
    Instance,
    Knot,
    Objekt,
    Promize,
    Query,
    Table,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { EarTagService } from '../services/earTagService';
import { GoogleChartService } from '../services/googleChartService';
import { ItemService } from '../services/itemService';
import { LanguageService } from '../services/languageService';
import { PlanPreyService } from '../services/planPreyService';
import { PlanService } from '../services/planService';
import { UserService } from '../services/userService';
import { UtilService } from '../services/utilService';
import { DashboardController } from './dashboardController';

export class PlansShowController extends DashboardController {
    planId: string;
    plan: Objekt;
    organizationId: string;
    btnPlansEditKnot: Knot<HTMLElement>;
    btnPlanPreysNewKnot: Knot<HTMLElement>;
    btnEarTagsNewKnot: Knot<HTMLElement>;
    btnItemsNewKnot: Knot<HTMLElement>;
    earTagsTable: Table;
    itemsTable: Table;
    planPreysTable: Table;
    isLoadedCharts: boolean;

    constructor(
        instances: Instance,
        private planService: PlanService,
        private planPreyService: PlanPreyService,
        private itemService: ItemService,
        private googleChartService: GoogleChartService,
        private earTagService: EarTagService,
        private utilService: UtilService,
        private userService: UserService,
        private languageService: LanguageService,
    ) {
        super(instances);
    }

    protected override _initLayout(): void {
        this.planId = this.state.getParam('planId');
        this.plan = new Objekt();

        this._initToolbarButtons();
        this._initTabPanel('plan-prey-chart');
        this._changeToolbarButtons();

        this._loadPlan().then(() => {
            this._drawContent();
        });
    }

    private _loadPlan(): Promize {
        const deferred = new Deferred();
        this.planService
            .get(this.planId, {
                fields: 'id,full_name,organization_id',
            })
            .then(
                (response) => {
                    this.plan = response.get('plan');
                    this.organizationId = this.plan.get('organization_id');
                    deferred.resolve();
                },
                () => {
                    deferred.reject();
                },
            );
        return deferred.promise();
    }

    private _initToolbarButtons(): void {
        this.helper.iconButton(
            '.btn-plans-index',
            this.dom,
            () => {
                this.state.goBack('plans.index');
            },
            '',
            true,
            [],
        );

        this.btnPlansEditKnot = this.helper.iconButton(
            '.btn-plans-edit',
            this.dom,
            () => {
                this.planService
                    .editDialog(this.plan)
                    .then(this.utilService.handleEditResponse('plans.index'));
            },
        );

        this.btnPlanPreysNewKnot = this.helper.iconButton(
            '.btn-plan-preys-new',
            this.dom,
            () => {
                this.planPreyService.newDialogByPlan(this.planId).then(() => {
                    this._drawContent();
                });
            },
        );

        this.btnEarTagsNewKnot = this.helper.iconButton(
            '.btn-ear-tags-new',
            this.dom,
            () => {
                this.earTagService.newDialogByPlan(this.planId).then(() => {
                    this._drawContent();
                });
            },
        );

        this.btnItemsNewKnot = this.helper.iconButton(
            '.btn-items-new',
            this.dom,
            () => {
                this.itemService.newDialogByPlan(this.planId).then(() => {
                    this.state.refresh(true);
                });
            },
        );
    }

    protected override _drawContent(): Promize | boolean {
        this.googleChartService
            .loadSDK(['corechart'])
            .then(this._drawCharts.bind(this));

        this._changeToolbarButtons();
        this._initPlanPreysTable();
        this._initEarTagsTable();
        this._initItemsTable();
        return true;
    }

    private _changeToolbarButtons(): void {
        this.utilService.showButton(
            this.btnPlansEditKnot,
            this.activeTab === 'plan-prey-chart',
        );

        this.utilService.showButton(
            this.btnEarTagsNewKnot,
            this.activeTab === 'ear-tag-table',
        );

        this.utilService.showButton(
            this.btnPlanPreysNewKnot,
            this.activeTab === 'plan-prey-table',
        );

        this.utilService.showButton(
            this.btnItemsNewKnot,
            this.activeTab === 'item-table',
        );
    }

    private _initEarTagsTable(): void {
        if (eq(this.activeTab, 'ear-tag-table')) {
            if (!this.earTagsTable) {
                this.earTagsTable = new Table(
                    this.dom,
                    '.ear-tags-table',
                    this.earTagService.getTableOptions(),
                );

                this.earTagsTable.setActions([
                    {
                        style: () => {
                            return [
                                'edit',
                                this.languageService.translate('buttons.edit'),
                                false,
                                !this.userService.hasAccessByOrganization(
                                    this.organizationId,
                                    'ear_tags_write',
                                ),
                            ];
                        },
                        click: (earTag) => {
                            this.earTagService.editDialog(earTag).then(() => {
                                this._drawContent();
                            });
                        },
                    },
                ]);

                this.earTagsTable.eventAction = (params) => {
                    params.set('fields', this.earTagService.getTableFields());
                    this.earTagService
                        .getAllByPlan(this.planId, params)
                        .then((response) => {
                            const count = response.get<number>('count');
                            this.earTagsTable.setCount(count);
                            const earTags = response.get<Objekt[]>('ear_tags');
                            this.earTagsTable.setData(earTags);
                        });
                };
                this.earTagsTable.render();
            } else {
                this.earTagsTable.refresh();
            }
        }
    }

    private _initItemsTable(): void {
        if (eq(this.activeTab, 'item-table')) {
            if (!this.itemsTable) {
                this.itemsTable = new Table(
                    this.dom,
                    '.items-table',
                    this.itemService.getTableOptions(),
                );

                this.itemsTable.setActions([
                    {
                        style: () => {
                            return [
                                'edit',
                                this.languageService.translate('buttons.edit'),
                                false,
                                !this.userService.hasAccessByOrganization(
                                    this.organizationId,
                                    'items_write',
                                ),
                            ];
                        },
                        click: (item) => {
                            this.itemService.editDialog(item).then(() => {
                                this.state.refresh(true);
                            });
                        },
                    },
                ]);

                this.itemsTable.eventAction = (params) => {
                    params.set('fields', this.itemService.getTableFields());
                    this.itemService
                        .getAllByPlan(this.planId, params)
                        .then((response) => {
                            const count = response.get<number>('count');
                            this.itemsTable.setCount(count);
                            const items = response.get<Objekt[]>('items');
                            this.itemsTable.setData(items);
                        });
                };
                this.itemsTable.render();
            } else {
                this.itemsTable.refresh();
            }
        }
    }

    private _initPlanPreysTable(): void {
        if (eq(this.activeTab, 'plan-prey-table')) {
            if (!this.planPreysTable) {
                this.planPreysTable = new Table(
                    this.dom,
                    '.preys-table',
                    this.planPreyService.getTableOptions(),
                );

                this.planPreysTable.setActions([
                    {
                        style: (planPrey) => {
                            return [
                                'edit',
                                this.languageService.translate('buttons.edit'),
                                !planPrey.get('id'),
                                !this.userService.hasAccessByOrganization(
                                    this.organizationId,
                                    'plan_preys_write',
                                ),
                            ];
                        },
                        click: (planPrey) => {
                            this.planPreyService
                                .editDialog(planPrey)
                                .then(() => {
                                    this._drawContent();
                                });
                        },
                    },
                ]);

                this.planPreysTable.eventAction = (params) => {
                    params.set('fields', this.planPreyService.getTableFields());
                    this.planPreyService
                        .getAllByPlan(this.planId, params)
                        .then((response) => {
                            const count = response.get<number>('count');
                            this.planPreysTable.setCount(count);
                            const planPreys =
                                response.get<Objekt[]>('plan_preys');
                            this.planPreysTable.setData(planPreys);
                        });
                };
                this.planPreysTable.render();
            } else {
                this.planPreysTable.refresh();
            }
        }
    }

    private _drawCharts(): void {
        if (eq(this.activeTab, 'plan-prey-chart')) {
            if (!this.isLoadedCharts) {
                const barChartOptions = {
                    width: '100%',
                    legend: 'none',
                    colors: ['#795548', '#FF9800'],
                    hAxis: {
                        textStyle: {
                            fontSize: 16,
                        },
                    },
                    vAxis: {
                        textStyle: {
                            fontSize: 16,
                        },
                    },
                    bar: {
                        groupWidth: 50,
                    },
                };

                this._drawPreysChart(
                    '.big-prey',
                    '.big-prey-chart',
                    'big',
                    barChartOptions,
                );
                this._drawPreysChart(
                    '.small-prey',
                    '.small-prey-chart',
                    'small',
                    barChartOptions,
                );
                this._drawPreysChart(
                    '.other-small-prey',
                    '.other-small-prey-chart',
                    'other_small',
                    barChartOptions,
                );
                this._drawPreysChart(
                    '.mixed-prey',
                    '.mixed-prey-chart',
                    'mixed',
                    barChartOptions,
                );
                this.isLoadedCharts = true;
            }
        }
    }

    private _drawPreysChart(
        containerClass: string,
        chartContainerClass: string,
        classification: string,
        barChartOptions: object,
    ) {
        this.planPreyService
            .getAllByPlanAndClassification(this.planId, classification, {
                fields: 'full_name,count,hunted_count',
            })
            .then((response) => {
                const planPreys = response.get<Objekt[]>('plan_preys');
                if (planPreys.length > 0) {
                    const containerKnot = new Query(
                        containerClass,
                        this.dom,
                    ).getKnot();
                    containerKnot.removeClass('hidden');

                    const barChartKnot = new Query(
                        chartContainerClass,
                        containerKnot,
                    ).getKnot();
                    const barChart = new google.visualization.BarChart(
                        barChartKnot.getNode(),
                    );

                    const data = new google.visualization.DataTable();
                    data.addColumn(
                        'string',
                        this.languageService.translate(
                            'mongoid.attributes.prey.full_name',
                        ),
                    );
                    data.addColumn(
                        'number',
                        this.languageService.translate(
                            'mongoid.attributes.prey.count',
                        ),
                    );
                    data.addColumn(
                        'number',
                        this.languageService.translate(
                            'mongoid.attributes.prey.hunted_count',
                        ),
                    );
                    eachArray(planPreys, (planPrey) => {
                        data.addRow([
                            planPrey.get('full_name'),
                            planPrey.get('count'),
                            planPrey.get('hunted_count'),
                        ]);
                    });
                    barChart.draw(data, barChartOptions);
                }
            });
    }

    exit(): void {
        super.exit();

        this.googleChartService.reset();
    }
}

export const plansShowController = app.controller(
    resources.plansShowController,
    [
        resources.instances,
        resources.planService,
        resources.planPreyService,
        resources.itemService,
        resources.googleChartService,
        resources.earTagService,
        resources.utilService,
        resources.userService,
        resources.languageService,
    ],
    PlansShowController,
);
