import { Flash, Instance, Objekt, Table } from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { AdminOrderService } from '../services/adminOrderService';
import { LanguageService } from '../services/languageService';
import { AdminController } from './adminController';

export class AdminOrdersIndexController extends AdminController {
    private table: Table;
    private flash: Flash;

    constructor(
        instances: Instance,
        private adminOrderService: AdminOrderService,
        private languageService: LanguageService,
    ) {
        super(instances);

        this.flash = instances.flash;
    }

    protected override _initToolbarButtons(): void {
        this.helper.iconButton(
            '.btn-admin-index',
            this.dom,
            () => {
                this.state.goBack('admin.index');
            },
            '',
            true,
            [],
        );
    }

    protected override _initTable(): void {
        if (!this.table) {
            this.table = new Table(
                this.dom,
                'table',
                this.adminOrderService.getTableOptions(),
            );

            this.table.setActions([
                {
                    style: () => {
                        return [
                            'description',
                            this.languageService.translate('buttons.view'),
                        ];
                    },
                    click: this._showOrderDialog.bind(this),
                },
                {
                    style: () => {
                        return [
                            'edit',
                            this.languageService.translate('buttons.edit'),
                        ];
                    },
                    click: (order) => {
                        this.adminOrderService
                            .editDialog(order, '/admin/')
                            .then(() => {
                                this._drawContent();
                            });
                    },
                },
            ]);

            this.table.eventAction = (params) => {
                params.set('query', this.query);
                params.set('fields', this.adminOrderService.getTableFields());
                this.adminOrderService.getAll(params).then((response) => {
                    const count = response.get<number>('count');
                    this.table.setCount(count);
                    const orders = response.get<Objekt[]>('orders');
                    this.table.setData(orders);
                });
            };

            this.table.render();
        } else {
            this.table.refresh();
        }
    }

    private _showOrderDialog(order: Objekt): void {
        const cleanOrder = order.denyKeys(['organization.', 'user.']);
        const orderText = JSON.stringify(cleanOrder).replace(/,"/g, ',<br>"');
        this.flash.addDefault(orderText, 0);
    }
}

export const adminOrdersIndexController = app.controller(
    resources.adminOrdersIndexController,
    [
        resources.instances,
        resources.adminOrderService,
        resources.languageService,
    ],
    AdminOrdersIndexController,
);
