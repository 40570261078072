import {
    Deferred,
    EventBus,
    format,
    Instance,
    Script,
    Service,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { ConfigService } from './configService';

export class FacebookPixelService extends Service {
    script: Script;
    eventBus: EventBus;

    constructor(
        instances: Instance,
        private configService: ConfigService,
    ) {
        super();

        this.script = instances.script;
        this.eventBus = instances.eventBus;
    }

    enter(): void {
        this.eventBus.set('state.change', (state) => {
            const url = state.get('url');
            this.sendPageView(url);
        });

        this._loadSDK();
    }

    private _asyncInit() {
        const deferred = new Deferred();
        if (window['fbq']) {
            window['fbq']('init', this.configService.get('facebook.pixel_id'));
            window['fbq']('track', 'PageView');
            deferred.resolve();
        } else {
            deferred.reject();
        }
        return deferred.promise();
    }

    private _loadSDK() {
        const deferred = new Deferred();

        const n = (window['fbq'] = function (...args) {
            n['callMethod']
                ? // eslint-disable-next-line prefer-spread
                  n['callMethod']['apply'](n, args)
                : n['queue']['push'](args);
        });
        if (!window['_fbq']) {
            window['_fbq'] = n;
        }
        n['push'] = n;
        n['loaded'] = true;
        n['version'] = '2.0';
        n['queue'] = [];

        const locale = this.configService.getLocale().replace('-', '_');
        this.script
            .load(
                'facebook-pixel-sdk',
                format('//connect.facebook.net/{0}/fbevents.js', [locale]),
            )
            .then(
                () => {
                    this._asyncInit().defer(deferred);
                },
                () => {
                    // If bocked by AdBlocker
                    deferred.resolve();
                },
            );
        return deferred.promise();
    }

    reset(): void {
        this.script.remove('facebook-pixel-sdk');
    }

    sendPageView(url: string): void {
        if (window['fbq']) {
            window['fbq']('trackCustom', 'virtualPageview', { url: url });
        }
    }

    track(eventName: string, opt_params?: object): void {
        if (window['fbq']) {
            window['fbq']('track', eventName, opt_params);
        }
    }
}

export const facebookPixelService = app.service(
    resources.facebookPixelService,
    [resources.instances, resources.configService],
    FacebookPixelService,
);
