import {
    Deferred,
    eq,
    GoogleMap,
    Instance,
    Knot,
    Objekt,
    Promize,
    Query,
    Table,
    Viewer,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { ItemService } from '../services/itemService';
import { LanguageService } from '../services/languageService';
import { MapService } from '../services/mapService';
import { TrophyService } from '../services/trophyService';
import { UserService } from '../services/userService';
import { UtilService } from '../services/utilService';
import { DashboardController } from './dashboardController';

export class TrophiesShowController extends DashboardController {
    viewer: Viewer;
    trophyId: string;
    trophy: Objekt;
    btnTrophiesEditKnot: Knot<HTMLElement>;
    btnItemsNewKnot: Knot<HTMLElement>;
    organizationId: string;
    itemsTable: Table;
    map: GoogleMap;

    constructor(
        instances: Instance,
        private mapService: MapService,
        private trophyService: TrophyService,
        private itemService: ItemService,
        private utilService: UtilService,
        private languageService: LanguageService,
        private userService: UserService,
    ) {
        super(instances);

        this.viewer = instances.viewer;
    }

    protected override _initLayout(): void {
        this.trophyId = this.state.getParam('trophyId');
        this.trophy = new Objekt();

        this._initToolbarButtons();
        this._initTabPanel('details');
        this._changeToolbarButtons();

        this._initViewer();
        this._initLinks('#details a');
        this._correctDates();

        this._loadTrophy().then(() => {
            this._drawContent();
        });
    }

    private _initToolbarButtons(): void {
        this.helper.iconButton(
            '.btn-trophies-index',
            this.dom,
            () => {
                this.state.goBack('trophies.index');
            },
            '',
            true,
            [],
        );

        this.btnTrophiesEditKnot = this.helper.iconButton(
            '.btn-trophies-edit',
            this.dom,
            () => {
                this.trophyService
                    .editDialog(this.trophy)
                    .then(
                        this.utilService.handleEditResponse('trophies.index'),
                    );
            },
        );

        this.btnItemsNewKnot = this.helper.iconButton(
            '.btn-items-new',
            this.dom,
            () => {
                this.itemService.newDialogByTrophy(this.trophyId).then(() => {
                    this.state.refresh(true);
                });
            },
        );
    }

    private _initViewer(): void {
        const imageBoxKnot = new Query('.image-box', this.dom).getKnot();
        imageBoxKnot.addEventListener('click', () => {
            const imageUrl = this.trophy.get<string>('photo.url');
            const fullName = this.trophy.get<string>('full_name');
            this.viewer.loadImage(imageUrl, fullName);
        });
    }

    private _correctDates(): void {
        this.utilService.correctDate(
            '.killing-at',
            'format.datetime',
            this.dom,
        );
    }

    private _loadTrophy(): Promize {
        const deferred = new Deferred();
        this.trophyService
            .get(this.trophyId, {
                fields: 'id,full_name,location,photo,class_type',
            })
            .then(
                (response) => {
                    this.trophy = response.get('trophy');
                    this.organizationId = this.trophy.get('organization_id');
                    deferred.resolve();
                },
                () => {
                    deferred.reject();
                },
            );
        return deferred.promise();
    }

    protected override _drawContent(): Promize | boolean {
        this._changeToolbarButtons();

        this._initMap();
        this._initItemsTable();
        return true;
    }

    private _changeToolbarButtons(): void {
        this.utilService.showButton(
            this.btnTrophiesEditKnot,
            this.activeTab === 'details',
        );

        this.utilService.showButton(
            this.btnItemsNewKnot,
            this.activeTab === 'item-table',
        );
    }

    private _initItemsTable(): void {
        if (eq(this.activeTab, 'item-table')) {
            if (!this.itemsTable) {
                this.itemsTable = new Table(
                    this.dom,
                    '.items-table',
                    this.itemService.getTableOptions(),
                );

                this.itemsTable.setActions([
                    {
                        style: () => {
                            return [
                                'edit',
                                this.languageService.translate('buttons.edit'),
                                false,
                                !this.userService.hasAccessByOrganization(
                                    this.organizationId,
                                    'items_write',
                                ),
                            ];
                        },
                        click: (item) => {
                            this.itemService.editDialog(item).then(() => {
                                this.state.refresh(true);
                            });
                        },
                    },
                ]);

                this.itemsTable.eventAction = (params) => {
                    params.set('fields', this.itemService.getTableFields());
                    this.itemService
                        .getAllByTrophy(this.trophyId, params)
                        .then((response) => {
                            const count = response.get<number>('count');
                            this.itemsTable.setCount(count);
                            const items = response.get<Objekt[]>('items');
                            this.itemsTable.setData(items);
                        });
                };
                this.itemsTable.render();
            } else {
                this.itemsTable.refresh();
            }
        }
    }

    private _initMap(): void {
        if (eq(this.activeTab, 'details')) {
            if (!this.map) {
                this.map = this.mapService.createGoogleMap(this.dom);

                this.map.setMarkerIcon(
                    'location',
                    this.mapService.getIcon('location', '#795548'),
                );

                this.map.eventMarkerClick = this._eventMarkerClick.bind(this);
            }
            const trophyId = this.trophy.get<string>('id');
            const location = this.trophy.get<Objekt>('location', new Objekt());
            this.map.createMarker(
                trophyId,
                '',
                'location',
                location.get('latitude'),
                location.get('longitude'),
                this.trophy,
            );
        }
    }

    private _eventMarkerClick(trophy: Objekt): void {
        const trophyId = trophy.get<string>('id');
        const address = this.trophy.get<string>('location.address');
        this.map.openInfoWindow(trophyId, address);
    }

    exit(): void {
        super.exit();

        this.mapService.removeGoogleMap(this.map);
    }
}

export const trophiesShowController = app.controller(
    resources.trophiesShowController,
    [
        resources.instances,
        resources.mapService,
        resources.trophyService,
        resources.itemService,
        resources.utilService,
        resources.languageService,
        resources.userService,
    ],
    TrophiesShowController,
);
