import {
    ceil,
    CheckboxField,
    Controller,
    DateIO,
    DateTimeField,
    debounce,
    Depot,
    Flash,
    Form,
    format,
    Helper,
    Instance,
    isNull,
    isUndefined,
    Knot,
    Objekt,
    Query,
    SelectField,
    State,
    TextField,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { AssetService } from '../services/assetService';
import { ConfigService } from '../services/configService';
import { CouponService } from '../services/couponService';
import { LanguageService } from '../services/languageService';
import { LicenseService } from '../services/licenseService';
import { OrderService } from '../services/orderService';
import { SiteService } from '../services/siteService';
import { UserService } from '../services/userService';

export class OrdersNewController extends Controller {
    currentUser: Objekt;
    localDepot: Depot;
    orderKey: string;
    licenseTypeKey: string;
    exchangeRate: Objekt;
    couponDiscount: number;
    priceBox: Knot<HTMLElement>;
    discountBox: Knot<HTMLElement>;
    licenseBox: Knot<HTMLElement>;
    licenseCaptionBox: Knot<HTMLElement>;
    isCompanyField: CheckboxField;
    taxNumberField: TextField;
    organizationField: SelectField;
    couponCodeField: TextField;
    licenseTypeField: SelectField;
    yearField: DateTimeField;
    private helper: Helper;
    private state: State;
    private dom: Knot;
    private flash: Flash;

    constructor(
        instances: Instance,
        private orderService: OrderService,
        private couponService: CouponService,
        private configService: ConfigService,
        private siteService: SiteService,
        private licenseService: LicenseService,
        private userService: UserService,
        private languageService: LanguageService,
        private assetService: AssetService,
    ) {
        super();

        this.localDepot = instances.localDepot;
        this.helper = instances.helper;
        this.state = instances.state;
        this.dom = instances.dom;
        this.flash = instances.flash;
    }

    enter(): void {
        this._initLayout();
    }

    protected _initLayout(): void {
        this.currentUser = this.userService.getUser();
        this.orderKey = 'order.data';
        this.licenseTypeKey = 'order.license-type';
        this.exchangeRate = new Objekt();
        this.couponDiscount = 0;

        this._initToolbarButtons();
        this._initElements();
        this._initOrderForm();
    }

    private _initToolbarButtons(): void {
        this.helper.iconButton(
            '.btn-site-landing-page',
            this.dom,
            () => {
                this.state.goBack('site.landing-page');
            },
            '',
            true,
            [],
        );
    }

    private _initElements(): void {
        this.priceBox = new Query('.price', this.dom).getKnot();
        this.discountBox = new Query('.discount', this.dom).getKnot();
        this.licenseBox = new Query('.license', this.dom).getKnot();
        this.licenseCaptionBox = new Query(
            'h3.license-caption',
            this.dom,
        ).getKnot();
    }

    private _getOrder(): Objekt {
        const order = new Objekt({
            order: {
                first_name: this.currentUser.get('first_name'),
                last_name: this.currentUser.get('last_name'),
                email: this.currentUser.get('email'),
                phone: this.currentUser.get('phone'),
            },
        });

        const storageOrder = this.localDepot.get(this.orderKey);
        if (storageOrder) {
            const oldOrder = new Objekt(storageOrder);
            order.set('order', oldOrder.get('order'));
            order.set('user', oldOrder.get('user'));
        }

        const licenseType = this.state.getParam('licenseType');
        if (licenseType) {
            order.set('order.license_type', licenseType);
        }

        const organizationId = this.state.getParam('organizationId');
        if (organizationId) {
            order.set('order.organization_id', organizationId);
        }

        return order;
    }

    private _initOrderForm(): void {
        const order = this._getOrder();
        const form = new Form(this.dom);
        form.setModel(order);

        const submitField = form.findByModel('submit');
        const userCaptchaField = form.findByModel('user.captcha');
        userCaptchaField.eventChange = (_value) => {
            submitField.setDisabled(false);
        };

        form.eventSubmit = (formData) => {
            form.lock();
            const orderData = formData;
            const organizationId = orderData.get<string>(
                'order.organization_id',
            );
            this.orderService
                .createByOrganization(organizationId, orderData)
                .then(
                    (response) => {
                        this.localDepot.set(this.orderKey, orderData);
                        this.localDepot.remove(this.licenseTypeKey);
                        this.flash.addMessage(response.get('message'));
                        form.setModel(response);
                        const redirectUrl = response.get('order.redirect_url');
                        if (redirectUrl) {
                            this.state.redirect(
                                response.get('order.redirect_url'),
                            );
                        } else {
                            this.state.go('site.home');
                        }
                    },
                    (response) => {
                        this.flash.addMessage(response.get('message'));
                        form.unlock();
                        form.setErrors(response.get('errors'));
                    },
                );
        };
        form.eventReset = () => {
            this.state.go('site.landing-page');
        };

        this.isCompanyField = form.findByModel('order.is_company');
        this.taxNumberField = form.findByModel('order.tax_number');

        this.isCompanyField.eventChange = (isCompany) => {
            if (isCompany) {
                this.taxNumberField.setRequired(true);
            } else {
                this.taxNumberField.setRequired(false);
            }
        };

        this.organizationField = form.findByModel('order.organization_id');
        this.couponCodeField = form.findByModel('order.coupon_code');
        this.licenseTypeField = form.findByModel('order.license_type');
        this.yearField = form.findByModel('order.year_id');

        this.organizationField.eventChange = (organizationId) => {
            this.state.setParam('organizationId', organizationId);
            this._checkCouponCode();
            this._setLicense();
        };

        this.couponCodeField.eventChange = debounce(() => {
            this._checkCouponCode();
        });

        this.licenseTypeField.eventChange = (licenseType) => {
            this.state.setParam('licenseType', licenseType);
            this._setAmount();
            this._setLicense();
        };

        this.yearField.eventChange = () => {
            this._setAmount();
            this._setLicense();
        };

        this._setLicense();
        this._loadExchangeRate();
    }

    private _loadExchangeRate(): void {
        this.siteService.getExchangeRate().then((response) => {
            this.exchangeRate = response.get('exchange_rate');
            this._setAmount();
        });
    }

    private _setPriceBox(
        licenseType: string,
        yearId: number,
        couponDiscount: number,
    ): void {
        const originalPrice =
            this.configService.get<number>(
                format('license_types.{0}', [licenseType]),
            ) * yearId;
        const discount = this._getDiscount(yearId, couponDiscount);
        const price = originalPrice * (1 - discount);
        const rate = this.exchangeRate.get<number>('rate');
        const currency = this.exchangeRate.get<string>('currency');
        const amount = this.languageService.currency(price * rate, currency);
        const caption = format('{0}: <b>{1}</b>', [
            this.languageService.translate('text.orders.new.sum_payment'),
            amount,
        ]);
        this.priceBox.setHtml(caption);
    }

    private _setLicense(): void {
        let startAt = new Date();
        const yearId = this.yearField.getValue();
        const organizationId = this.organizationField.getValue();
        if (organizationId) {
            this.licenseService
                .getAllByOrganization(organizationId, {
                    fields: 'license_type_text,expired',
                    column: 'expired',
                    order: 'desc',
                    limit: 1,
                })
                .then((response) => {
                    const licenses = response.get<Objekt[]>('licenses');
                    let licenseType = '';
                    if (licenses.length === 1) {
                        const license = licenses[0];
                        startAt = DateIO.parse(
                            license.get('expired'),
                            'YYYY-MM-DD',
                        );
                        licenseType = license.get('license_type_text');
                    }
                    this._setLicenseBox(startAt, yearId, licenseType);
                });
        } else {
            this._setLicenseBox(startAt, yearId);
        }
    }

    private _setLicenseBox(
        startAt: Date,
        yearId: number,
        opt_licenseType: string | undefined = '',
    ): void {
        const isRenew = DateIO.isBefore(new Date(), startAt);
        const description = isRenew
            ? this.languageService.translate('text.orders.new.renew_license', {
                  license_type: opt_licenseType,
              })
            : this.languageService.translate('text.orders.new.new_license');
        const descriptionCssClass = isRenew ? 'text-info' : 'text-success';
        const licenseCaptionContent = format('<div class="{1}">{0}</div>', [
            description,
            descriptionCssClass,
        ]);
        this.licenseCaptionBox.setHtml(licenseCaptionContent);

        const endAt = isRenew
            ? DateIO.addYears(startAt, yearId)
            : DateIO.addYears(new Date(), yearId);
        const startAtFormat = this.languageService.format(
            new Date(),
            'format.datetime',
        );
        const endAtFormat = this.languageService.format(
            endAt,
            'format.datetime',
        );
        const newLicenseType = this.licenseTypeField.getValue();
        const newLicenseName = this.languageService.translate(
            format('order.license_type.{0}', [newLicenseType]),
            null,
            this.languageService.translate('order.license_type.unknown'),
        );
        const licenseBoxContent = format(
            '<div class="image"><img src="{4}" alt="" /></div><div class="content"><h4>{3}</h4><h5>{0}:</h5><div>{1} ‒ <b>{2}</b><div></div>',
            [
                this.languageService.translate('text.orders.new.expired'),
                startAtFormat,
                endAtFormat,
                newLicenseName,
                this.assetService.getPath(
                    'images/licenses/' + newLicenseType + '.png',
                ),
            ],
        );
        this.licenseBox.setHtml(licenseBoxContent);
    }

    private _getDiscount(yearId: number, couponDiscount: number): number {
        const ratio = this.configService.get<number>('license_types.ratio');
        yearId = isNull(yearId) || isUndefined(yearId) ? 0 : yearId;
        const ratioDiscount = (yearId - 1) * ratio;
        let discount = couponDiscount / 100;
        discount += ratioDiscount;
        return discount > 1 ? 1 : discount;
    }

    private _setDiscountBox(yearId: number, couponDiscount: number): void {
        const discount = this._getDiscount(yearId, couponDiscount);
        const discountInPercent = ceil(discount * 100, 1);
        const discountText = format('{0}%', [discountInPercent]);
        const discountCssClass =
            discountInPercent > 0 ? 'text-success' : 'text-default';
        const caption = format('{0}: <span class="{2}">{1}</span>', [
            this.languageService.translate('text.orders.new.discount'),
            discountText,
            discountCssClass,
        ]);
        this.discountBox.setHtml(caption);
    }

    private _setAmount(): void {
        this._setPriceBox(
            this.licenseTypeField.getValue(),
            this.yearField.getValue(),
            this.couponDiscount,
        );
        this._setDiscountBox(this.yearField.getValue(), this.couponDiscount);
    }

    private _checkCouponCode(): void {
        const organizationId = this.organizationField.getValue();
        const couponCode = this.couponCodeField.getValue();
        if (organizationId && couponCode) {
            this.couponService
                .checkCodeByOrganization(organizationId, couponCode)
                .then(
                    (response) => {
                        this.flash.addMessage(response.get('message'));
                        this.couponDiscount = response.get('coupon.discount');
                        this._setAmount();
                    },
                    () => {
                        this.couponDiscount = 0;
                        this._setAmount();
                    },
                );
        } else {
            this.couponDiscount = 0;
            this._setAmount();
        }
    }
}

export const ordersNewController = app.controller(
    resources.ordersNewController,
    [
        resources.instances,
        resources.orderService,
        resources.couponService,
        resources.configService,
        resources.siteService,
        resources.licenseService,
        resources.userService,
        resources.languageService,
        resources.assetService,
    ],
    OrdersNewController,
);
