import {
    CardCollection,
    DateIO,
    Dialog,
    eq,
    Flash,
    Form,
    format,
    Instance,
    Knot,
    noop,
    Objekt,
    Promize,
    Query,
    Table,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { AssetService } from '../services/assetService';
import { BigGameTrophyService } from '../services/bigGameTrophyService';
import { HuntService } from '../services/huntService';
import { IndividualHuntService } from '../services/individualHuntService';
import { LanguageService } from '../services/languageService';
import { OrganizationService } from '../services/organizationService';
import { SmallGameTrophyService } from '../services/smallGameTrophyService';
import { SocialHuntService } from '../services/socialHuntService';
import { TrophyService } from '../services/trophyService';
import { UtilService } from '../services/utilService';
import { DashboardController } from './dashboardController';

export class HuntsIndexController extends DashboardController {
    btnSocialHuntsNewKnot: Knot<HTMLElement>;
    btnIndividualHuntsNewKnot: Knot<HTMLElement>;
    btnHuntsRegisterKnot: Knot<HTMLElement>;
    huntCardCollection: CardCollection;
    individualHuntsTable: Table;
    private flash: Flash;
    private dialog: Dialog;

    constructor(
        instances: Instance,
        private trophyService: TrophyService,
        private huntService: HuntService,
        private socialHuntService: SocialHuntService,
        private individualHuntService: IndividualHuntService,
        private smallGameTrophyService: SmallGameTrophyService,
        private bigGameTrophyService: BigGameTrophyService,
        private organizationService: OrganizationService,
        private assetService: AssetService,
        private utilService: UtilService,
        private languageService: LanguageService,
    ) {
        super(instances);

        this.flash = instances.flash;
        this.dialog = instances.dialog;
    }

    protected override _initLayout(): void {
        this._initToolbarButtons();
        this._initFilterForm();
        this._initTabPanel('individual');
        this._changeToolbarButtons();

        this._drawContent();
    }

    private _initToolbarButtons(): void {
        this.helper.iconButton(
            '.btn-site-home',
            this.dom,
            () => {
                this.state.goBack('site.home');
            },
            '',
            true,
            [],
        );

        this.btnSocialHuntsNewKnot = this.helper.iconButton(
            '.btn-social-hunts-new',
            this.dom,
            () => {
                this.socialHuntService
                    .newDialog('organizations', 'organization_id')
                    .then((hunt) => {
                        this.chooseTrophyDialogByHunt(hunt);
                    });
            },
        );

        this.btnIndividualHuntsNewKnot = this.helper.iconButton(
            '.btn-individual-hunts-new',
            this.dom,
            () => {
                this.individualHuntService
                    .newDialog('organizations', 'organization_id')
                    .then((hunt) => {
                        this.chooseTrophyDialogByHunt(hunt);
                    });
            },
        );

        this.btnHuntsRegisterKnot = this.helper.iconButton(
            '.btn-hunts-register',
            this.dom,
            () => {
                this._registerHuntDialog();
            },
        );
    }

    private _initHuntCardCollection(): void {
        if (eq(this.activeTab, 'social')) {
            if (!this.huntCardCollection) {
                this.huntCardCollection = new CardCollection(
                    this.dom,
                    '.hunt-collection',
                    {
                        formatStartAt: this.formatStartAt.bind(this),
                    },
                    {
                        no_content: {
                            image_url: this.assetService.getPath(
                                'images/others/no-content.png',
                            ),
                            text: this.languageService.translate(
                                'text.no_content',
                            ),
                        },
                        row_count: 12,
                        sort: {
                            column: 'start_at',
                            order: 'desc',
                        },
                    },
                );
                this.huntCardCollection.eventCardKnot =
                    this._huntCardKnot.bind(this);
                this.huntCardCollection.eventAction = (params) => {
                    params.set('query', this.query);
                    params.set('organization_ids', this.organizationIds);
                    params.set(
                        'fields',
                        'id,title,start_at,location,photo,' +
                            this.organizationService.getAssociationTableFields(),
                    );
                    this.socialHuntService.getAll(params).then((response) => {
                        const count = response.get<number>('count');
                        this.huntCardCollection.setCount(count);
                        const socialHunts =
                            response.get<Objekt[]>('social_hunts');
                        this.huntCardCollection.setData(socialHunts);
                    });
                };

                this.huntCardCollection.render();
            } else {
                this.huntCardCollection.refresh(1);
            }
        }
    }

    private _initHuntTable(): void {
        if (eq(this.activeTab, 'individual')) {
            if (!this.individualHuntsTable) {
                this.individualHuntsTable = new Table(
                    this.dom,
                    '.individual-hunts-table',
                    this.individualHuntService.getTableOptions(),
                );

                this.individualHuntsTable.setActions([
                    {
                        style: (hunt) => {
                            return [
                                'info',
                                hunt.get('description'),
                                false,
                                !hunt.get('description'),
                            ];
                        },
                        click: noop(),
                    },
                    {
                        style: () => {
                            return [
                                'edit',
                                this.languageService.translate('buttons.edit'),
                                false,
                                false,
                            ];
                        },
                        click: (hunt) => {
                            this.huntService.editDialog(hunt).then(() => {
                                this._drawContent();
                            });
                        },
                    },
                ]);

                this.individualHuntsTable.eventAction = (params) => {
                    params.set('query', this.query);
                    params.set('organization_ids', this.organizationIds);
                    params.set(
                        'fields',
                        this.individualHuntService.getTableFields(),
                    );
                    this.individualHuntService
                        .getAll(params)
                        .then((response) => {
                            const count = response.get<number>('count');
                            this.individualHuntsTable.setCount(count);
                            const individualHunts =
                                response.get<Objekt[]>('individual_hunts');
                            this.individualHuntsTable.setData(individualHunts);
                        });
                };

                this.individualHuntsTable.render();
            } else {
                this.individualHuntsTable.refresh();
            }
        }
    }

    protected override _drawContent(): Promize | boolean {
        this._changeToolbarButtons();

        this._initHuntCardCollection();
        this._initHuntTable();
        return true;
    }

    private _changeToolbarButtons(): void {
        this.utilService.showButton(
            this.btnSocialHuntsNewKnot,
            this.activeTab === 'social',
        );

        this.utilService.showButton(
            this.btnIndividualHuntsNewKnot,
            this.activeTab === 'individual',
        );

        this.utilService.showButton(
            this.btnHuntsRegisterKnot,
            this.activeTab === 'individual',
        );
    }

    private _huntCardKnot(cardKnot: Knot, hunt: Objekt): void {
        this._initHuntActivity(cardKnot, hunt);
        this._initHuntShowButton(cardKnot, hunt);
        this._initCardButtons(cardKnot, hunt);
        this.utilService.initOrganizationLink(cardKnot, hunt);
    }

    private _initHuntActivity(cardKnot: Knot, hunt: Objekt): void {
        const cardItem = new Query('.card', cardKnot).getKnot();
        const startAt = DateIO.parse(hunt.get('start_at'));
        const cssClass = DateIO.isBefore(new Date(), startAt)
            ? 'active'
            : 'inactive';
        cardItem.addClass(cssClass);
    }

    private _initHuntShowButton(cardKnot: Knot, hunt: Objekt): void {
        this.helper.link(
            'a.card',
            cardKnot,
            (href) => {
                this.state.go(href);
            },
            this.state.resolveUrl('hunts.show', {
                huntId: hunt.get('id'),
            }),
        );
    }

    private _initCardButtons(cardKnot: Knot, hunt: Objekt): void {
        // let actionsKnot = new Query('.actions', cardKnot).getKnot();
    }

    chooseTrophyDialogByHunt(hunt: Objekt): void {
        const huntId = hunt.get<string>('id');
        this._drawContent();
        this.trophyService.chooseTrophyDialog().then((classification) => {
            if (classification === 'small') {
                this.smallGameTrophyService.newDialogByHunt(huntId).then(() => {
                    this._drawContent();
                });
            } else if (classification === 'big') {
                this.bigGameTrophyService.newDialogByHunt(huntId).then(() => {
                    this._drawContent();
                });
            }
        });
    }

    formatStartAt(startAt: string): string {
        return this.languageService.format(
            DateIO.parse(startAt),
            'format.datetime',
        );
    }

    private _registerHuntDialog(): void {
        this.dialog
            .loadTemplate(
                format('/client/v1/individual-hunts/register.html', []),
            )
            .then((dialogKnot) => {
                const form = new Form(dialogKnot);
                form.eventSubmit = (formData) => {
                    form.lock();
                    const huntData = formData.get<Objekt>('individual_hunt');
                    const organizationId = formData.get<string>(
                        'individual_hunt.organization_id',
                    );
                    this.individualHuntService
                        .createByOrganization(organizationId, huntData)
                        .then(
                            (response) => {
                                this.flash.addMessage(response.get('message'));
                                this.dialog.close();
                                this._drawContent();
                            },
                            (response) => {
                                this.flash.addMessage(response.get('message'));
                                form.unlock();
                                form.setErrors(response.get('errors'));
                            },
                        );
                };
                form.eventReset = () => {
                    this.dialog.close();
                };

                this.individualHuntService.handleForm(form);
                this.dialog.open();
            });
    }
}

export const huntsIndexController = app.controller(
    resources.huntsIndexController,
    [
        resources.instances,
        resources.trophyService,
        resources.huntService,
        resources.socialHuntService,
        resources.individualHuntService,
        resources.smallGameTrophyService,
        resources.bigGameTrophyService,
        resources.organizationService,
        resources.assetService,
        resources.utilService,
        resources.languageService,
    ],
    HuntsIndexController,
);
