import {
    Async,
    EventBus,
    format,
    GoogleMap,
    IconOptions,
    Instance,
    isUndefined,
    Knot,
    LocationField,
    Objekt,
    Script,
    Service,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { AssetService } from './assetService';
import { ConfigService } from './configService';

export class MapService extends Service {
    eventBus: EventBus;
    script: Script;
    assetIcons: { [key: string]: IconOptions };
    colorSchemeChangeSubscription: Function;
    locationField: LocationField;

    constructor(
        instances: Instance,
        private assetService: AssetService,
        private configService: ConfigService,
    ) {
        super();

        this.eventBus = instances.eventBus;
        this.script = instances.script;
    }

    enter() {
        this._initAssetIcons();

        return this._loadSDK();
    }

    private _loadSDK() {
        const apiKey = this.configService.get<string>('google.api_key');
        const async = new Async();
        const calls = [
            () => {
                return this.script.load(
                    'google-map-sdk',
                    format(
                        'https://maps.googleapis.com/maps/api/js?libraries=geometry,visualization&key={0}',
                        [apiKey],
                    ),
                );
            },
        ];
        return async.serial(calls);
    }

    private _initAssetIcons(): void {
        this.assetIcons = {
            huntingFeeder: {
                url: 'images/markers/hunting-feeder.png',
                size: [28, 21],
                origin: [0, 0],
                anchor: [14, 21],
                coords: [0, 0, 0, 28, 28, 21, 21, 0],
            },
            huntingSurface: {
                url: 'images/markers/hunting-surface.png',
                size: [32, 30],
                origin: [0, 0],
                anchor: [16, 30],
                coords: [0, 0, 0, 32, 32, 30, 30, 0],
            },
            huntingStand: {
                url: 'images/markers/hunting-stand.png',
                size: [32, 49],
                origin: [0, 0],
                anchor: [16, 49],
                coords: [0, 0, 0, 32, 32, 49, 49, 0],
            },
            huntingHome: {
                url: 'images/markers/hunting-home.png',
                size: [40, 37],
                origin: [0, 0],
                anchor: [20, 37],
                coords: [0, 0, 0, 40, 40, 37, 37, 0],
            },
            location: {
                url: 'images/markers/location.png',
                size: [48, 48],
                origin: [0, 0],
                anchor: [24, 48],
                coords: [0, 0, 0, 48, 48, 48, 48, 0],
            },
        };
    }

    createGoogleMap(
        dom: Knot,
        opt_selector: string | undefined = '.map',
        opt_options: (object | null) | undefined = null,
        opt_callback:
            | (((arg0: GoogleMap, arg1: string) => any) | null)
            | undefined = null,
    ): GoogleMap {
        const callback = (scheme) => {
            opt_callback?.(map, scheme);
        };

        const options = opt_options || {
            mapTypeId: 'custom',
            mapTypeControlOptions: {
                mapTypeIds: ['custom'],
            },
        };
        const map = new GoogleMap(dom, opt_selector, options);

        let colorScheme = this.configService.get<string>('colorScheme');
        this._setColorSchemeOnGoogleMap(map, colorScheme);
        callback(colorScheme);

        this.colorSchemeChangeSubscription = this.eventBus.set(
            'app.colorSchemeChange',
            (appColorScheme) => {
                colorScheme = appColorScheme;
                this._setColorSchemeOnGoogleMap(map, appColorScheme);
                this._setColorSchemeOnLocationField(appColorScheme);
                callback(colorScheme);
            },
        );

        map.eventMapTypeChange = (mapType) => {
            callback(colorScheme);
        };

        return map;
    }

    private _setColorSchemeOnGoogleMap(
        map: GoogleMap,
        colorScheme: string,
    ): void {
        if (colorScheme === 'dark') {
            map.setCustomMapStyle('custom', 'Terrain', this.mapStyleDark());
        } else {
            map.setCustomMapStyle('custom', 'Terrain', this.mapStyleLight());
        }
    }

    handleColorSchemeOnLocationField(locationField: LocationField): void {
        this.locationField = locationField;

        this.locationField.setMapType('custom');

        const colorScheme = this.configService.get<string>('colorScheme');
        this._setColorSchemeOnLocationField(colorScheme);
    }

    private _setColorSchemeOnLocationField(colorScheme: string): void {
        if (this.locationField) {
            if (colorScheme === 'dark') {
                this.locationField.setCustomMapStyle(
                    'custom',
                    'Terrain',
                    this.mapStyleDark(),
                );
            } else {
                this.locationField.setCustomMapStyle(
                    'custom',
                    'Terrain',
                    this.mapStyleLight(),
                );
            }
        }
    }

    removeGoogleMap(map: GoogleMap): void {
        if (map) {
            this.eventBus.remove(
                'app.colorSchemeChange',
                this.colorSchemeChangeSubscription,
            );
        }
    }

    getIcon(iconName: string, color: string): IconOptions {
        const assetIcon = this.assetIcons[iconName];
        return {
            ...assetIcon,
            url: this.assetService.getManipulateColorPath(
                assetIcon['url'],
                color,
            ),
        };
    }

    setBuildingIcons(
        map: GoogleMap,
        color: string,
        opt_postfix: string | undefined = '',
    ): void {
        map.setMarkerIcon(
            'feeder' + opt_postfix,
            this.getIcon('huntingFeeder', color),
        );
        map.setMarkerIcon(
            'surface' + opt_postfix,
            this.getIcon('huntingSurface', color),
        );
        map.setMarkerIcon(
            'stand' + opt_postfix,
            this.getIcon('huntingStand', color),
        );
        map.setMarkerIcon(
            'home' + opt_postfix,
            this.getIcon('huntingHome', color),
        );
        map.setMarkerIcon(
            'location' + opt_postfix,
            this.getIcon('location', color),
        );
    }

    getHeatMapOptions(): object {
        return {
            opacity: 0.6,
            radius: null,
            gradient: [
                'rgba(107, 174, 214, 0.2)',
                'rgba(0, 0, 0, 1)',
                'rgba(254, 217, 118, 1)',
                'rgba(254, 178, 76, 1)',
                'rgba(253, 141, 60, 1)',
                'rgba(240, 59, 32, 1)',
                'rgba(189, 0, 38, 1)',
            ],
        };
    }

    getAreaPolygonOptions(opt_options?: object): object {
        return new Objekt({
            strokeColor: '#FF9800',
            fillColor: '#436938',
        }).merge(opt_options);
    }

    getWeightedLocations(
        locations: Array<{ latitude: number; longitude: number }>,
    ): Array<{ latitude: number; longitude: number; weight: number }> {
        const locationCounter = {};
        for (const location of locations) {
            const key = `${location.latitude}-${location.longitude}`;
            if (isUndefined(locationCounter[key])) {
                locationCounter[key] = 0;
            }
            locationCounter[key] += 1;
        }
        const weightedLocations = [];
        for (const key in locationCounter) {
            // eslint-disable-next-line no-prototype-builtins
            if (locationCounter.hasOwnProperty(key)) {
                const [latitudeStr, longitudeStr] = key.split('-');
                const latitude = parseFloat(latitudeStr);
                const longitude = parseFloat(longitudeStr);
                // TODO: remove if validation for geo_point is ready and all data is valid
                if (latitude !== 0 && longitude !== 0) {
                    weightedLocations.push({
                        latitude: latitude,
                        longitude: longitude,
                        weight: locationCounter[key],
                    });
                }
            }
        }
        return weightedLocations;
    }

    mapStyleLight(): Array<google.maps.MapTypeStyle | null> {
        return [
            {
                elementType: 'geometry',
                stylers: [
                    {
                        color: '#ebe3cd',
                    },
                ],
            },
            {
                elementType: 'labels.text.fill',
                stylers: [
                    {
                        color: '#523735',
                    },
                ],
            },
            {
                elementType: 'labels.text.stroke',
                stylers: [
                    {
                        color: '#f5f1e6',
                    },
                ],
            },
            {
                featureType: 'administrative',
                elementType: 'geometry.stroke',
                stylers: [
                    {
                        color: '#c9b2a6',
                    },
                ],
            },
            {
                featureType: 'administrative.land_parcel',
                elementType: 'geometry.stroke',
                stylers: [
                    {
                        color: '#dcd2be',
                    },
                ],
            },
            {
                featureType: 'administrative.land_parcel',
                elementType: 'labels.text.fill',
                stylers: [
                    {
                        color: '#ae9e90',
                    },
                ],
            },
            {
                featureType: 'landscape.natural',
                elementType: 'geometry',
                stylers: [
                    {
                        color: '#dfd2ae',
                    },
                ],
            },
            {
                featureType: 'poi',
                elementType: 'geometry',
                stylers: [
                    {
                        color: '#dfd2ae',
                    },
                ],
            },
            {
                featureType: 'poi',
                elementType: 'labels.text.fill',
                stylers: [
                    {
                        color: '#93817c',
                    },
                ],
            },
            {
                featureType: 'poi.park',
                elementType: 'geometry.fill',
                stylers: [
                    {
                        color: '#a5b076',
                    },
                ],
            },
            {
                featureType: 'poi.park',
                elementType: 'labels.text.fill',
                stylers: [
                    {
                        color: '#447530',
                    },
                ],
            },
            {
                featureType: 'road',
                elementType: 'geometry',
                stylers: [
                    {
                        color: '#f5f1e6',
                    },
                ],
            },
            {
                featureType: 'road.arterial',
                elementType: 'geometry',
                stylers: [
                    {
                        color: '#fdfcf8',
                    },
                ],
            },
            {
                featureType: 'road.highway',
                elementType: 'geometry',
                stylers: [
                    {
                        color: '#f8c967',
                    },
                ],
            },
            {
                featureType: 'road.highway',
                elementType: 'geometry.stroke',
                stylers: [
                    {
                        color: '#e9bc62',
                    },
                ],
            },
            {
                featureType: 'road.highway.controlled_access',
                elementType: 'geometry',
                stylers: [
                    {
                        color: '#e98d58',
                    },
                ],
            },
            {
                featureType: 'road.highway.controlled_access',
                elementType: 'geometry.stroke',
                stylers: [
                    {
                        color: '#db8555',
                    },
                ],
            },
            {
                featureType: 'road.local',
                elementType: 'labels.text.fill',
                stylers: [
                    {
                        color: '#806b63',
                    },
                ],
            },
            {
                featureType: 'transit.line',
                elementType: 'geometry',
                stylers: [
                    {
                        color: '#dfd2ae',
                    },
                ],
            },
            {
                featureType: 'transit.line',
                elementType: 'labels.text.fill',
                stylers: [
                    {
                        color: '#8f7d77',
                    },
                ],
            },
            {
                featureType: 'transit.line',
                elementType: 'labels.text.stroke',
                stylers: [
                    {
                        color: '#ebe3cd',
                    },
                ],
            },
            {
                featureType: 'transit.station',
                elementType: 'geometry',
                stylers: [
                    {
                        color: '#dfd2ae',
                    },
                ],
            },
            {
                featureType: 'water',
                elementType: 'geometry.fill',
                stylers: [
                    {
                        color: '#b9d3c2',
                    },
                ],
            },
            {
                featureType: 'water',
                elementType: 'labels.text.fill',
                stylers: [
                    {
                        color: '#92998d',
                    },
                ],
            },
        ];
    }

    mapStyleDark(): Array<google.maps.MapTypeStyle | null> {
        return [
            {
                elementType: 'geometry',
                stylers: [
                    {
                        color: '#242f3e',
                    },
                ],
            },
            {
                elementType: 'labels.text.fill',
                stylers: [
                    {
                        color: '#746855',
                    },
                ],
            },
            {
                elementType: 'labels.text.stroke',
                stylers: [
                    {
                        color: '#242f3e',
                    },
                ],
            },
            {
                featureType: 'administrative.locality',
                elementType: 'labels.text.fill',
                stylers: [
                    {
                        color: '#d59563',
                    },
                ],
            },
            {
                featureType: 'poi',
                elementType: 'labels.text.fill',
                stylers: [
                    {
                        color: '#d59563',
                    },
                ],
            },
            {
                featureType: 'poi.park',
                elementType: 'geometry',
                stylers: [
                    {
                        color: '#263c3f',
                    },
                ],
            },
            {
                featureType: 'poi.park',
                elementType: 'labels.text.fill',
                stylers: [
                    {
                        color: '#6b9a76',
                    },
                ],
            },
            {
                featureType: 'road',
                elementType: 'geometry',
                stylers: [
                    {
                        color: '#38414e',
                    },
                ],
            },
            {
                featureType: 'road',
                elementType: 'geometry.stroke',
                stylers: [
                    {
                        color: '#212a37',
                    },
                ],
            },
            {
                featureType: 'road',
                elementType: 'labels.text.fill',
                stylers: [
                    {
                        color: '#9ca5b3',
                    },
                ],
            },
            {
                featureType: 'road.highway',
                elementType: 'geometry',
                stylers: [
                    {
                        color: '#746855',
                    },
                ],
            },
            {
                featureType: 'road.highway',
                elementType: 'geometry.stroke',
                stylers: [
                    {
                        color: '#1f2835',
                    },
                ],
            },
            {
                featureType: 'road.highway',
                elementType: 'labels.text.fill',
                stylers: [
                    {
                        color: '#f3d19c',
                    },
                ],
            },
            {
                featureType: 'transit',
                elementType: 'geometry',
                stylers: [
                    {
                        color: '#2f3948',
                    },
                ],
            },
            {
                featureType: 'transit.station',
                elementType: 'labels.text.fill',
                stylers: [
                    {
                        color: '#d59563',
                    },
                ],
            },
            {
                featureType: 'water',
                elementType: 'geometry',
                stylers: [
                    {
                        color: '#17263c',
                    },
                ],
            },
            {
                featureType: 'water',
                elementType: 'labels.text.fill',
                stylers: [
                    {
                        color: '#515c6d',
                    },
                ],
            },
            {
                featureType: 'water',
                elementType: 'labels.text.stroke',
                stylers: [
                    {
                        color: '#17263c',
                    },
                ],
            },
        ];
    }
}

export const mapService = app.service(
    resources.mapService,
    [resources.instances, resources.assetService, resources.configService],
    MapService,
);
