import { Instance, Objekt, Promize, Table } from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { ItemService } from '../services/itemService';
import { DashboardController } from './dashboardController';

export class ItemsIndexByUserController extends DashboardController {
    userId: string;
    itemsTable: Table;

    constructor(
        instances: Instance,
        private itemService: ItemService,
    ) {
        super(instances);
    }

    protected override _initLayout(): void {
        this.userId = this.state.getParam('userId');

        this._initToolbarButtons();

        this._drawContent();
    }

    protected override _drawContent(): Promize | boolean {
        this._initItemTable();
        return true;
    }

    private _initToolbarButtons(): void {
        this.helper.iconButton(
            '.btn-users-home',
            this.dom,
            () => {
                this.state.goBack('users.home');
            },
            '',
            true,
            [],
        );
    }

    private _initItemTable(): void {
        if (!this.itemsTable) {
            this.itemsTable = new Table(
                this.dom,
                '.items-table',
                this.itemService.getTableOptions(),
            );

            this.itemsTable.eventAction = (params) => {
                params.set('fields', this.itemService.getTableFields());
                this.itemService
                    .getAllByUser(this.userId, params)
                    .then((response) => {
                        const count = response.get<number>('count');
                        this.itemsTable.setCount(count);
                        const items = response.get<Objekt[]>('items');
                        this.itemsTable.setData(items);
                    });
            };
            this.itemsTable.render();
        } else {
            this.itemsTable.refresh();
        }
    }
}

export const itemsIndexByUserController = app.controller(
    resources.itemsIndexByUserController,
    [resources.instances, resources.itemService],
    ItemsIndexByUserController,
);
