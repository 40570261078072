import {
    Deferred,
    EventBus,
    Instance,
    Script,
    Service,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { ConfigService } from './configService';
import { LanguageService } from './languageService';

export class GoogleAnalytics4Service extends Service {
    script: Script;
    eventBus: EventBus;

    constructor(
        instances: Instance,
        private languageService: LanguageService,
        private configService: ConfigService,
    ) {
        super();
        this.script = instances.script;
        this.eventBus = instances.eventBus;
    }

    enter(): void {
        this.eventBus.set('state.change', (state) => {
            const url = state.get('url');
            this.sendPageView(url);

            const title = state.get('title');
            const screenName = this.languageService.translate(title);
            this.sendScreenView(screenName);
        });

        this.eventBus.set('module.serviceLoaded', () => {
            const value = Math.round(performance.now());
            this.sendTiming('dependencies', 'load', value);
        });

        this.eventBus.set('module.loaded', () => {
            const value = Math.round(performance.now());
            this.sendTiming('modules', 'load', value);
        });

        this._loadSDK();
    }

    private _loadSDK() {
        const deferred = new Deferred();

        window['dataLayer'] = window['dataLayer'] || [];
        window['gtag'] = (...args) => {
            window['dataLayer'].push(args);
        };
        window['gtag']('js', new Date());
        window['gtag']('consent', 'default', {
            ad_storage: 'granted',
            analytics_storage: 'granted',
            personalization_storage: 'granted',
            functionality_storage: 'granted',
            security_storage: 'granted',
        });

        this.script
            .load(
                'google-analytics-4',
                'https://www.googletagmanager.com/gtag/js',
                {
                    id: this.configService.get('google.ga4_measurement_id'),
                },
            )
            .then(
                () => {
                    const appName = this.configService.get('app.name');
                    const trackingId = this.configService.get(
                        'google.ga4_measurement_id',
                    );

                    window['gtag']('config', trackingId, {
                        app_name: appName,
                        send_page_view: false,
                    });
                    deferred.resolve();
                },
                () => {
                    // If bocked by AdBlocker
                    deferred.reject();
                },
            );
        return deferred.promise();
    }

    sendPageView(url: string): void {
        if (window['gtag']) {
            window['gtag']('event', 'page_view', {
                // 'page_title': '<Page Title>',
                page_location: url,
            });
        }
    }

    sendScreenView(screenName: string): void {
        if (window['gtag']) {
            window['gtag']('event', 'screen_view', {
                screenName: screenName,
            });
        }
    }

    sendException(
        description: string,
        opt_isFatal: boolean | undefined = false,
    ): void {
        if (window['gtag']) {
            window['gtag']('event', 'exception', {
                description: description,
                fatal: opt_isFatal,
            });
        }
    }

    sendEvent(
        category: string,
        action: string,
        opt_label: string | undefined,
        opt_value: number | undefined,
    ): void {
        if (window['gtag']) {
            window['gtag']('event', action, {
                event_category: category,
                event_label: opt_label,
                value: opt_value,
            });
        }
    }

    sendSocial(network: string, action: string, target: string): void {
        if (window['gtag']) {
            window['gtag']('event', 'auth', {
                event_category: network,
                event_label: action,
            });
        }
    }

    sendTiming(
        category: string,
        variable: string,
        value: number,
        opt_label?: string,
    ): void {
        if (window['gtag']) {
            window['gtag']('event', 'timing_complete', {
                name: variable,
                value: value,
                event_category: category,
                event_label: opt_label,
            });
        }
    }

    purchaseItem(
        transactionId: string,
        id: string,
        name: string,
        opt_category: string | undefined,
        opt_price: string | undefined,
        opt_currency: string | undefined,
    ): void {
        if (window['gtag']) {
            window['gtag']('event', 'purchase', {
                transaction_id: transactionId,
                value: opt_price,
                currency: opt_currency,
                items: [
                    {
                        id,
                        name,
                        category: opt_category,
                        price: opt_price,
                        quantity: 1,
                    },
                ],
            });
        }
    }
}

export const googleAnalytics4Service = app.service(
    resources.googleAnalytics4Service,
    [resources.instances, resources.languageService, resources.configService],
    GoogleAnalytics4Service,
);
