import { Depot, Instance, Objekt, Promize, Table } from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { IndividualHuntService } from '../services/individualHuntService';
import { ReportService } from '../services/reportService';
import { DashboardController } from './dashboardController';

export class ReportsBuildingsWhoTheyHuntController extends DashboardController {
    localDepot: Depot;
    individualHuntsTable: Table;

    constructor(
        instances: Instance,
        private reportService: ReportService,
        private individualHuntService: IndividualHuntService,
    ) {
        super(instances);

        this.localDepot = instances.localDepot;
    }

    protected override _initLayout(): void {
        this._initToolbarButtons();

        this._drawContent();
    }

    protected override _drawContent(): Promize | boolean {
        this._initHuntTable();
        return true;
    }

    private _initToolbarButtons(): void {
        this.helper.iconButton(
            '.btn-reports-index',
            this.dom,
            () => {
                this.state.goBack('reports.index');
            },
            '',
            true,
            [],
        );

        this.helper.iconButton('.btn-reports-filter', this.dom, () => {
            this.reportService.detailsDialog('buildings');
        });
    }

    private _initHuntTable(): void {
        if (!this.individualHuntsTable) {
            this.individualHuntsTable = new Table(
                this.dom,
                '.individual-hunts-table',
                this.individualHuntService.getTableOptions(),
            );

            this.individualHuntsTable.eventAction = (params) => {
                params.set(
                    'fields',
                    this.individualHuntService.getTableFields(),
                );
                const buildingsReport = new Objekt(
                    this.localDepot.get(
                        this.reportService.getStorageKeyReport('buildings'),
                    ),
                );
                this.reportService
                    .getAllByBuildings(buildingsReport, params)
                    .then((response) => {
                        const count = response.get<number>('count');
                        this.individualHuntsTable.setCount(count);
                        const hunts = response.get<Objekt[]>('hunts');
                        this.individualHuntsTable.setData(hunts);
                    });
            };

            this.individualHuntsTable.render();
        } else {
            this.individualHuntsTable.refresh();
        }
    }
}

export const reportsBuildingsWhoTheyHuntController = app.controller(
    resources.reportsBuildingsWhoTheyHuntController,
    [
        resources.instances,
        resources.reportService,
        resources.individualHuntService,
    ],
    ReportsBuildingsWhoTheyHuntController,
);
