import { Instance, Knot, TableCalculation } from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { AdminService } from './adminService';
import { AssetService } from './assetService';
import { LanguageService } from './languageService';

export class AdminUserService extends AdminService {
    constructor(
        instances: Instance,
        $languageService: LanguageService,
        $assetService: AssetService,
    ) {
        super('user-users-name', instances, $languageService, $assetService);
    }

    getTableOptions(): object {
        return {
            no_content: {
                image_url: this.assetService.getPath(
                    'images/others/no-content.png',
                ),
                text: this.languageService.translate('text.no_content'),
            },
            sort: {
                column: 'is_valid',
                order: 'asc',
            },
            row_count: 12,
            column: 'name',
            columns: [
                'avatar',
                'name',
                'email',
                'gender_text',
                'is_valid',
                'is_banned',
                'actions',
            ],
            sorted: ['name:asc', 'email:asc', 'is_valid:asc', 'is_banned:asc'],
            calculations: this.getTableCalculations(),
        };
    }

    getTableFields(): string {
        return 'id,avatar,name,email,gender_text,is_valid,is_banned';
    }

    getTableCalculations(): TableCalculation {
        return {
            avatar: (user) => {
                const imageWrapper = new Knot('div');
                imageWrapper.addClass('img-wrapper');
                const imageKnot = new Knot('img');
                imageKnot.addClass('img-responsive');
                imageKnot.setAttribute('src', user.get('avatar.thumb.url'));
                imageWrapper.appendChild(imageKnot);
                return imageWrapper;
            },
            is_valid: (user) => {
                const isValid = user.get('is_valid');
                const iconKnot = new Knot('em');
                iconKnot.addClass(['material-icons', 'text-success']);
                iconKnot.setHtml('check_circle');
                return isValid ? iconKnot : '';
            },
            is_banned: (user) => {
                const isValid = user.get('is_banned');
                const iconKnot = new Knot('em');
                iconKnot.addClass(['material-icons', 'text-error']);
                iconKnot.setHtml('do_disturb');
                return isValid ? iconKnot : '';
            },
        };
    }
}

export const adminUserService = app.service(
    resources.adminUserService,
    [resources.instances, resources.languageService, resources.assetService],
    AdminUserService,
);
