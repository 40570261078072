import { Form, Instance, Objekt, SelectField } from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { AssetService } from './assetService';
import { LanguageService } from './languageService';
import { ModelService } from './modelService';
import { OpenSeasonService } from './openSeasonService';

export class PlanService extends ModelService {
    constructor(
        instances: Instance,
        $languageService: LanguageService,
        $assetService: AssetService,
        private openSeasonService: OpenSeasonService,
    ) {
        super(
            'plan-plans-full_name',
            instances,
            $languageService,
            $assetService,
        );
    }

    getAllByOrganization(organizationId: string, opt_params?: object) {
        return this.getAllBy('organizations', organizationId, opt_params);
    }

    createByOrganization(organizationId: string, plan: Objekt) {
        return this.createBy('organizations', organizationId, plan);
    }

    handleForm(form: Form, opt_entityName?: string): void {
        const openSeasonsField = form.findByModel<SelectField>(
            'plan.open_season_id',
        );
        const organizationField = form.findByModel<SelectField>(
            'plan.organization_id',
        );
        organizationField.eventChange = (organizationId) => {
            if (organizationId) {
                openSeasonsField.showLoader();
                this.openSeasonService
                    .getAllByOrganization(organizationId, {
                        fields: 'id,name',
                    })
                    .then((response) => {
                        const openSeasons =
                            response.get<Objekt[]>('open_seasons');
                        openSeasonsField.setOptions(openSeasons, 'id', 'name');
                    });
            } else {
                openSeasonsField.setOptions([]);
            }
        };
    }
}

export const planService = app.service(
    resources.planService,
    [
        resources.instances,
        resources.languageService,
        resources.assetService,
        resources.openSeasonService,
    ],
    PlanService,
);
