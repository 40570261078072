import { Instance, Objekt } from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { AssetService } from './assetService';
import { LanguageService } from './languageService';
import { ModelService } from './modelService';

export class CategoryService extends ModelService {
    constructor(
        instances: Instance,
        $languageService: LanguageService,
        $assetService: AssetService,
    ) {
        super(
            'category-categories-name',
            instances,
            $languageService,
            $assetService,
        );
    }

    getAllByOrganization(organizationId: string, opt_params?: object) {
        return this.getAllBy('organizations', organizationId, opt_params);
    }

    getAllByBudget(budgetId: string, opt_params?: object) {
        return this.getAllBy('budgets', budgetId, opt_params);
    }

    createByOrganization(organizationId: string, category: Objekt) {
        return this.createBy('organizations', organizationId, category);
    }

    getTableOptions(): object {
        return {
            no_content: {
                image_url: this.assetService.getPath(
                    'images/others/no-content.png',
                ),
                text: this.languageService.translate('text.no_content'),
            },
            sort: {
                column: 'name',
                order: 'asc',
            },
            row_count: 12,
            columns: ['name', 'type_text', 'actions'],
            calculations: this.getTableCalculations(),
        };
    }

    getTableFields(): string {
        return 'id,name,type_text';
    }
}

export const categoryService = app.service(
    resources.categoryService,
    [resources.instances, resources.languageService, resources.assetService],
    CategoryService,
);
