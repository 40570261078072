import { format, Instance, Objekt } from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { AssetService } from './assetService';
import { LanguageService } from './languageService';
import { PersonService } from './personService';

export class MemberService extends PersonService {
    constructor(
        instances: Instance,
        $languageService: LanguageService,
        $assetService: AssetService,
    ) {
        super(
            'member-members-name',
            instances,
            $languageService,
            $assetService,
        );
    }

    downgrade(member: Objekt) {
        const memberId = member.get('id');
        return this.http.post(
            format('/api/v1/members/{0}/downgrade.json', [memberId]),
            member,
        );
    }

    exportList(organizationId: string, opt_params?: object) {
        return this.http.get(
            format('/api/v1/organizations/{0}/members/export-list.xlsx', [
                organizationId,
            ]),
            opt_params,
            {
                responseType: 'blob',
            },
        );
    }

    exportContact(organizationId: string, opt_params?: object) {
        return this.http.get(
            format('/api/v1/organizations/{0}/members/export-contact.vcf', [
                organizationId,
            ]),
            opt_params,
            {
                responseType: 'blob',
            },
        );
    }
}

export const memberService = app.service(
    resources.memberService,
    [resources.instances, resources.languageService, resources.assetService],
    MemberService,
);
