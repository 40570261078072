import {
    Confirm,
    Deferred,
    Dialog,
    eq,
    Flash,
    Form,
    format,
    Instance,
    Knot,
    Objekt,
    Promize,
    Query,
    Table,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { AppleAuthService } from '../services/appleAuthService';
import { DeviceService } from '../services/deviceService';
import { FacebookService } from '../services/facebookService';
import { GoogleIdentityService } from '../services/googleIdentityService';
import { HunterTicketService } from '../services/hunterTicketService';
import { LanguageService } from '../services/languageService';
import { Oauth2Service } from '../services/oauth2Service';
import { UserService } from '../services/userService';
import { UtilService } from '../services/utilService';
import { DashboardController } from './dashboardController';

export class UsersProfileController extends DashboardController {
    userId: string;
    user: Objekt;
    btnHunterTicketsNewKnot: Knot<HTMLElement>;
    btnAppDownloadKnot: Knot<HTMLElement>;
    userEditForm: Form;
    userRemoveForm: Form;
    hunterTicketsTable: Table;
    devicesTable: Table;
    private flash: Flash;
    private confirm: Confirm;
    private dialog: Dialog;

    constructor(
        instances: Instance,
        private facebookService: FacebookService,
        private googleIdentityService: GoogleIdentityService,
        private appleAuthService: AppleAuthService,
        private oauth2Service: Oauth2Service,
        private deviceService: DeviceService,
        private hunterTicketService: HunterTicketService,
        private userService: UserService,
        private languageService: LanguageService,
        private utilService: UtilService,
    ) {
        super(instances);

        this.flash = instances.flash;
        this.confirm = instances.confirm;
        this.dialog = instances.dialog;
    }

    protected override _initLayout(): void {
        this.userId = this.userService.getUser('id');
        this.user = new Objekt();

        this._initToolbarButtons();
        this._initTabPanel('personal-data');
        this._changeToolbarButtons();

        this._loadUser().then(() => {
            this._initSocials();
            this._drawContent();
        });
    }

    protected override _drawContent(): Promize | boolean {
        this._changeToolbarButtons();

        this._initUserEditForm();
        this._initUserRemoveForm();

        this._initHunterTicketsTable();
        this._initDevicesTable();
        return true;
    }

    private _loadUser(): Promize {
        const deferred = new Deferred();
        this.userService
            .get(this.userId, {
                fields: 'id,name,email,avatar',
            })
            .then(
                (response) => {
                    this.user = response.get('user');
                    deferred.resolve();
                },
                () => {
                    deferred.reject();
                },
            );
        return deferred.promise();
    }

    private _initToolbarButtons(): void {
        this.helper.iconButton(
            '.btn-users-home',
            this.dom,
            () => {
                this.state.goBack('users.home');
            },
            '',
            true,
            [],
        );

        this.btnHunterTicketsNewKnot = this.helper.iconButton(
            '.btn-hunter-tickets-new',
            this.dom,
            () => {
                this.hunterTicketService
                    .newDialogByUser(this.userId)
                    .then(() => {
                        this._drawContent();
                    });
            },
        );

        this.btnAppDownloadKnot = this.helper.iconButton(
            '.btn-mobile-app-download',
            this.dom,
            () => {
                this._showDownloadDialog();
            },
        );
    }

    private _changeToolbarButtons(): void {
        this.utilService.showButton(
            this.btnHunterTicketsNewKnot,
            this.activeTab === 'hunter-tickets',
        );

        this.utilService.showButton(
            this.btnAppDownloadKnot,
            this.activeTab === 'devices',
        );
    }

    private _initUserEditForm(): void {
        if (eq(this.activeTab, 'personal-data')) {
            if (!this.userEditForm) {
                this.userEditForm = new Form(this.dom, '.edit-user');
                this.userEditForm.eventSubmit = (formData) => {
                    this.userEditForm.lock();
                    const userData = formData.get<Objekt>('user');
                    this.userService.update(userData).then(
                        (response) => {
                            this.userEditForm.setModel(response);
                            this.flash.addMessage(response.get('message'));
                            this.userEditForm.unlock();
                        },
                        (response) => {
                            this.flash.addMessage(response.get('message'));
                            this.userEditForm.unlock();
                            this.userEditForm.setErrors(response.get('errors'));
                        },
                    );
                };
            }
        }
    }

    private _initUserRemoveForm(): void {
        if (eq(this.activeTab, 'personal-data')) {
            if (!this.userRemoveForm) {
                this.userRemoveForm = new Form(this.dom, '.remove-user');
                this.userRemoveForm.eventButton = () => {
                    this.userService.removeConfirm(this.user).then(() => {
                        this.userService.logout();
                    });
                };
            }
        }
    }

    private _initSocials(): void {
        const socialCardKnots = new Query('.social-card', this.dom);
        socialCardKnots.each((socialCardKnot) => {
            const actionKnot = new Query('.action', socialCardKnot).getKnot();
            actionKnot.removeChildren();
            const social = actionKnot.getAttribute('data-social');
            const active = socialCardKnot.hasClass('active');
            let buttonText = this.languageService.translate('buttons.merge');
            if (social !== 'email') {
                buttonText = active
                    ? this.languageService.translate('buttons.disconnect')
                    : this.languageService.translate('buttons.connect');
            }
            const linkKnot = this.helper.createLink(buttonText, () => {
                if (active) {
                    this._removeAccountByType(social);
                } else {
                    this._mergeAccountByType(social);
                }
            });
            actionKnot.appendChild(linkKnot);
        });
    }

    private _mergeAccountByType(type: string): void {
        switch (type) {
            case 'email':
                this.userService.loginDialog().then((user) => {
                    this.userService
                        .loginWithEmail(user, false)
                        .then((response) => {
                            this._mergeUserConfirm(response.get('user'));
                        });
                });
                break;
            case 'facebook':
                this.facebookService.loginDialog().then((accessToken) => {
                    this.userService
                        .loginWithFacebook(accessToken, 'access_token', false)
                        .then((response) => {
                            this._mergeUserConfirm(response.get('user'));
                        });
                });
                break;
            case 'google':
                this.googleIdentityService.loginDialog().then((idToken) => {
                    this.userService
                        .loginWithGoogle(idToken, 'id_token', false)
                        .then((response) => {
                            this._mergeUserConfirm(response.get('user'));
                        });
                });
                break;
            case 'apple':
                this.appleAuthService
                    .loginDialog('id_token')
                    .then((idToken, email, firstName, lastName) => {
                        this.userService
                            .loginWithApple(
                                idToken,
                                email,
                                firstName,
                                lastName,
                                'id_token',
                                false,
                            )
                            .then((response) => {
                                this._mergeUserConfirm(response.get('user'));
                            });
                    });
                break;
            case 'microsoft':
                this.oauth2Service
                    .loginDialog('microsoft')
                    .then((code, redirectUri) => {
                        this.userService
                            .loginWithMicrosoft(code, redirectUri, false)
                            .then((response) => {
                                this._mergeUserConfirm(response.get('user'));
                            });
                    });
                break;
            default:
                break;
        }
    }

    private _mergeUserConfirm(user: Objekt): void {
        this.confirm.load(
            this.languageService.translate('users_controller.merge.confirm', {
                user_name: user.get('name'),
                user_email: user.get('email'),
                current_user_name: this.user.get('name'),
                current_user_email: this.user.get('email'),
            }),
            this.languageService.translate('buttons.merge'),
            this.languageService.translate('buttons.cancel'),
            '',
            'warning',
        );
        this.confirm.eventOK = () => {
            const token = user.get<string>('token');
            this.userService.merge(token).then(
                (response) => {
                    this.flash.addMessage(response.get('message'));
                    this.state.refresh(true);
                },
                (response) => {
                    this.flash.addMessage(response.get('message'));
                },
            );
        };
        this.confirm.open();
    }

    private _removeAccountByType(type: string): void {
        this.confirm.load(
            this.languageService.translate(
                'users_controller.destroy_social.confirm',
                {
                    social: type,
                },
            ),
            this.languageService.translate('buttons.disconnect'),
            this.languageService.translate('buttons.cancel'),
            '',
            'warning',
        );
        this.confirm.eventOK = () => {
            this.userService.removeSocial(type).then(
                (response) => {
                    this.flash.addMessage(response.get('message'));
                    this.state.refresh(true);
                },
                (response) => {
                    this.flash.addMessage(response.get('message'));
                },
            );
        };
        this.confirm.open();
    }

    private _initHunterTicketsTable(): void {
        if (eq(this.activeTab, 'hunter-tickets')) {
            if (!this.hunterTicketsTable) {
                this.hunterTicketsTable = new Table(
                    this.dom,
                    '.hunter-tickets-table',
                    this.hunterTicketService.getTableOptions(),
                );

                this.hunterTicketsTable.setActions([
                    {
                        style: () => {
                            return [
                                'edit',
                                this.languageService.translate('buttons.edit'),
                            ];
                        },
                        click: (hunterTicket) => {
                            this.hunterTicketService
                                .editDialog(hunterTicket)
                                .then(() => {
                                    this._drawContent();
                                });
                        },
                    },
                ]);

                this.hunterTicketsTable.eventAction = (params) => {
                    params.set(
                        'fields',
                        this.hunterTicketService.getTableFields(),
                    );
                    this.hunterTicketService
                        .getAllByUser(this.userId, params)
                        .then((response) => {
                            const count = response.get<number>('count');
                            this.hunterTicketsTable.setCount(count);
                            const hunterTickets =
                                response.get<Objekt[]>('hunter_tickets');
                            this.hunterTicketsTable.setData(hunterTickets);
                        });
                };

                this.hunterTicketsTable.render();
            } else {
                this.hunterTicketsTable.refresh();
            }
        }
    }

    private _initDevicesTable(): void {
        if (eq(this.activeTab, 'devices')) {
            if (!this.devicesTable) {
                this.devicesTable = new Table(
                    this.dom,
                    '.devices-table',
                    this.deviceService.getTableOptions(),
                );

                this.devicesTable.setActions([
                    {
                        style: () => {
                            return [
                                'delete',
                                this.languageService.translate(
                                    'buttons.delete',
                                ),
                            ];
                        },
                        click: (device) => {
                            this.deviceService
                                .removeConfirm(device)
                                .then(() => {
                                    this._drawContent();
                                });
                        },
                    },
                ]);

                this.devicesTable.eventAction = (params) => {
                    params.set('fields', this.deviceService.getTableFields());
                    this.deviceService
                        .getAllByUser(this.userId, params)
                        .then((response) => {
                            const count = response.get<number>('count');
                            this.devicesTable.setCount(count);
                            const devices = response.get<Objekt[]>('devices');
                            this.devicesTable.setData(devices);
                        });
                };

                this.devicesTable.render();
            } else {
                this.devicesTable.refresh();
            }
        }
    }

    private _showDownloadDialog(): void {
        this.dialog
            .loadTemplate(
                format('/client/v1/users/{0}/devices/download.html', [
                    this.userId,
                ]),
            )
            .then((dialogKnot) => {
                this.dialog.open();
            });
    }

    enter(): void {
        super.enter();

        this.facebookService.loadSDK();
        this.googleIdentityService.loadSDK();
        this.appleAuthService.loadSDK();
    }

    exit(): void {
        super.exit();

        this.facebookService.reset();
        this.googleIdentityService.reset();
        this.appleAuthService.reset();
    }
}

export const usersProfileController = app.controller(
    resources.usersProfileController,
    [
        resources.instances,
        resources.facebookService,
        resources.googleIdentityService,
        resources.appleAuthService,
        resources.oauth2Service,
        resources.deviceService,
        resources.hunterTicketService,
        resources.userService,
        resources.languageService,
        resources.utilService,
    ],
    UsersProfileController,
);
