import {
    Depot,
    EventBus,
    Form,
    IconToggleField,
    Instance,
    LeftMenu,
    Navigation,
    Objekt,
    Query,
    Screen,
    Service,
    State,
    SwitchField,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';
import { ConfigService } from './configService';
import { LanguageService } from './languageService';
import { MenuService } from './menuService';

export class LeftMenuService extends Service {
    leftMenu: LeftMenu;
    state: State;
    eventBus: EventBus;
    screen: Screen;
    localDepot: Depot;
    leftMenuNavigation: Navigation;
    settingKey = 'settings';
    settings: Objekt;
    settingsForm: Form;

    constructor(
        instances: Instance,
        private menuService: MenuService,
        private languageService: LanguageService,
        private configService: ConfigService,
    ) {
        super();

        this.leftMenu = instances.leftMenu;
        this.state = instances.state;
        this.eventBus = instances.eventBus;
        this.screen = instances.screen;
        this.localDepot = instances.localDepot;
    }

    enter(): void {
        this._setLeftMenuNavigation();
        this._setSettingsForm();
    }

    private _setLeftMenuNavigation(): void {
        this.leftMenuNavigation = this.menuService.getMenuItems(true);

        this.eventBus.set('state.change', (state) => {
            this.leftMenuNavigation.setActive(state.get('id'));
        });

        this.leftMenuNavigation.bindToContainer(
            this.leftMenu.getMainContainer(),
        );
    }

    private _setSettingsForm(): void {
        const colorScheme = this.screen.isColorScheme('dark')
            ? 'dark'
            : 'light';
        this.settings = new Objekt(
            this.localDepot.get(this.settingKey) || {
                auto_color_scheme: true,
                color_scheme: colorScheme,
            },
        );
        if (this.settings.get<boolean>('auto_color_scheme')) {
            this.settings.set('color_scheme', colorScheme);
        }

        this.configService.set(
            'colorScheme',
            this.settings.get('color_scheme'),
        );

        const darkModeCssClass = 'dark-theme';
        const bodyKnot = new Query('body').getKnot();
        const setColorSchemeLabel = (colorScheme) => {
            if (colorScheme === 'dark') {
                colorSchemeField.setLabel(
                    this.languageService.translate('text.dark_mode'),
                );
            } else {
                colorSchemeField.setLabel(
                    this.languageService.translate('text.light_mode'),
                );
            }
        };

        this.settingsForm = new Form(this.leftMenu.getSubContainer());

        const colorSchemeField =
            this.settingsForm.findByModel<IconToggleField>('color_scheme');
        const autoColorSchemeField =
            this.settingsForm.findByModel<SwitchField>('auto_color_scheme');

        autoColorSchemeField.eventChange = (autoColorScheme) => {
            if (autoColorScheme) {
                colorSchemeField.setDisabled(true);
            } else {
                colorSchemeField.setDisabled(false);
            }

            const currentColorScheme = this.screen.isColorScheme('dark')
                ? 'dark'
                : 'light';
            colorSchemeField.setValue(currentColorScheme);

            this._saveSettingsForm();
        };

        colorSchemeField.eventChange = (colorScheme) => {
            setColorSchemeLabel(colorScheme);

            if (colorScheme === 'dark') {
                bodyKnot.addClass(darkModeCssClass);
            } else {
                bodyKnot.removeClass(darkModeCssClass);
            }

            this._saveSettingsForm();
        };

        const currentAutoColorScheme = this.settings.get('auto_color_scheme');
        autoColorSchemeField.setValue(currentAutoColorScheme);

        const currentColorScheme = this.settings.get('color_scheme');
        setColorSchemeLabel(currentColorScheme);
        colorSchemeField.setValue(currentColorScheme);

        this.eventBus.set('window.colorSchemeChange', (colorScheme) => {
            if (autoColorSchemeField.getValue()) {
                colorSchemeField.setValue(colorScheme);
            }
        });
    }

    private _saveSettingsForm(): void {
        const model = this.settingsForm.getModel();
        this.localDepot.set(this.settingKey, model);

        const colorScheme = model.get('color_scheme', 'light');
        this.configService.set('colorScheme', colorScheme);
        this.eventBus.call('app.colorSchemeChange', [colorScheme]);
    }
}

export const leftMenuService = app.service(
    resources.leftMenuService,
    [
        resources.instances,
        resources.menuService,
        resources.languageService,
        resources.configService,
    ],
    LeftMenuService,
);
