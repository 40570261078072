import {
    Controller,
    Helper,
    Instance,
    Knot,
    Query,
    State,
} from '@siposdani87/sui-js';
import { app } from '../app';
import { resources } from '../resources';

export class PeopleIndexController extends Controller {
    organizationId: string;
    private helper: Helper;
    private state: State;
    private dom: Knot;

    constructor(instances: Instance) {
        super();

        this.helper = instances.helper;
        this.state = instances.state;
        this.dom = instances.dom;
    }

    enter(): void {
        this._initLayout();
    }

    protected _initLayout(): void {
        this.organizationId = this.state.getParam('organizationId');

        this._initToolbarButtons();
        this._initMenu();
    }

    private _initToolbarButtons(): void {
        this.helper.iconButton(
            '.btn-organizations-show',
            this.dom,
            () => {
                this.state.goBack('organizations.show', {
                    organizationId: this.organizationId,
                });
            },
            '',
            true,
            [],
        );
    }

    private _initMenu(): void {
        const contentNodes = new Query('a', this.dom);
        contentNodes.each((node) => {
            this.helper.linkElement(
                node,
                (href) => {
                    this.state.go(href);
                },
                this.state.resolveUrl(node.getAttribute('data-state-id'), {
                    organizationId: this.organizationId,
                }),
            );
        });
    }
}

export const peopleIndexController = app.controller(
    resources.peopleIndexController,
    [resources.instances],
    PeopleIndexController,
);
